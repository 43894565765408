import { createSelector } from 'reselect';

const selectReportDomain = state => state.report;


const makeSelectRevenueForcasting = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('revenueForcasting') : []
  );

const makeSelectRevenueForcastingLoading = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('isRevenueForcastingLoading') : false
  );

const makeSelectRevenueForcastingError = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('revenueForcastingError') : null
  );


const makeSelectReportList = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('reportList') : []
  );

const makeSelectReportListLoading = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('isReportListLoading') : false
  );

const makeSelectReportListError = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('reportListError') : ''
  );

const makeSelectReportPeriodSuccess = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('reportPeriodList') : []
  );

const makeSelectReportPrintSuccess = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('reportPdf') : ''
  );

const makeSelectReportCSVSuccess = () =>
  createSelector(
    selectReportDomain,
    reportState =>
      reportState ? reportState.get('reportCSV') : ''
  );

export {
  makeSelectRevenueForcasting,
  makeSelectRevenueForcastingLoading,
  makeSelectRevenueForcastingError,
  makeSelectReportList,
  makeSelectReportListLoading,
  makeSelectReportListError,
  makeSelectReportPeriodSuccess,
  makeSelectReportPrintSuccess,
  makeSelectReportCSVSuccess
}
