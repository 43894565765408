import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
// import PropTypes from 'prop-types';
import './pages.css';
// import Cookies from 'js-cookie';
import "@ui5/webcomponents-icons/dist/log";
// import { saveLoginUser } from '../../store/action/authAction';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        
        return null
    }

    componentDidMount (){
  }

    componentDidUpdate (prevProps, prevState){
    }

    render() {

        return (
          <div className='dashboard-center'>
            Welcome to WELAWA
          </div>
        )
    }
}

Dashboard.propTypes = {

  }
  
  const mapStateToProps = createStructuredSelector({
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  
  
  export default (compose(withConnect)(Dashboard));
