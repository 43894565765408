import { 
    SAVE_LOGIN_USER_STATUS, 
    SAVE_USER_EXPIRE_SUCCESS,
    SEND_ACTIVE_USER_REQUEST,
    SEND_ACTIVE_USER_SUCCESS,
  } from '../constant/authConstant';
  
  export function saveLoginUser(open){
    return {
      type: SAVE_LOGIN_USER_STATUS,
      open,
    }
  };

  export function saveUserExpire(data){
    return {
      type: SAVE_USER_EXPIRE_SUCCESS,
      payload: data,
    }
  };

  export function sendUserActiveRequest(data){
    return {
      type: SEND_ACTIVE_USER_REQUEST,
      payload: data,
    }
  };

  export function sendUserActiveSuccess(data){
    return {
      type: SEND_ACTIVE_USER_SUCCESS,
      payload: data,
    }
  };
  