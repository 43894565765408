import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../settingMenu.css';
import * as selectors from '../../../../store/selector/usersSelector';
import * as actions from '../../../../store/action/usersAction';
import Table from '../../../layout/form/Table';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import AddNewUser from './AddNew';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import Loader from '../../../layout/form/Loader';
import { loadObjectStatusRequest, loadObjectStatusSuccess, saveCommonSearchSuccess } from '../../../../store/action/action';
import common from '../../../common';
import { makeSelectCommonSearchData, makeSelectObjectStatusList } from '../../../../store/selector/selector';
import SelectField from '../../../layout/form/SelectField';
import Pagination from '../../../layout/form/Pagination';
import { makeSelectSelectedEmployeeSuccess } from '../../../../store/selector/employeeSelector';
import { loadUserRoleListRequest } from '../../../../store/action/userRoleAction';
import { makeUserRoleListSuccess } from '../../../../store/selector/userRoleSelector';

class Users extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            isAddNewUsersModalOpen:false, // TODO
            isFilterFormOpen: false,
            isValidationError:false,
            errorMassage:'',
            pageNumber:1,
            pageSize:20,
            userId:'',
            name:'',
            email:'',
            phoneNumber:'',
            status:'',
            selectedRow:{},
            isUpdate: false,
            isNewUser: false
        };

      };

    handleModal = (data) => {
        if(data && data.id){
            this.props.dispatch(actions.loadSelectedUserRequest(data.id))
            this.setState({selectedRow:data, isUpdate: true})
        }else{
            this.setState({isNewUser:true})
        }
        this.props.dispatch(actions.saveIsUsersModalOpenStatus(true));
    }

    handleModalClose = () => {
        this.setState({selectedRow:{}, isUpdate:false, isNewUser: false})
        this.props.dispatch(actions.saveIsUsersModalOpenStatus(false));
        this.props.dispatch(actions.loadSelectedUserSuccess({}))
        this.getUserListData()
    }

    handleSaveAddNew = (details) => {
        const { selectedUser } = this.props;
        if(
            details.firstName === ''|| 
            details.lastName === ''|| 
            details.phoneNumber === ''|| 
            details.email === '' ){
            this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
            return
        }

        if(details.userRoleList.length === 0){
            this.setState({isValidationError:true, errorMassage:common.massages.requiredUserRoleMassage})
            return
        }

        if(this.state.isUpdate && details.userId ===''){
            this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
            return
        }
        const tempItemList = details.tempUserRoleList.filter(data => (data.operation === "D"))
        const list = details.userRoleList.concat(tempItemList)

        const parameters = {
            id: selectedUser.id,
            UserId: details.userId,
            FirstName: details.firstName,
            LastName: details.lastName,
            MobileNo: details.phoneNumber,
            Email: details.email,
            UserRole: details.userRole ,
            Notes: details.notes === '' ? null: details.notes,
            Status: details.status === '' ? null: details.status,
            logo: details.logo,
            UserRoles: list,
            employee: details.employee
        }
        if(this.state.isUpdate){
            this.props.dispatch(actions.sendUserUpdateRequest(parameters))
        }else{
            this.props.dispatch(actions.sendAddNewUserRequest(parameters))}
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.sendAddNewUserError(''))
    }

    successModalClose = () => {
        // this.getUserListData()
        this.props.dispatch(actions.sendAddNewUserSuccess(''))
        this.handleModalClose()
        // this.props.dispatch(actions.saveIsUsersModalOpenStatus(false));
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleChangeSelect = (value, id) => {
        this.setState({
          [id]: value
        })
    }

    handleFilter = () =>{
        this.getUserListData()
        this.handleFilterForm()
    }

    handleFilterFormClose = () =>{
        this.setState({
            isFilterFormOpen:false,
            userId:'',
            name:'',
            email:'',
            phoneNumber:'',
            status:'',
            pageSize:20,
            pageNumber:1,
        })

        const parameters = {
            pageSize:20,
            pageNumber:1,
            userId:'',
            name:'',
            email:'',
            phoneNumber:'',
            status:''
        }
        this.props.dispatch(actions.loadUserListRequest(parameters))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen:!this.state.isFilterFormOpen,
            userId:this.state.userId,
            name:this.state.name,
            email:this.state.email,
            phoneNumber:this.state.phoneNumber,
            status:this.state.status
        })
    }

    setStatusDescription= (data) => {
        const { objectStatusList } = this.props;
        if(objectStatusList && objectStatusList.length && objectStatusList.length !== 0){
          const desc = objectStatusList.find(select =>  select.id === data.status)
          return (desc && desc.description ? desc.description : '')
        }
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    handleStatus = (data, type) =>{
        const { objectStatusList } = this.props;
        var element = ''
        var parameters = {}
        if(objectStatusList && objectStatusList.length && objectStatusList.length !== 0){
            element = objectStatusList.find(select =>  select.id === data.status)
        }
        if(element.description === type){
            this.setState({isValidationError:true, errorMassage:'Already User ' + type})
            return
        }
        if(type === "Active"){
            parameters = {
                userId: data.userId,
                status: common.userStatus.active,
                id: data.id
            }
        }else if (type === "Inactive"){
            parameters = {
                userId: data.userId,
                status: common.userStatus.inActive,
                id: data.id
            }
        }
        this.props.dispatch(actions.sendUserStatusUpdateRequest(parameters))
    }

    getUserListData = () => {
        var user = null
        if(this.props.commonSearchData && this.props.commonSearchData.component && this.props.commonSearchData.component ==='employee'){
            user  = this.props.commonSearchData.id
            this.setState({
                userId:user
            })
        }
        this.props.dispatch(saveCommonSearchSuccess({}))
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            userId: user === null ?  this.state.userId : user,
            name:this.state.name,
            email:this.state.email,
            phoneNumber:this.state.phoneNumber,
            status:this.state.status
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber:1,
                pageSize:20
            })
        }
        this.props.dispatch(actions.loadUserListRequest(parameters))
    }

    addTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Add new User
        </Tooltip>
    );

    filterTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Filter
        </Tooltip>
    );

    componentDidMount (){
        const parameters = {
            pageSize: 9999,
            pageNumber: 1,
            commonSearch: ''
        }
        this.props.dispatch(loadObjectStatusSuccess([]))
        this.getUserListData()
        this.props.dispatch(loadObjectStatusRequest(common.objectId.user));
        this.props.dispatch(loadUserRoleListRequest(parameters))
    }

    componentDidUpdate (prevProps, prevState){
        if (this.props.success !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }

        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getUserListData()
        }
    }

    render() {

        const { 
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isUpdate,
            selectedRow,
            isNewUser
         } = this.state;
        const { 
            isAddNewUsersModalOpen, 
            error, 
            success,
            userList,
            loading,
            objectStatusList,
            selectedUser,
            roleList,
            userAccess,
            selectedEmployee
        } = this.props;

        const columns = [ {
            Header: 'User Id',
            accessor:'userId',
            width: "10%",
          },{
            Header: 'Name',
            accessor: 'firstName' 
          }, {
            Header: 'Phone Number',
            accessor:'mobileNo',
            width: "10%",
          }, {
            Header: 'Email',
            accessor: 'email',
          }, {
            Header: 'Status',
            Cell:((row)=>(
              <div>
                {this.setStatusDescription(row.row.original)}
              </div>
            )),
            width: "10%",
          }, {
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={() =>this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>    
                      <span className="dropdown" style={{float:'right'}}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                <li onClick={userAccess && userAccess.modifyUser ? ()=>this.handleStatus(row.row.original,"Active") : ''}>Active</li>
                                <li onClick={userAccess && userAccess.modifyUser ? ()=>this.handleStatus(row.row.original,"Inactive") : ''}>InActive</li>
                            </div>
                        </span> 
                    </span>}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {(loading || 
                        (userList.result && userList.result.length && userList.result.length === 0)) ? 
                        <Loader show={loading}/> :''}

                {(isValidationError || error !== '') && 
                    <ErrorModal
                        show={true} 
                        massage={error === ''  ? errorMassage : error}
                        handleClose={this.errorModalClose}
                    />}
                {success !== '' && 
                    <SuccessModal
                        show={true} 
                        massage={success}
                        handleClose={this.successModalClose}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <ui5-icon class="samples-margin" name="person-placeholder" id="header-icon"></ui5-icon><span>USERS</span>
                    </div>
                </div>
                <div className="content-body">
                    <div style={{display:'flex', justifyContent:'space-between', margin:'5px 0'}}>
                        <div style={{display:'flex'}}>
                            <div>
                                <h5 style={{marginLeft:'5px'}}>User List</h5>
                            </div>
                        </div>
                        <div className="header-btn">
                            <div style={{display:'flex'}}>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.addTooltip}
                                >
                                    <span className={userAccess && userAccess.createUser ? "header-icon" : "header-icon-disabled"} onClick={userAccess && userAccess.createUser ? () => this.handleModal(false) : ''}>
                                        <ui5-icon class="samples-margin" name="add" id="header-icon"></ui5-icon>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.filterTooltip}
                                >
                                    <span className="header-icon"  onClick={() => this.handleFilterForm()}>
                                        <ui5-icon class="samples-margin" name="filter" id="header-icon"></ui5-icon>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    {isFilterFormOpen && 
                        <div className="filter-form">
                            <div className="filter-header">
                                <h5>Filter Form</h5>
                                <div className="btn-close" onClick={this.handleFilterFormClose} >
                                    <ui5-icon class="samples-margin" name="decline" id="btn-icon"></ui5-icon>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col-sm-2">
                                        <TextField                                            
                                            text="User Id"
                                            controlId="userId"
                                            placeholder="User Id"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.userId}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField                                            
                                            text="Name"
                                            controlId="name"
                                            placeholder="Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.name}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Email Address"
                                            controlId="email"
                                            placeholder="Email Address"
                                            type="email"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.email}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                            <TextField                                            
                                                text="Phone Number"
                                                controlId="phoneNumber"
                                                placeholder="Phone Number"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.phoneNumber}
                                            />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            onChange={(e)=>this.handleChangeSelect(e,'status')}
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            isClearable
                                        />
                                    </div>   
                                </div>
                            <div className="filter-btns">
                                <ButtonGroup
                                    primaryBtnClick={this.handleFilter}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary" 
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary" 
                                />
                            </div>
                        </div>
                        }
                    <div className="table-content">
                        <Table
                            columns={columns}
                            data={userList && userList.result && userList.result.length ? userList.result : [] }                   
                        />
                    </div>
                    <Pagination
                        currentPage={userList.page ? userList.page :this.state.pageNumber}
                        getNextPage={this.getNextPage}
                        getPrevPage={this.getPrevPage}
                        totalPage = {userList.totalPages}
                        setClickedPage= {this.setClickedPage}
                        currentPageSize={this.state.pageSize}
                        setPageSize={this.setPageSize}
                    />
                </div>
                {isAddNewUsersModalOpen && 
                    <AddNewUser
                        isAddNewUsersModalOpen={isAddNewUsersModalOpen}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                        selectedUser={selectedUser}
                        isUpdate={isUpdate}
                        selectedRow={selectedRow}
                        objectStatusList={objectStatusList}
                        roleList={roleList.result}
                        modifyUser={userAccess && userAccess.modifyUser}
                        isNewUser={isNewUser}
                        selectedEmployee={selectedEmployee}
                    />
                }
            </div>
        )
    }
}


Users.propTypes = {
    isAddNewUsersModalOpen: PropTypes.bool,
    error: PropTypes.any,
    success: PropTypes.any,
    userList: PropTypes.any,
    loading: PropTypes.bool,
    objectStatusList: PropTypes.any,
    selectedUser: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isAddNewUsersModalOpen: selectors.makeSelectUsersPopupOpen(),
    error: selectors.makeSelectAddNewUserError(),
    success: selectors.makeSelectAddNewUserSuccess(),
    userList: selectors.makeSelectUserListSuccess(),
    loading: selectors.makeSelectUserListLoading(),
    objectStatusList:makeSelectObjectStatusList(),
    selectedUser: selectors.makeSelectSelectedUserSuccess(),
    roleList: makeUserRoleListSuccess(),
    userAccess: selectors.makeSelectUserAccessSuccess(),
    selectedEmployee: makeSelectSelectedEmployeeSuccess(),
    commonSearchData: makeSelectCommonSearchData(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(Users));