import { createSelector } from 'reselect';

const selectUserRoleDomain = state => state.userRoles;

const makeSelectUserRolePopupOpen = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('isAddNewUserRoleModalOpen') : false
  );

const makeSelectUserRoleError = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('error') : null
  );

const makeSelectUserRoleLoading = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('loading') : false
  );

const makeUserRoleListSuccess = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('roleList') : []
  );

const makeObjectListSuccess = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('objectList') : []
  );

const makeObjectOperationListSuccess = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('objectOperationList') : []
  );


const makeUserRoleObjectListSuccess = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('roleObjectList') : []
  );

const makeSelectUserRoleSuccess = () =>
  createSelector(
    selectUserRoleDomain,
    userRoleState =>
      userRoleState ? userRoleState.get('success') : null
  );


export {
  makeSelectUserRolePopupOpen,
  makeSelectUserRoleError,
  makeUserRoleListSuccess,
  makeObjectListSuccess,
  makeObjectOperationListSuccess,
  makeSelectUserRoleLoading,
  makeUserRoleObjectListSuccess,
  makeSelectUserRoleSuccess
}
