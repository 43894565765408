import React from 'react';
import PropTypes from 'prop-types';
import './form.css';

const Loader = ({show}) => {

  return (
      <div>
        <div id="cover-spin"></div>
      </div>
  )
}

Loader.propTypes = {
  show:PropTypes.bool
}

export default Loader;