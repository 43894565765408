import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getUserRoleRequest(parameters) {
    return axios.get(baseURL + 'roles?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&commonSearch='+parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getUserRoleObjectRequest(id) {
    return axios.get(baseURL + 'roles/objects/'+ id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function addNewUserRoleRequest(data){
    const parameters = JSON.stringify(data)
      return axios.post(baseURL+'roles', parameters, {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : sessionStorage.getItem("accessToken")
          }
      })
}

export function updateUserRoleRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'roles/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteUserRoleRequest(id){
    return axios.delete(baseURL+'roles/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getObjectList() {
    return axios.get(baseURL + 'object', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getObjectOperationList(parameters) {
    return axios.get(baseURL + 'objectOperation/role?Search=' + parameters.search+'&Id='+parameters.role, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}
