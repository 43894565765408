import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css'
import TextEditor from './TextEditor';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from './ButtonGroup';
import TextField from './TextField';

// const pdfAsDataUri = "data:application/pdf;base64,JVBERi0xLjUK...";

class EmailViewer extends Component {

    render() {
        const {
            show,
            onHide,
            title,
            editorState,
            onEditorStateChange,
            invoiceEmailDetails,
            handleSendEmail,
            handleChangeEmailDetails
        } = this.props
        return (
            <div>
                <BootstrapModal 
                    show={show} 
                    onHide={onHide}
                    animation={false}
                    centered
                    >
                    <div style={{top:'60px',left:'50%', transform: 'translate(-50%, 0)', position:"fixed", backgroundColor:'#fff',width: '90%'}}>
                        <BootstrapModal.Header closeButton>
                            <BootstrapModal.Title style={{fontSize:'1.1rem'}}>{title}</BootstrapModal.Title>
                        </BootstrapModal.Header>
                        <BootstrapModal.Body style={{maxHeight: 'calc(100vh - 200px)', minHeight: 'calc(100vh - 200px)',overflowY: 'auto'}}>
                            <div className='row email-viewer'>
                                <div className='col-sm'>
                                    <TextField
                                        text="Email Subject"
                                        controlId="emailSubject"
                                        defaultValue={invoiceEmailDetails.emailSubject}
                                        onChange={handleChangeEmailDetails}
                                    />
                                </div>
                                <div className='col-sm'>
                                    <TextField
                                        text="To"
                                        controlId="receiverEmail"
                                        defaultValue={invoiceEmailDetails.receiverEmail}
                                        onChange={handleChangeEmailDetails}
                                    />
                                </div>
                                <div className='col-sm'>
                                    <TextField
                                        text="From"
                                        controlId="senderEmail"
                                        defaultValue={invoiceEmailDetails.senderEmail}
                                        // onBlur={this.handleChange}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='email-viewer' >
                                <div className='email-editor'>
                                    <TextEditor
                                        editorState={editorState}
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </div>
                                <div className='email-body' style={{height:500}}>
                                    { invoiceEmailDetails && invoiceEmailDetails.emailPdf && <iframe title={title} src={`data:application/pdf;base64,${invoiceEmailDetails.emailPdf}`} height="100%" width="100%" ></iframe>}
                                </div>
                            </div>
                        </BootstrapModal.Body>
                        <BootstrapModal.Footer>
                            <div>
                                <ButtonGroup
                                    primaryBtnClick={onHide}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Go Back'
                                    primaryBtnIcon='undo'
                                    secondaryBtnClick={handleSendEmail}
                                    secondaryBtnText='Send'
                                    secondaryBtnVariant="outline-primary" 
                                    secondaryBtnIcon='email'
                                />
                            </div>
                        </BootstrapModal.Footer>
                    </div>
                </BootstrapModal>
            </div>
        )
    }

} 

EmailViewer.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    onEditorStateChange: PropTypes.any,
    editorState: PropTypes.any,
    handleSendEmail: PropTypes.any
}

export default EmailViewer;
