const PATH = 'app/EmailConfiguration';

export const LOAD_EMAIL_CLIENT_LIST_REQUEST = `${PATH}LOAD_EMAIL_CLIENT_LIST_REQUEST`;
export const LOAD_EMAIL_CLIENT_LIST_ERROR = `${PATH}LOAD_EMAIL_CLIENT_LIST_ERROR`;
export const LOAD_EMAIL_CLIENT_LIST_LOADING = `${PATH}LOAD_EMAIL_CLIENT_LIST_LOADING`;
export const LOAD_EMAIL_CLIENT_LIST_SUCCESS = `${PATH}LOAD_EMAIL_CLIENT_LIST_SUCCESS`;
export const SEND_ADD_NEW_EMAIL_CLIENT_REQUEST = `${PATH}SEND_ADD_NEW_EMAIL_CLIENT_REQUEST`;
export const SEND_UPDATE_EMAIL_CLIENT_REQUEST = `${PATH}SEND_UPDATE_EMAIL_CLIENT_REQUEST`;
export const SEND_EMAIL_CLIENT_SUCCESS = `${PATH}SEND_EMAIL_CLIENT_SUCCESS`;
export const LOAD_EMAIL_SERVER_LIST_REQUEST = `${PATH}LOAD_EMAIL_SERVER_LIST_REQUEST`;
export const LOAD_EMAIL_SERVER_LIST_SUCCESS = `${PATH}LOAD_EMAIL_SERVER_LIST_SUCCESS`;
export const SEND_DELETE_EMAIL_CLIENT_REQUEST = `${PATH}SEND_DELETE_EMAIL_CLIENT_REQUEST`;
