import { fromJS } from 'immutable';
import {
  LOAD_MAKE_LIST_SUCCESS,
  LOAD_MODEL_LIST_SUCCESS,
  LOAD_SELECTED_VEHICLE_SUCCESS,
  LOAD_VEHICLE_LIST_SUCCESS,
} from '../constant/vehicleConstant';

export const initialState = fromJS({
    vehicleList:[],
    makeList:[],
    modelList:[],
    selectedVehicle: {}
});

function vehicleReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_VEHICLE_LIST_SUCCESS:
      return state.set('vehicleList', action.payload)
    case LOAD_MAKE_LIST_SUCCESS:
      return state.set('makeList', action.payload)
    case LOAD_MODEL_LIST_SUCCESS:
      return state.set('modelList', action.payload)
    case LOAD_SELECTED_VEHICLE_SUCCESS:
      return state.set('selectedVehicle', action.payload)
    default:
      return state;
  }
}

export default vehicleReducer;
