import React from "react";
import '../../layout/form/form.css'
import VemasLogo from '../../../images/WELAWA.png';
import Loader from "../../layout/form/Loader";

function Default({
    handleWarningModalClose,
    handleChangeStep
}) {
    return (
        <div>
            <Loader show={true}/>
            <div className="vemas_logo">
                <img alt="vemaslogo" id="vemas_logo_default" src={VemasLogo} />
            </div>
        </div>
    )
}

export default Default;