import { 
    LOAD_CUSTOMER_LIST_ERROR,
    LOAD_CUSTOMER_LIST_LOADING,
    LOAD_CUSTOMER_LIST_REQUEST,
    LOAD_CUSTOMER_LIST_SUCCESS,
    LOAD_SELECTED_CUSTOMER_SUCCESS,
    SAVE_IS_CUSTOMER_MODAL_OPEN_STATUS,
    SEND_ADD_NEW_CUSTOMER_REQUEST,
    SEND_CUSTOMER_SUCCESS,
    SEND_DELETE_CUSTOMER_REQUEST,
    SEND_UPDATE_CUSTOMER_REQUEST,
    LOAD_SELECTED_CUSTOMER_REQUEST,
    SEND_CUSTOMER_NOTIFICATION_REQUEST
    } from '../constant/customerConstant';
    
    export function saveIsCustomerModalOpenStatus(open){
      return {
        type: SAVE_IS_CUSTOMER_MODAL_OPEN_STATUS,
        open,
      }
    };
  
    export function loadCustomerListRequest(data) {
      return {
        type: LOAD_CUSTOMER_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadCustomerListLoading(data) {
      return {
        type: LOAD_CUSTOMER_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadCustomerListSuccess(data) {
      return {
        type: LOAD_CUSTOMER_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadCustomerListError(data) {
      return {
        type: LOAD_CUSTOMER_LIST_ERROR,
        payload: data,
      }
    };
  
    export function sendAddNewCustomerRequest(data) {
      return {
        type: SEND_ADD_NEW_CUSTOMER_REQUEST,
        payload: data,
      }
    };
    
    export function sendCustomerSuccess(data) {
      return {
        type: SEND_CUSTOMER_SUCCESS,
        payload: data,
      }
    };
    
    export function sendUpdateCustomerRequest(data) {
      return {
        type: SEND_UPDATE_CUSTOMER_REQUEST,
        payload: data,
      }
    };
    
    export function sendDeleteCustomerRequest(data) {
      return {
        type: SEND_DELETE_CUSTOMER_REQUEST,
        payload: data,
      }
    };

    export function loadSelectedCustomerSuccess(data) {
      return {
        type: LOAD_SELECTED_CUSTOMER_SUCCESS,
        payload: data,
      }
    };
  
    export function loadSelectedCustomerRequest(data) {
      return {
        type:LOAD_SELECTED_CUSTOMER_REQUEST,
        payload: data,
      }
    };

    export function sendCustomerNotificationRequest(data) {
      return {
        type: SEND_CUSTOMER_NOTIFICATION_REQUEST,
        payload: data,
      }
    };
    
    