import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/appBillingSelector';
// import { makeSelectTitleList } from '../../../../store/selector/titleSelector';
import { makeSelectObjectStatusList } from '../../../../store/selector/selector'
import * as actions from '../../../../store/action/appBillingAction';
// import { loadTitleListRequest } from '../../../../store/action/titleAction';
import PropTypes from 'prop-types';
import '../../settingMenu/settingMenu.css';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import common, { numberWithCommas } from '../../../common';
import SearchField from '../../../layout/form/SearchField';
import TextField from '../../../layout/form/TextField';
import Pagination from '../../../layout/form/Pagination';
import { loadObjectStatusRequest, loadObjectStatusSuccess, saveCommonSearchSuccess } from '../../../../store/action/action';
import Button from '../../../layout/form/Button';
import { InvoiceDetails } from './InvoiceDetails';
import PdfViewer from '../../../layout/form/PdfViewer';

const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)

class AppInvoice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFilterFormOpen: false,
            invoiceNo: '',
            invoiceDate: moment(prevDate).format(common.dateFormat),
            dueDate: moment(today).format(common.dateFormat),
            selectedRow: {},
            showMenu: false,
            pageNumber: 1,
            pageSize: 20,
            status: '',
            commonSearch: '',
            isPrintModalOpen: false
        }

    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen: !this.state.isFilterFormOpen,
            invoiceNo: this.state.invoiceNo,
            invoiceDate: this.state.invoiceDate,
            dueDate: this.state.dueDate,
            status: this.state.status,
            commonSearch: '',
        })
    }

    handleChangeSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleDateChange = (date, id) => {
        if (date === null && id === "invoiceDate") {
            this.setState({
                [id]: moment(prevDate).format(common.dateFormat),
            })
        }
        else if (date === null && id === "dueDate") {
            this.setState({
                [id]: moment(today).format(common.dateFormat),
            })
        } else {
            this.setState({
                [id]: moment(date).format(common.dateFormat),
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleFilter = () => {
        this.getInvoiceListData()
        this.handleFilterForm()
    }

    handleChangeSearchValue = (e) => {
        if (e.target.value === "" || e.target.value === null) {
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen: false,
            invoiceNo: '',
            status: '',
            invoiceDate: moment(prevDate).format(common.dateFormat),
            dueDate: moment(today).format(common.dateFormat),
            pageSize: 20,
            pageNumber: 1,
        })
    }

    handleFilterFormClose = () => {
        this.props.dispatch(actions.loadAppInvoiceListSuccess([]))
        this.setState({
            isFilterFormOpen: false,
            invoiceNo: '',
            status: '',
            invoiceDate: moment(prevDate).format(common.dateFormat),
            dueDate: moment(today).format(common.dateFormat),
            pageSize: 20,
            pageNumber: 1,
            commonSearch: '',
        })

        const parameters = {
            pageSize: 20,
            pageNumber: 1,
            invoiceNo: '',
            status: '',
            invoiceDate: moment(prevDate).format(common.dateFormat),
            dueDate: moment(today).format(common.dateFormat),
            commonSearch: ''
        }
        this.props.dispatch(actions.loadAppInvoiceListRequest(parameters))
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    getInvoiceListData = () => {
        this.props.dispatch(actions.loadAppInvoiceListSuccess([]))
        this.props.dispatch(saveCommonSearchSuccess({}))
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            invoiceNo: this.state.invoiceNo,
            invoiceDate: this.state.invoiceDate,
            dueDate: this.state.dueDate,
            status: this.state.status,
            commonSearch: this.state.commonSearch
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.props.dispatch(actions.loadAppInvoiceListRequest(parameters))
    }

    onPressKeySearch = (value) => {
        this.props.dispatch(actions.loadAppInvoiceListSuccess([]))
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            invoiceNo: this.state.invoiceNo,
            invoiceDate: this.state.invoiceDate,
            dueDate: this.state.dueDate,
            status: this.state.status,
            commonSearch: value
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.setState({
            commonSearch: value,
            invoices: [],
            initial: true
        })
        this.props.dispatch(actions.loadAppInvoiceListRequest(parameters))
    }

    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if (objectStatusList && objectStatusList.length && objectStatusList.length !== 0) {
            const desc = objectStatusList.find(select => select.id === data.status)
            return (desc && desc.description ? desc.description : '')
        }
    }

    setPaymentMethod = (data) => {
        const { appPaymentMethod } = this.props;
        if (appPaymentMethod && appPaymentMethod.length && appPaymentMethod.length !== 0) {
            const desc = appPaymentMethod.find(select => select.platfomId === data.paymentMethod)
            return (desc && desc.description ? desc.description : '')
        }
    }

    handleModal = (data) => {
        this.props.dispatch(actions.saveIsAppInvoiceModalOpenStatus(true))
        this.props.dispatch(actions.loadSelectedAppInvoiceRequest(data.id))
    }

    handleModalClose = () => {
        this.props.dispatch(actions.saveIsAppInvoiceModalOpenStatus(false))
        this.props.dispatch(actions.loadSelectedAppInvoiceSuccess({}))
    }

    handlePrintById = (data) => {
        this.setState({ isPrintModalOpen: true })
        this.props.dispatch(actions.loadAppInvoicePrintRequest(data.id))
    }

    printModalClose = () => {
        this.setState({ isPrintModalOpen: false })
        this.props.dispatch(actions.loadAppInvoicePrintSuccess(''))
    }


    componentDidMount() {
        this.props.dispatch(loadObjectStatusSuccess([]))
        this.getInvoiceListData()
        this.props.dispatch(loadObjectStatusRequest(common.objectId.appInvoice));
        this.props.dispatch(actions.loadAppPaymentMethodRequest())
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getInvoiceListData()
        }
    }

    render() {

        const {
            appInvoiceList,
            isAppInvoiceModalOpen,
            appInvoiceListError,
            isAppInvoiceListLoading,
            selectedAppInvoice,
            objectStatusList,
            appInvoicePrint,
            appPaymentMethod
        } = this.props;

        const {
            isFilterFormOpen,
            isPrintModalOpen
        } = this.state;

        const columns = [{
            Header: 'Invoice No',
            accessor: 'invoiceNo',
        }, {
            Header: 'Invoice Date',
            accessor: 'invoiceDate',
            Cell: ((row) => (
                <div >
                    {row.row.original.invoiceDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.invoiceDate)}
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Due Date',
            accessor: 'paymentDueDate',
            Cell: ((row) => (
                <div >
                    {row.row.original.paymentDueDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.paymentDueDate)}
                </div>
            )),
            width: "10%",
        },{
            Header: 'Payment Method',
            accessor: 'paymentMethod',
            Cell: ((row) => (
                <div >
                    {this.setPaymentMethod(row.row.original)}
                </div>
            )),
            width: "10%",
        },{
            Header: () => (<div style={{ textAlign: "right" }}>Amount</div>),
            // Header: 'Amount',
            accessor: 'total',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.grossValue).toFixed(2))}
                </div>
            ))
        }, {
            Header: 'Status',
            // accessor: 'statusId',
            Cell: ((row) => (
                <div style={{ padding: '2px' }}>
                    {row.row.original.status === "20" ?
                        <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                        row.row.original.status === "30" ?
                            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                            <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div>
                    }
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                        <span onClick={() => this.handlePrintById(row.row.original)}><ui5-icon class="samples-margin" name="print" id="table-icon"></ui5-icon> </span>


                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {isAppInvoiceListLoading ?
                    <Loader show={isAppInvoiceListLoading} /> : ''}

                {appInvoiceListError !== '' &&
                    <ErrorModal
                        show={true}
                        massage={appInvoiceListError}
                        handleClose={this.errorModalClose}
                    />}
                {!isAppInvoiceModalOpen &&
                    <div className="content-header">
                        <div className="header-pages">
                            <span><Link to='./appBilling'><ui5-icon class="samples-margin" name="crm-service-manager" id="tab-icon"></ui5-icon> App Services</Link></span>
                            <span><Link to='./appPaymentMethod'><ui5-icon class="samples-margin" name="payment-approval" id="tab-icon"></ui5-icon> Payment method</Link></span>
                            <span><Link to='./appInvoice'><ui5-icon class="samples-margin" name="customer-order-entry" id="tab-icon"></ui5-icon> App Invoice</Link></span>
                        </div>
                        <div className="header-btn">
                            <Button
                                onClick={this.handleFilterForm}
                                text='Filter'
                                variant="outline-primary"
                                icon='filter'
                            />
                        </div>
                    </div>}
                <div className="body-content">
                    {isAppInvoiceModalOpen ?
                        <InvoiceDetails
                            handleClose={this.handleModalClose}
                            selectedInvoice={selectedAppInvoice}
                            objectStatusList={objectStatusList}
                            appPaymentMethod={appPaymentMethod}
                        /> :
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <h5 style={{ marginLeft: '5px' }}>Invoice List</h5>
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <SearchField
                                            onClickSearch={this.getInvoiceListData}
                                            controlId="commonSearch"
                                            onChange={this.handleChangeSearchValue}
                                            defaultValue={this.state.commonSearch}
                                            onPressKeySearch={this.onPressKeySearch}
                                        />
                                    </div>
                                </div>

                            </div>
                            {isFilterFormOpen &&
                                <div className="filter-form">
                                    <div className="filter-header">
                                        <h5>Filter Form</h5>
                                        <div className="btn-close" onClick={this.handleFilterFormClose} >
                                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-sm">
                                            <TextField
                                                text="Invoice no"
                                                controlId="invoiceNo"
                                                placeholder="Invoice no"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.invoiceNo}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            {/* <SelectField
                                        text="Status"
                                        controlId="status"
                                        onChange={(e)=>this.handleChangeSelect(e,'status')}
                                        options={objectStatusList}
                                        selectedValue={this.state.status}
                                        isClearable
                                    /> */}
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-sm">
                                            {/* <DatePicker
                                        text="Invoice Date"
                                        controlId="invoiceDate"
                                        onChange={(e) => this.handleDateChange(e,"invoiceDate")}
                                        value={new Date(this.state.invoiceDate)}
                                        // format={dateFormat}
                                    /> */}
                                        </div>
                                        <div className="col-sm">
                                            {/* <DatePicker
                                        text="Due Date"
                                        controlId="dueDate"
                                        onChange={(e) => this.handleDateChange(e,"dueDate")}
                                        value={new Date(this.state.dueDate)}
                                        // format={dateFormat}
                                    /> */}
                                        </div>
                                    </div>
                                    <div className="filter-btns">
                                        {/* <ButtonGroup
                                    primaryBtnClick={this.handleFilter}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary" 
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary" 
                                /> */}
                                    </div>
                                </div>}
                            <div className="table-content">
                                <Table
                                    columns={columns}
                                    data={appInvoiceList.result && appInvoiceList.result.length ? appInvoiceList.result : []}
                                />
                            </div>
                            <Pagination
                                currentPage={appInvoiceList.page ? appInvoiceList.page : this.state.pageNumber}
                                getNextPage={this.getNextPage}
                                getPrevPage={this.getPrevPage}
                                totalPage={appInvoiceList.totalPages}
                                setClickedPage={this.setClickedPage}
                                currentPageSize={this.state.pageSize}
                                setPageSize={this.setPageSize}
                            />

                        </div>
                    }
                    {
                        isPrintModalOpen && appInvoicePrint !== '' &&
                        <PdfViewer
                            show={isPrintModalOpen}
                            pdf={appInvoicePrint}
                            onHide={this.printModalClose}
                            title="Invoice"
                        />
                    }
                </div>

            </div>
        )
    }
}

AppInvoice.propTypes = {
    isAppInvoiceModalOpen: PropTypes.bool,
    appInvoiceList: PropTypes.any,

}

const mapStateToProps = createStructuredSelector({
    isAppInvoiceModalOpen: selectors.makeSelectAppInvoicePopupOpen(),
    appInvoiceList: selectors.makeSelectAppInvoiceList(),
    isAppInvoiceListLoading: selectors.makeSelectAppInvoiceListLoading(),
    appInvoiceListError: selectors.makeSelectAppInvoiceListError(),
    objectStatusList: makeSelectObjectStatusList(),
    selectedAppInvoice: selectors.makeSelectSelectedInvoiceSuccess(),
    appInvoicePrint: selectors.makeSelectAppInvoicePrintSuccess(),
    appPaymentMethod: selectors.makeSelectAppPaymentMethodSuccess()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(AppInvoice));