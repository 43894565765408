import React from "react";
import Table from "../../../layout/form/Table";
import { numberWithCommas } from "../../../common";
import TextField from "../../../layout/form/TextField";
import DatePicker from '../../../layout/form/datePicker';
import SelectField from "../../../layout/form/SelectField";
import 'bootstrap/dist/css/bootstrap.min.css';
import Label from 'react-bootstrap/cjs/FormLabel';
import FormControl from 'react-bootstrap/cjs/FormControl';

export function InvoiceDetails(
    {
        selectedInvoice,
        handleClose,
        objectStatusList,
        appPaymentMethod
    }) {

    const columns = [
        {
            Header: 'Description',
            accessor: 'description',
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Quantity</div>),
            accessor: 'quantity',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.quantity).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Unit Price</div>),
            accessor: 'unitPrice',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.unitPrice).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Discount</div>),
            accessor: 'discountAmount',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.discountAmount).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Tax</div>),
            accessor: 'taxAmount',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.taxAmount).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Value</div>),
            accessor: 'value',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat((row.row.original.quantity * row.row.original.unitPrice) - row.row.original.discountAmount + row.row.original.taxAmount).toFixed(2))}
                </div>
            ))
        }

    ]
    return (
        <div>
            <div className="filter-header">
                <h5 style={{ marginLeft: '15px' }}>
                    Invoice {selectedInvoice && selectedInvoice.id ? " - " + selectedInvoice.invoiceNo : ""}
                </h5>
                <div className="btn-close" onClick={handleClose} style={{ marginRight: '10px' }}>
                    <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                </div>
            </div>
            <div className="row" style={{ margin: '0 5px' }}>
                <div className="col-sm page_header">
                    <div className="page_header-title">
                        <span>Invoice Details</span>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <TextField
                                text="Invoice no"
                                controlId="invoiceNo"
                                type="text"
                                disabled
                                defaultValue={selectedInvoice.invoiceNo}
                            />
                        </div>
                        <div className="col-sm">
                            <DatePicker
                                text="Invoice date"
                                controlId="invoiceDate"
                                value={selectedInvoice && selectedInvoice.invoiceDate ? new Date(selectedInvoice.invoiceDate) : ''}
                                disabled
                            // format={dateFormat}
                            />
                        </div>
                        <div className="col-sm">
                            <DatePicker
                                text="Due date"
                                controlId="paymentDueDate"
                                disabled
                                value={selectedInvoice && selectedInvoice.paymentDueDate ? new Date(selectedInvoice.paymentDueDate) : ''}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <SelectField
                                text="Status"
                                controlId="status"
                                options={objectStatusList}
                                selectedValue={selectedInvoice.status}
                                disabled
                            />
                        </div>
                        <div className="col-sm-4">
                            <SelectField
                                text="Payment Method"
                                controlId="paymentMethod"
                                options={appPaymentMethod}
                                selectedValue={selectedInvoice.paymentMethod}
                                id="platfomId"
                                disabled
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className="table-invoice">
                <Table
                    columns={columns}
                    data={selectedInvoice && selectedInvoice.invoiceItems ? selectedInvoice.invoiceItems : []}
                />
            </div>
            <div className='col-sm ' style={{ textAlign: 'right', marginRight: '-15px' }}>
                <div className="row value" style={{ marginBottom: '20px' }}>
                    <div className='col-sm'></div>
                    <div className='col-sm value-title'>
                        <Label >
                            Sub total
                        </Label>
                    </div>
                    <div className='col-sm'>
                        <FormControl
                            controlId="netValue"
                            type="number"
                            style={{ textAlign: 'right' }}
                            value={parseFloat(selectedInvoice.netValue).toFixed(2)}
                            disabled
                        />
                    </div>
                </div>
                <div className="row value" style={{ marginBottom: '20px' }}>
                    <div className='col-sm'></div>
                    <div className='col-sm value-title'>
                        <Label >
                            Discount
                        </Label>
                    </div>
                    <div className='col-sm'>
                        <FormControl
                            controlId="discountValue"
                            type="number"
                            style={{ textAlign: 'right' }}
                            value={parseFloat(selectedInvoice.discountValue).toFixed(2)}
                            disabled
                        />
                    </div>
                </div>
                <div className="row value" style={{ marginBottom: '20px' }}>
                    <div className='col-sm'></div>
                    <div className='col-sm value-title'>
                        <Label >
                            Tax
                        </Label>
                    </div>
                    <div className='col-sm'>
                        <FormControl
                            controlId="taxValue"
                            type="number"
                            style={{ textAlign: 'right' }}
                            value={parseFloat(selectedInvoice.taxValue).toFixed(2)}
                            disabled
                        />
                    </div>
                </div>
                <div className="row value" style={{ marginBottom: '20px' }}>
                    <div className='col-sm'></div>
                    <div className='col-sm value-title'>
                        <Label >
                            Total
                        </Label>
                    </div>
                    <div className='col-sm'>
                        <FormControl
                            controlId="grossValue"
                            type="number"
                            style={{ textAlign: 'right' }}
                            value={parseFloat(selectedInvoice.grossValue).toFixed(2)}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}