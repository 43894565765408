const PATH = 'app/Title';

export const SAVE_IS_TITLE_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_TITLE_MODAL_OPEN_STATUS`;
export const LOAD_TITLE_LIST_REQUEST = `${PATH}LOAD_TITLE_LIST_REQUEST`;
export const LOAD_TITLE_LIST_ERROR = `${PATH}LOAD_TITLE_LIST_ERROR`;
export const LOAD_TITLE_LIST_LOADING = `${PATH}LOAD_TITLE_LIST_LOADING`;
export const LOAD_TITLE_LIST_SUCCESS = `${PATH}LOAD_TITLE_LIST_SUCCESS`;
export const SEND_ADD_NEW_TITLE_REQUEST = `${PATH}SEND_ADD_NEW_TITLE_REQUEST`;
export const SEND_TITLE_SUCCESS = `${PATH}SEND_TITLE_SUCCESS`;
export const SEND_UPDATE_TITLE_REQUEST = `${PATH}SEND_UPDATE_TITLE_REQUEST`;
export const SEND_DELETE_TITLE_REQUEST = `${PATH}SEND_DELETE_TITLE_REQUEST`;