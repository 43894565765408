import { createSelector } from 'reselect';

const selectEmployeeDomain = state => state.employee;

const makeSelectEmployeePopupOpen =() =>
  createSelector(
    selectEmployeeDomain,
    employeeState => 
      employeeState ? employeeState.get('isEmployeeModalOpen') : false
  );

const makeSelectEmployeeList = () =>
  createSelector(
    selectEmployeeDomain,
    employeeState => 
      employeeState ? employeeState.get('employeeList') : []
  );

const makeSelectEmployeeListLoading = () =>
  createSelector(
    selectEmployeeDomain,
    employeeState => 
      employeeState ? employeeState.get('isEmployeeListLoading') : false
  );

const makeSelectEmployeeListError = () =>
  createSelector(
    selectEmployeeDomain,
    employeeState => 
      employeeState ? employeeState.get('employeeListError') : null
  );

const makeSelectEmployeeSuccess = () =>
  createSelector(
    selectEmployeeDomain,
    employeeState => 
      employeeState ? employeeState.get('employeeSuccess') : null
  );

const makeSelectSelectedEmployeeSuccess = () =>
  createSelector(
    selectEmployeeDomain,
    employeeState => 
    employeeState ? employeeState.get('selectedEmployee') : ''
  );


export {
    makeSelectEmployeePopupOpen,
    makeSelectEmployeeList,
    makeSelectEmployeeListLoading,
    makeSelectEmployeeListError,
    makeSelectEmployeeSuccess,
    makeSelectSelectedEmployeeSuccess
}
