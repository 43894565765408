import { takeLatest, put, call } from 'redux-saga/effects';
// import * as dashboard from './dashboardSaga';
import * as tenant from './tenantSaga';
import * as user from './usersSaga';
import * as project from './projectSaga';
import * as timesheet from './timesheetSaga';
import * as employee from './employeeSaga';
import * as accounting from './accountSaga';
import * as a2aMapping from './a2aMappingSaga';
import * as title from './titleSaga';
import * as userRole from './usersRoleSaga';
import * as customer from './customerSaga';
import * as report from './reportSaga';
import * as invoice from './invoiceSaga';
import * as appBilling from './appBillingSaga';
import * as emailConfig from './emailConfigurationSaga';
import {
  loadAppBillingTermsSuccess,
  loadAppPackageSuccess,
  loadAppUOMSuccess,
  loadCountrySuccess,
  loadObjectStatusSuccess,
  loadPublicCalendarSuccess,
  loadTenantSuccess,
  loadTenantUserSuccess,
  loadUOMConversionFactorSuccess,
  loadUOMSuccess
} from '../../store/action/action';
import { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import {
  getAppPackages,
  getAppUOMList,
  getBillingTerms,
  getCountryList,
  getObjectStatusList,
  getPublicCalendar,
  getTenantCommon,
  getUOMCoversionFactorList,
  getUOMList,
} from '../service/service';
import { getSelectedUserRequest } from '../service/usersService';
import {
  LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST,
  LOAD_TENANT_BASIC_REQUEST,
  LOAD_TENANT_CALENDAR_REQUEST,
  LOAD_TENANT_FINANCE_REQUEST,
  LOAD_TENANT_OPEN_HOURS_REQUEST,
  LOAD_TENANT_PACKAGE_REQUEST,
  LOAD_TENANT_PAYMENT_METHOD_REQUEST,
  SEND_CREATE_TENANT_CALENDAR_REQUEST,
  SEND_DELETE_TENANT_CALENDAR_REQUEST,
  SEND_TENANT_IMAGE_REQUEST,
  SEND_UPDATE_TENANT_DETAILS_REQUEST,
  SEND_UPDATE_TENANT_OPEN_HOURS_REQUEST
} from '../../store/constant/tenantConstant';
import {
  LOAD_SELECTED_USER_REQUEST,
  LOAD_USER_ACCESS_REQUEST,
  LOAD_USER_LIST_REQUEST,
  // LOAD_USER_ROLE_LIST_REQUEST,
  SEND_ADD_NEW_USER_REQUEST,
  SEND_USER_STATUS_UPDATE_REQUEST,
  SEND_USER_UPDATE_REQUEST
} from '../../store/constant/usersConstant';
import {
  LOAD_PROJECT_ACTIVITY_LIST_BY_STATUS_REQUEST,
  LOAD_PROJECT_ALLOCATION_TYPE_REQUEST,
  LOAD_PROJECT_BILLING_TYPE_REQUEST,
  LOAD_PROJECT_LIST_BY_STATUS_REQUEST,
  LOAD_PROJECT_LIST_REQUEST,
  LOAD_PROJECT_TIME_INPUT_TYPE_REQUEST,
  LOAD_PROJECT_TYPE_REQUEST,
  LOAD_REPORT_BY_PROJECT_REQUEST,
  LOAD_SELECTED_PROJECT_REQUEST,
  SEND_ADD_NEW_PROJECT_REQUEST,
  SEND_DELETE_PROJECT_REQUEST,
  SEND_IMPORT_PROJECT_ACTIVITY_REQUEST,
  SEND_UPDATE_PROJECT_ACTIVITY_REQUEST,
  SEND_UPDATE_PROJECT_ASSIGNMENT_REQUEST,
  SEND_UPDATE_PROJECT_REQUEST
} from '../../store/constant/projectConstant';
import {
  LOAD_APP_BILLING_TERMS_REQUEST,
  LOAD_APP_PACKAGE_REQUEST,
  LOAD_APP_UOM_REQUEST,
  LOAD_COUNTRY_REQUEST,
  LOAD_OBJECT_STATUS_REQUEST,
  LOAD_PUBLIC_CALENDAR_REQUEST,
  LOAD_TENANT_REQUEST,
  LOAD_TENANT_USER_REQUEST,
  LOAD_UOM_CONVERSION_FACTOR_REQUEST,
  LOAD_UOM_REQUEST
} from '../../store/constant/constant';
import {
  LOAD_SELECTED_TIMESHEET_REQUEST,
  LOAD_TIMESHEET_APPROVALS_REQUEST,
  LOAD_TIMESHEET_DETAILS_REQUEST,
  LOAD_TIMESHEET_LIST_REQUEST,
  LOAD_TIME_TYPE_REQUEST,
  SEND_ADD_NEW_TIMESHEET_REQUEST,
  SEND_DELETE_TIMESHEET_DETAILS_REQUEST,
  SEND_DELETE_TIMESHEET_REQUEST,
  SEND_TIMESHEET_COPY_REQUEST,
  SEND_TIMESHEET_DETAILS_COPY_REQUEST,
  SEND_TIMESHEET_DETAILS_REQUEST,
  SEND_UPDATE_TIMESHEET_REQUEST,
  SEND_UPDATE_TIMESHEET_STATUS_REQUEST
} from '../../store/constant/timesheetConstant';
import {
  LOAD_EMPLOYEE_LIST_REQUEST,
  LOAD_SELECTED_EMPLOYEE_REQUEST,
  SEND_ADD_NEW_EMPLOYEE_REQUEST,
  SEND_UPDATE_EMPLOYEE_REQUEST
} from '../../store/constant/employeeConstant';
import {
  LOAD_ACCOUNT_SYSTEM_REQUEST,
  LOAD_INTEGRATION_SCENARIO_REQUEST,
  LOAD_QUICKBOOKS_CONNECTION_REQUEST,
  SEND_CONNECTION_REQUEST,
  SEND_CONNECTION_UPDATE_REQUEST,
  SEND_DELETE_CONNECTION_REQUEST,
  SEND_INTEGRATION_SCENARIO_REQUEST
} from '../../store/constant/accountConstant';
import {
  LOAD_CUSTOMER_LIST_REQUEST,
  LOAD_SELECTED_CUSTOMER_REQUEST,
  SEND_ADD_NEW_CUSTOMER_REQUEST,
  SEND_CUSTOMER_NOTIFICATION_REQUEST,
  SEND_DELETE_CUSTOMER_REQUEST,
  SEND_UPDATE_CUSTOMER_REQUEST
} from '../../store/constant/customerConstant';
import { LOAD_A2A_CUSTOMER_REQUEST, LOAD_A2A_EMPLOYEE_REQUEST, LOAD_A2A_PAY_TYPE_REQUEST, LOAD_A2A_PROJECT_REQUEST, LOAD_ACCOUNT_COMPANY_FILE_REQUEST, LOAD_ACCOUNT_CUSTOMER_REQUEST, LOAD_ACCOUNT_EMPLOYEE_REQUEST, LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_REQUEST, LOAD_ACCOUNT_PAY_TYPE_REQUEST, SEND_A2A_CUSTOMER_REQUEST, SEND_A2A_EMPLOYEE_REQUEST, SEND_A2A_PAY_TYPE_REQUEST, SEND_ACCOUNT_COMPANY_FILE_REQUEST } from '../../store/constant/a2aMappingConstant';
import { LOAD_TITLE_LIST_REQUEST } from '../../store/constant/titleConstant';
import { LOAD_OBJECT_LIST_REQUEST, LOAD_OBJECT_OPERATION_LIST_REQUEST, LOAD_USER_ROLE_LIST_REQUEST, LOAD_USER_ROLE_OBJECT_LIST_REQUEST, SEND_ADD_NEW_USER_ROLE_REQUEST, SEND_DELETE_USER_ROLE_REQUEST, SEND_UPDATE_USER_ROLE_REQUEST } from '../../store/constant/userRolesConstant';
import { LOAD_REPORT_CSV_REQUEST, LOAD_REPORT_PERIOD_REQUEST, LOAD_REPORT_PRINT_REQUEST, LOAD_REPORT_REQUEST, LOAD_REVENUE_FORCASTING_REQUEST } from '../../store/constant/reportConstant';
import { LOAD_INVOICE_ADD_PRINT_REQUEST, LOAD_INVOICE_ADD_SEND_REQUEST, LOAD_INVOICE_EMAIL_REQUEST, LOAD_INVOICE_LIST_REQUEST, LOAD_INVOICE_PRINT_BY_ID_REQUEST, LOAD_INVOICE_PRINT_REQUEST, LOAD_INVOICE_SEND_REQUEST, LOAD_INVOICE_TIMESHEET_DETAILS_REQUEST, LOAD_PAYMENT_METHOD_REQUEST, LOAD_PAYMENT_TERM_REQUEST, LOAD_SELECTED_INVOICE_REQUEST, SEND_ADD_NEW_INVOICE_REQUEST, SEND_DELETE_INVOICE_REQUEST, SEND_INVOICE_EMAIL_REQUEST, SEND_UPDATE_INVOICE_REQUEST } from '../../store/constant/invoiceConstant';
import { LOAD_APP_INVOICE_LIST_REQUEST, LOAD_APP_INVOICE_PRINT_REQUEST, LOAD_APP_PAYMENT_METHOD_REQUEST, LOAD_APP_SERVICE_TENANT_REQUEST, LOAD_PAYMENT_SETUP_REQUEST, LOAD_SELECTED_APP_INVOICE_REQUEST, SEND_APP_BILLING_REQUEST, SEND_DEFAULT_PAYMENT_METHOD_REQUEST, SEND_PAYMENT_METHOD_REQUEST, SEND_UPDATE_PAYMENT_METHOD_REQUEST } from '../../store/constant/appBillingConstant';
import { LOAD_EMAIL_CLIENT_LIST_REQUEST, LOAD_EMAIL_SERVER_LIST_REQUEST, SEND_ADD_NEW_EMAIL_CLIENT_REQUEST, SEND_DELETE_EMAIL_CLIENT_REQUEST, SEND_UPDATE_EMAIL_CLIENT_REQUEST } from '../../store/constant/emailConfigurationConstant';

export function* loadObjectStatusRequest(data) {
  try {
    const response = yield call(getObjectStatusList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(loadObjectStatusSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}

export function* loadTenantCommonRequest(data) {
  try {
    const response = yield call(getTenantCommon, data.payload);
    if (response && response.status && response.status === 200) {
      sessionStorage.setItem('dateFormat', response.data.dateFormat);
      const tenant = response.data
      yield put(loadTenantSuccess(tenant))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
  } catch (error) {
  }
}

export function* loadTenantUserRequest(data) {
  try {
    const response = yield call(getSelectedUserRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const User = response.data
      yield put(loadTenantUserSuccess(User))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
  } catch (error) {
  }
}

export function* loadCountryRequest() {
  try {
    const response = yield call(getCountryList);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(loadCountrySuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}


export function* loadUOMRequest() {
  try {
    const response = yield call(getUOMList);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(loadUOMSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}

export function* loadAppUOMRequest(data) {
  try {
    const response = yield call(getAppUOMList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(loadAppUOMSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    // console.log(error)
  }
}

export function* loadUOMConversionFactorRequest(data) {
  try {
    const response = yield call(getUOMCoversionFactorList);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(loadUOMConversionFactorSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}

export function* loadAppPackagesRequest() {
  try {
    const response = yield call(getAppPackages);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const list = response.data.result.result;
      yield put(loadAppPackageSuccess(list))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    // console.log(error)
  }
}

export function* loadBillingTermsRequest() {
  try {
    const response = yield call(getBillingTerms);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const list = response.data.result.result;
      const default_ = list.find(e => e.default === true)
      const result = {
        list: list,
        default: default_.id
      }
      yield put(loadAppBillingTermsSuccess(result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    // console.log(error)
  }
}

export function* loadPublicCalendarRequest(data) {
  try {
    const response = yield call(getPublicCalendar, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const list = response.data.result.result;
      yield put(loadPublicCalendarSuccess(list))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    // console.log(error)
  }
}

export default function* rootSaga() {
  yield takeLatest(LOAD_COUNTRY_REQUEST, loadCountryRequest);
  yield takeLatest(LOAD_OBJECT_STATUS_REQUEST, loadObjectStatusRequest);
  yield takeLatest(LOAD_TENANT_REQUEST, loadTenantCommonRequest);
  yield takeLatest(LOAD_TENANT_USER_REQUEST, loadTenantUserRequest);
  yield takeLatest(LOAD_PUBLIC_CALENDAR_REQUEST, loadPublicCalendarRequest);
  yield takeLatest(LOAD_TENANT_BASIC_REQUEST, tenant.loadTenantBasicRequest);
  yield takeLatest(LOAD_APP_PACKAGE_REQUEST, loadAppPackagesRequest);
  yield takeLatest(LOAD_APP_BILLING_TERMS_REQUEST, loadBillingTermsRequest);
  yield takeLatest(LOAD_APP_UOM_REQUEST, loadAppUOMRequest);
  yield takeLatest(LOAD_UOM_CONVERSION_FACTOR_REQUEST, loadUOMConversionFactorRequest);
  yield takeLatest(SEND_UPDATE_TENANT_DETAILS_REQUEST, tenant.sendUpdateTenantRequest);
  yield takeLatest(LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST, tenant.loadTenantAccountSystemRequest);
  yield takeLatest(LOAD_TENANT_OPEN_HOURS_REQUEST, tenant.loadTenantOpenHoursRequest);
  yield takeLatest(LOAD_TENANT_FINANCE_REQUEST, tenant.loadTenantFinanceRequest);
  yield takeLatest(SEND_UPDATE_TENANT_OPEN_HOURS_REQUEST, tenant.sendUpdateTenantOpenHoursRequest);
  yield takeLatest(LOAD_TENANT_PAYMENT_METHOD_REQUEST, tenant.loadTenantPaymentMethodRequest);
  yield takeLatest(LOAD_TENANT_PACKAGE_REQUEST, tenant.loadTenantPackageRequest);
  yield takeLatest(SEND_CREATE_TENANT_CALENDAR_REQUEST, tenant.sendAddNewTenantHolidayRequest);
  yield takeLatest(LOAD_TENANT_CALENDAR_REQUEST, tenant.loadTenantCalendarRequest);
  yield takeLatest(SEND_DELETE_TENANT_CALENDAR_REQUEST, tenant.sendDeleteTenantHolidayRequest);
  yield takeLatest(SEND_TENANT_IMAGE_REQUEST, tenant.sendUpdateTenantImageRequest);
  yield takeLatest(LOAD_USER_ACCESS_REQUEST, user.loadUserAccessRequest);
  yield takeLatest(SEND_ADD_NEW_USER_REQUEST, user.sendAddNewUserRequest);
  yield takeLatest(LOAD_USER_LIST_REQUEST, user.loadUserListRequest);
  yield takeLatest(SEND_USER_STATUS_UPDATE_REQUEST, user.sendUpdateUserStatusRequest);
  yield takeLatest(LOAD_SELECTED_USER_REQUEST, user.loadSelectedUserRequest);
  yield takeLatest(SEND_USER_UPDATE_REQUEST, user.sendUpdateUserRequest);
  // yield takeLatest(LOAD_USER_ROLE_LIST_REQUEST, user.loadUserRoleListRequest);
  yield takeLatest(LOAD_PROJECT_LIST_REQUEST, project.loadProjectListRequest);
  yield takeLatest(LOAD_SELECTED_PROJECT_REQUEST, project.selectedProjectRequest);
  yield takeLatest(SEND_ADD_NEW_PROJECT_REQUEST, project.sendAddNewProjectRequest);
  yield takeLatest(SEND_UPDATE_PROJECT_REQUEST, project.sendUpdateProjectRequest);
  yield takeLatest(SEND_DELETE_PROJECT_REQUEST, project.sendDeleteProjectRequest);
  yield takeLatest(SEND_UPDATE_PROJECT_ACTIVITY_REQUEST, project.sendUpdateProjectActivityRequest);
  yield takeLatest(SEND_IMPORT_PROJECT_ACTIVITY_REQUEST, project.sendImportProjectActivityRequest);
  yield takeLatest(SEND_UPDATE_PROJECT_ASSIGNMENT_REQUEST, project.sendUpdateProjectAssignmentRequest);
  yield takeLatest(LOAD_PROJECT_ACTIVITY_LIST_BY_STATUS_REQUEST, project.loadSummarisedProjectListRequest);
  yield takeLatest(LOAD_PROJECT_LIST_BY_STATUS_REQUEST, project.loadProjectListbyStatusRequest);
  yield takeLatest(LOAD_PROJECT_ALLOCATION_TYPE_REQUEST, project.loadProjectAllocationTypeListRequest);
  yield takeLatest(LOAD_PROJECT_TYPE_REQUEST, project.loadProjectTypeListRequest);
  yield takeLatest(LOAD_PROJECT_BILLING_TYPE_REQUEST, project.loadProjectBillingTypeListRequest);
  yield takeLatest(LOAD_PROJECT_TIME_INPUT_TYPE_REQUEST, project.loadProjectTimeInputTypeListRequest);
  yield takeLatest(LOAD_REPORT_BY_PROJECT_REQUEST, project.selectedProjectReportRequest);
  yield takeLatest(LOAD_TIMESHEET_LIST_REQUEST, timesheet.loadTimesheetListRequest);
  yield takeLatest(SEND_ADD_NEW_TIMESHEET_REQUEST, timesheet.sendAddNewTimesheetRequest);
  yield takeLatest(LOAD_SELECTED_TIMESHEET_REQUEST, timesheet.loadSelectedTimesheetRequest);
  yield takeLatest(SEND_UPDATE_TIMESHEET_REQUEST, timesheet.sendUpdateTimesheetRequest);
  yield takeLatest(SEND_DELETE_TIMESHEET_REQUEST, timesheet.sendDeleteTimesheetRequest);
  yield takeLatest(SEND_UPDATE_TIMESHEET_STATUS_REQUEST, timesheet.sendUpdateStatusTimesheetRequest);
  yield takeLatest(LOAD_TIME_TYPE_REQUEST, timesheet.loadTimeTypeListRequest);
  yield takeLatest(LOAD_TIMESHEET_DETAILS_REQUEST, timesheet.loadTimesheetDetailsRequest);
  yield takeLatest(SEND_DELETE_TIMESHEET_DETAILS_REQUEST, timesheet.sendDeleteTimesheetDetailsRequest);
  yield takeLatest(SEND_TIMESHEET_DETAILS_REQUEST, timesheet.sendNewTimesheetDetailsRequest);
  yield takeLatest(SEND_TIMESHEET_DETAILS_COPY_REQUEST, timesheet.copyTimesheetDetailsRequest);
  yield takeLatest(SEND_TIMESHEET_COPY_REQUEST, timesheet.copyTimesheetRequest);
  yield takeLatest(LOAD_TIMESHEET_APPROVALS_REQUEST, timesheet.loadTimesheetApprovalRequest);
  yield takeLatest(LOAD_EMPLOYEE_LIST_REQUEST, employee.loadEmployeeListRequest);
  yield takeLatest(SEND_ADD_NEW_EMPLOYEE_REQUEST, employee.sendAddNewEmployeeRequest);
  yield takeLatest(LOAD_SELECTED_EMPLOYEE_REQUEST, employee.loadSelectedEmployeeRequest);
  yield takeLatest(SEND_UPDATE_EMPLOYEE_REQUEST, employee.sendUpdateEmployeeRequest);
  yield takeLatest(LOAD_ACCOUNT_SYSTEM_REQUEST, accounting.loadAccountSystemRequest);
  yield takeLatest(LOAD_QUICKBOOKS_CONNECTION_REQUEST, accounting.loadQuickBooksConnectionRequest);
  yield takeLatest(SEND_DELETE_CONNECTION_REQUEST, accounting.sendDeleteConnectionRequest);
  yield takeLatest(SEND_CONNECTION_REQUEST, accounting.addConnectionRequest);
  yield takeLatest(SEND_CONNECTION_UPDATE_REQUEST, accounting.updateConnectionDateRequest);
  yield takeLatest(LOAD_INTEGRATION_SCENARIO_REQUEST, accounting.loadIntegrationScenarioRequest);
  yield takeLatest(SEND_INTEGRATION_SCENARIO_REQUEST, accounting.sendUpdateIntegrationScenarioRequest);
  yield takeLatest(LOAD_ACCOUNT_EMPLOYEE_REQUEST, a2aMapping.loadAccountEmployeeRequest);
  yield takeLatest(LOAD_A2A_EMPLOYEE_REQUEST, a2aMapping.loadMappingEmployeeRequest);
  yield takeLatest(LOAD_ACCOUNT_PAY_TYPE_REQUEST, a2aMapping.loadAccountPayTypeRequest);
  yield takeLatest(LOAD_A2A_PAY_TYPE_REQUEST, a2aMapping.loadMappingPayTypeRequest);
  yield takeLatest(SEND_A2A_PAY_TYPE_REQUEST, a2aMapping.sendUpdateMappingPayTypeRequest);
  yield takeLatest(SEND_A2A_EMPLOYEE_REQUEST, a2aMapping.sendUpdateMappingEmployeeRequest);
  yield takeLatest(LOAD_ACCOUNT_COMPANY_FILE_REQUEST, a2aMapping.loadAccountCompanyFileRequest);
  yield takeLatest(SEND_ACCOUNT_COMPANY_FILE_REQUEST, a2aMapping.sendCompanyFileRequest);
  yield takeLatest(LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_REQUEST, a2aMapping.loadEmployeeSynchroniseRequest);
  yield takeLatest(LOAD_ACCOUNT_CUSTOMER_REQUEST, a2aMapping.loadAccountCustomerRequest);
  yield takeLatest(LOAD_A2A_CUSTOMER_REQUEST, a2aMapping.loadMappingCustomerRequest);
  yield takeLatest(SEND_A2A_CUSTOMER_REQUEST, a2aMapping.sendUpdateMappingCustomerRequest);
  yield takeLatest(LOAD_A2A_PROJECT_REQUEST, a2aMapping.loadMappingProjectRequest);
  yield takeLatest(LOAD_TITLE_LIST_REQUEST, title.loadTitleListRequest);
  yield takeLatest(LOAD_USER_ROLE_LIST_REQUEST, userRole.loadUserRoleListRequest);
  yield takeLatest(LOAD_USER_ROLE_OBJECT_LIST_REQUEST, userRole.loadUserRoleObjectListRequest);
  yield takeLatest(LOAD_OBJECT_LIST_REQUEST, userRole.loadObjectRequest);
  yield takeLatest(LOAD_OBJECT_OPERATION_LIST_REQUEST, userRole.loadObjectOperationRequest);
  yield takeLatest(SEND_ADD_NEW_USER_ROLE_REQUEST, userRole.sendAddNewUserRoleRequest);
  yield takeLatest(SEND_UPDATE_USER_ROLE_REQUEST, userRole.sendUpdateUserRoleRequest);
  yield takeLatest(SEND_DELETE_USER_ROLE_REQUEST, userRole.sendDeleteUserRoleRequest);
  yield takeLatest(LOAD_CUSTOMER_LIST_REQUEST, customer.loadCustomerListRequest);
  yield takeLatest(SEND_ADD_NEW_CUSTOMER_REQUEST, customer.sendAddNewCustomerRequest);
  yield takeLatest(LOAD_SELECTED_CUSTOMER_REQUEST, customer.loadSelectedCustomerRequest);
  yield takeLatest(SEND_UPDATE_CUSTOMER_REQUEST, customer.sendUpdateCustomerRequest);
  yield takeLatest(SEND_DELETE_CUSTOMER_REQUEST, customer.sendDeleteCustomerRequest);
  yield takeLatest(SEND_CUSTOMER_NOTIFICATION_REQUEST, customer.customerNotificationRequest);
  yield takeLatest(LOAD_REVENUE_FORCASTING_REQUEST, report.loadRevenueForcastingReportRequest);
  yield takeLatest(LOAD_REPORT_PERIOD_REQUEST, report.loadReportPeriodRequest);
  yield takeLatest(LOAD_REPORT_REQUEST, report.loadReportListRequest);
  yield takeLatest(LOAD_REPORT_PRINT_REQUEST, report.loadReportPrintRequest);
  yield takeLatest(LOAD_REPORT_CSV_REQUEST, report.loadReportCSVRequest);
  yield takeLatest(LOAD_INVOICE_LIST_REQUEST, invoice.loadInvoiceListRequest);
  yield takeLatest(SEND_ADD_NEW_INVOICE_REQUEST, invoice.sendAddNewInvoiceRequest);
  yield takeLatest(LOAD_SELECTED_INVOICE_REQUEST, invoice.loadSelectedInvoiceRequest);
  yield takeLatest(SEND_DELETE_INVOICE_REQUEST, invoice.sendDeleteInvoiceRequest);
  yield takeLatest(SEND_UPDATE_INVOICE_REQUEST, invoice.sendUpdateInvoiceRequest);
  yield takeLatest(LOAD_INVOICE_PRINT_REQUEST, invoice.sendPrintInvoiceRequest);
  yield takeLatest(LOAD_INVOICE_SEND_REQUEST, invoice.sendUpdateEmailInvoiceRequest);
  yield takeLatest(LOAD_INVOICE_ADD_SEND_REQUEST, invoice.sendAddNewInvoiceEmailRequest);
  yield takeLatest(LOAD_INVOICE_ADD_PRINT_REQUEST, invoice.sendAddAndPrintInvoiceRequest);
  yield takeLatest(LOAD_INVOICE_PRINT_BY_ID_REQUEST, invoice.loadPrintbyIdRequest);
  yield takeLatest(LOAD_PAYMENT_TERM_REQUEST, invoice.loadPaymentTermRequest);
  yield takeLatest(LOAD_INVOICE_EMAIL_REQUEST, invoice.loadInvoiceEmailRequest);
  yield takeLatest(SEND_INVOICE_EMAIL_REQUEST, invoice.sendInvoiceEmailRequest);
  // yield takeLatest(SEND_INVOICE_NOTIFICATION_REQUEST, invoice.invoiceNotificationRequest);
  yield takeLatest(LOAD_PAYMENT_METHOD_REQUEST, invoice.loadPaymentMethodRequest);
  yield takeLatest(LOAD_INVOICE_TIMESHEET_DETAILS_REQUEST, invoice.loadInvoiceTimesheetDetailsRequest);
  yield takeLatest(LOAD_UOM_REQUEST, loadUOMRequest);
  yield takeLatest(LOAD_APP_SERVICE_TENANT_REQUEST, appBilling.loadApptenantServiceRequest);
  yield takeLatest(SEND_APP_BILLING_REQUEST, appBilling.sendUpdateBillingRequest);
  yield takeLatest(LOAD_PAYMENT_SETUP_REQUEST, appBilling.loadPaymentSetupRequest);
  yield takeLatest(SEND_PAYMENT_METHOD_REQUEST, appBilling.sendUpdatePaymentMethodRequest);
  yield takeLatest(LOAD_APP_INVOICE_LIST_REQUEST, appBilling.loadAppInvoiceListRequest);
  yield takeLatest(LOAD_SELECTED_APP_INVOICE_REQUEST, appBilling.loadSelectedAppInvoiceRequest);
  yield takeLatest(LOAD_APP_INVOICE_PRINT_REQUEST, appBilling.loadPrintbyIdRequest);
  yield takeLatest(LOAD_APP_PAYMENT_METHOD_REQUEST, appBilling.loadAppPaymentMethodRequest);
  yield takeLatest(SEND_DEFAULT_PAYMENT_METHOD_REQUEST, appBilling.sendDefaultPaymentMethodRequest);
  yield takeLatest(SEND_UPDATE_PAYMENT_METHOD_REQUEST, appBilling.sendUpdatePaymentMethodDetailsRequest);
  yield takeLatest(LOAD_EMAIL_SERVER_LIST_REQUEST, emailConfig.loadEmailServerListRequest);
  yield takeLatest(LOAD_EMAIL_CLIENT_LIST_REQUEST, emailConfig.loadEmailClientListRequest);
  yield takeLatest(SEND_ADD_NEW_EMAIL_CLIENT_REQUEST, emailConfig.sendAddNewEmailClientRequest);
  yield takeLatest(SEND_UPDATE_EMAIL_CLIENT_REQUEST, emailConfig.sendUpdateEmailClientRequest);
  yield takeLatest(SEND_DELETE_EMAIL_CLIENT_REQUEST, emailConfig.sendDeleteEmailClientRequest);
}