import { fromJS } from 'immutable';
import {
  LOAD_A2A_CUSTOMER_ERROR,
  LOAD_A2A_CUSTOMER_LOADING,
  LOAD_A2A_CUSTOMER_SUCCESS,
  LOAD_A2A_EMPLOYEE_SUCCESS,
  LOAD_A2A_MAPPING_ERROR,
  LOAD_A2A_MAPPING_LOADING,
  LOAD_A2A_MAPPING_SUCCESS,
  LOAD_A2A_PAY_TYPE_SUCCESS,
  LOAD_A2A_PROJECT_ERROR,
  LOAD_A2A_PROJECT_LOADING,
  LOAD_A2A_PROJECT_SUCCESS,
  LOAD_ACCOUNT_COMPANY_FILE_SUCCESS,
  LOAD_ACCOUNT_CUSTOMER_SUCCESS,
  LOAD_ACCOUNT_EMPLOYEE_SUCCESS,
  LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_SUCCESS,
  LOAD_ACCOUNT_PAY_TYPE_SUCCESS,
  SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS,
  SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS,
  SAVE_IS_LOAD_ACCOUNT_CUSTOMER,
  SEND_A2A_CUSTOMER_SUCCESS,
} from '../constant/a2aMappingConstant';

export const initialState = fromJS({
  accountEmployeeList: [],
  mappingEmployeeList: [],
  isA2AMappingLoading: false,
  a2aMappingError: null,
  a2aMappingSuccess: null,
  isAccountEmployeeList: false,
  accountPayTypeList: [],
  mappingPayTypeList: [],
  isAccountPayTypeList: false,
  accountCompanyFileList: [],
  empolyeeSynchronise: {},
  accountCustomerList: [],
  isAccountCustomerList: false,
  mappingCustomerList: [],
  a2aCustomerLoading: false,
  a2aCustomerError: null,
  mappingCustomerSuccess: null,
  mappingProjectList: [],
  a2aProjectLoading: false,
  a2aProjectError: null,
});

function a2aMappingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_A2A_MAPPING_LOADING:
      return state.set('isA2AMappingLoading', action.payload)
    case LOAD_A2A_MAPPING_ERROR:
      return state.set('a2aMappingError', action.payload)
    case LOAD_A2A_MAPPING_SUCCESS:
      return state.set('a2aMappingSuccess', action.payload)
    case LOAD_ACCOUNT_EMPLOYEE_SUCCESS:
      return state.set('accountEmployeeList', action.payload)
    case LOAD_A2A_EMPLOYEE_SUCCESS:
      return state.set('mappingEmployeeList', action.payload)
    case SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS:
      return state.set('isAccountEmployeeList', action.payload)
    case LOAD_ACCOUNT_PAY_TYPE_SUCCESS:
      return state.set('accountPayTypeList', action.payload)
    case LOAD_A2A_PAY_TYPE_SUCCESS:
      return state.set('mappingPayTypeList', action.payload)
    case SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS:
      return state.set('isAccountPayTypeList', action.payload)
    case LOAD_ACCOUNT_COMPANY_FILE_SUCCESS:
      return state.set('accountCompanyFileList', action.payload)
    case LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_SUCCESS:
      return state.set('empolyeeSynchronise', action.payload)
    case LOAD_ACCOUNT_CUSTOMER_SUCCESS:
      return state.set('accountCustomerList', action.payload)
    case SAVE_IS_LOAD_ACCOUNT_CUSTOMER:
      return state.set('isAccountCustomerList', action.payload)
    case LOAD_A2A_CUSTOMER_SUCCESS:
      return state.set('mappingCustomerList', action.payload)
    case LOAD_A2A_CUSTOMER_LOADING:
      return state.set('a2aCustomerLoading', action.payload)
    case LOAD_A2A_CUSTOMER_ERROR:
      return state.set('a2aCustomerError', action.payload)
    case SEND_A2A_CUSTOMER_SUCCESS:
      return state.set('mappingCustomerSuccess', action.payload)
    case LOAD_A2A_PROJECT_SUCCESS:
      return state.set('mappingProjectList', action.payload)
    case LOAD_A2A_PROJECT_LOADING:
      return state.set('a2aProjectLoading', action.payload)
    case LOAD_A2A_PROJECT_ERROR:
      return state.set('a2aProjectError', action.payload)
    default:
      return state;
  }
}

export default a2aMappingReducer;
