const PATH = 'app/App';

export const LOAD_OBJECT_STATUS_REQUEST = `${PATH}LOAD_OBJECT_STATUS_REQUEST`;
export const LOAD_OBJECT_STATUS_SUCCESS = `${PATH}LOAD_OBJECT_STATUS_SUCCESS`;
export const LOAD_UOM_REQUEST = `${PATH}LOAD_UOM_REQUEST`;
export const LOAD_UOM_SUCCESS = `${PATH}LOAD_UOM_SUCCESS`;
export const LOAD_COUNTRY_REQUEST = `${PATH}LOAD_COUNTRY_REQUEST`;
export const LOAD_COUNTRY_SUCCESS = `${PATH}LOAD_COUNTRY_SUCCESS`;
export const LOAD_TENANT_REQUEST = `${PATH}LOAD_TENANT_REQUEST`;
export const LOAD_TENANT_SUCCESS = `${PATH}LOAD_TENANT_SUCCESS`;
export const LOAD_TENANT_USER_REQUEST = `${PATH}LOAD_TENANT_USER_REQUEST`;
export const LOAD_TENANT_USER_SUCCESS = `${PATH}LOAD_TENANT_USER_SUCCESS`;
export const SAVE_USER_ACCESS = `${PATH}SAVE_USER_ACCESS`;
export const SAVE_COMMON_SEARCH_SUCCESS = `${PATH}SAVE_COMMON_SEARCH_SUCCESS`;
export const SAVE_TENANT_USER_EXPIRE_STATUS = `${PATH}SAVE_TENANT_USER_EXPIRE_STATUS`;
export const LOAD_APP_BILLING_TERMS_REQUEST = `${PATH}LOAD_APP_BILLING_TERMS_REQUEST`;
export const LOAD_APP_BILLING_TERMS_SUCCESS = `${PATH}LOAD_APP_BILLING_TERMS_SUCCESS`;
export const LOAD_APP_PACKAGE_REQUEST = `${PATH}LOAD_APP_PACKAGE_REQUEST`;
export const LOAD_APP_PACKAGE_SUCCESS = `${PATH}LOAD_APP_PACKAGE_SUCCESS`;
export const LOAD_PUBLIC_CALENDAR_REQUEST = `${PATH}LOAD_PUBLIC_CALENDAR_REQUEST`;
export const LOAD_PUBLIC_CALENDAR_SUCCESS = `${PATH}LOAD_PUBLIC_CALENDAR_SUCCESS`;
export const LOAD_APP_UOM_REQUEST = `${PATH}LOAD_APP_UOM_REQUEST`;
export const LOAD_APP_UOM_SUCCESS = `${PATH}LOAD_APP_UOM_SUCCESS`;
export const LOAD_UOM_CONVERSION_FACTOR_REQUEST = `${PATH}LOAD_UOM_CONVERSION_FACTOR_REQUEST`;
export const LOAD_UOM_CONVERSION_FACTOR_SUCCESS = `${PATH}LOAD_UOM_CONVERSION_FACTOR_SUCCESS`;