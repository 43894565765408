import { fromJS } from 'immutable';
import {
  SAVE_LOGIN_USER_STATUS, SAVE_USER_EXPIRE_SUCCESS, SEND_ACTIVE_USER_SUCCESS,
} from '../constant/authConstant';

export const initialState = fromJS({
    isLoginUser:false,
    userExpireDetails: '',
    userActiveAfterExpire:false
});

function authReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_LOGIN_USER_STATUS:
      return state.set('isLoginUser', action.open)
    case SAVE_USER_EXPIRE_SUCCESS:
      return state.set('userExpireDetails', action.open)
    case SEND_ACTIVE_USER_SUCCESS:
      return state.set('userActiveAfterExpire', action.open)
    default:
      return state;
  }
}

export default authReducer;
