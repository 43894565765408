import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewEmployeeRequest(data){
    const parameters = JSON.stringify(data)
    // console.log(parameters)
      return axios.post(baseURL+'employees', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
      })
}

export function getEmployeeList(parameters){
    return axios.get(baseURL+'employees?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&statusId='+parameters.statusId+'&firstName='+parameters.firstName+'&lastName='+parameters.lastName+'&email='+parameters.email+'&mobile='+parameters.mobile+'&commonSearch='+parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateEmployeeRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'employees/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedEmployeeRequest(id){
    return axios.get(baseURL+'employees/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}
