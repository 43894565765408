import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/customerSelector';
import { makeSelectTitleList } from '../../../../store/selector/titleSelector';
import { makeSelectCountryList, makeSelectObjectStatusList } from '../../../../store/selector/selector'
import * as actions from '../../../../store/action/customerAction';
import { loadTitleListRequest } from '../../../../store/action/titleAction';
import PropTypes from 'prop-types';
import '../../settingMenu/settingMenu.css';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/cjs/Form';
import moment from 'moment';
import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import AddNewCustomer from './AddNewCustomer';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import { Link } from 'react-router-dom';
import Pagination from '../../../layout/form/Pagination';
import { loadObjectStatusRequest, loadObjectStatusSuccess } from '../../../../store/action/action';
import common from '../../../common';
import Avatar from 'react-avatar';
import SearchField from '../../../layout/form/SearchField';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';
import { loadSelectedProjectSuccess } from '../../../../store/action/projectAction';

class Customer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            name: '',
            email: '',
            mobile: '',
            city: '',
            commonSearch: '',
            isValidationError: false,
            isDeleteModalOpen: false,
            selectedRow: {},
            errorMassage: '',
            isUpdate: false,
            pageNumber: 1,
            pageSize: 20,
            isSMSModalOpen: false,
            isEmailModalOpen: false,
            isSelectedEmails: false,
            isSelectedSMS: false,
            selectedList: [],
            customers: [],
            initial: true,
            isAllSelect: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { customerList } = prevProps;
        if (customerList && customerList.result && customerList.result.length && prevState.initial) {
            return {
                customers: customerList.result,
                initial: false
            }
        }
        return null
    }

    handleModal = (data) => {
        if (data && data.id) {
            this.props.dispatch(actions.loadSelectedCustomerRequest(data.id))
            this.setState({ selectedRow: data, isUpdate: true })
        }
        this.props.dispatch(actions.saveIsCustomerModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({ selectedRow: {}, isUpdate: false })
        this.props.dispatch(actions.saveIsCustomerModalOpenStatus(false));
        this.props.dispatch(actions.loadSelectedCustomerSuccess(''))
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadCustomerListError(''))
    }

    successModalClose = () => {
        this.getCustomerListData()
        this.props.dispatch(actions.sendCustomerSuccess(''))
        this.handleModalClose()
        this.handleEmailClose()
        this.handleSMSClose()
        this.props.dispatch(actions.loadCustomerListLoading(false))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen: !this.state.isFilterFormOpen,
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            city: this.state.city,
            commonSearch: ''
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeSearchValue = (e) => {
        if (e.target.value === "" || e.target.value === null) {
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen: false,
            name: '',
            email: '',
            mobile: '',
            city: '',
            pageSize: 20,
            pageNumber: 1,
        })
    }

    handleFilter = () => {
        this.getCustomerListData()
        this.handleFilterForm()
    }

    handleFilterFormClose = () => {
        this.props.dispatch(actions.loadCustomerListSuccess([]))
        this.setState({
            isFilterFormOpen: false,
            name: '',
            email: '',
            mobile: '',
            city: '',
            pageSize: 20,
            pageNumber: 1,
            commonSearch: '',
            initial: true,
            customers: []
        })

        const parameters = {
            pageSize: 20,
            pageNumber: 1,
            name: '',
            email: '',
            mobile: '',
            city: '',
            commonSearch: ''
        }
        this.props.dispatch(actions.loadCustomerListRequest(parameters))
    }

    handleSaveAddNew = (details) => {
        const { selectedCustomer } = this.props
        if (details.displayName === '' ||
            // details.cityBilling === '' ||
            // details.countryBilling === '' ||
            details.status === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }
        // const addresses = []
        const notes = []
        notes.push({
            Title: "note",
            NoteName: details.notes,
            recordId: details.noteId ? details.noteId : ''
        })


        const parameters = {
            id: (selectedCustomer && selectedCustomer.id && this.state.isUpdate) ? selectedCustomer.id : '',
            TitleId: details.titleId,
            FirstName: details.firstName,
            MiddleName: details.middleName,
            LastName: details.lastName,
            EmailAddress: details.email === '' ? null : details.email,
            BusinessName: details.companyName,
            FixedPhone: details.phone,
            MobilePhone: details.mobile,
            Fax: details.fax,
            DisplayName: details.displayName,
            CustomField1: details.customField1,
            CustomField2: details.customField2,
            notes: notes,
            ObjectStatus: details.status,
            // addresses: addresses,
            shpStreetNumber: details.streetNoShipping,
            shpStreetName: details.streetNameShipping,
            shpCity: details.cityShipping,
            shpPostalCode: details.postCodeShipping,
            shpRegion: details.stateShipping,
            shpCountry: {
                code: details.countryShipping,
            },
            billStreetNumber: details.streetNoBilling,
            billStreetName: details.streetNameBilling,
            billCity: details.cityBilling,
            billPostalCode: details.postCodeBilling,
            billRegion: details.stateBilling,
            billCountry: {
                code: details.countryBilling,
            },
        }
        // console.log(details)
        if (this.state.isUpdate) {
            this.props.dispatch(actions.sendUpdateCustomerRequest(parameters))
        } else {
            this.props.dispatch(actions.sendAddNewCustomerRequest(parameters))
        }
    }

    handleDeleteConfirm = (data) => {
        this.setState({ isDeleteModalOpen: true, selectedRow: data })
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false, selectedRow: {} })
    }

    handleDeleteCustomer = () => {
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteCustomerRequest(this.state.selectedRow.id))
    }

    handleCheckCustomer = (e, data) => {
        const { customers, selectedList } = this.state;
        var list = selectedList
        const checked = e.target.checked;
        const customer = customers.find(element => element.id === data.id);
        const customerIndex = customers.findIndex(element => element.id === data.id);
        const newCustomer = {
            isSelect: checked,
            displayName: customer.displayName,
            billCity: customer.billCity,
            mobilePhone: customer.mobilePhone,
            emailAddress: customer.emailAddress,
            id: customer.id
        }
        customers[customerIndex] = newCustomer
        if (checked) {
            const selectedCustomer = {
                id: customer.id,
                destinationNumber: customer.mobilePhone,
                receiverEmail: customer.emailAddress,
            }
            list.push(selectedCustomer)
        } else if (!checked) {
            list = selectedList.filter(element => element.id !== data.id);
        }
        this.setState({
            customers,
            selectedList: list
        })
    }

    handleCheckAll = (e) => {
        const { customers, selectedList } = this.state;
        var list = selectedList
        const checked = e.target.checked
        for (let i = 0; i < customers.length; i++) {
            const customer = customers[i]
            const element = {
                isSelect: checked,
                displayName: customer.displayName,
                billCity: customer.billCity,
                mobilePhone: customer.mobilePhone,
                emailAddress: customer.emailAddress,
                id: customer.id
            }
            customers[i] = element
            if (checked) {
                const selectedCustomer = {
                    id: customer.id,
                    destinationNumber: customer.mobilePhone,
                    receiverEmail: customer.emailAddress,
                }
                list.push(selectedCustomer)
            } else if (!checked) {
                list = selectedList.filter(item => item.id !== customer.id);
            }
        }
        this.setState({
            isAllSelect: checked,
            customers
        })
    }

    handleSMS = (details) => {
        this.setState({
            isSMSModalOpen: true,
            selectedRow: details
        })
    }

    handleSMSClose = (details) => {
        const { customers } = this.state;
        for (let i = 0; i < customers.length; i++) {
            const customer = customers[i]
            const element = {
                isSelect: false,
                displayName: customer.displayName,
                billCity: customer.billCity,
                mobilePhone: customer.mobilePhone,
                emailAddress: customer.emailAddress,
                id: customer.id
            }
            customers[i] = element
        }
        this.setState({
            isSMSModalOpen: false,
            selectedRow: {},
            initial: true,
            isSelectedSMS: false,
            customers: customers,
            selectedList: []
        })
    }

    handleAddSMS = (details) => {
        const { isSelectedSMS, selectedList } = this.state;
        if (details.mobile === '' && !isSelectedSMS) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        const list = []
        if (isSelectedSMS) {
            for (let i = 0; i < selectedList.length; i++) {
                const e = selectedList[i]
                const element = {
                    id: e.id,
                    destinationNumber: e.destinationNumber
                }
                list.push(element)
            }
        } else {
            const element = {
                id: details.id,
                destinationNumber: details.mobile
            }
            list.push(element)
        }
        const date = moment(details.date).format(common.dateFormat);
        const setDate = date + "T" + details.requestedTime
        const dateTime = new Date(setDate)
        var utcTime = new Date(dateTime).toISOString();
        const parms = {
            SMSBody: details.sms,
            SMS: list,
            RequestedDate: utcTime,
            objectId: common.objectId.customer
        }
        this.props.dispatch(actions.sendCustomerNotificationRequest(parms))
    }

    handleAddEmail = (details) => {
        const { isSelectedEmails, selectedList } = this.state;
        if (details.emailAddress === '' && !isSelectedEmails) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        if (details.emailSubject === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        const list = []
        if (isSelectedEmails) {
            for (let i = 0; i < selectedList.length; i++) {
                const e = selectedList[i]
                const element = {
                    id: e.id,
                    receiverEmail: e.receiverEmail
                }
                list.push(element)
            }
        }
        else {
            const element = {
                id: details.id,
                receiverEmail: details.emailAddress
            }
            list.push(element)
        }
        const date = moment(details.date).format(common.dateFormat);
        const setDate = date + "T" + details.requestedTime
        const dateTime = new Date(setDate)
        var utcTime = new Date(dateTime).toISOString();
        const parms = {
            EmailBody: encodeURIComponent(details.email),
            Email: list,
            EmailSubject: details.emailSubject,
            RequestedDate: utcTime,
            objectId: common.objectId.customer
        }
        this.props.dispatch(actions.sendCustomerNotificationRequest(parms))
    }

    handleSelectedEmail = () => {
        if (this.state.selectedList.length === 0) {
            this.setState({ isValidationError: true, errorMassage: 'Please select customers' })
            return
        }
        this.setState({
            isEmailModalOpen: true,
            isSelectedEmails: true
        })
    }

    handleSelectedSMS = () => {
        if (this.state.selectedList.length === 0) {
            this.setState({ isValidationError: true, errorMassage: 'Please select customers' })
            return
        }
        this.setState({
            isSMSModalOpen: true,
            isSelectedSMS: true
        })
    }

    handleEmail = (details) => {
        this.setState({
            isEmailModalOpen: true,
            selectedRow: details
        })
    }

    handleEmailClose = (details) => {
        const { customers } = this.state;
        for (let i = 0; i < customers.length; i++) {
            const customer = customers[i]
            const element = {
                isSelect: false,
                displayName: customer.displayName,
                billCity: customer.billCity,
                mobilePhone: customer.mobilePhone,
                emailAddress: customer.emailAddress,
                id: customer.id
            }
            customers[i] = element
        }
        this.setState({
            isEmailModalOpen: false,
            selectedRow: {},
            initial: true,
            isSelectedEmails: false,
            customers: customers,
            selectedList: []
        })
    }

    sendSMSTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Send SMS
        </Tooltip>
    );

    sendEmailTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Send Email
        </Tooltip>
    );

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    getCustomerListData = () => {
        this.props.dispatch(actions.loadCustomerListLoading(true))
        this.props.dispatch(actions.loadCustomerListSuccess([]))
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            city: this.state.city,
            commonSearch: this.state.commonSearch
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.setState({
            customers: [],
            initial: true
        })
        this.props.dispatch(actions.loadCustomerListRequest(parameters))
    }

    onPressKeySearch = (value) => {
        this.props.dispatch(actions.loadCustomerListSuccess([]))
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            city: this.state.city,
            commonSearch: value
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.setState({
            commonSearch: value,
            customers: [],
            initial: true
        })
        this.props.dispatch(actions.loadCustomerListRequest(parameters))
    }

    addTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Add new Customer
        </Tooltip>
    );

    filterTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Filter
        </Tooltip>
    );

    componentDidMount() {
        this.props.dispatch(actions.loadCustomerListSuccess([]))
        this.props.dispatch(actions.loadSelectedCustomerSuccess(''))
        this.props.dispatch(loadSelectedProjectSuccess(''))
        this.props.dispatch(loadObjectStatusSuccess([]))
        const parameters = {
            pageSize: 9999,
            pageNumber: '',
            name: '',
        }
        this.getCustomerListData()
        this.props.dispatch(loadTitleListRequest(parameters))
        this.props.dispatch(loadObjectStatusRequest(common.objectId.customer));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.customerSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }

        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getCustomerListData()
        }
    }

    render() {

        const {
            isAddNewCustomerModalOpen,
            customerList,
            isCustomerListLoading,
            customerListError,
            customerSuccess,
            titleList,
            objectStatusList,
            countryList,
            selectedCustomer,
            // tenantUserExpireStatus,
            // emailTemplateList,
            // smsTemplateList,
            userAccess
        } = this.props;

        const {
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow,
            // isSMSModalOpen,
            // isEmailModalOpen,
            customers,
            // selectedList
        } = this.state;

        const columns = [{
            Header: () => (
                <div style={{ margin: '5px 0 -10px 0', textAlign: "center" }}>
                    <Form.Group controlId="addressCheckBox">
                        <Form.Check type="checkbox" style={{ fontSize: "14px" }} onChange={(e) => this.handleCheckAll(e)} checked={this.state.isAllSelect} />
                    </Form.Group>
                </div>
            ),
            accessor: 'select',
            width: "3%",
            Cell: ((row) => (
                <div style={{ margin: '5px 0 -10px 0', textAlign: "center" }}>
                    <Form.Group controlId="addressCheckBox">
                        <Form.Check type="checkbox" style={{ fontSize: "14px" }} onChange={(e) => this.handleCheckCustomer(e, row.row.original)} checked={row.row.original.isSelect} />
                    </Form.Group>
                </div>
            ))
        }, {
            Header: 'Name',
            // accessor: 'displayName',
            Cell: ((row) =>
                <div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '10px' }}>
                            <Avatar name={row.row.original.displayName} size={30} round="50%" maxInitials={2} />
                        </div>
                        <div style={{ marginTop: '5px' }}>{row.row.original.displayName}</div>
                    </div>
                </div>
            )
        }, {
            Header: 'City',
            accessor: 'billCity',
            // Cell:((row) => (
            //     <div>
            //         {row.row.original.addresses && row.row.original.addresses[0].city}
            //     </div>
            // )),
            width: "15%",
        }, {
            Header: 'Mobile',
            accessor: 'mobilePhone',
            width: "15%",
        }, {
            Header: 'Email',
            accessor: 'emailAddress',
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {/* <Button */}
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                        {/* <span onClick={()=>this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>   */}
                        <span className="dropdown" style={{ float: 'right' }}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                {userAccess && userAccess.deleteCustomer ? <li onClick={() => this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="action-icon"></ui5-icon> Delete</li> : ''}
                            </div>
                        </span>
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {isCustomerListLoading ?
                    <Loader show={isCustomerListLoading} /> : ''}

                {(isValidationError || customerListError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : customerListError}
                        handleClose={this.errorModalClose}
                    />}

                {(customerSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={customerSuccess}
                        handleClose={this.successModalClose}
                    />}
                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteCustomer}
                        title={`${selectedRow.firstName} ${selectedRow.middleName} ${selectedRow.lastName}`}
                    />}
                {/* <div className="content-header">
                    <div className="header-pages">
                        <span>
                            <Link to='./customer'>
                                <ui5-icon class="samples-margin" name="customer" id="tab-icon"></ui5-icon> Customer
                            </Link>
                        </span>
                    </div>
                    <div className="header-btn">
                        <ButtonGroup
                            primaryBtnClick={this.handleModal}
                            secondaryBtnClick={this.handleFilterForm}
                            primaryBtnVariant="outline-primary"
                            primaryBtnText='Add New'
                            primaryBtnIcon='sys-add'
                            primaryBtnDisabled={userAccess && !userAccess.createCustomer}
                            secondaryBtnText='Filter'
                            secondaryBtnVariant="outline-primary" 
                            secondaryBtnIcon='filter'
                        />
                    </div>
                </div> */}
                <div className="content-header">
                    <div className="header-pages">
                        <span>
                            {userAccess &&
                                userAccess.displayCustomer &&
                                <Link to='./customer'>
                                    <ui5-icon class="samples-margin" name="customer" id="header-icon"></ui5-icon><span>CUSTOMER</span>
                                </Link>}
                            {userAccess &&
                                userAccess.displayInvoice &&
                                <Link to='./invoice'>
                                    <ui5-icon class="samples-margin" name="monitor-payments" id="header-icon"></ui5-icon><span>INVOICE</span>
                                </Link>}
                        </span>

                    </div>
                </div>
                <div className="content-body">
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <h5 style={{ marginLeft: '5px' }}>Customer List</h5>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                <SearchField
                                    onClickSearch={this.getCustomerListData}
                                    controlId="commonSearch"
                                    onChange={this.handleChangeSearchValue}
                                    defaultValue={this.state.commonSearch}
                                    onPressKeySearch={this.onPressKeySearch}
                                />
                            </div>
                        </div>
                        <div className="header-btn">
                            <div style={{ display: 'flex' }}>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.addTooltip}
                                >
                                    <span className={userAccess && userAccess.createCustomer ? "header-icon" : "header-icon-disabled"} onClick={userAccess && userAccess.createCustomer ? () => this.handleModal(false) : ''}>
                                        <ui5-icon class="samples-margin" name="add" id="header-icon"></ui5-icon>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.filterTooltip}
                                >
                                    <span className="header-icon" onClick={() => this.handleFilterForm()}>
                                        <ui5-icon class="samples-margin" name="filter" id="header-icon"></ui5-icon>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    {isFilterFormOpen &&
                        <div className="filter-form">
                            <div className="filter-header">
                                <h5>Filter Form</h5>
                                <div className="btn-close" onClick={this.handleFilterFormClose} >
                                    <ui5-icon class="samples-margin" name="decline" id="btn-icon"></ui5-icon>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="Name"
                                        controlId="name"
                                        placeholder="Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.name}
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Email"
                                        controlId="email"
                                        placeholder="Email"
                                        type="email"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.email}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="City"
                                        controlId="city"
                                        placeholder="City"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.city}
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Mobile"
                                        controlId="mobile"
                                        placeholder="Mobile"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.mobile}
                                    />
                                </div>
                            </div>
                            <div className="filter-btns">
                                <ButtonGroup
                                    primaryBtnClick={this.handleFilter}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary"
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary"
                                />
                            </div>
                        </div>}
                    <div className="table-content">
                        <Table
                            columns={columns}
                            data={customers}
                        />
                    </div>
                </div>
                <Pagination
                    currentPage={customerList.page ? customerList.page : this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage={customerList.totalPages}
                    setClickedPage={this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
                {isAddNewCustomerModalOpen &&
                    <AddNewCustomer
                        isAddNewCustomerModalOpen={isAddNewCustomerModalOpen}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                        selectedRow={selectedRow}
                        titleList={titleList}
                        objectStatusList={objectStatusList}
                        countryList={countryList}
                        selectedCustomer={selectedCustomer}
                        isUpdate={this.state.isUpdate}
                        userAccess={userAccess}
                    />
                }
                {/* {isSMSModalOpen && 
                    <SMS
                        isModalOpen={isSMSModalOpen}
                        handleModalClose={this.handleSMSClose}
                        data={selectedRow}
                        handleSaveAddNew={this.handleAddSMS}
                        isSelectedSMS={this.state.isSelectedSMS}
                        smsTemplateList={smsTemplateList.result && smsTemplateList.result.length ? smsTemplateList.result : [] }
                    />}
                {isEmailModalOpen && 
                    <Email
                        isModalOpen={isEmailModalOpen}
                        handleModalClose={this.handleEmailClose}
                        data={selectedRow}
                        handleSaveAddNew={this.handleAddEmail}
                        selectedList={selectedList}
                        isSelectedEmails={this.state.isSelectedEmails}
                        emailTemplateList={emailTemplateList.result && emailTemplateList.result.length ? emailTemplateList.result : [] }
                    />} */}
            </div>
        )
    }
}

Customer.propTypes = {
    isAddNewCustomerModalOpen: PropTypes.bool,
    customerList: PropTypes.any,
    isCustomerListLoading: PropTypes.bool,
    customerListError: PropTypes.any,
    customerSuccess: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedCustomer: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
    isAddNewCustomerModalOpen: selectors.makeSelectCustomerPopupOpen(),
    customerList: selectors.makeSelectCustomerList(),
    isCustomerListLoading: selectors.makeSelectCustomerListLoading(),
    customerListError: selectors.makeSelectCustomerListError(),
    customerSuccess: selectors.makeSelectCustomerSuccess(),
    titleList: makeSelectTitleList(),
    objectStatusList: makeSelectObjectStatusList(),
    countryList: makeSelectCountryList(),
    selectedCustomer: selectors.makeSelectSelectedCustomerSuccess(),
    // tenantUserExpireStatus: makeSelectTenantUserExpireStatus(),
    userAccess: makeSelectUserAccessSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Customer));