import { createSelector } from 'reselect';

const selectEmailClientDomain = state => state.emailConfiguration;

const makeSelectEmailClientList = () =>
  createSelector(
    selectEmailClientDomain,
    emailClientState =>
      emailClientState ? emailClientState.get('emailClientList') : []
  );

const makeSelectEmailClientListLoading = () =>
  createSelector(
    selectEmailClientDomain,
    emailClientState =>
      emailClientState ? emailClientState.get('isEmailClientListLoading') : false
  );

const makeSelectEmailClientListError = () =>
  createSelector(
    selectEmailClientDomain,
    emailClientState =>
      emailClientState ? emailClientState.get('emailClientListError') : false
  );

const makeSelectEmailClientSuccess = () =>
  createSelector(
    selectEmailClientDomain,
    emailClientState =>
      emailClientState ? emailClientState.get('emailClientSuccess') : false
  );

const makeSelectEmailServerList = () =>
  createSelector(
    selectEmailClientDomain,
    emailClientState =>
      emailClientState ? emailClientState.get('emailServer') : []
  );

export {
  makeSelectEmailClientList,
  makeSelectEmailClientListLoading,
  makeSelectEmailClientListError,
  makeSelectEmailClientSuccess,
  makeSelectEmailServerList
}
