import React from 'react';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Form from 'react-bootstrap/cjs/Form';
import TextField from '../../../layout/form/TextField';
import '../../pages.css';
import common from '../../../common';
import SelectField from '../../../layout/form/SelectField';

const Address = ({
    data,
    handleChange,
    countryList,
    handleCheck,
    handleChangeBillingCountry,
    handleChangeShippingCountry,
    isClickMoreFields,
    isUserAccess
}) => (
    <div>
        <div className="row" style={{margin:'5px'}}>
            <div className="col-sm-6">
                <div style={{display:'flex'}}>
                    <span className="address-title" >Billing Address</span>
                </div>
                <div className="row" style={{marginTop:'15px'}}>
                    <div className="col-sm">
                        <TextField
                            text="Street No"
                            controlId="streetNoBilling"
                            // placeholder="Street No"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.streetNoBilling}
                            maxLength={common.maxLengths.streetNo}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            text="Street Name"
                            controlId="streetNameBilling"
                            // placeholder="Street Name"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.streetNameBilling}
                            maxLength={common.maxLengths.streetName}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <TextField
                            text="City"
                            controlId="cityBilling"
                            // placeholder="City"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.cityBilling}
                            maxLength={common.maxLengths.cityName}
                            disabled = {!isClickMoreFields || !isUserAccess}
                            // required
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            text="State"
                            controlId="stateBilling"
                            // placeholder="state"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.stateBilling}
                            maxLength={common.maxLengths.region}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <TextField
                            text="Post Code"
                            controlId="postCodeBilling"
                            // placeholder="Post Code"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.postCodeBilling}
                            maxLength={common.maxLengths.postCode}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                    <div className="col-sm">
                        {/* <TextField
                            text="Country"
                            controlId="countryBilling"
                            // placeholder="country"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.countryBilling}
                            as="select"
                            options={countryList}
                            required
                        /> */}
                        <SelectField
                            text="Country"
                            controlId="countryBilling"
                            onChange={handleChangeBillingCountry}
                            options={countryList}
                            selectedValue={data.countryBilling}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                        
                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="shipping-address">
                    <span className="address-title" >Shipping Address</span>
                    <Form.Group controlId="addressCheckBox">
                        <Form.Check type="checkbox" style={{fontSize:"14px"}} label="Same as billing" onChange={isUserAccess ? handleCheck : ''}/>
                    </Form.Group>
                </div>
                <div className="row" >
                    <div className="col-sm">
                        <TextField
                            text="Street No"
                            controlId="streetNoShipping"
                            // placeholder="Street No"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.streetNoShipping}
                            maxLength={common.maxLengths.streetNo}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            text="Street Name"
                            controlId="streetNameShipping"
                            // placeholder="Street Name"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.streetNameShipping}
                            maxLength={common.maxLengths.streetName}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <TextField
                            text="City"
                            controlId="cityShipping"
                            // placeholder="City"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.cityShipping}
                            maxLength={common.maxLengths.cityName}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            text="State"
                            controlId="stateShipping"
                            // placeholder="state"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.stateShipping}
                            maxLength={common.maxLengths.region}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <TextField
                            text="Post Code"
                            controlId="postCodeShipping"
                            // placeholder="Post Code"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.postCodeShipping}
                            maxLength={common.maxLengths.postCode}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                    <div className="col-sm">
                        {/* <TextField
                            text="Country"
                            controlId="countryShipping"
                            // placeholder="country"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.countryShipping}
                            as="select"
                            options={countryList}
                        /> */}
                        <SelectField
                            text="Country"
                            controlId="countryShipping"
                            onChange={handleChangeShippingCountry}
                            options={countryList}
                            selectedValue={data.countryShipping}
                            disabled = {!isClickMoreFields || !isUserAccess}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )

Address.propTypes = {
    data: PropTypes.any,
    handleChange: PropTypes.any,
    countryList: PropTypes.any,
    handleCheck: PropTypes.any,
    handleChangeBillingCountry: PropTypes.any,
    handleChangeShippingCountry: PropTypes.any,
    isClickMoreFields: PropTypes.any,
}

export default Address;