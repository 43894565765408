import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as actions from '../../../store/action/projectAction';
import * as selectors from '../../../store/selector/projectSelector';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../layout/form/Button';
import TextField from '../../layout/form/TextField';
import SelectField from '../../layout/form/SelectField';
import DatePicker from '../../layout/form/datePicker';
import common from '../../common';
import moment from 'moment';
import { makeSelectObjectStatusList } from '../../../store/selector/selector';
// import { makeSelectUserListSuccess } from '../../../store/selector/usersSelector';
import SuccessModal from '../../layout/form/successModal';
import { makeSelectCustomerList } from '../../../store/selector/customerSelector';
import { makeSelectEmployeeList } from '../../../store/selector/employeeSelector';


// const dateFormat = sessionStorage.getItem("dateFormat")
export class AddProject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: null,
            projectName: null,
            projectCode: null,
            customerId: null,
            planStartDate: null,
            planEndDate: null,
            actualStartDate: null,
            actualEndDate: null,
            status: null,
            notes: null,
            projectManagerId: 0,
            initial: true,
            isEdit: true,
            isChangeData: false,
            projectTypeId: null,
            projectBillingTypeId: null,
            purchaseOrderNo: null,
            projectTimeInputTypeId: null
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { selectedProject, isUpdate, objectStatusList } = prevProps;
        if (selectedProject && selectedProject.id && prevState.initial) {
            return {
                id: selectedProject.id,
                projectName: selectedProject.projectName,
                projectCode: selectedProject.projectCode,
                customerId: selectedProject.customerId,
                planStartDate: selectedProject.planStartDate,
                planEndDate: selectedProject.planEndDate,
                actualStartDate: selectedProject.actualStartDate,
                actualEndDate: selectedProject.actualEndDate,
                projectManagerId: selectedProject.projectManagerId,
                status: selectedProject.status,
                notes: selectedProject.notes,
                projectBillingTypeId: selectedProject.projectBillingTypeId,
                projectTimeInputTypeId: selectedProject.projectTimeInputTypeId,
                projectTypeId: selectedProject.projectTypeId,
                purchaseOrderNo: selectedProject.purchaseOrderNo,
                initial: false,
                isEdit: false,
                isChangeData: false
            }
        } else if (objectStatusList && objectStatusList.length && objectStatusList.length !== 0 && !isUpdate && prevState.initial) {
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            const currentDate = new Date()
            return {
                status: defaultStatus[0].id,
                id: null,
                projectName: null,
                projectCode: null,
                customerId: null,
                planStartDate: moment(currentDate).format(common.dateFormat),
                planEndDate: moment(currentDate).format(common.dateFormat),
                actualStartDate: moment(currentDate).format(common.dateFormat),
                actualEndDate: moment(currentDate).format(common.dateFormat),
                notes: null,
                projectManagerId: 0,
                initial: false,
            }
        }

        return null
    }

    handleEdit = () => {
        this.setState({
            isEdit: true
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            isChangeData: true
        })
    }

    handleBlur = (e) => {
        this.setState({
            [e.target.id]: e.target.value.toUpperCase(),
            isChangeData: true
        })
    }

    handleDateChange = (date, id) => {
        if (date === null) {
            return
        }
        this.setState({
            [id]: date === null ? '' : moment(date).format(common.dateFormat),
            isChangeData: true
        })
    }

    handleSelect = (value, id) => {
        this.setState({
            [id]: value,
            isChangeData: true
        })
    }

    editTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit
        </Tooltip>
    );

    successModalClose = () => {
        this.setState({
            initial: true,
            isChangeData: false
        })
        this.props.dispatch(actions.sendProjectSuccess(null))
    }

    componentDidMount() {
        this.props.dispatch(actions.sendProjectSuccess(null))
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.projectSuccess !== null) {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {
        const {
            handleClose,
            // handleSaveAddNew,
            handleTab,
            objectStatusList,
            employeeList,
            isUpdate,
            projectSuccess,
            modifyProject,
            customerList,
            handleSaveProject,
            projectBillingTypeList,
            projectTimeInputTypeList,
            projectTypeList,
            selectedProject
        } = this.props;

        return (
            <div>
                {(projectSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={projectSuccess}
                        handleClose={this.successModalClose}
                    />}
                <div className='content-body'>
                    <div className="filter-header">
                        <div className="header-pages">
                            <span>Project </span>
                        </div>
                        <div className="btn-close" onClick={handleClose} >
                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                        </div>
                    </div>
                    <div className="content-header" style={{ margin: '-20px 0 15px 0' }}>
                        <div className="header-pages">
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectDetails', this.state)}><ui5-icon class="samples-margin" name="header" id="title-icon"></ui5-icon> Header</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectMilestone', this.state)}><ui5-icon class="samples-margin" name="add-coursebook" id="title-icon"></ui5-icon> Milestone</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectActivities', this.state)}><ui5-icon class="samples-margin" name="multiselect-all" id="title-icon"></ui5-icon> Activities</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectAssignments', this.state)}><ui5-icon class="samples-margin" name="add-employee" id="title-icon"></ui5-icon> Assignments</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectApprovers', this.state)}><ui5-icon class="samples-margin" name="kpi-managing-my-area" id="title-icon"></ui5-icon> Approvers</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.editTooltip}
                            >
                                <span className={modifyProject ? "header-icon" : "header-icon-disabled"} onClick={modifyProject && isUpdate ? () => this.handleEdit() : ''}>
                                    <ui5-icon class="samples-margin" name="edit" id="header-icon"></ui5-icon>
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="profile-content">
                        <div className="page_header">
                            <div className="page_header-title">
                                <span>Header</span>
                            </div>
                            <div>
                                <form >
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <TextField
                                                text="Project Name"
                                                controlId="projectName"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.projectName}
                                                required
                                                disabled={!this.state.isEdit}
                                                maxLength={common.maxLengths.projectName}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <TextField
                                                text="Project Code"
                                                controlId="projectCode"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.projectCode}
                                                required
                                                disabled={!this.state.isEdit}
                                                maxLength={common.maxLengths.projectCode}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <SelectField
                                                text="Project Type"
                                                controlId="projectTypeId"
                                                onChange={(e) => this.handleSelect(e, 'projectTypeId')}
                                                options={
                                                    projectTypeList && projectTypeList.length && projectTypeList.length > 0 ?
                                                        projectTypeList.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ?
                                                            1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0)) : []}
                                                required={true}
                                                selectedValue={this.state.projectTypeId}
                                                disabled={!this.state.isEdit}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <SelectField
                                                text="Project Billing Type"
                                                controlId="projectBillingTypeId"
                                                onChange={(e) => this.handleSelect(e, 'projectBillingTypeId')}
                                                options={
                                                    projectBillingTypeList && projectBillingTypeList.length && projectBillingTypeList.length > 0 ?
                                                        projectBillingTypeList.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ?
                                                            1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0)) : []}
                                                required={true}
                                                selectedValue={this.state.projectBillingTypeId}
                                                disabled={!this.state.isEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-6">
                                            <SelectField
                                                text="Project manager"
                                                controlId="projectManagerId"
                                                onChange={(e) => this.handleSelect(e, 'projectManagerId')}
                                                options={
                                                    employeeList && employeeList.result && employeeList.result.length && employeeList.result.length > 0 ?
                                                        employeeList.result.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ?
                                                            1 : ((b.firstName.toLowerCase() > a.firstName.toLowerCase()) ? -1 : 0)) : []}
                                                required={true}
                                                selectedValue={this.state.projectManagerId}
                                                display='firstName'
                                                disabled={!this.state.isEdit}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <SelectField
                                                text="Customer"
                                                controlId="customerId"
                                                onChange={(e) => this.handleSelect(e, 'customerId')}
                                                options={
                                                    customerList && customerList.result && customerList.result.length && customerList.result.length > 0 ?
                                                        customerList.result.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ?
                                                            1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0)) : []}
                                                required
                                                selectedValue={this.state.customerId}
                                                display='displayName'
                                                disabled={!this.state.isEdit}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <SelectField
                                                text="Project Time Input Type"
                                                controlId="projectTimeInputTypeId"
                                                onChange={(e) => this.handleSelect(e, 'projectTimeInputTypeId')}
                                                options={
                                                    projectTimeInputTypeList && projectTimeInputTypeList.length && projectTimeInputTypeList.length > 0 ?
                                                        projectTimeInputTypeList.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ?
                                                            1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0)) : []}
                                                // required={true}
                                                selectedValue={this.state.projectTimeInputTypeId}
                                                disabled={!this.state.isEdit || (this.state.projectTimeInputTypeId !== null && selectedProject?.id > 0)}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Plan Start Date"
                                                controlId="planStartDate"
                                                onChange={(e) => this.handleDateChange(e, "planStartDate")}
                                                value={this.state.planStartDate === null ? '' : new Date(this.state.planStartDate)}
                                                disabled={!this.state.isEdit}
                                            // format={dateFormat}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Plan End Date"
                                                controlId="planEndDate"
                                                onChange={(e) => this.handleDateChange(e, "planEndDate")}
                                                value={this.state.planEndDate === null ? '' : new Date(this.state.planEndDate)}
                                                disabled={!this.state.isEdit}
                                            // format={dateFormat}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Actual Start Date"
                                                controlId="actualStartDate"
                                                onChange={(e) => this.handleDateChange(e, "actualStartDate")}
                                                value={this.state.actualStartDate === null ? '' : new Date(this.state.actualStartDate)}
                                                disabled={!this.state.isEdit}
                                            // format={dateFormat}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Actual End Date"
                                                controlId="actualEndDate"
                                                onChange={(e) => this.handleDateChange(e, "actualEndDate")}
                                                value={this.state.actualEndDate === null ? '' : new Date(this.state.actualEndDate)}
                                                disabled={!this.state.isEdit}
                                            // format={dateFormat}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-6">
                                            <TextField
                                                text="Notes"
                                                controlId="notes"
                                                value={this.state.notes}
                                                onChange={this.handleChange}
                                                as="textarea"
                                                row={50}
                                                style={{ height: 100 }}
                                                disabled={!this.state.isEdit}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <TextField
                                                text="Purchase Order No"
                                                controlId="purchaseOrderNo"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.purchaseOrderNo}
                                                disabled={!this.state.isEdit}
                                                maxLength={common.maxLengths.purchaseOrderNo}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <SelectField
                                                text="Status"
                                                controlId="status"
                                                onChange={(e) => this.handleSelect(e, 'status')}
                                                options={objectStatusList}
                                                required={true}
                                                selectedValue={this.state.status}
                                                disabled={!this.state.isEdit}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="outline-primary"
                                        text='Save'
                                        type="submit"
                                        onClick={() => handleSaveProject(this.state)}
                                        icon='save'
                                        disabled={!this.state.isEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AddProject.propTypes = {
    isModalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
    selectedProject: selectors.makeSelectSelectedProjectSuccess(),
    objectStatusList: makeSelectObjectStatusList(),
    employeeList: makeSelectEmployeeList(),
    projectSuccess: selectors.makeSelectProjectSuccess(),
    customerList: makeSelectCustomerList(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
export default (compose(withConnect)(AddProject));