import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import File from 'react-bootstrap/cjs/FormFile';
import '../settingMenu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../../layout/form/Button';
import TextField from '../../../layout/form/TextField';
import User from '../../../../images/user.png';
import common from '../../../common';
import SelectField from '../../../layout/form/SelectField';
import PhoneNumberField from '../../../layout/form/PhoneNumberField';
import { makeSelectObjectStatusList, makeSelectTenantUser } from '../../../../store/selector/selector';
import * as selectors from '../../../../store/selector/usersSelector';
import * as actions from '../../../../store/action/usersAction';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import { loadObjectStatusRequest } from '../../../../store/action/action';

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

class MyAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: '',
            userId: '',
            firstName: '',
            phoneNumber: '',
            email: '',
            lastName: '',
            userRole: 'general',
            notes: '',
            logo: '',
            initial: true,
            userRoleList: [],
            tempUserRoleList: [],
            isDeleteUserRole: false,
            employee: 0
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { tenantUser } = prevProps;
        if (tenantUser && tenantUser.id && prevState.initial) {
            var itemList = []

            if (tenantUser.userRoles.length !== 0) {
                for (let i = 0; i < tenantUser.userRoles.length; i++) {
                    const element = tenantUser.userRoles[i];
                    const newRow = {
                        roleId: element.roleId,
                        id: element.id,
                        operation: "U"
                    }
                    itemList.push(newRow)
                }
            }
            return {
                status: tenantUser.status,
                userId: tenantUser.userId,
                firstName: tenantUser.firstName,
                phoneNumber: tenantUser.mobileNo,
                email: tenantUser.email,
                lastName: tenantUser.lastName,
                userRole: tenantUser.userRole,
                notes: tenantUser.notes,
                logo: tenantUser.logo,
                initial: false,
                userRoleList: itemList
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    mobileNumChange = (e) => {
        this.setState({
            phoneNumber: e
        })
    }

    handleChangeLogo = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        this.setState({ logo: base64 })
    }

    addNewRow = () => {
        var { userRoleList } = this.state;
        var newRow = {
            roleId: 0,
            operation: "C",
            id: 0
        }
        userRoleList.push(newRow)
        this.setState({ userRoleList })
    }

    handleRole = (id, rowData) => {
        const { userRoleList } = this.state;
        const newRow = {
            roleId: id,
            id: rowData.original.id,
            operation: rowData.original.id > 0 ? "U" : "C"
        }
        userRoleList[rowData.index] = newRow
        this.setState({
            userRoleList
        })
    }

    handleDelete = (rowData) => {
        var { userRoleList } = this.state;
        const newRow = {
            roleId: rowData.original.roleId,
            id: rowData.original.id > 0 ? rowData.original.id : null,
            operation: "D"
        }
        userRoleList[rowData.index] = newRow
        this.setState({
            userRoleList,
            isDeleteUserRole: true,
        })
    }

    handleSaveAddNew = (details) => {
        const userId = sessionStorage.getItem("tenantUserId")
        if (
            details.firstName === '' ||
            details.lastName === '' ||
            details.phoneNumber === '' ||
            details.email === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        if (details.userRoleList.length === 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredUserRoleMassage })
            return
        }

        if (details.userId === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }
        const tempItemList = details.tempUserRoleList.filter(data => (data.operation === "D"))
        const list = details.userRoleList.concat(tempItemList)

        const parameters = {
            id: parseInt(userId),
            UserId: details.userId,
            FirstName: details.firstName,
            LastName: details.lastName,
            MobileNo: details.phoneNumber,
            Email: details.email,
            UserRole: details.userRole,
            Notes: details.notes === '' ? null : details.notes,
            Status: details.status === '' ? null : details.status,
            logo: details.logo,
            UserRoles: list,
            employee: details.employee
        }
        this.props.dispatch(actions.sendUserUpdateRequest(parameters))
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.sendAddNewUserError(''))
    }

    successModalClose = () => {
        this.props.dispatch(actions.sendAddNewUserSuccess(''))
    }

    componentDidMount() {
        this.props.dispatch(actions.loadUserRoleListRequest())
        this.props.dispatch(loadObjectStatusRequest(common.objectId.user));
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            userRoleList,
            isDeleteUserRole,
            tempUserRoleList,
        } = this.state;
        if (isDeleteUserRole) {
            const list = userRoleList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = userRoleList.filter(data => (data.id !== null))
            this.setState({
                userRoleList: list,
                isDeleteUserRole: false,
                tempUserRoleList: tempUserRoleList.concat(tempItemList)
            })
        }

        if (this.props.success !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {

        const {
            isValidationError,
            errorMassage
        } = this.state;

        const {
            objectStatusList,
            // roleList,
            // modifyUser,
            error,
            success,
            loading,
        } = this.props;

        return (
            <div>
                {loading ?
                    <Loader show={loading} /> : ''}

                {(isValidationError || error !== '') &&
                    <ErrorModal
                        show={true}
                        massage={error === '' ? errorMassage : error}
                        handleClose={this.errorModalClose}
                    />}
                {success !== '' &&
                    <SuccessModal
                        show={true}
                        massage={success}
                        handleClose={this.successModalClose}
                    />}
                <div className="profile-content">
                    <div className="page_header">
                        <div className='row'>
                            <div className='col-sm-5' style={{ border: '1px solid #becddf', borderRadius: '5px', margin: '10px 5px 5px 5px', paddingTop: '50px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className="userPhoto">
                                        <Image roundedCircle className="userProfileImage" src={this.state.logo ? this.state.logo : User} />
                                        <File id="userPhotoUpload" hidden ref={photoUpload => this.photoUpload = photoUpload} onChange={this.handleChangeLogo} />
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                variant="outline-primary"
                                                text='Change'
                                                size='sm'
                                                onClick={() => this.photoUpload.click()}
                                            />
                                        </div>
                                    </div>
                                    <div className='profile-businessName' style={{ margin: '50px 0', borderBottom: '1px solid #becddf' }}>
                                        {this.state.userId}
                                    </div>
                                    <div style={{ margin: '10px 0' }}>
                                        {this.state.firstName} {this.state.lastName}
                                    </div>
                                    <div style={{ margin: '10px 0' }}>
                                        {this.state.email}
                                    </div>
                                    <div style={{ margin: '10px 0 70px 0' }}>
                                        {this.state.phoneNumber}
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='profile-details'>
                                    <div className="page_header-title">
                                        <span>Basic Data</span>
                                    </div>
                                    <div className="row" style={{ margin: '25px 0' }}>
                                        <div className="col-sm">
                                            <TextField
                                                text="User Id"
                                                controlId="userId"
                                                placeholder="User Id"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.userId}
                                                maxLength={common.maxLengths.userId}
                                                disabled
                                            />
                                            <TextField
                                                text="First Name"
                                                controlId="firstName"
                                                placeholder="First Name"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.firstName}
                                                required
                                                maxLength={common.maxLengths.userFirstName}
                                            />
                                            <PhoneNumberField
                                                text="Phone Number"
                                                controlId="phoneNumber"
                                                type="text"
                                                // onBlur={this.mobileNumChange}
                                                hint="Please enter valid phone number (eg:- +xxxxxxxxxx)"
                                                // required={this.state.isMobileValidate}
                                                onChange={this.mobileNumChange}
                                                maxLength={common.maxLengths.userMobile}
                                                value={this.state.phoneNumber}
                                            // required
                                            />

                                        </div>
                                        <div className="col-sm">
                                            <TextField
                                                text="Email Address"
                                                controlId="email"
                                                placeholder="Email Address"
                                                type="email"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.email}
                                                required
                                                disabled
                                                maxLength={common.maxLengths.userEmail}
                                            />
                                            <TextField
                                                text="Last Name"
                                                controlId="lastName"
                                                placeholder="Last Name"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.lastName}
                                                required
                                                maxLength={common.maxLengths.userLastName}
                                            />
                                            <SelectField
                                                text="Status"
                                                controlId="status"
                                                onChange={(e) => this.handleChangeSelect(e, 'status')}
                                                options={objectStatusList}
                                                selectedValue={this.state.status}
                                                isClearable
                                                required
                                            />
                                            <TextField
                                                text="Notes"
                                                controlId="notes"
                                                placeholder="Notes"
                                                type="text"
                                                as="textarea"
                                                rows={5}
                                                onChange={this.handleChange}
                                                defaultValue={this.state.notes}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-btns" style={{ marginRight: '15px' }}>
                                        <Button
                                            variant="outline-primary"
                                            text='Save'
                                            size='sm'
                                            icon='save'
                                            onClick={() => this.handleSaveAddNew(this.state)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MyAccount.propTypes = {
    isSideBarStatus: PropTypes.bool,
    tenantUser: PropTypes.any,
    activeRoute: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
    tenantUser: makeSelectTenantUser(),
    roleList: selectors.makeUserRoleListrSuccess(),
    error: selectors.makeSelectAddNewUserError(),
    success: selectors.makeSelectAddNewUserSuccess(),
    userList: selectors.makeSelectUserListSuccess(),
    loading: selectors.makeSelectUserListLoading(),
    objectStatusList: makeSelectObjectStatusList(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(MyAccount));