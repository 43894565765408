import { createSelector } from 'reselect';

const selectAuthDomain = state => state.auth;

const makeSelectLoginUser =() =>
  createSelector(
    selectAuthDomain,
    authState => 
      authState ? authState.get('isLoginUser') : false
  );

const makeSelectUserExpire =() =>
  createSelector(
    selectAuthDomain,
    authState => 
      authState ? authState.get('userExpireDetails') : ''
  );

const makeSelectUserActive =() =>
  createSelector(
    selectAuthDomain,
    authState => 
      authState ? authState.get('userActiveAfterExpire') : false
  );

export {
    makeSelectLoginUser,
    makeSelectUserExpire,
    makeSelectUserActive
}
