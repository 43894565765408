import React, { Component } from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css'

// const pdfAsDataUri = "data:application/pdf;base64,JVBERi0xLjUK...";

class PdfViewer extends Component {

    render() {
        const {
            show,
            onHide,
            title,
            // body,
            footer,
            pdf
        } = this.props
        return (
            <div>
                {/* {window.print(pdfAsDataUri)} */}
                <BootstrapModal 
                    show={show} 
                    onHide={onHide}
                    animation={false}
                    // size="lg"
                    dialogClassName="modal_width"
                    // centered
                    >
                    <BootstrapModal.Header closeButton>
                        <BootstrapModal.Title style={{fontSize:'1.1rem'}}>{title}</BootstrapModal.Title>
                    </BootstrapModal.Header>
                    <BootstrapModal.Body style={{height:600}}>
                        <iframe title={title} src={`data:application/pdf;base64,${pdf}`} height="100%" width="100%" ></iframe>
                    </BootstrapModal.Body>
                    <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>
                </BootstrapModal>
            </div>
        )
    }

} 

PdfViewer.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    title: PropTypes.any,
    // body: PropTypes.any,
    footer: PropTypes.any,
    pdf: PropTypes.any
}

export default PdfViewer;
