import React from 'react'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css';

const Pagination = ({
    currentPage,
    getNextPage,
    getPrevPage,
    totalPage,
    setClickedPage,
    currentPageSize,
    setPageSize
}) => {

    return (
        <div className="pagination">
            <div>
                <div className="pagination-details">
                    <span onClick={()=>setPageSize(10)} className={currentPageSize === 10 ? "active-page": ''}>10</span> |
                    <span onClick={()=>setPageSize(20)} className={currentPageSize === 20 ? "active-page": ''}>20</span> |
                    <span onClick={()=>setPageSize(50)} className={currentPageSize === 50 ? "active-page": ''}>50</span> |
                    <span onClick={()=>setPageSize(100)} className={currentPageSize === 100 ? "active-page": ''}>100</span>
                </div>
            </div>
            <div style={{display:'flex'}}>
            {currentPage !== 1 && totalPage !==0 &&
                <ui5-icon onClick={getPrevPage} class="samples-margin" name="close-command-field" id="pagination-icon"></ui5-icon>}
            <div className="pagination-details">
                {currentPage !== 1 && totalPage !==0 && <span onClick={()=>setClickedPage(currentPage - 1)}>
                    {currentPage - 1}
                </span> } |
                <span className="active-page">
                    {currentPage}
                </span>  |
                {currentPage !== totalPage && totalPage !==0 &&<span onClick={()=>setClickedPage(currentPage + 1)} >
                    {currentPage + 1}
                </span>} 
            </div>
            {currentPage !== totalPage && totalPage !==0 &&
                <ui5-icon onClick={getNextPage} class="samples-margin" name="open-command-field" id="pagination-icon"></ui5-icon>}
            </div>
        </div>
    )
}


Pagination.propTypes = {
    currentPage: PropTypes.any,
    getNextPage: PropTypes.any,
    getPrevPage: PropTypes.any,
    totalPage: PropTypes.any,
    setClickedPage: PropTypes.any,
    currentPageSize: PropTypes.any,
    setPageSize: PropTypes.any
}

export default Pagination;
