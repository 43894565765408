import { put, call } from 'redux-saga/effects';
import {
  addNewCustomerRequest,
  getCustomerList,
  updateCustomerRequest,
  deleteCustomerRequest,
  getSelectedCustomerRequest,
  sendCustomerNotificationRequest
} from '../service/customerService';
import * as actions from '../../store/action/customerAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewCustomerRequest(data){
  // console.log('sendAddNewCustomerRequest')
  yield put(actions.loadCustomerListError(''))
  try{
    // yield put(actions.loadCustomerListLoading(true));
    const response = yield call(addNewCustomerRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      // console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendCustomerSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewCustomerLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewCustomerLoading(false));
    // console.log(error.response.data.detail)
    const massage = common.error
    yield put(actions.loadCustomerListError(massage))
  }
}

export function* loadCustomerListRequest(data){
  // console.log('loadCustomerListRequest')
  yield put(actions.loadCustomerListError(''))
  try{
    yield put(actions.loadCustomerListLoading(true));
    const response = yield call(getCustomerList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result.result)
      const customerList = response.data.result
      yield put(actions.loadCustomerListSuccess(customerList))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      yield put(actions.loadCustomerListLoading(false));
      const massage = common.error
      yield put(actions.loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadCustomerListLoading(false));
  }catch(error){
    yield put(actions.loadCustomerListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadCustomerListError(massage))
  }
}

export function* sendUpdateCustomerRequest(data){
  // console.log('sendUpdateCustomerRequest')
  yield put(actions.loadCustomerListError(''))
  try{
    // yield put(actions.sendUpdateCustomerLoading(true));
    const response = yield call(updateCustomerRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendCustomerSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      const massage = response.data.message
      yield put(actions.loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateCustomerLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateCustomerLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadCustomerListError(massage))
  }
}

export function* sendDeleteCustomerRequest(data){
  // console.log('sendDeleteCustomerRequest')
  yield put(actions.loadCustomerListError(''))
  try{
    yield put(actions.loadCustomerListLoading(true));
    const response = yield call(deleteCustomerRequest,data.payload);
    if(response && response.status && response.status === 200 && response.data &&  response.data.statusCode &&  response.data.statusCode === 404 && response.data.result.code ){
      var massage = common.massages.customerDeleteMassage
      const errorCode = common.deleteMassages.find(element => element.id === response.data.result.code)
      if(errorCode){
        massage = errorCode.description
      }
      yield put(actions.loadCustomerListError(massage)) 
    } 
    else if(response && response.status && response.status === 200 && response.data &&  response.data.statusCode &&  response.data.statusCode === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendCustomerSuccess(massage)) 
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadCustomerListLoading(false));
  }catch(error){
    yield put(actions.loadCustomerListLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadCustomerListError(massage))
  }
}

export function* loadSelectedCustomerRequest(data){
  // console.log('loadSelectedCustomerRequest')
  yield put(actions.loadCustomerListError(''))
  try{
    yield put(actions.loadCustomerListLoading(true));
    const response = yield call(getSelectedCustomerRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data)
      const customer = response.data
      yield put(actions.loadSelectedCustomerSuccess(customer))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else {
      yield put(actions.loadCustomerListLoading(false));
      const massage = common.error
      yield put(actions.loadCustomerListError(massage))
    }
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadCustomerListLoading(false));
  }catch(error){
    yield put(actions.loadCustomerListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadCustomerListError(massage))
  }
}

export function* customerNotificationRequest(data){
  yield put(actions.loadCustomerListError(''))
  try{
    const response = yield call(sendCustomerNotificationRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      // console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendCustomerSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage = common.error
    yield put(actions.loadCustomerListError(massage))
  }
}
