import React,{ Component } from 'react';
import JoditEditor from "jodit-react";
import PropTypes from 'prop-types';

class TextEditor  extends Component {
    updateContent = (value) => {
        this.setState({content:value})
    }

	jodit;
	setRef = jodit => this.jodit = jodit;
	
	 config = {
        zIndex: 0,
        readonly: false,
        activeButtonsInReadOnly: ["source", "fullsize", "print", "about"],
        toolbarButtonSize: "middle",
        theme: "default",
        enableDragAndDropFileToEditor: true,
        saveModeInCookie: false,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        direction: "ltr",
        language: "en",
        debugLanguage: false,
        i18n: "en",
        tabIndex: -1,
        toolbar: true,
        enter: "P",
        useSplitMode: false,
        colorPickerDefaultTab: "background",
        imageDefaultWidth: 100,
        uploader: {
            insertImageAsBase64URI: true,
        },
        buttons: [
            'source', '|',
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent',  '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'copyformat', '|',
            'symbol',
            'fullsize',
            'print',
            'about'
        ],
        placeholder: "",
        showXPathInStatusbar: false,
        toolbarAdaptive: true,
        toolbarSticky: true,
        };
    
    render() {
        const { 
            editorState,
            onEditorStateChange
        } = this.props

        return (
            <div>
                <JoditEditor
                    editorRef={this.setRef}
                    value={editorState}
                    config={this.config}
                    onChange={onEditorStateChange}
                />
            </div>
        );
    }
}

TextEditor.propTypes = {
    editorState: PropTypes.any,
    onEditorStateChange: PropTypes.any,
}

export default TextEditor;