import React from "react";
import { Link } from 'react-router-dom';
import '../../layout/form/form.css'
import ButtonGroup from '../../layout/form/ButtonGroup';
import VemasLogo from '../../../images/WELAWA.png';
import { numberWithCommas } from "../../common";

function openInNewTab  (url){
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

function ActivationDetails({
    handleChangeStep,billingPrice, handleFirstStep
}) {
    return (
        <div>
            <div className="vemas_logo">
                <img alt="vemaslogo" id="vemas_logo" src={VemasLogo} />
            </div>
            <div style={{ margin: '0 5% 2% 5%' }}>
                <div style={{ textAlign: 'center', fontWeight: 600, borderBottom: '2px solid #d8a61e', paddingBottom: '5px', marginBottom: '40px', fontSize:"18px", color:"orange" }}>Your monthly subscription fee is ${numberWithCommas(parseFloat(billingPrice).toFixed(2))}</div>
                <div>
                    We trust VEMAS will help you run your workshop simple and more efficiently. By clicking Agree, you agree to our Terms and Conditions and have read and acknowledge our Privacy Statement.(<Link  onClick={()=> {openInNewTab('https://vemas.com.au/privacy/')}}>Privacy policy</Link> and <Link>Terms of use</Link>)
                </div>
                <div className="filter-btns" style={{ margin: '20px 0' }}>
                    <ButtonGroup
                        primaryBtnClick={() => handleFirstStep("firstStep")}
                        primaryBtnVariant="outline-primary"
                        primaryBtnText='Back'
                        secondaryBtnClick={() => handleChangeStep("thirdStep")}
                        secondaryBtnText='Agree'
                        secondaryBtnVariant="outline-primary"
                    />
                </div>
            </div>
        </div>
    )
}

export default ActivationDetails;