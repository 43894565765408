import { createSelector } from 'reselect';

const selectA2AMappingDomain = state => state.a2aMapping;

const makeSelectA2AMappingLoading = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('isA2AMappingLoading') : false
  );

const makeSelectA2AMappingError = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aMappingError') : null
  );

const makeSelectA2AMappingSuccess = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aMappingSuccess') : null
  );

const makeSelectAccountEmployee = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountEmployeeList') : []
  );


const makeSelectMappingEmployee = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('mappingEmployeeList') : []
  );

const makeSelectAccountEmployeeListStatus = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('isAccountEmployeeList') : false
  );

const makeSelectAccountPayType = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountPayTypeList') : []
  );


const makeSelectMappingPayType = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('mappingPayTypeList') : []
  );

const makeSelectAccountPayTypeListStatus = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('isAccountPayTypeList') : false
  );

const makeSelectAccountCompanyFile = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountCompanyFileList') : []
  );

const makeSelectAccountEmployeeSynchronise = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('empolyeeSynchronise') : {}
  );


const makeSelectAccountCustomer = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountCustomerList') : []
  );

const makeSelectIsLoadAccountCustomer = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('isAccountCustomerList') : false
  );

const makeSelectMappingCustomer = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('mappingCustomerList') : []
  );

const makeSelectA2ACustomerLoading = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aCustomerLoading') : false
  );

const makeSelectA2ACustomerError = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aCustomerError') : null
  );

const makeSelectSendMappingCustomerSuccess = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('mappingCustomerSuccess') : null
  );

const makeSelectMappingProject = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('mappingProjectList') : []
  );

const makeSelectA2AProjectLoading = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aProjectLoading') : false
  );

const makeSelectA2AProjectError = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aProjectError') : null
  );


export {
  makeSelectA2AMappingError,
  makeSelectA2AMappingLoading,
  makeSelectA2AMappingSuccess,
  makeSelectAccountEmployee,
  makeSelectMappingEmployee,
  makeSelectAccountEmployeeListStatus,
  makeSelectAccountPayType,
  makeSelectMappingPayType,
  makeSelectAccountPayTypeListStatus,
  makeSelectAccountCompanyFile,
  makeSelectAccountEmployeeSynchronise,
  makeSelectAccountCustomer,
  makeSelectIsLoadAccountCustomer,
  makeSelectMappingCustomer,
  makeSelectA2ACustomerError,
  makeSelectA2ACustomerLoading,
  makeSelectSendMappingCustomerSuccess,
  makeSelectMappingProject,
  makeSelectA2AProjectLoading,
  makeSelectA2AProjectError
}
