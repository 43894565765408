import React from 'react';
import PropTypes from 'prop-types';
import './form.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import 'bootstrap/dist/css/bootstrap.min.css';
import InputGroup from 'react-bootstrap/cjs/InputGroup';
import FormControl from 'react-bootstrap/cjs/FormControl';

const SearchField = ({
    controlId,
    onClickSearch,
    onChange,
    defaultValue,
    onPressKeySearch,
    ...props
}) => (
    <div style={{marginTop:'10px', width:'200%'}}>
        <InputGroup size="sm" controlId={controlId}>
            <FormControl 
                {...props}
                placeholder="Search"
                onBlur={onChange}
                size="sm"
                defaultValue={defaultValue}
                onKeyPress = {(e) => {e.key === "Enter" && onPressKeySearch(e.target.value)}}
            />
            <InputGroup.Text id="inputGroup-sizing-sm" style={{cursor:'pointer'}} onClick={onClickSearch}>
                <span style={{fontSize:'2px'}}>
                    <ui5-icon class="samples-margin" name="search" id="search-icon"></ui5-icon>
                </span>
            </InputGroup.Text>
        </InputGroup>
    </div>
);

SearchField.propTypes = {
    controlId: PropTypes.any,
    onClickSearch: PropTypes.any,
    onChange: PropTypes.any,
}

export default SearchField;