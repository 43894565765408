import { createSelector } from 'reselect';

const selectInvoiceDomain = state => state.invoice;

const makeSelectInvoicePopupOpen = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('isAddNewInvoiceModalOpen') : false
  );

const makeSelectInvoiceList = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('invoiceList') : []
  );

const makeSelectInvoiceListLoading = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('isInvoiceListLoading') : false
  );

const makeSelectInvoiceListError = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('invoiceListError') : ''
  );

const makeSelectInvoiceSuccess = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('invoiceSuccess') : ''
  );

const makeSelectInvoicePrintSuccess = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('invoicePrint') : ''
  );

const makeSelectSelectedInvoiceSuccess = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('selectedInvoice') : {}
  );

const makeSelectPaymentTermSuccess = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('paymentTermList') : []
  );

const makeSelectInvoiceEmailSuccess = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('invoiceEmail') : null
  );

const makeSelectSaveIsEmailEditorOpen = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('isEmailEditorOpen') : null
  );

const makeSelectPaymentMethodList = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('paymentMethodList') : []
  );

const makeSelectInvoiceTimesheetDetailList = () =>
  createSelector(
    selectInvoiceDomain,
    invoiceState =>
      invoiceState ? invoiceState.get('invoiceTimesheetDetails') : []
  );

export {
  makeSelectInvoicePopupOpen,
  makeSelectInvoiceList,
  makeSelectInvoiceListLoading,
  makeSelectInvoiceListError,
  makeSelectInvoiceSuccess,
  makeSelectInvoicePrintSuccess,
  makeSelectSelectedInvoiceSuccess,
  makeSelectPaymentTermSuccess,
  makeSelectInvoiceEmailSuccess,
  makeSelectSaveIsEmailEditorOpen,
  makeSelectPaymentMethodList,
  makeSelectInvoiceTimesheetDetailList
}
