import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import InjectedCheckoutForm from './InjectedCheckoutForm';

const stripePromise = loadStripe('pk_test_51Ltmf9CvP8BnDeXDhpWlZ5I4yLO7ebmhRzClwtLd1EvxGO1pHMcvQs1SZ2XZSkEVv84TuMjmMK8fpIX4GUnOApKS00HR2DN4Fc');
// const stripePromise = loadStripe('pk_live_51Ltmf9CvP8BnDeXDUA5LxkTkEmkQkDbOlTKUVnLEGpe1sO6hxkA5GcI1QEIZl8o3AGiCUWm8EvnYaLB3V5Rvoxee004eluyNox');

class PaymentMethod extends Component {

  render() {
    const {
      paymentSetup,
      handleClose,
      handleSubmit
    } = this.props;

    const options = {
      clientSecret: paymentSetup.setupIntentClientSecret,
      appearance: {},
    };
    return (
      <div>
        <Elements stripe={stripePromise} options={options}>
          <InjectedCheckoutForm
            clientSecret={paymentSetup.setupIntentClientSecret}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </Elements>
      </div>
    )
  }
}

export default PaymentMethod;