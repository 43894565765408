import React, { Component } from "react";
import OpeningHours from './OpeningHours';
import PublicHoliday from "./PublicHoliday";
import TenantHoliday from "./TenantHoliday";

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: "publicHoliday"
        }
    }

    handleCalendarTab = (e) => {
        this.setState({
            tab: e
        })
    }

    render() {
        const { handleTab, handleSubmit, userAccess } = this.props;
        const { tab } = this.state;
        return (
            <div>
                {tab === "publicHoliday" && <PublicHoliday handleTab={handleTab} handleCalendarTab={this.handleCalendarTab} />}
                {tab === "openingHours" && <OpeningHours handleTab={handleTab} handleSubmit={handleSubmit} userAccess={userAccess} handleCalendarTab={this.handleCalendarTab} />}
                {tab === "tenantHoliday" && <TenantHoliday handleTab={handleTab} handleCalendarTab={this.handleCalendarTab} />}
            </div>
        )
    }

}

export default Calendar;