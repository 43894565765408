import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import common from '../../../common';
import PhoneNumberField from '../../../layout/form/PhoneNumberField';
import SelectField from '../../../layout/form/SelectField';

export class AddApprover extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: '',
            approverEmail: '',
            approverMobile: '',
            operation: "C",
            id: 0,
            initial: true,
            sequence: 0
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { selectedApprover, sequence, userList } = prevProps;
        if (selectedApprover && selectedApprover.id && userList && userList.length > 0 && prevState.initial) {
            const user = userList.find(e => e.id === selectedApprover.userId)
            return {
                userId: selectedApprover.userId,
                approverEmail: user.email,
                approverMobile: user.mobileNo,
                operation: "U",
                id: selectedApprover.id,
                sequence: selectedApprover.approvalSequence,
                initial: false
            }
        } else if (selectedApprover === null && prevState.initial) {
            return {
                sequence: sequence,
                initial: false
            }
        }
        return null
    }

    handleSelect = (value, id) => {
        const { userList } = this.props;
        const user = userList.find(e => e.id === value)
        this.setState({
            [id]: value,
            approverEmail: user.email,
            approverMobile: user.mobileNo,
        })
    }

    mobileNumChange = (e) => {
        this.setState({
            approverMobile: e
        })
    }

    // handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log(this.state)
    // }


    render() {
        const {
            show,
            handleModalClose,
            handleSaveAddNew,
            userList
        } = this.props;

        const { sequence } = this.state;

        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleModalClose}
                    title={sequence + " - Approver Information"}
                    width='40%'
                    body={
                        <div>
                            <form style={{ marginTop: "5px" }}>
                                <div className="col-sm">
                                    <SelectField
                                        text="Name"
                                        controlId="userId"
                                        onChange={(e) => this.handleSelect(e, 'userId')}
                                        options={
                                            userList && userList.length > 0 ?
                                                userList.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ?
                                                    1 : ((b.firstName.toLowerCase() > a.firstName.toLowerCase()) ? -1 : 0)) : []}
                                        required={true}
                                        selectedValue={this.state.userId}
                                        display='firstName'
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Email"
                                        controlId="approverEmail"
                                        // placeholder="Email"
                                        type="approverEmail"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.approverEmail}
                                        maxLength={common.maxLengths.approverEmail}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm">
                                    <PhoneNumberField
                                        controlId="approverMobile"
                                        text="Mobile Number"
                                        type="text"
                                        disabled
                                        onChange={this.mobileNumChange}
                                        maxLength={common.maxLengths.approverMobile}
                                        value={this.state.approverMobile}
                                    />
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
            </div>
        )
    }
}

AddApprover.propTypes = {
    show: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any
}

export default AddApprover;