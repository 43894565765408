import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function addNewEmailClientRequest(data){
    const parameters = JSON.stringify(data)
      return axios.post(baseURL+'tenantEmailConfigs', parameters, {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : sessionStorage.getItem("accessToken")
          }
      })
}


export function updateEmailClientRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'tenantEmailConfigs/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getEmailClientList(){
    return axios.get(baseURL+'tenantEmailConfigs', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getEmailServerList(){
    return axios.get(baseURL+'emailServers', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteEmailConfigRequest(id){
    return axios.delete(baseURL+'tenantEmailConfigs/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}
