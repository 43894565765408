import { createSelector } from 'reselect';

const selectNavBarDomain = state => state.navBar;

const makeSelectSideBarStatus =() =>
  createSelector(
    selectNavBarDomain,
    navBarState => 
      navBarState ? navBarState.get('isSideBarStatus') : true
  );

const makeSelectActiveRoute =() =>
  createSelector(
    selectNavBarDomain,
    navBarState => 
      navBarState ? navBarState.get('activeRoute') : ''
  );

export {
    makeSelectSideBarStatus,
    makeSelectActiveRoute
}
