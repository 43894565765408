import { fromJS } from 'immutable';
import {
  SAVE_ACTIVE_ROUTE,
  SAVE_IS_SIDE_BAR_STATUS,
} from '../constant/navBarConstant';

export const initialState = fromJS({
    isSideBarStatus:true,
    activeRoute:''
});

function navBarReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_SIDE_BAR_STATUS:
      return state.set('isSideBarStatus', action.open)
    case SAVE_ACTIVE_ROUTE:
      return state.set('activeRoute', action.data)
    default:
      return state;
  }
}

export default navBarReducer;
