import { createSelector } from 'reselect';

const selectAccountingDomain = state => state.accounting;

const makeSelectAccountSystem = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('accountSystem') : []
  );

const makeSelectAccountLoading = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('isAccountLoading') : false
  );

const makeSelectAccountError = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('accountError') : ''
  );

const makeSelectQuickBooksConnection = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('quickBooksConnection') : ''
  );

const makeSelectAccountConnection = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('accountConnection') : ''
  );

const makeSelectAccountConnectionUpdate = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('accountConnectionUpdate') : false
  );

const makeSelectIntegrationScenarioLoading = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('isIntegrationScenarioLoading') : false
  );

const makeSelectIntegrationScenarioList = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('integrationScenarioList') : []
  );

const makeSelectIntegrationScenarioSuccess = () =>
  createSelector(
    selectAccountingDomain,
    accountingState =>
      accountingState ? accountingState.get('integrationScenarioSuccess') : null
  );

export {
  makeSelectAccountSystem,
  makeSelectAccountLoading,
  makeSelectAccountError,
  makeSelectQuickBooksConnection,
  makeSelectAccountConnection,
  makeSelectAccountConnectionUpdate,
  makeSelectIntegrationScenarioLoading,
  makeSelectIntegrationScenarioList,
  makeSelectIntegrationScenarioSuccess
}
