import { fromJS } from 'immutable';
import {
  LOAD_EMAIL_CLIENT_LIST_ERROR,
  LOAD_EMAIL_CLIENT_LIST_LOADING,
  LOAD_EMAIL_CLIENT_LIST_SUCCESS,
  SEND_EMAIL_CLIENT_SUCCESS,
  LOAD_EMAIL_SERVER_LIST_SUCCESS
} from '../constant/emailConfigurationConstant';

export const initialState = fromJS({
  emailClientList: [],
  isEmailClientListLoading: false,
  emailClientListError: false,
  emailClientSuccess: false,
  emailServer: []
});

function emailConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_EMAIL_CLIENT_LIST_SUCCESS:
      return state.set('emailClientList', action.payload)
    case LOAD_EMAIL_CLIENT_LIST_LOADING:
      return state.set('isEmailClientListLoading', action.payload)
    case LOAD_EMAIL_CLIENT_LIST_ERROR:
      return state.set('emailClientListError', action.payload)
    case SEND_EMAIL_CLIENT_SUCCESS:
      return state.set('emailClientSuccess', action.payload)
    case LOAD_EMAIL_SERVER_LIST_SUCCESS:
      return state.set('emailServer', action.payload)
    default:
      return state;
  }
}

export default emailConfigurationReducer;
