import { fromJS } from 'immutable';
import {
  LOAD_TITLE_LIST_ERROR,
  LOAD_TITLE_LIST_LOADING,
  LOAD_TITLE_LIST_SUCCESS,
  SAVE_IS_TITLE_MODAL_OPEN_STATUS,
  SEND_TITLE_SUCCESS,
} from '../constant/titleConstant';

export const initialState = fromJS({
    isAddNewTitleModalOpen:false,
    titleList:[],
    isTitleListLoading:false,
    titleListError:'',
    titleSuccess:''
});

function titleReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_TITLE_MODAL_OPEN_STATUS:
      return state.set('isAddNewTitleModalOpen', action.open)
    case LOAD_TITLE_LIST_SUCCESS:
      return state.set('titleList', action.payload)
    case LOAD_TITLE_LIST_LOADING:
      return state.set('isTitleListLoading', action.payload)
    case LOAD_TITLE_LIST_ERROR:
      return state.set('titleListError', action.payload)
    case SEND_TITLE_SUCCESS:
      return state.set('titleSuccess', action.payload)
    default:
      return state;
  }
}

export default titleReducer;
