const PATH = 'app/UserRoles';

export const SAVE_IS_USER_ROLE_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_USER_ROLE_MODAL_OPEN_STATUS`;
export const LOAD_USER_ROLE_LIST_REQUEST = `${PATH}LOAD_USER_ROLE_LIST_REQUEST`;
export const LOAD_USER_ROLE_LIST_SUCCESS = `${PATH}LOAD_USER_ROLE_LIST_SUCCESS`;
export const LOAD_USER_ROLE_LIST_ERROR = `${PATH}LOAD_USER_ROLE_LIST_ERROR`;
export const LOAD_USER_ROLE_LIST_LOADING = `${PATH}LOAD_USER_ROLE_LIST_LOADING`;
export const LOAD_OBJECT_LIST_REQUEST = `${PATH}LOAD_OBJECT_LIST_REQUEST`;
export const LOAD_OBJECT_LIST_SUCCESS = `${PATH}LOAD_OBJECT_LIST_SUCCESS`;
export const LOAD_OBJECT_OPERATION_LIST_REQUEST = `${PATH}LOAD_OBJECT_OPERATION_LIST_REQUEST`;
export const LOAD_OBJECT_OPERATION_LIST_SUCCESS = `${PATH}LOAD_OBJECT_OPERATION_LIST_SUCCESS`;
export const LOAD_USER_ROLE_OBJECT_LIST_REQUEST = `${PATH}LOAD_USER_ROLE_OBJECT_LIST_REQUEST`;
export const LOAD_USER_ROLE_OBJECT_LIST_SUCCESS = `${PATH}LOAD_USER_ROLE_OBJECT_LIST_SUCCESS`;
export const SEND_ADD_NEW_USER_ROLE_REQUEST = `${PATH}SEND_ADD_NEW_USER_ROLE_REQUEST`;
export const SEND_UPDATE_USER_ROLE_REQUEST = `${PATH}SEND_UPDATE_USER_ROLE_REQUEST`;
export const SEND_DELETE_USER_ROLE_REQUEST = `${PATH}SEND_DELETE_USER_ROLE_REQUEST`;
export const SEND_USER_ROLE_SUCCESS = `${PATH}SEND_USER_ROLE_SUCCESS`;