import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import common from '../../../common';
import DatePicker from '../../../layout/form/datePicker';
import moment from 'moment';
import Form from 'react-bootstrap/cjs/Form';

const today = new Date()
export class AddTenantHoliday extends Component {

    constructor(props) {
        super(props);

        this.state = {
            description: '',
            fromDate: moment(today).format(common.dateFormat),
            toDate: moment(today).format(common.dateFormat),
            isDateRange: false,
            initial: true
        };

    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleDateChange = (date, id) => {
        if (id === "fromDate" && !this.state.isDateRange) {
            const setDate = moment(date).format(common.dateFormat);
            this.setState({
                [id]: setDate,
                toDate: setDate
            })
        }
        else{
            this.setState({
                [id]: moment(date).format(common.dateFormat),
            })
        }

    }

    handleCheckDateRange = (e) => {
        this.setState({
            isDateRange: e.target.checked
        })
    }

    render() {
        const {
            isAddNew,
            handleModalClose,
            handleSaveAddNew,
            // selectedRow
        } = this.props;

        return (
            <div>
                <Modal
                    show={isAddNew}
                    onHide={handleModalClose}
                    title="Company Holiday"
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{ marginTop: "5px" }}>
                                <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className="col-sm">
                                        <Form.Group controlId="addressCheckBox">
                                            <Form.Check type="checkbox" style={{ fontSize: "14px" }} label="Date range" onChange={this.handleCheckDateRange} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Description"
                                            controlId="description"
                                            placeholder="Description"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.description}
                                            maxLength={common.maxLengths.titleName}
                                        // disabled={selectedRow && selectedRow.id ? true: false}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Date ( From Date )"
                                            controlId="fromDate"
                                            onChange={(e) => this.handleDateChange(e, "fromDate")}
                                            value={new Date(this.state.fromDate)}
                                        // format={dateFormat}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <DatePicker
                                            text="To Date"
                                            controlId="toDate"
                                            onChange={(e) => this.handleDateChange(e, "toDate")}
                                            value={new Date(this.state.toDate)}
                                            disabled={!this.state.isDateRange}
                                        // format={dateFormat}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
            </div>
        )
    }
}

AddTenantHoliday.propTypes = {
    isAddNew: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any
}

export default AddTenantHoliday;