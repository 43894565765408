import { fromJS } from 'immutable';
import {
  LOAD_ACCOUNT_ERROR,
  LOAD_ACCOUNT_LOADING,
  LOAD_ACCOUNT_SYSTEM_SUCCESS,
  LOAD_INTEGRATION_SCENARIO_LOADING,
  LOAD_INTEGRATION_SCENARIO_SUCCESS,
  LOAD_QUICKBOOKS_CONNECTION_SUCCESS,
  SEND_CONNECTION_SUCCESS,
  SEND_CONNECTION_UPDATE_SUCCESS,
  SEND_INTEGRATION_SCENARIO_SUCCESS,
} from '../constant/accountConstant';

export const initialState = fromJS({
  accountSystem: [],
  isAccountLoading: false,
  accountError: '',
  quickBooksConnection: '',
  accountConnection: '',
  accountConnectionUpdate: false,
  integrationScenarioList: [],
  isIntegrationScenarioLoading: false,
  integrationScenarioSuccess: null
});

function accountReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ACCOUNT_SYSTEM_SUCCESS:
      return state.set('accountSystem', action.payload)
    case LOAD_ACCOUNT_LOADING:
      return state.set('isAccountLoading', action.payload)
    case LOAD_ACCOUNT_ERROR:
      return state.set('accountError', action.payload)
    case LOAD_QUICKBOOKS_CONNECTION_SUCCESS:
      return state.set('quickBooksConnection', action.payload)
    case SEND_CONNECTION_SUCCESS:
      return state.set('accountConnection', action.payload)
    case SEND_CONNECTION_UPDATE_SUCCESS:
      return state.set('accountConnectionUpdate', action.payload)
    case LOAD_INTEGRATION_SCENARIO_LOADING:
      return state.set('isIntegrationScenarioLoading', action.payload)
    case LOAD_INTEGRATION_SCENARIO_SUCCESS:
      return state.set('integrationScenarioList', action.payload)
    case SEND_INTEGRATION_SCENARIO_SUCCESS:
      return state.set('integrationScenarioSuccess', action.payload)
    default:
      return state;
  }
}

export default accountReducer;
