import { fromJS } from 'immutable';
import {
  LOAD_APP_BILLING_ERROR,
  LOAD_APP_BILLING_LOADING,
  LOAD_APP_INVOICE_LIST_ERROR,
  LOAD_APP_INVOICE_LIST_LOADING,
  LOAD_APP_INVOICE_LIST_SUCCESS,
  LOAD_APP_INVOICE_PRINT_SUCCESS,
  LOAD_APP_PAYMENT_METHOD_SUCCESS,
  LOAD_APP_SERVICE_TENANT_SUCCESS,
  LOAD_PAYMENT_SETUP_SUCCESS,
  LOAD_SELECTED_APP_INVOICE_SUCCESS,
  SAVE_IS_APP_INVOICE_MODAL_OPEN_STATUS,
  SEND_APP_BILLING_SUCCESS,
} from '../constant/appBillingConstant';

export const initialState = fromJS({
  isAppBillingLoading: false,
  appBillingError: '',
  appTenantServiceList: [],
  appBillingSuccess: '',
  paymentSetup: null,
  isAppInvoiceModalOpen: false,
  appInvoiceList: [],
  isAppInvoiceListLoading: false,
  appInvoiceListError: '',
  selectedAppInvoice: {},
  appInvoicePrint: '',
  appPaymentMethod: []
});

function ratingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_APP_SERVICE_TENANT_SUCCESS:
      return state.set('appTenantServiceList', action.payload)
    case LOAD_APP_BILLING_LOADING:
      return state.set('isAppBillingLoading', action.payload)
    case LOAD_APP_BILLING_ERROR:
      return state.set('appBillingError', action.payload)
    case SEND_APP_BILLING_SUCCESS:
      return state.set('appBillingSuccess', action.payload)
    case LOAD_PAYMENT_SETUP_SUCCESS:
      return state.set('paymentSetup', action.payload)
    case SAVE_IS_APP_INVOICE_MODAL_OPEN_STATUS:
      return state.set('isAppInvoiceModalOpen', action.open)
    case LOAD_APP_INVOICE_LIST_SUCCESS:
      return state.set('appInvoiceList', action.payload)
    case LOAD_APP_INVOICE_LIST_LOADING:
      return state.set('isAppInvoiceListLoading', action.payload)
    case LOAD_APP_INVOICE_LIST_ERROR:
      return state.set('appInvoiceListError', action.payload)
    case LOAD_SELECTED_APP_INVOICE_SUCCESS:
      return state.set('selectedAppInvoice', action.payload)
    case LOAD_APP_INVOICE_PRINT_SUCCESS:
      return state.set('appInvoicePrint', action.payload)
    case LOAD_APP_PAYMENT_METHOD_SUCCESS:
      return state.set('appPaymentMethod', action.payload)
    default:
      return state;
  }
}

export default ratingReducer;
