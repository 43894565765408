import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import React from 'react';
// import Modal from '../../../layout/form/Modal';
import ButtonGroup from '../../layout/form/ButtonGroup';
import '../auth.css'

class CheckoutForm extends React.Component {

  render() {
    const { stripe, elements, handleSubmit, handleChangeStep } = this.props
    return (
      <div>
        <div>
          <form className='payment-method'>
            <PaymentElement />
          </form>
          <div className="filter-btns" style={{ margin: '20px 0' }}>
            <ButtonGroup
              primaryBtnClick={() => handleChangeStep("secondStep")}
              primaryBtnVariant="outline-primary"
              primaryBtnText='Back'
              secondaryBtnClick={(e) => handleSubmit(e, stripe, elements)}
              secondaryBtnText='Agree'
              secondaryBtnVariant="outline-primary"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default function InjectedCheckoutForm({ clientSecret, handleSubmit, handleChangeStep }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} clientSecret={clientSecret} handleSubmit={handleSubmit} handleChangeStep={handleChangeStep} />
      )}
    </ElementsConsumer>
  )
}