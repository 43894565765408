import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/a2aMappingSelector';
// import { Link } from 'react-router-dom';
import Table from '../../../layout/form/Table';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import * as actions from '../../../../store/action/a2aMappingAction';
import { makeSelectTenantAccountSystemSuccess } from '../../../../store/selector/tenantSelector';
import SelectField from '../../../layout/form/SelectField';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import Loader from '../../../layout/form/Loader';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Pagination from '../../../layout/form/Pagination';
import { loadTenantAccountSystemRequest } from '../../../../store/action/tenantAction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InformationModal from '../../../layout/form/InformationModal';

const customStyles = {
  control: base => ({
    ...base,
    height: 20,
    minHeight: 32,
    marginBottom: 0,
    marginTop: -20
  })
};

const tenantId = sessionStorage.getItem("tenantId")
class MyobMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employeeList: [],
      payTypeList: [],
      initial: true,
      pageNumber: 1,
      pageSize: 20,
      defaultActiveKey: 'account',
      isSynchroniseInforModal: false
    }
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    const {
      tenantAccountSystem,
      isAccountEmployeeList,
      mappingEmployeeList,
      isAccountPayTypeList,
      mappingPayTypeList,
    } = prevProps;
    if (
      prevState.initial &&
      tenantAccountSystem && tenantAccountSystem.id === 0 && isAccountEmployeeList && isAccountPayTypeList) {
      var newMappingEmployeeList = []
      var newMappingPayTypeList = []

      if (mappingEmployeeList && mappingEmployeeList.result && mappingEmployeeList.result.length && mappingEmployeeList.result.length > 0) {
        for (let q = 0; q < mappingEmployeeList.result.length; q++) {
          const employeeItem_ = mappingEmployeeList.result[q]
          const item = {
            tenantId: employeeItem_.tenantId,
            intlEmployeeId: employeeItem_.intlEmployeeId,
            accSystemId: employeeItem_.accSystemId,
            firstName: employeeItem_.firstName,
            lastName: employeeItem_.lastName,
            isChange: false,
            extlEmployeeId: employeeItem_.extlEmployeeId,
            extlLastName: employeeItem_.extlLastName
          }
          newMappingEmployeeList.push(item)
        }
      }

      if (mappingPayTypeList && mappingPayTypeList.result && mappingPayTypeList.result.length && mappingPayTypeList.result.length > 0) {
        for (let q = 0; q < mappingPayTypeList.result.length; q++) {
          const payTypeItem_ = mappingPayTypeList.result[q]
          const item = {
            tenantId: payTypeItem_.tenantId,
            intlPayTypeId: payTypeItem_.intlPayTypeId,
            accSystemId: payTypeItem_.accSystemId,
            intlName: payTypeItem_.intlName,
            isChange: false,
            extlPayTypeId: payTypeItem_.extlPayTypeId
          }
          newMappingPayTypeList.push(item)
        }
      }
      return {
        initial: false,
        employeeList: newMappingEmployeeList,
        payTypeList: newMappingPayTypeList
      }
    }
    return null
  }

  handleSavePayType = () => {
    const { payTypeList } = this.state;
    const PayTypies = payTypeList.filter(e => e.isChange === true)
    const parms = {
      tenantId: parseInt(tenantId),
      PayTypies: PayTypies,
      AccSystemId: this.props.tenantAccountSystem.systemId
    }
    if (PayTypies && PayTypies.length > 0) {
      this.props.dispatch(actions.sendMappingPayTypeRequest(parms))
    }
  }

  handlePayType = (e, rowData) => {
    const { payTypeList } = this.state;
    var newRow = {
      tenantId: rowData.original.tenantId,
      isChange: true,
      accSystemId: "30",
      intlPayTypeId: rowData.original.intlPayTypeId,
      intlName: rowData.original.intlName,
      extlPayTypeId: e
    }
    payTypeList[rowData.index] = newRow
    this.setState({
      payTypeList
    })
  }

  handleSaveEmployee = () => {
    const { employeeList } = this.state;
    const Employees = employeeList.filter(e => e.isChange === true)
    const parms = {
      tenantId: parseInt(tenantId),
      Employees: Employees,
      AccSystemId: this.props.tenantAccountSystem.systemId
    }
    if (Employees && Employees.length > 0) {
      this.props.dispatch(actions.sendMappingEmployeeRequest(parms))
    }
  }



  handleEmployee = (e, rowData) => {
    const { employeeList } = this.state;
    const { accountEmployeeList } = this.props;
    const selectedRow = accountEmployeeList.find(row => row.id === e)
    var newRow = {
      tenantId: rowData.original.tenantId,
      accSystemId: rowData.original.accSystemId,
      isChange: true,
      intlEmployeeId: rowData.original.intlEmployeeId,
      firstName: rowData.original.firstName,
      extlLastName: selectedRow.lastName,
      extlEmployeeId: e,
      lastName: rowData.original.lastName
    }
    employeeList[rowData.index] = newRow
    this.setState({
      employeeList
    })
  }

  handleClose = () => {
    this.setState({
      initial: true
    })
    this.props.dispatch(actions.saveAccountEmployeeListStatus(false))
    this.props.dispatch(actions.saveAccountPayTypeListStatus(false))
    this.getA2AMappingList()
  }

  errorModalClose = () => {
    this.props.dispatch(actions.loadA2AMappingError(''))
  }

  successModalClose = () => {
    this.props.dispatch(actions.loadA2AMappingSuccess(null))
    this.props.dispatch(actions.saveAccountEmployeeListStatus(false))
    this.getA2AMappingList()
  }

  getNextPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
  }

  getPrevPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
  }

  setClickedPage = (page) => {
    this.setState({
      pageNumber: page
    })
  }

  setPageSize = (size) => {
    this.setState({
      pageSize: size,
      pageNumber: 1
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.a2aMappingSuccess !== null) {
      setTimeout(
        () => this.successModalClose(),
        1000
      );
    }

    if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
      this.setState({
        initial: true
      })
      this.getMappingEmployeeListData()
      this.props.dispatch(actions.saveAccountEmployeeListStatus(false))
    }
  }

  componentDidMount() {
    const id = sessionStorage.getItem("tenantId")
    this.props.dispatch(loadTenantAccountSystemRequest(id))
    this.getA2AMappingList()
  }

  getA2AMappingList = () => {
    this.props.dispatch(actions.loadAccountEmployeeRequest())
    this.props.dispatch(actions.loadAccountPayTypeRequest())
    this.getMappingEmployeeListData()
    this.getMappingPayTypeListData()
  }

  getMappingEmployeeListData = () => {
    const parms = {
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    }
    this.props.dispatch(actions.loadMappingEmployeeRequest(parms))
  }

  getMappingPayTypeListData = () => {
    const parms = {
      pageSize: 9999,
      pageNumber: 1,
    }
    this.props.dispatch(actions.loadMappingPayTypeRequest(parms))
  }

  synchroniseTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Synchronise employee
    </Tooltip>
  );

  handleModal = () => {
    this.setState({
      isSynchroniseInforModal: true
    })
    this.props.dispatch(actions.loadAccountEmployeeSynchroniseRequest())
  }

  handleModalClose = () => {
    this.setState({
      isSynchroniseInforModal: false
    })
    this.getMappingEmployeeListData()
    this.props.dispatch(actions.saveAccountEmployeeListStatus(false))
  }

  render() {
    const {
      isA2AMappingLoading,
      a2aMappingError,
      a2aMappingSuccess,
      accountEmployeeList,
      mappingEmployeeList,
      accountPayTypeList,
      empolyeeSynchronise
    } = this.props;

    const {
      employeeList,
      payTypeList,
      defaultActiveKey,
      isSynchroniseInforModal
    } = this.state;

    const columnsEmployee = [{
      Header: 'First name',
      accessor: 'firstName',
      width: '15%',
    }, {
      Header: 'Last name',
      accessor: 'lastName',
      width: '15%',
    }, {
      Header: 'Acc.System first Name',
      accessor: 'extlEmployeeId',
      Cell: (rowData) => (
        <div>
          <SelectField
            controlId="extlEmployeeId"
            onChange={(e) => this.handleEmployee(e, rowData.cell.row)}
            options={accountEmployeeList}
            style={customStyles}
            selectedValue={rowData.cell.row.original.extlEmployeeId}
            display="name"
            id="id"
          />
        </div>
      ),
    }, {
      Header: 'Acc.System last name',
      accessor: 'extlLastName',
    }]

    const columnsPayType = [{
      Header: 'Description',
      accessor: 'intlName'
    }, {
      Header: 'Acc.System Pay Type Description',
      accessor: 'accountSystemDescription',
      Cell: (rowData) => (
        <div>
          <SelectField
            controlId="accountSystemId"
            onChange={(e) => this.handlePayType(e, rowData.cell.row)}
            options={accountPayTypeList}
            style={customStyles}
            selectedValue={rowData.cell.row.original.extlPayTypeId}
            display="description"
            id="id"
          />
        </div>
      ),
    }]

    const columnsResultList = [{
      Header: 'Employee',
      accessor: 'id',
      width: '40%',
      Cell: (rowData) => (
        <div>
          {rowData.cell.row.original.id + " - " + rowData.cell.row.original.reference}
        </div>
      ),
    }, {
      Header: 'Description',
      accessor: 'description'
    }]

    return (
      <div>
        {a2aMappingError !== '' &&
          <ErrorModal
            show={true}
            massage={a2aMappingError}
            handleClose={this.errorModalClose}
          />}

        {(a2aMappingSuccess) &&
          <SuccessModal
            show={true}
            massage={a2aMappingSuccess}
            handleClose={this.successModalClose}
          />}
        {isA2AMappingLoading ?
          <Loader /> : ''}

        {isSynchroniseInforModal ?
          <InformationModal
            show={isSynchroniseInforModal}
            handleModalClose={this.handleModalClose}
            body={
              <div>
                {empolyeeSynchronise.resultList ? empolyeeSynchronise && empolyeeSynchronise.resultList.length === 0 ? "Employees have already been created" :
                  <div className="table-content">
                    <Table
                      columns={columnsResultList}
                      data={empolyeeSynchronise.resultList}
                    />
                  </div> : "Synchronising...."}
              </div>}
          /> : ''}

        <div className="body-content">
          <Tabs defaultActiveKey={defaultActiveKey} id="masterDataMappingTab">
            <Tab
              eventKey="account"
              title={<span> Pay Type</span>}>
              <div className="table-content">
                <Table
                  columns={columnsPayType}
                  data={payTypeList}
                />
              </div>
              <div className="filter-btns">
                <ButtonGroup
                  primaryBtnClick={this.handleClose}
                  // primaryBtnIcon='decline'
                  primaryBtnText='Cancel'
                  primaryBtnIcon='undo'
                  primaryBtnVariant="outline-primary"
                  secondaryBtnClick={this.handleSavePayType}
                  secondaryBtnIcon='save'
                  secondaryBtnText='Save'
                  secondaryBtnVariant="outline-primary"
                />
              </div>
            </Tab>
            <Tab
              eventKey="employee"
              title={<span> Employee</span>}>
              <div>
                <div className="header-btn">
                  <div style={{ display: 'flex' }}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={this.synchroniseTooltip}
                    >
                      <span className="header-icon" onClick={() => this.handleModal(false)}>
                        <ui5-icon class="samples-margin" name="synchronize" id="header-icon"></ui5-icon>
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="table-content">
                  <Table
                    columns={columnsEmployee}
                    data={employeeList}
                  // data={accountPayTypeList && accountPayTypeList.length ? accountPayTypeList : []}                   
                  />
                </div>
              </div>
              <Pagination
                currentPage={mappingEmployeeList.page ? mappingEmployeeList.page : this.state.pageNumber}
                getNextPage={this.getNextPage}
                getPrevPage={this.getPrevPage}
                totalPage={mappingEmployeeList.totalPages}
                setClickedPage={this.setClickedPage}
                currentPageSize={this.state.pageSize}
                setPageSize={this.setPageSize}
              />
              <div className="filter-btns">
                <ButtonGroup
                  primaryBtnClick={this.handleClose}
                  // primaryBtnIcon='decline'
                  primaryBtnText='Cancel'
                  primaryBtnIcon='undo'
                  primaryBtnVariant="outline-primary"
                  secondaryBtnClick={this.handleSaveEmployee}
                  secondaryBtnIcon='save'
                  secondaryBtnText='Save'
                  secondaryBtnVariant="outline-primary"
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }

}

MyobMapping.propTypes = {

}


const mapStateToProps = createStructuredSelector({
  isA2AMappingLoading: selectors.makeSelectA2AMappingLoading(),
  a2aMappingError: selectors.makeSelectA2AMappingError(),
  tenantAccountSystem: makeSelectTenantAccountSystemSuccess(),
  mappingEmployeeList: selectors.makeSelectMappingEmployee(),
  accountEmployeeList: selectors.makeSelectAccountEmployee(),
  isAccountEmployeeList: selectors.makeSelectAccountEmployeeListStatus(),
  mappingPayTypeList: selectors.makeSelectMappingPayType(),
  accountPayTypeList: selectors.makeSelectAccountPayType(),
  isAccountPayTypeList: selectors.makeSelectAccountPayTypeListStatus(),
  a2aMappingSuccess: selectors.makeSelectA2AMappingSuccess(),
  empolyeeSynchronise: selectors.makeSelectAccountEmployeeSynchronise()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(MyobMapping));
