import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getAccountEmployeeRequest(id) {
    return axios.get(baseURL + 'accountIntegration/employee', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingEmployeeRequest(parameters) {
    return axios.get(baseURL + 'a2aEmployee?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendMappingEmployeeRequest(data) {
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL + 'a2aEmployee', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getAccountPayTypeRequest(id) {
    return axios.get(baseURL + 'accountIntegration/payTypeCategory', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingPayTypeRequest(parameters) {
    return axios.get(baseURL + 'a2aPayType?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function sendMappingPayTypeRequest(data) {
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL + 'a2aPayType', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getAccountCompanyFileRequest(id) {
    return axios.get(baseURL + 'accountIntegration/companyFiles', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendAccountCompanyFileRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'accountIntegration/companyFiles', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getAccountEmployeeSynchroniseRequest(id) {
    return axios.get(baseURL + 'accountIntegration/synchronise/employee', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function getAccountCustomerRequest(id) {
    return axios.get(baseURL + 'accountIntegration/customers', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingCustomerRequest(parameters) {
    return axios.get(baseURL + 'a2aCustomer?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendMappingCustomerRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'a2aCustomer', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingProjectRequest(parameters) {
    return axios.get(baseURL + 'a2aProject?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}