import { put, call } from 'redux-saga/effects';
import {
  getRevenueForcastingReport
} from '../service/projectService';
import * as actions from '../../store/action/reportAction';
import {
  getReportList,
  getTenantPeriod,
} from '../service/reportService';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadRevenueForcastingReportRequest(data) {
  yield put(actions.loadRevenueForcastingError(null))
  try {
    yield put(actions.loadRevenueForcastingLoading(true));
    const response = yield call(getRevenueForcastingReport, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result
      yield put(actions.loadRevenueForcastingSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadRevenueForcastingLoading(false));
      const massage = common.error
      yield put(actions.loadRevenueForcastingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadRevenueForcastingLoading(false));
  } catch (error) {
    yield put(actions.loadRevenueForcastingLoading(false));
    console.log(error)
    const massage = common.error
    yield put(actions.loadRevenueForcastingError(massage))
  }
}


export function* loadReportPeriodRequest(data) {
  //console.log('loadReportPeriodRequest')
  // yield put(actions.loadReportError(''))
  try {
    const response = yield call(getTenantPeriod);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      //console.log(response.data.result.result)
      const reportPeriodList = response.data.result.result
      yield put(actions.loadReportPeriodSuccess(reportPeriodList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    // else{
    // const massage = common.error
    //console.log(massage)
    // yield put(actions.loadReportError(massage))
    // }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    //console.log(error)
    //   const massage = common.error
    //   yield put(actions.loadReportError(massage))
  }
}

export function* loadReportListRequest(data) {
  //console.log('loadReportListRequest')
  yield put(actions.loadReportError(''))
  try {
    yield put(actions.loadReportLoading(true));
    const response = yield call(getReportList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      //console.log(response.data.result.result)
      const reportList = response.data.result
      yield put(actions.loadReportSuccess(reportList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadReportLoading(false));
      const massage = common.error
      yield put(actions.loadReportError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadReportLoading(false));
  } catch (error) {
    yield put(actions.loadReportLoading(false));
    //console.log(error)
    const massage = common.error
    yield put(actions.loadReportError(massage))
  }
}

export function* loadReportPrintRequest(data) {
  //console.log('loadReportPrintRequest')
  yield put(actions.loadReportError(''))
  try {
    yield put(actions.loadReportLoading(true));
    const response = yield call(getReportList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200 && response.data.result.errorCode && response.data.result.errorCode === 5000) {
      const massage = 'Data not available for requested period'
      yield put(actions.loadReportError(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      //console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(actions.loadReportPrintSuccess(pdf))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadReportError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadReportLoading(false));
  } catch (error) {
    yield put(actions.loadReportLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.loadReportError(massage))
  }
}

export function* loadReportCSVRequest(data) {
  //console.log('loadReportCSVRequest')
  yield put(actions.loadReportError(''))
  try {
    yield put(actions.loadReportLoading(true));
    const response = yield call(getReportList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200 && response.data.result.errorCode && response.data.result.errorCode === 5000) {
      const massage = 'Data not available for requested period'
      yield put(actions.loadReportError(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      //console.log(response.data.result)
      const csv = response.data.result
      yield put(actions.loadReportCSVSuccess(csv))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadReportError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadReportLoading(false));
  } catch (error) {
    yield put(actions.loadReportLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.loadReportError(massage))
  }
}

