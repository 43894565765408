import { 
    LOAD_TITLE_LIST_ERROR,
    LOAD_TITLE_LIST_LOADING,
    LOAD_TITLE_LIST_REQUEST,
    LOAD_TITLE_LIST_SUCCESS,
    SAVE_IS_TITLE_MODAL_OPEN_STATUS,
    SEND_ADD_NEW_TITLE_REQUEST,
    SEND_TITLE_SUCCESS,
    SEND_DELETE_TITLE_REQUEST,
    SEND_UPDATE_TITLE_REQUEST,
    } from '../constant/titleConstant';
    
    export function saveIsTitleModalOpenStatus(open){
      return {
        type: SAVE_IS_TITLE_MODAL_OPEN_STATUS,
        open,
      }
    };
  
    export function loadTitleListRequest(data) {
      return {
        type: LOAD_TITLE_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadTitleListLoading(data) {
      return {
        type: LOAD_TITLE_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadTitleListSuccess(data) {
      return {
        type: LOAD_TITLE_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadTitleListError(data) {
      return {
        type: LOAD_TITLE_LIST_ERROR,
        payload: data,
      }
    };
  
    export function sendAddNewTitleRequest(data) {
      return {
        type: SEND_ADD_NEW_TITLE_REQUEST,
        payload: data,
      }
    };
    
    export function sendTitleSuccess(data) {
      return {
        type: SEND_TITLE_SUCCESS,
        payload: data,
      }
    };
    
    export function sendUpdateTitleRequest(data) {
      return {
        type: SEND_UPDATE_TITLE_REQUEST,
        payload: data,
      }
    };
    
    export function sendDeleteTitleRequest(data) {
      return {
        type: SEND_DELETE_TITLE_REQUEST,
        payload: data,
      }
    };
    
    