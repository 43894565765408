import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import DatePicker from '../../../layout/form/datePicker';
import moment from 'moment';
import common from '../../../common';

export class UpdateAccountSystem extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            activeFromDate:'',
            initial:true
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        if(prevProps.activeFromDate && prevProps.activeFromDate !=='' && prevState.initial){
            return {
                activeFromDate: moment(prevProps.activeFromDate).format(common.dateFormat),
                initial:false
            }
        }
        return null
    }

    handleDateChange = (date) => {
        this.setState({
            activeFromDate:date
        })
    }
    
  
    render() {
        const {
            isUpdateAccountSystemModalOpen, 
            handleModalClose,
            handleSaveAddNew,
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isUpdateAccountSystemModalOpen} 
                    onHide={handleModalClose}
                    title="Accounting System Integration"
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{marginTop:"5px"}}>
                                <div style={{margin:"5px 15px"}}>
                                Please note that the effective date is a very important date for deciding which invoices need to be integrated with the accounting system. Any invoices created on or after effective date, will be pushed into accounting system. Please make sure that the effective date is correct. If you are not sure, please check with your accountant before you activate the integration.
                                </div>
                                <div className="col-sm">
                                    <DatePicker
                                        text="Active From Date"
                                        controlId="activeFromDate"
                                        onChange={(e) => this.handleDateChange(e)}
                                        value={this.state.activeFromDate === '' ? '' : new Date(this.state.activeFromDate) }
                                    />
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

UpdateAccountSystem.propTypes = {
    isUpdateAccountSystemModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any
}

export default UpdateAccountSystem;