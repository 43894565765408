import { put, call } from 'redux-saga/effects';
import {
  addNewEmailClientRequest,
  deleteEmailConfigRequest,
  getEmailClientList,
  getEmailServerList,
  updateEmailClientRequest
} from '../service/emailConfigurationService';
import * as actions from '../../store/action/emailConfigurationAction';
import { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';

export function* loadEmailServerListRequest(data) {
  try {
    const response = yield call(getEmailServerList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const titleList = response.data.result.result
      yield put(actions.loadEmailServerListSuccess(titleList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}


export function* sendAddNewEmailClientRequest(data) {
  yield put(actions.loadEmailClientListError(false))
  try {
    yield put(actions.loadEmailClientListLoading(true));
    const response = yield call(addNewEmailClientRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      yield put(actions.sendEmailClientSuccess(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadEmailClientListError(true))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadEmailClientListLoading(false));
  } catch (error) {
    yield put(actions.loadEmailClientListLoading(false));
    yield put(actions.loadEmailClientListError(true))
  }
}

export function* loadEmailClientListRequest(data) {
  // yield put(actions.loadEmailClientListError(null))
  try {
    yield put(actions.loadEmailClientListLoading(true));
    const response = yield call(getEmailClientList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const emailClientList = response.data.result.result
      yield put(actions.loadEmailClientListSuccess(emailClientList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    // else {
    //   yield put(actions.loadEmailClientListLoading(false));
    //   const massage = common.error
    //   yield put(actions.loadEmailClientListError(massage))
    // }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadEmailClientListLoading(false));
  } catch (error) {
    yield put(actions.loadEmailClientListLoading(false));
    // const massage = common.error
    // yield put(actions.loadEmailClientListError(massage))
  }
}

export function* sendUpdateEmailClientRequest(data) {
  yield put(actions.loadEmailClientListError(false))
  try {
    yield put(actions.loadEmailClientListLoading(true));
    const response = yield call(updateEmailClientRequest, data.payload);
    if (response && response.status && response.status === 200) {
      yield put(actions.sendEmailClientSuccess(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      // const massage = response.data.message
      yield put(actions.loadEmailClientListError(true))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadEmailClientListLoading(false));
  } catch (error) {
    yield put(actions.loadEmailClientListLoading(false));
    // const massage = common.error
    yield put(actions.loadEmailClientListError(true))
  }
}


export function* sendDeleteEmailClientRequest(data){
  // console.log('sendDeleteInvoiceRequest')
  yield put(actions.loadEmailClientListError(false))
  try{
    yield put(actions.loadEmailClientListLoading(true));
    const response = yield call(deleteEmailConfigRequest,data.payload);
    if(response &&  response.data && response.data.statusCode === 200){
      yield put(actions.sendEmailClientSuccess(true)) 
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadEmailClientListLoading(false));
      yield put(actions.loadEmailClientListError(true))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadEmailClientListLoading(false));
  }catch(error){
    yield put(actions.loadEmailClientListLoading(false));
    yield put(actions.loadEmailClientListError(true))
  }
}
