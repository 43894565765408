import { put, call } from 'redux-saga/effects';
import {
  addNewTitleRequest,
  getTitleList,
  updateTitleRequest,
  deleteTitleRequest
} from '../service/titleService';
import * as actions from '../../store/action/titleAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewTitleRequest(data){
  //console.log('sendAddNewTitleRequest')
  yield put(actions.loadTitleListError(''))
  try{
    // yield put(actions.loadTitleListLoading(true));
    const response = yield call(addNewTitleRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      //console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendTitleSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else {
      const massage = response.data.message
      yield put(actions.loadTitleListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewTitleLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewTitleLoading(false));
    //console.log(error.response.data.detail)
    const massage  =common.error
    yield put(actions.loadTitleListError(massage))
  }
}

export function* loadTitleListRequest(data){
  //console.log('loadTitleListRequest')
  yield put(actions.loadTitleListError(''))
  try{
    yield put(actions.loadTitleListLoading(true));
    const response = yield call(getTitleList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.result)
      const titleList = response.data.result.result
      yield put(actions.loadTitleListSuccess(titleList))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadTitleListLoading(false));
      const massage  =common.error
      yield put(actions.loadTitleListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadTitleListLoading(false));
  }catch(error){
    yield put(actions.loadTitleListLoading(false));
    //console.log(error)
    const massage  =common.error
    yield put(actions.loadTitleListError(massage))
  }
}

export function* sendUpdateTitleRequest(data){
  //console.log('sendUpdateTitleRequest')
  yield put(actions.loadTitleListError(''))
  try{
    // yield put(actions.sendUpdateTitleLoading(true));
    const response = yield call(updateTitleRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendTitleSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadTitleListError(massage))
    } 
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateTitleLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateTitleLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadTitleListError(massage))
  }
}

export function* sendDeleteTitleRequest(data){
  //console.log('sendDeleteTitleRequest')
  yield put(actions.loadTitleListError(''))
  try{
    yield put(actions.loadTitleListLoading(true));
    const response = yield call(deleteTitleRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendTitleSuccess(massage)) 
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadTitleListLoading(false));
      const massage  =common.error
      yield put(actions.loadTitleListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadTitleListLoading(false));
  }catch(error){
    yield put(actions.loadTitleListLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadTitleListError(massage))
  }
}
