const PATH = 'app/Account';

export const LOAD_ACCOUNT_SYSTEM_REQUEST = `${PATH}LOAD_ACCOUNT_SYSTEM_REQUEST`;
export const LOAD_ACCOUNT_ERROR = `${PATH}LOAD_ACCOUNT_ERROR`;
export const LOAD_ACCOUNT_LOADING = `${PATH}LOAD_ACCOUNT_LOADING`;
export const LOAD_ACCOUNT_SYSTEM_SUCCESS = `${PATH}LOAD_ACCOUNT_SYSTEM_SUCCESS`;
export const LOAD_QUICKBOOKS_CONNECTION_REQUEST = `${PATH}LOAD_QUICKBOOKS_CONNECTION_REQUEST`;
export const LOAD_QUICKBOOKS_CONNECTION_SUCCESS = `${PATH}LOAD_QUICKBOOKS_CONNECTION_SUCCESS`;
export const SEND_DELETE_CONNECTION_REQUEST = `${PATH}SEND_DELETE_CONNECTION_REQUEST`;
export const SEND_DELETE_CONNECTION_SUCCESS = `${PATH}SEND_DELETE_CONNECTION_SUCCESS`;
export const SEND_CONNECTION_REQUEST = `${PATH}SEND_CONNECTION_REQUEST`;
export const SEND_CONNECTION_SUCCESS = `${PATH}SEND_CONNECTION_SUCCESS`;
export const SEND_CONNECTION_UPDATE_REQUEST = `${PATH}SEND_CONNECTION_UPDATE_REQUEST`;
export const SEND_CONNECTION_UPDATE_SUCCESS = `${PATH}SEND_CONNECTION_UPDATE_SUCCESS`;
export const LOAD_INTEGRATION_SCENARIO_LOADING = `${PATH}LOAD_INTEGRATION_SCENARIO_LOADING`;
export const LOAD_INTEGRATION_SCENARIO_REQUEST = `${PATH}LOAD_INTEGRATION_SCENARIO_REQUEST`;
export const LOAD_INTEGRATION_SCENARIO_SUCCESS = `${PATH}LOAD_INTEGRATION_SCENARIO_SUCCESS`;
export const SEND_INTEGRATION_SCENARIO_REQUEST = `${PATH}SEND_INTEGRATION_SCENARIO_REQUEST`;
export const SEND_INTEGRATION_SCENARIO_SUCCESS = `${PATH}SEND_INTEGRATION_SCENARIO_SUCCESS`;