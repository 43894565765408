import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
// import './form.css'
import ButtonGroup from '../layout/form/ButtonGroup';

const InformationModal = ({
    show,
    handleInfoModalClose,
    handleInfo,
}) => (
      <BootstrapModal 
        show={show} 
        onHide={handleInfoModalClose}
        animation={false}
        size="lg"
        // dialogClassName="modal_width"
        centered
        >
        <BootstrapModal.Header closeButton>
            <BootstrapModal.Title style={{color:'#18e022', fontSize:'1.1rem'}}>
                 Vemas Subscription Alert !
            </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body style={{color:'#000000'}}> 
            <div style={{fontWeight:600, borderBottom:'2px solid #000000', paddingBottom:'5px', marginBottom:'20px'}}>Vemas trial period expired</div>
            <div>Your 30-day trial mode has expired.</div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <ButtonGroup
                primaryBtnClick={handleInfoModalClose}
                primaryBtnVariant="warning"
                primaryBtnText='Cancel'
                secondaryBtnClick={handleInfo}
                secondaryBtnText='Submit'                               
                secondaryBtnVariant="warning"  
            />
        </BootstrapModal.Footer>
      </BootstrapModal>
);

InformationModal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleInfo: PropTypes.any,
    handleInfoModalClose:PropTypes.any,
}

export default InformationModal;