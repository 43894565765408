import React from 'react';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import BootstrapFormLabel from 'react-bootstrap/cjs/FormLabel';
import BootstrapFormGroup from 'react-bootstrap/cjs/FormGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

const TextField = ({
    controlId,
    text,
    type,
    placeholder,
    as,
    row,
    onChange,
    options,
    display,
    required,
    ...props
}) => (
    <div style={{fontSize:'14px'}}>
        <BootstrapFormGroup controlId={controlId} >
            <BootstrapFormLabel style={{fontWeight:500}}>
                {text} {required ? <span style={{color:'#FF5733'}}>*</span> : ''}
            </BootstrapFormLabel>
            <BootstrapFormControl 
                {...props}
                type={type}
                placeholder={placeholder}
                as={as}
                row={row}
                onChange={onChange}
                size="sm"
            >
                {options && 
                    <> 
                        <option key='' value=''> </option> 
                        {options.map(option =>
                        <option key={option.id} value={option.id}>{display ?  option[display] :option.description}</option>)}
                    </> }
            </BootstrapFormControl>
            
            {/* {text ? 
                <div className="row">
                    <div className="col-sm-3">
                        <BootstrapFormLabel>
                            {text}
                        </BootstrapFormLabel>
                    </div>
                    <div className="col-sm-9" style={{maxWidth:'600px'}}>
                        <BootstrapFormControl 
                            {...props}
                            type={type}
                            placeholder={placeholder}
                            as={as}
                            row={row}
                            onChange={onChange}
                            size="sm"
                        />
                    </div>
                </div> : 
                <div>
                    <BootstrapFormControl 
                        {...props}
                        type={type}
                        placeholder={placeholder}
                        as={as}
                        row={row}
                        onChange={onChange}
                        size="sm"
                    />
                </div>
            } */}
        </BootstrapFormGroup>
    </div>
);

TextField.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.string,
    type: PropTypes.any,
    placeholder: PropTypes.any,
    as: PropTypes.any,
    row: PropTypes.any,
    onChange: PropTypes.any,
    options: PropTypes.any,
    display: PropTypes.any,
    required: PropTypes.any
}

export default TextField;