import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import IntegrationScenario from './IntegrationScenario';
import * as selector from '../../../../store/selector/accountSelector'
import * as actions from '../../../../store/action/accountAction'
import { createStructuredSelector } from 'reselect';
import common from '../../../common';
import Loader from '../../../layout/form/Loader';
import SuccessModal from '../../../layout/form/successModal';
import MyobCompany from './MyobCompany';
import MyobMapping from './MyobMapping';

class Myob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scenarios: [],
            tab: "SCENARIO",
            initial: true,
            customer: false,
            timesheet: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { integrationScenarioList } = prevProps;
        const { initial } = prevState;
        if (initial && integrationScenarioList && integrationScenarioList.tenantId && integrationScenarioList.tenantId > 0) {
            var timesheetMapping = integrationScenarioList.scenarios.find(e => e.integrationScenarioType === 'TIMESHEET_TO_EMPLOYEE')

            return {
                scenarios: integrationScenarioList.scenarios,
                initial: false,
                timesheet: (timesheetMapping && timesheetMapping.status)
            }
        }
        return null;
    }



    redirectTimesheetMapping = () => {
        this.setState({
            tab: "TIMESHEET"
        })
    }

    redirectCompanyMapping= () => {
        this.setState({
            tab: "COMPANY"
        })
    }

    redirectScenarioMapping = () => {
        this.setState({
            tab: "SCENARIO"
        })
    }

    redirectDataMapping = () => {
        this.setState({
            tab: "TIMESHEET_DATA"
        })
    }


    handleSaveScenario = () => {
        const { scenarios } = this.state;
        const { integrationScenarioList } = this.props;
        integrationScenarioList.scenarios = scenarios
        this.props.dispatch(actions.sendIntegrationScenarioRequest(integrationScenarioList))
    }

    successModalClose = () => {
        this.props.dispatch(actions.sendIntegrationScenarioSuccess(null))
        this.props.dispatch(actions.loadIntegrationScenarioSuccess([]))
        this.props.dispatch(actions.loadIntegrationScenarioRequest())
        this.getIntegrationScenario()
        this.setState({
            initial: true
        })
    }

    componentDidMount() {
        this.getIntegrationScenario()
    }

    getIntegrationScenario = () => {
        this.props.dispatch(actions.loadIntegrationScenarioRequest(common.accounting.myob))
    }

    getActive = (value, rowData) => {
        const { scenarios } = this.state;
        const newRow = rowData.original
        newRow.status = value
        scenarios[rowData.index] = newRow
        this.setState({
            scenarios
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.integrationScenarioSuccess !== null) {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {

        const {
            scenarios,
            tab,
            timesheet
        } = this.state;

        const {
            isIntegrationScenarioLoading,
            integrationScenarioSuccess,
        } = this.props;

        return (
            <div>
                {(isIntegrationScenarioLoading) &&
                    <Loader show={isIntegrationScenarioLoading} />}

                {(integrationScenarioSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={integrationScenarioSuccess}
                        handleClose={() => this.successModalClose()}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <span>
                            <Link to='./accounting'>
                                <ui5-icon class="samples-margin" name="connected" id="tab-icon"></ui5-icon> Connection
                            </Link>
                        </span>
                        <span>
                            <Link to='./myob'>
                                <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                            </Link>
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <span style={{ cursor: 'pointer' }} onClick={() => this.redirectScenarioMapping()}><ui5-icon class="samples-margin" name="calendar" id="tab-icon"></ui5-icon> Integration Scenario</span>
                    {/* {timesheet ?
                        <span style={{ cursor: 'pointer' }} onClick={() => this.redirectTimesheetMapping()} ><ui5-icon class="samples-margin" name="list" id="tab-icon"></ui5-icon> Timesheet</span> : ''} */}
                    {timesheet ?
                        <span style={{ cursor: 'pointer' }} onClick={() => this.redirectCompanyMapping()}><ui5-icon class="samples-margin" name="calendar" id="tab-icon"></ui5-icon> Company</span> : ''}
                    {timesheet ?
                        <span style={{ cursor: 'pointer' }} onClick={() => this.redirectDataMapping()} ><ui5-icon class="samples-margin" name="list" id="tab-icon"></ui5-icon> Data</span> : ''}
                </div>
                {
                    tab === "SCENARIO" ?
                        <IntegrationScenario
                            scenarios={scenarios}
                            getActive={this.getActive}
                            handleSaveScenario={this.handleSaveScenario}
                        /> :
                        ""
                }
                {
                    tab === "COMPANY" ?
                        <MyobCompany
                        /> :
                        ""
                }
                {
                    tab === "TIMESHEET_DATA" ?
                        <MyobMapping
                        /> :
                        ""
                }
            </div>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    integrationScenarioList: selector.makeSelectIntegrationScenarioList(),
    isIntegrationScenarioLoading: selector.makeSelectIntegrationScenarioLoading(),
    integrationScenarioSuccess: selector.makeSelectIntegrationScenarioSuccess()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default (compose(withConnect)(Myob));
