import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectUOMList } from '../../../../store/selector/selector';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import 'bootstrap/dist/css/bootstrap.min.css';
import Label from 'react-bootstrap/cjs/FormLabel';
import FormControl from 'react-bootstrap/cjs/FormControl';
import FormGroup from 'react-bootstrap/cjs/FormGroup';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
// import { makeSelectRepairList } from '../../../../store/selector/repairSelector';
// import { makeSelectPartList } from '../../../../store/selector/partSelector';
import Table from '../../../layout/form/Table';
// import { makeSelectInvoicePrintSuccess } from '../../../../store/selector/invoiceSelector';
import PdfViewer from '../../../layout/form/PdfViewer';
// import { loadSelectedJobCardRequest, loadSelectedJobCardSuccess } from '../../../../store/action/jobCardAction';
// import { makeSelectSelectedJobCardSuccess } from '../../../../store/selector/jobCardSelector';
import DatePicker from '../../../layout/form/datePicker';
import moment from 'moment';
import common, { calculateTotal } from '../../../common';
// import { loadSelectedVehicleSuccess } from '../../../../store/action/vehicleAction';
// import WarningModal from '../../../layout/form/warningModal';
// import { makeSelectProductList } from '../../../../store/selector/productSelector';
import Form from 'react-bootstrap/cjs/Form';
// import EmailViewer from '../../../layout/form/EmailViewer';
import ErrorModal from '../../../layout/form/errorModal';
import { loadInvoiceTimsheetDetailsRequest, loadInvoiceTimsheetDetailsSuccess } from '../../../../store/action/invoiceAction';
import { makeSelectInvoiceTimesheetDetailList } from '../../../../store/selector/invoiceSelector';
import { makeSelectEmployeeList } from '../../../../store/selector/employeeSelector';
import EmailViewer from '../../../layout/form/EmailViewer';


const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};

const customStylesDiscount = {
    control: base => ({
        ...base,
        height: 30,
        minHeight: 38,
        marginBottom: 0,
        marginTop: -20
    })
};

export class AddNewInvoice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: '',
            jobCardNo: '',
            vehicleNo: '',
            customer: '',
            printName: '',
            email: '',
            invoiceDate: '',
            invoiceNo: '',
            paymentTerm: '',
            paymentMethodId: '',
            dueDate: '',
            referenceNo: '',
            invoiceItems: [],
            isDelete: false,
            subTotal: 0,
            discountValue: 0,
            tax: 0,
            total: 0,
            discountTypeValue: 0,
            discountType: '',
            customerNotes: '',
            initial: true,
            tempInvoiceItemsList: [],
            selectedJobCardId: '',
            isProjectChange: false,
            selectedTaxRate: 0,
            isWarning: false,
            selectedJobCardDetails: '',
            isPriceInclusiveTax: false,
            gstCalValue: 0,
            isUserAccess: false,
            isValidationError: false,
            errorMassage: '',
            project: 0,
            periodEndDate: '',
            projectMilestoneSpecified: false
        }
    }


    static getDerivedStateFromProps(prevProps, prevState) {
        const {
            selectedInvoice, isUpdate, selectedRow,
            objectStatusList, paymentTermList, tenantFinance,
            selectedCustomer, isNewInvoice, paymentMethodList,
            userAccess, selectedProject, customerList
        } = prevProps;
        
        const { isPriceInclusiveTax,discountType,discountTypeValue } = prevState;
        var access = false

        // Update Invoice create 
        if (selectedInvoice && selectedInvoice.id && prevState.initial && isUpdate && selectedInvoice.id === selectedRow.id) {

            if (userAccess && userAccess.modifyInvoice) {
                access = true
            }

            var itemList = []

            if (selectedInvoice.invoiceItems.length !== 0) {
                for (let i = 0; i < selectedInvoice.invoiceItems.length; i++) {
                    const element = selectedInvoice.invoiceItems[i];

                    const newRow = element
                    newRow.recordIndex = ''
                    newRow.operation = "U"
                    newRow.tax = element.tax ? element.tax : ''
                    itemList.push(newRow)
                }
            }
            const values = calculateTotal(tenantFinance.taxRate, itemList, selectedInvoice.taxIncPrice, selectedInvoice.discountType, selectedInvoice.discountTypeValue)

            return {
                status: selectedInvoice.statusId,
                customer: selectedInvoice.customerId,
                printName: selectedInvoice.customerName,
                invoiceDate: moment(selectedInvoice.invoiceDate).format(common.dateFormat),
                invoiceNo: selectedInvoice.invoiceNo,
                email: selectedInvoice.customerEmail ? selectedInvoice.customerEmail : '',
                paymentTerm: parseInt(selectedInvoice.paymentTermId),
                paymentMethodId: selectedInvoice.paymentMethodId,
                dueDate: moment(selectedInvoice.dueDate).format(common.dateFormat),
                referenceNo: selectedInvoice.referenceNo,
                invoiceItems: itemList,
                subTotal: selectedInvoice.subTotal,
                discountValue: selectedInvoice.discountValue,
                tax: selectedInvoice.tax,
                selectedTaxRate: selectedInvoice.taxRate,
                total: selectedInvoice.total,
                discountTypeValue: selectedInvoice.discountTypeValue,
                discountType: selectedInvoice.discountType,
                customerNotes: selectedInvoice.notes ? selectedInvoice.notes : '',
                initial: false,
                isPriceInclusiveTax: selectedInvoice.taxIncPrice,
                gstCalValue: values.gstCalValue,
                isUserAccess: access,
                project: selectedInvoice.projectId,
                periodEndDate: selectedInvoice.referenceDate
            }
        }
        // New Invoice create using customer
        else if (
            objectStatusList && objectStatusList.length && objectStatusList.length !== 0 &&
            paymentTermList && paymentTermList.length && paymentTermList.length !== 0 &&
            !isUpdate && prevState.initial && tenantFinance && tenantFinance.id &&
            selectedCustomer && selectedCustomer.id) {
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            const defaultPaymentMethod = paymentMethodList.filter(data => (data.default === true))
            const defaultPaymentTerm = paymentTermList.filter(data => (data.id === tenantFinance.defaultPaymentTerm))
            const today = new Date()
            const dueDate = new Date()
            dueDate.setDate(dueDate.getDate() + defaultPaymentTerm[0].numberOfDaysDue)
            if (userAccess && userAccess.createInvoice) {
                access = true
            }
            return {
                status: defaultStatus[0].id,
                initial: false,
                invoiceDate: moment(today).format(common.dateFormat),
                dueDate: moment(dueDate).format(common.dateFormat),
                paymentTerm: defaultPaymentTerm[0].id,
                paymentMethodId: defaultPaymentMethod[0].id,
                selectedTaxRate: tenantFinance.taxRate,
                customer: selectedCustomer.id,
                printName: selectedCustomer.displayName,
                email: selectedCustomer.emailAddress,
                isUserAccess: access,
                periodEndDate: moment(today).format(common.dateFormat),
            }
        }

        else if(objectStatusList && objectStatusList.length && objectStatusList.length !== 0 &&
            paymentTermList && paymentTermList.length && paymentTermList.length !== 0 &&
            !isUpdate && prevState.initial && tenantFinance && tenantFinance.id && customerList && customerList.length !== 0 && 
            paymentMethodList && paymentMethodList.length && paymentMethodList.length !== 0 &&
            selectedProject && selectedProject.id){
                const defaultStatus = objectStatusList.filter(data => (data.default === true))
                const defaultPaymentMethod = paymentMethodList.filter(data => (data.default === true))
                const defaultPaymentTerm = paymentTermList.filter(data => (data.id === tenantFinance.defaultPaymentTerm))
                const customer = customerList.find(row => row.id === selectedProject.customerId)
                const today = new Date()
                const dueDate = new Date()
                dueDate.setDate(dueDate.getDate() + defaultPaymentTerm[0].numberOfDaysDue)
                if (userAccess && userAccess.createInvoice) {
                    access = true
                }

                var itemList_ = []

                if (selectedProject && selectedProject.ProjectMilestones && selectedProject.ProjectMilestones.length >= 0) {
                    for (let i = 0; i < selectedProject.ProjectMilestones.length; i++) {
                        const element = selectedProject.ProjectMilestones[i];

                        const newRow = {
                            description: element.description,
                            subTotal: element.approvedValue > 0 ? element.approvedValue : 0,
                            quantity: element.approvedEffort > 0 ? element.approvedEffort : 0,
                            price: element.approvedValue > 0 ? element.approvedValue / element.approvedEffort : 0,
                            unitOfMeasure: 'EA',
                            referenceItemId: element.id
                        }
                        newRow.recordIndex = ''
                        newRow.operation = "U"
                        newRow.tax = element.tax ? element.tax : ''
                        itemList_.push(newRow)
                    }
                }
                const values_ = calculateTotal(tenantFinance.taxRate, itemList_, isPriceInclusiveTax,discountType,discountTypeValue)

                return {
                    status: defaultStatus[0].id,
                    initial: false,
                    invoiceDate: moment(today).format(common.dateFormat),
                    dueDate: moment(dueDate).format(common.dateFormat),
                    paymentTerm: defaultPaymentTerm[0].id,
                    paymentMethodId: defaultPaymentMethod[0].id,
                    selectedTaxRate: tenantFinance.taxRate,
                    customer: customer && customer.id,
                    printName: customer && customer.displayName,
                    email: customer && customer.emailAddress,
                    project: selectedProject.id,
                    referenceNo: selectedProject.purchaseOrderNo,
                    isUserAccess: access,
                    invoiceItems:itemList_,
                    subTotal:values_.subTotal,
                    tax: values_.tax,
                    total: values_.total,
                    gstCalValue: values_.gstCalValue,
                    projectMilestoneSpecified: true,
                    periodEndDate: moment(today).format(common.dateFormat),
                }
            }

        // New Invoice create
        else if (
            objectStatusList && objectStatusList.length && objectStatusList.length !== 0 &&
            paymentTermList && paymentTermList.length && paymentTermList.length !== 0 &&
            isNewInvoice && prevState.initial && tenantFinance && tenantFinance.id) {
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            const defaultPaymentMethod = paymentMethodList.filter(data => (data.default === true))
            const defaultPaymentTerm = paymentTermList.filter(data => (data.id === tenantFinance.defaultPaymentTerm))
            const today = new Date()
            const dueDate = new Date()
            dueDate.setDate(dueDate.getDate() + defaultPaymentTerm[0].numberOfDaysDue)
            if (userAccess && userAccess.createInvoice) {
                access = true
            }
            return {
                status: defaultStatus[0].id,
                initial: false,
                invoiceDate: moment(today).format(common.dateFormat),
                dueDate: moment(dueDate).format(common.dateFormat),
                periodEndDate: moment(today).format(common.dateFormat),
                paymentTerm: defaultPaymentTerm[0].id,
                paymentMethodId: defaultPaymentMethod[0].id,
                selectedTaxRate: tenantFinance.taxRate,
                isUserAccess: access
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeSelect = (value, id) => {
        const { projectList } = this.props;
        if (id === "project") {
            this.props.dispatch(loadInvoiceTimsheetDetailsSuccess([]))
            const parms = {
                projectId: value,
                date: this.state.periodEndDate
            }
            this.props.dispatch(loadInvoiceTimsheetDetailsRequest(parms))
            const project = projectList.find(e => e.id === value)
            if (project && project.id > 0) {
                this.setState({
                    [id]: value,
                    customer: project.customerId,
                    printName: project.customerName,
                    email: project.customerEmail,
                    invoiceItems: [],
                    tempInvoiceItemsList: [],
                    subTotal: 0,
                    discountValue: 0,
                    tax: 0,
                    total: 0,
                    discountTypeValue: 0,
                    discountType: '',
                    isProjectChange: true

                })
            } else {
                this.setState({
                    [id]: value,
                    customer: '',
                    printName: '',
                    email: '',
                    invoiceItems: [],
                    tempInvoiceItemsList: [],
                    subTotal: 0,
                    discountValue: 0,
                    tax: 0,
                    total: 0,
                    discountTypeValue: 0,
                    discountType: '',
                    isProjectChange: true

                })
            }
        }
        else {
            this.setState({
                [id]: value
            })
        }
    }

    handleChangeStatus = (id) => {
        this.setState({ status: id })
    }

    handleCheck = (e) => {
        const { discountType, discountTypeValue } = this.state;
        this.setState({
            isPriceInclusiveTax: e.target.checked
        })
        this.calTotal(this.state.invoiceItems, e.target.checked, discountType, discountTypeValue)
    }

    handleChangeDiscountType = (e, valueType) => {
        const { invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;

        if (valueType === "discountType" && e === "0" && discountTypeValue > 100) {
            this.calTotal(invoiceItems, isPriceInclusiveTax, e, 0)
            this.setState({
                discountTypeValue: 0,
                discountType: e
            })
            return
        }
        if (e && e.target && e.target.id === 'discountTypeValue' && discountType === "0" && parseInt(e.target.value) > 100) {
            this.calTotal(invoiceItems, isPriceInclusiveTax, e, 0)
            this.setState({
                discountTypeValue: 0,
            })
            return
        }
        if (valueType === "discountType") {
            this.setState({
                discountType: e
            })
            this.calTotal(invoiceItems, isPriceInclusiveTax, e, discountTypeValue)
        } else {
            this.setState({
                [e.target.id]: e.target.value
            })
        }

        if (e && e.target && e.target.id === 'discountTypeValue') {
            this.calTotal(invoiceItems, isPriceInclusiveTax, discountType, e.target.value)
        }

        if (valueType === 'discountType' && (e === '' || e === null)) {
            this.setState({
                discountTypeValue: 0
            })
            this.calTotal(invoiceItems, isPriceInclusiveTax, discountType, 0)
        }
    }

    handleWarningModalClose = () => {
        const { objectStatusList, paymentTermList, tenantFinance, paymentMethodList } = this.props;
        const defaultStatus = objectStatusList.filter(data => (data.default === true))
        const defaultPaymentMethod = paymentMethodList.filter(data => (data.default === true))
        const defaultPaymentTerm = paymentTermList.filter(data => (data.id === tenantFinance.defaultPaymentTerm))
        const today = new Date()
        const dueDate = new Date()
        dueDate.setDate(dueDate.getDate() + defaultPaymentTerm[0].numberOfDaysDue)
        this.setState({
            isWarning: false,
            selectedJobCardDetails: '',
            status: defaultStatus[0].id,
            invoiceDate: moment(today).format(common.dateFormat),
            dueDate: moment(dueDate).format(common.dateFormat),
            paymentTerm: defaultPaymentTerm[0].id,
            paymentMethodId: defaultPaymentMethod[0].id,
            selectedTaxRate: tenantFinance.taxRate,
            jobCardNo: '',
            vehicleNo: '',
            customer: '',
            printName: '',
            email: '',
            invoiceNo: '',
            referenceNo: '',
            invoiceItems: [],
            subTotal: 0,
            discountValue: 0,
            tax: 0,
            total: 0,
            discountTypeValue: 0,
            discountType: '',
            customerNotes: '',
        })
    }

    handleChangeCustomer = (id) => {
        const customerId = parseInt(id)
        const { customerList } = this.props;
        const selctedCustomer = customerList.find(row => row.id === customerId)
        this.setState({
            customer: selctedCustomer.id,
            printName: selctedCustomer.displayName ? selctedCustomer.displayName : '',
            email: selctedCustomer.emailAddress ? selctedCustomer.emailAddress : '',
        })
    }

    handleChangePaymentTerm = (id) => {
        const { paymentTermList } = this.props;
        const { invoiceDate } = this.state;
        const dateInvoice = new Date(invoiceDate)
        const selctedPaymentTerm = paymentTermList.find(row => row.id === id)
        dateInvoice.setDate(dateInvoice.getDate() + selctedPaymentTerm.numberOfDaysDue)
        const newDueDate = moment(dateInvoice).format(common.dateFormat)
        this.setState({
            paymentTerm: id,
            dueDate: newDueDate
        })
    }

    handleDateChange = (date, id) => {
        const { paymentTermList } = this.props;
        if (date === null) {
            const today = new Date()
            const currentDate = new Date()
            const selctedPaymentTerm = paymentTermList.find(row => row.id === this.state.paymentTerm)
            today.setDate(today.getDate() + selctedPaymentTerm.numberOfDaysDue)
            const newDueDate = moment(today).format(common.dateFormat)
            this.setState({
                invoiceDate: moment(currentDate).format(common.dateFormat),
                dueDate: newDueDate
            })
        } else {
            const dateInvoice = new Date(date)
            const selctedPaymentTerm = paymentTermList.find(row => row.id === this.state.paymentTerm)
            dateInvoice.setDate(dateInvoice.getDate() + selctedPaymentTerm.numberOfDaysDue)
            const newDueDate = moment(dateInvoice).format(common.dateFormat)
            this.setState({
                [id]: moment(date).format(common.dateFormat),
                dueDate: newDueDate
            })
        }
    }

    handlePeriodEndDateChange = (date) => {
        this.props.dispatch(loadInvoiceTimsheetDetailsSuccess([]))
        if (date == null) {
            const currentDate = new Date()
            const parms = {
                projectId: this.state.project,
                date: moment(currentDate).format(common.dateFormat)
            }
            if (this.state.project > 0) {
                this.props.dispatch(loadInvoiceTimsheetDetailsRequest(parms))
            }
            this.setState({
                periodEndDate: moment(currentDate).format(common.dateFormat),
                invoiceItems: [],
                tempInvoiceItemsList: [],
                subTotal: 0,
                discountValue: 0,
                tax: 0,
                total: 0,
                discountTypeValue: 0,
                discountType: '',
                isProjectChange: true
            })
        } else {
            const currentDate = new Date(date)
            const parms = {
                projectId: this.state.project,
                date: moment(currentDate).format(common.dateFormat)
            }
            if (this.state.project > 0) {
                this.props.dispatch(loadInvoiceTimsheetDetailsRequest(parms))
            }
            this.setState({
                periodEndDate: moment(currentDate).format(common.dateFormat),
                invoiceItems: [],
                tempInvoiceItemsList: [],
                subTotal: 0,
                discountValue: 0,
                tax: 0,
                total: 0,
                discountTypeValue: 0,
                discountType: '',
                isProjectChange: true
            })
        }
    }

    addNewRow = () => {
        var { invoiceItems } = this.state;
        var newRow = { recordIndex: "", description: "", operation: "C", salesCategoryId: 0, unitOfMeasure: "DAY" }
        invoiceItems.push(newRow)
        this.setState({ invoiceItems })
    }

    handleRowDescription = (e, rowData) => {
        const { invoiceItems } = this.state;
        const selectedRow = invoiceItems[rowData.index]
        const newRow = selectedRow
        newRow.operation = rowData.original.id ? "U" : "C"
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.description = e.target.value
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems })
    }

    handleRowUnitPrice = (e, rowData) => {
        const { invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        const selectedRow = invoiceItems[rowData.index]
        const newRow = selectedRow
        newRow.operation = rowData.original.id ? "U" : "C"
        newRow.price = e.target.value
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.subTotal = (e.target.value && selectedRow.quantity ? e.target.value * selectedRow.quantity : 0)
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems })
        this.calTotal(invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue)
    }

    handleRowQuantity = (e, rowData) => {
        const { invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        const selectedRow = invoiceItems[rowData.index]
        const newRow = selectedRow
        newRow.operation = rowData.original.id ? "U" : "C"
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.quantity = e.target.value
        newRow.subTotal = (selectedRow.price && e.target.value ? selectedRow.price * e.target.value : 0)
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems })
        this.calTotal(invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue)
    }

    handleDelete = (rowData) => {
        var { invoiceItems } = this.state;
        const newRow = rowData.original
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.operation = "D"
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems, isDelete: true })
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
    }

    calTotal = (invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue) => {
        const { tenantFinance } = this.props;
        const values = calculateTotal(tenantFinance.taxRate, invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue)
        this.setState({
            subTotal: values.subTotal,
            discountValue: values.discountValue,
            tax: values.tax,
            total: values.total,
            gstCalValue: values.gstCalValue
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            invoiceItems,
            isDelete,
            tempInvoiceItemsList,
            isPriceInclusiveTax,
            discountType,
            discountTypeValue,
            isProjectChange,
            project,
            periodEndDate
        } = this.state;
        const { invoiceTimesheetDetails, employeeList } = this.props;
        if (isDelete) {
            const invoiceItemsList = invoiceItems.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = invoiceItems.filter(data => (data.id !== null))
            this.setState({ invoiceItems: invoiceItemsList, isDelete: false, tempInvoiceItemsList: tempInvoiceItemsList.concat(tempItemList) })
            this.calTotal(invoiceItemsList, isPriceInclusiveTax, discountType, discountTypeValue)
        }

        if (isProjectChange &&
            invoiceTimesheetDetails && invoiceTimesheetDetails.length &&
            (prevState.project === project || prevState.periodEndDate === periodEndDate)
        ) {
            const list = []
            for (let i = 0; i < invoiceTimesheetDetails.length; i++) {
                const item = invoiceTimesheetDetails[i]
                const employee = employeeList.result.find(e => e.userId === item.userId)
                const employeeName = employee && employee.id ? employee.firstName + " " + employee.lastName : ''
                if (list.length > 0) {
                    const existRow = list.find(e => e.userId === item.userId)
                    if (existRow && existRow.userId > 0) {
                        const timesheetDetail = existRow.timesheetDetails
                        timesheetDetail.push(item.id)
                        const existRowIndex = list.indexOf(e => e.userId === item.userId)
                        const element = existRow
                        element.quantity = existRow.quantity + item.time
                        element.subTotal = existRow.subTotal + (item.time * existRow.price)
                        element.timesheetDetails = timesheetDetail
                        list[existRowIndex] = element

                    } else {
                        const timesheetDetail = []
                        timesheetDetail.push(item.id)
                        const element = {
                            userId: item.userId,
                            recordIndex: '',
                            description: employeeName,
                            unitOfMeasure: "DAY",
                            quantity: item.time ? item.time : 0.00,
                            price: item.sellRate ? item.sellRate : 0.00,
                            subTotal: (item.time !== 0 && item.sellRate !== 0) ? item.time * item.sellRate : 0.00,
                            operation: "U",
                            timesheetDetails: timesheetDetail,
                            salesCategoryId: 4,
                            referenceItemId: 0
                        }
                        list.push(element)
                    }
                } else {
                    const timesheetDetail = []
                    timesheetDetail.push(item.id)
                    const element = {
                        userId: item.userId,
                        recordIndex: '',
                        description: employeeName,
                        unitOfMeasure: "DAY",
                        quantity: item.time ? item.time : 0.00,
                        price: item.sellRate ? item.sellRate : 0.00,
                        subTotal: (item.time !== 0 && item.sellRate !== 0) ? item.time * item.sellRate : 0.00,
                        operation: "U",
                        timesheetDetails: timesheetDetail,
                        salesCategoryId: 4,
                        referenceItemId: 0
                    }
                    list.push(element)
                }

            }
            this.setState({
                isProjectChange: false,
                tempInvoiceItemsList: [],
                invoiceItems: list
            })
            this.calTotal(list, isPriceInclusiveTax, discountType, discountTypeValue)
        }
    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            uomList,
            customerList,
            objectStatusList,
            handlePrint,
            isPrintModalOpen,
            invoicePrint,
            printModalClose,
            handleSendEmail,
            paymentTermList,
            tenantFinance,
            isUpdate,
            selectedInvoice,
            isEmailEditorOpen,
            handleEmailViewerClose,
            invoiceEmailDetails,
            onEditorChange,
            handleSendInvoiceEmail,
            handleChangeEmailDetails,
            paymentMethodList,
            isInvoiceListLoading,
            projectList
        } = this.props;

        const {
            invoiceItems,
            // isWarning,
            // selectedJobCardDetails,
            gstCalValue,
            isUserAccess,
            isValidationError,
            errorMassage
        } = this.state;

        const invoiceItemsColumns = [{
            Header: 'Item No',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="index"
                        type="text"
                        defaultValue={rowData.cell.row.index + 1}
                        size="sm"
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Description',
            accessor: 'description',
            // width:1000,
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="description"
                        type="text"
                        defaultValue={rowData.cell.row.original.description}
                        size="sm"
                        onBlur={(e) => this.handleRowDescription(e, rowData.cell.row)}
                        maxLength={common.maxLengths.jobCardDescription}
                        disabled={this.state.project > 0 || !isUserAccess}
                    />
                </div>
            ),
        }, {
            Header: 'UOM',
            accessor: 'unitOfMeasure',
            width: '10%',
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="unitOfMeasure"
                        options={uomList}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.unitOfMeasure}
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Unit Price',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="price"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.price).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowUnitPrice(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!isUserAccess}
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Quantity',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="quantity"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.quantity).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowQuantity(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!isUserAccess}
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Amount',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="amount"
                        type="text"
                        value={rowData.cell.row.original.subTotal ? parseFloat(rowData.cell.row.original.subTotal).toFixed(2) : '0.00'}
                        size="sm"
                        disabled
                        style={{ textAlign: 'right' }}
                    // width="100%"
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span onClick={isUserAccess && this.state.status !== "40" && this.state.project === 0 ? () => this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={isUserAccess && this.state.status !== "40" && this.state.project === 0 ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]
        return (
            <div>
                {(isValidationError) &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : ''}
                        handleClose={this.errorModalClose}
                    />}
                {tenantFinance && tenantFinance.id &&
                    <div>
                        <div className="filter-header">
                            <h5 style={{ marginLeft: '15px' }}>
                                Invoice {isUpdate && selectedInvoice && selectedInvoice.id ? " - " + selectedInvoice.invoiceNo : ""}
                            </h5>
                            <div className="btn-close" onClick={handleClose} style={{ marginRight: '10px' }}>
                                <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                            </div>
                        </div>
                        <div className="row" style={{ margin: '0 5px' }}>
                            <div className="col-sm page_header">
                                <div className="page_header-title">
                                    <span>Project Details</span>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Project"
                                            controlId="project"
                                            options={projectList}
                                            selectedValue={this.state.project}
                                            onChange={(e) => this.handleChangeSelect(e, "project")}
                                            required
                                            display="projectName"
                                            disabled={!isUserAccess || isUpdate}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Period end date"
                                            controlId="periodEndDate"
                                            required
                                            onChange={(e) => this.handlePeriodEndDateChange(e)}
                                            value={this.state.periodEndDate === '' ? '' : new Date(this.state.periodEndDate)}
                                            disabled={!isUserAccess || isUpdate}
                                        />
                                    </div>
                                </div>
                                <SelectField
                                    text="Customer"
                                    controlId="customer"
                                    options={
                                        customerList && customerList.length && customerList.length > 0 ?
                                            customerList.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ?
                                                1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0)) : []}
                                    selectedValue={this.state.customer}
                                    onChange={this.handleChangeCustomer}
                                    display="displayName"
                                    disabled={this.state.project > 0 || !isUserAccess}
                                    required
                                />
                                <TextField
                                    text="Print name"
                                    controlId="printName"
                                    placeholder="Print name"
                                    type="text"
                                    onChange={this.handleChange}
                                    required
                                    defaultValue={this.state.printName}
                                    disabled={!isUserAccess}
                                />
                                <TextField
                                    text="Email address"
                                    controlId="email"
                                    placeholder="Email address"
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.email}
                                    disabled={!isUserAccess}
                                />
                            </div>
                            <div className="col-sm page_header">
                                <div className="page_header-title">
                                    <span>Reference Details</span>
                                </div>
                                <TextField
                                    text="Invoice no"
                                    controlId="invoiceNo"
                                    placeholder="Invoice no"
                                    type="text"
                                    onChange={this.handleChange}
                                    disabled
                                    defaultValue={this.state.invoiceNo}
                                />
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Payment term"
                                            controlId="paymentTerm"
                                            options={paymentTermList}
                                            selectedValue={this.state.paymentTerm}
                                            onChange={this.handleChangePaymentTerm}
                                            required
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Payment method"
                                            controlId="paymentMethodId"
                                            options={paymentMethodList}
                                            selectedValue={this.state.paymentMethodId}
                                            onChange={(e) => this.handleChangeSelect(e, 'paymentMethodId')}
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Invoice date"
                                            controlId="invoiceDate"
                                            onChange={(e) => this.handleDateChange(e, "invoiceDate")}
                                            value={this.state.invoiceDate === '' ? '' : new Date(this.state.invoiceDate)}
                                            required
                                            disabled={!isUserAccess}
                                        // format={dateFormat}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Due date"
                                            controlId="dueDate"
                                            disabled
                                            required
                                            // onChange={(e) => this.handleDateChange(e,"dueDate")}
                                            value={this.state.dueDate === '' ? '' : new Date(this.state.dueDate)}
                                        // format={dateFormat}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Reference no"
                                            controlId="referenceNo"
                                            placeholder="Reference no"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.referenceNo}
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            onChange={this.handleChangeStatus}
                                            required
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="invoice-title">
                                <span>Invoice Items</span>
                                <span>
                                    <div style={{ margin: '0 20px' }}>
                                        <Form.Group controlId="inclusiveTaxCheckBox">
                                            <Form.Check type="checkbox" style={{ fontSize: "14px" }} checked={this.state.isPriceInclusiveTax} label="Price inclusive tax" onChange={isUserAccess ? this.handleCheck : ''} />
                                        </Form.Group>
                                    </div>
                                </span>
                            </div>
                            <div className="table-invoice">
                                <Table
                                    columns={invoiceItemsColumns}
                                    data={invoiceItems}
                                />
                                {this.state.project > 0 ? "" :
                                    <div className="add-icon" >
                                        <ui5-icon class="samples-margin" name="add-activity" id="add-activity" onClick={isUserAccess && this.state.status !== "40" ? this.addNewRow : ''}></ui5-icon>
                                    </div>}

                            </div>
                        </div>
                        <div className="row" style={{ margin: '0 5px' }}>
                            <div className='col-sm-6'>
                                <TextField
                                    text="Notes"
                                    controlId="customerNotes"
                                    defaultValue={this.state.customerNotes}
                                    onBlur={this.handleChange}
                                    as="textarea"
                                    // row={50}
                                    style={{ height: 200 }}
                                    disabled={!isUserAccess}
                                />
                            </div>
                            <div className='col-sm ' style={{ textAlign: 'right', marginRight: '-15px' }}>
                                <div className="row value" style={{ marginBottom: '20px' }}>
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            Sub total
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="subTotal"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.subTotal).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value" >
                                    <div className='col-sm'>
                                        {/* <FormGroup controlId="discountType">
                                        <FormControl
                                            as="select"
                                            style={{textAlign:'right'}}
                                            value={this.state.discountType}
                                            onChange={this.handleChangeDiscountType}
                                        >
                                            <option key='' value=''> </option> 
                                            <option key='0' value='0'>Discount Percentage</option> 
                                            <option key='1' value='1'>Discount Value</option> 
                                        </FormControl>
                                    </FormGroup> */}
                                        <SelectField
                                            controlId="discountType"
                                            onChange={(e) => this.handleChangeDiscountType(e, "discountType")}
                                            options={common.discountType}
                                            style={customStylesDiscount}
                                            selectedValue={this.state.discountType}
                                            isClearable
                                            disabled={!isUserAccess}
                                        // id="partId"
                                        />
                                    </div>
                                    <div className='col-sm'>
                                        <FormGroup controlId="discountTypeValue">
                                            <FormControl
                                                type="number"
                                                style={{ textAlign: 'right' }}
                                                value={this.state.discountTypeValue}
                                                onChange={this.handleChangeDiscountType}
                                                disabled={!isUserAccess}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="discountValue"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.discountValue).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value" style={{ marginBottom: '20px' }}>
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            {gstCalValue > 0 ?
                                                <small>Include GST at {this.state.selectedTaxRate}% on {parseFloat(gstCalValue).toFixed(2)}</small> :
                                                <div>Tax {this.state.selectedTaxRate} %</div>
                                            }
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="tax"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.tax).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value">
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            Total due
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="total"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.total).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-btns" style={{ marginRight: '15px' }}>
                            <ButtonGroup
                                primaryBtnClick={() => handleSaveAddNew(this.state)}
                                primaryBtnDisabled={!isUserAccess || isInvoiceListLoading || this.state.status === "40"}
                                primaryBtnIcon='save'
                                primaryBtnText='Save'
                                primaryBtnVariant="outline-primary"
                                secondaryBtnClick={() => handleSendEmail(this.state)}
                                secondaryBtnIcon='email'
                                secondaryBtnText='Save and send'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnDisabled={this.state.email === '' || !isUserAccess || isInvoiceListLoading || this.state.status === "40"}
                            />
                            <ButtonGroup
                                secondaryBtnClick={handleClose}
                                secondaryBtnText='Go Back'
                                secondaryBtnIcon='undo'
                                secondaryBtnVariant="outline-primary"
                                primaryBtnClick={() => handlePrint(this.state)}
                                primaryBtnIcon='print'
                                primaryBtnText='Print or preview'
                                primaryBtnVariant="outline-primary"
                                primaryBtnDisabled={!isUserAccess || isInvoiceListLoading || this.state.status === "40"}
                            />
                        </div>
                        {
                            isPrintModalOpen && invoicePrint !== '' &&
                            <PdfViewer
                                show={isPrintModalOpen}
                                pdf={invoicePrint}
                                onHide={printModalClose}
                            />
                        }
                        {
                            isEmailEditorOpen &&
                            <EmailViewer
                                show={isEmailEditorOpen}
                                onHide={handleEmailViewerClose}
                                title="Invoice"
                                editorState={invoiceEmailDetails && invoiceEmailDetails.email && invoiceEmailDetails.email.replace('margin: 2% 20%', 'margin: 1% 5%').replace('h2', 'h3')}
                                // emailView = {invoiceEmailDetails && invoiceEmailDetails.emailTemplate &&
                                // invoiceEmailDetails.emailTemplate.replace('margin: 2% 20%', 'margin: 1% 5%').replace('[email_content]', invoiceEmailDetails.emailContent).replace('h2','h3')}
                                onEditorStateChange={onEditorChange}
                                handleSendEmail={handleSendInvoiceEmail}
                                invoiceEmailDetails={invoiceEmailDetails}
                                handleChangeEmailDetails={handleChangeEmailDetails}
                            />
                        }
                    </div>}
            </div>
        )
    }
}

AddNewInvoice.propTypes = {
    handleClose: PropTypes.any,
    customerList: PropTypes.any,
    selectedInvoice: PropTypes.any,
    // modelList: PropTypes.any,
    handleSaveAddNew: PropTypes.any,
    objectStatusList: PropTypes.any,
    productList: PropTypes.any,
    uomList: PropTypes.any,
    isPrintModalOpen: PropTypes.any,
    isUpdate: PropTypes.bool,
    invoicePrint: PropTypes.any,
    printModalClose: PropTypes.any,
    selectedJobCard: PropTypes.any,
    handleSendEmail: PropTypes.any,
    paymentTermList: PropTypes.any,
    selectedCustomer: PropTypes.any,
    isNewInvoice: PropTypes.any,
}


const mapStateToProps = createStructuredSelector({
    uomList: makeSelectUOMList(),
    invoiceTimesheetDetails: makeSelectInvoiceTimesheetDetailList(),
    employeeList: makeSelectEmployeeList(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(AddNewInvoice));