import { fromJS } from 'immutable';
import {
  LOAD_REVENUE_FORCASTING_ERROR,
  LOAD_REVENUE_FORCASTING_LOADING,
  LOAD_REVENUE_FORCASTING_SUCCESS,
  LOAD_REPORT_CSV_SUCCESS,
  LOAD_REPORT_ERROR,
  LOAD_REPORT_LOADING,
  LOAD_REPORT_PERIOD_SUCCESS,
  LOAD_REPORT_PRINT_SUCCESS,
  LOAD_REPORT_SUCCESS,
} from '../constant/reportConstant';

export const initialState = fromJS({
  revenueForcasting: [],
  isRevenueForcastingLoading: false,
  revenueForcastingError: null,
  reportList:[],
  isReportListLoading:false,
  reportListError:'',
  reportSuccess:'',
  reportPeriodList:[],
  reportPdf:'',
  reportCSV:''
});

function reportReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_REVENUE_FORCASTING_SUCCESS:
      return state.set('revenueForcasting', action.payload)
    case LOAD_REVENUE_FORCASTING_LOADING:
      return state.set('isRevenueForcastingLoading', action.payload)
    case LOAD_REVENUE_FORCASTING_ERROR:
      return state.set('revenueForcastingError', action.payload)
    case LOAD_REPORT_SUCCESS:
      return state.set('reportList', action.payload)
    case LOAD_REPORT_LOADING:
      return state.set('isReportListLoading', action.payload)
    case LOAD_REPORT_ERROR:
      return state.set('reportListError', action.payload)
    case LOAD_REPORT_PERIOD_SUCCESS:
      return state.set('reportPeriodList', action.payload)
    case LOAD_REPORT_PRINT_SUCCESS:
      return state.set('reportPdf', action.payload)
    case LOAD_REPORT_CSV_SUCCESS:
      return state.set('reportCSV', action.payload)
    default:
      return state;
  }
}

export default reportReducer;
