import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { makeSelectPublicCalendar } from '../../../../store/selector/selector';
import Table from '../../../layout/form/Table';
import moment from 'moment';
import common from '../../../common';
import DatePicker from '../../../layout/form/datePicker';
import { loadPublicCalendarRequest, loadPublicCalendarSuccess } from '../../../../store/action/action';
import Loader from '../../../layout/form/Loader';
import { makeSelectTenantLoading } from '../../../../store/selector/tenantSelector';
import Button from '../../../layout/form/Button';

const dateFormat = sessionStorage.getItem("dateFormat")
const today = new Date()
var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
var endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

class PublicHoliday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleId: 0,
            vehicleRegNo: '',
            customer: 0,
            fromDate: moment(startDate).format(common.dateFormat),
            toDate: moment(endDate).format(common.dateFormat)
        }
    }

    handleDateChange = (date, id) => {
        if (date === null && id === "fromDate") {
            const setDate = moment(startDate).format(common.dateFormat);
            this.setState({
                [id]: setDate
            })
        } else if (date === null && id === "toDate") {
            const setDate = moment(endDate).format(common.dateFormat);
            this.setState({
                [id]: setDate
            })
        }
        else {
            const setDate = moment(date).format(common.dateFormat);
            this.setState({
                [id]: setDate
            })
        }
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    getPublicCalendar = () => {
        this.props.dispatch(loadPublicCalendarSuccess([]))
        const parameters = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        this.props.dispatch(loadPublicCalendarRequest(parameters))
    }

    componentDidMount() {
        this.props.dispatch(loadPublicCalendarSuccess([]))
        this.getPublicCalendar()
    }

    render() {
        const columns = [{
            Header: 'Date',
            accessor: 'date',
            width: "20%",
            Cell: ((row) => (
                <div>
                    {row.row.original.date === '1/1/0001' ? '' : this.setDateFormat(row.row.original.date)}
                </div>
            )),
        }, {
            Header: 'Description',
            accessor: 'description',
            Cell: ((row) => (
                <div>
                    {row.row.original.description}
                </div>
            ))
        }]
        const { publicCalendar, handleTab, handleCalendarTab, isTenantDetailsLoading } = this.props
        return (
            <div>
                {(isTenantDetailsLoading) ?
                    <Loader show={isTenantDetailsLoading} /> : ''}
                <div className="content-header">
                    <div className="header-pages">
                        <span onClick={() => handleTab('basicData', this.state)}><Link><ui5-icon class="samples-margin" name="home" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Basic Data</Link></span>
                        <span onClick={() => handleTab('finance', this.state)}><ui5-icon class="samples-margin" name="customer-financial-fact-sheet" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Finance</span>
                        {/* <span onClick={() => handleTab('correspondence', this.state)}><ui5-icon class="samples-margin" name="letter" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Correspondence</span> */}
                        <span onClick={() => handleTab('openingHours', this.state)}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Calendar</span>
                        {/* <span onClick={() => handleTab('marketing', this.state)}><ui5-icon class="samples-margin" name="official-service" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Marketing</span> */}
                        {/* <span onClick={() => handleTab('generateQR', this.state)}><ui5-icon class="samples-margin" name="bar-code" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> QR</span> */}
                        {/* <span onClick={() => handleTab('compliance', this.state)}><ui5-icon class="samples-margin" name="attachment-zip-file" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Compliance</span> */}
                    </div>
                </div>
                <div className="page_header-title"></div>
                <div className="profile-content">
                    <div className="content-header">
                        <div className="header-pages">
                            <span onClick={() => handleCalendarTab('publicHoliday')}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Public holidays</span>
                            <span onClick={() => handleCalendarTab('tenantHoliday')}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Company holidays</span>
                            <span onClick={() => handleCalendarTab('openingHours')}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Opening Hours</span>
                        </div>
                    </div>
                </div>
                <div className="body-content">
                    <div className="col-sm ">
                        <div className="row">
                            <div className="col-sm">
                                <DatePicker
                                    text="From Date"
                                    controlId="fromDate"
                                    onChange={(e) => this.handleDateChange(e, "fromDate")}
                                    value={new Date(this.state.fromDate)}
                                // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="To Date"
                                    controlId="toDate"
                                    onChange={(e) => this.handleDateChange(e, "toDate")}
                                    value={new Date(this.state.toDate)}
                                // format={dateFormat}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="btnSave">
                        <Button
                            variant="outline-primary"
                            text='Search'
                            onClick={() => this.getPublicCalendar()}
                            icon="search"
                        />
                    </div>
                </div>
                <div className="table-content">
                    <div>
                        <h6 style={{ marginLeft: '5px' }}>Public holidays</h6>
                    </div>
                    <Table
                        columns={columns}
                        data={publicCalendar && publicCalendar.length ? publicCalendar.filter(e => e.description) : []}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    publicCalendar: makeSelectPublicCalendar(),
    isTenantDetailsLoading: makeSelectTenantLoading(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(PublicHoliday));