const PATH = 'app/Vehicle';

export const LOAD_VEHICLE_LIST_REQUEST = `${PATH}LOAD_VEHICLE_LIST_REQUEST`;
export const LOAD_VEHICLE_LIST_SUCCESS = `${PATH}LOAD_VEHICLE_LIST_SUCCESS`;
export const SEND_ADD_NEW_VEHICLE_REQUEST = `${PATH}SEND_ADD_NEW_VEHICLE_REQUEST`;
export const SEND_UPDATE_VEHICLE_REQUEST = `${PATH}SEND_UPDATE_VEHICLE_REQUEST`;
export const LOAD_SELECTED_VEHICLE_REQUEST = `${PATH}LOAD_SELECTED_VEHICLE_REQUEST`;
export const LOAD_SELECTED_VEHICLE_SUCCESS = `${PATH}LOAD_SELECTED_VEHICLE_SUCCESS`;
export const LOAD_MAKE_LIST_REQUEST = `${PATH}LOAD_MAKE_LIST_REQUEST`;
export const LOAD_MAKE_LIST_SUCCESS = `${PATH}LOAD_MAKE_LIST_SUCCESS`;
export const LOAD_MODEL_LIST_REQUEST = `${PATH}LOAD_MODEL_LIST_REQUEST`;
export const LOAD_MODEL_LIST_SUCCESS = `${PATH}LOAD_MODEL_LIST_SUCCESS`;