const PATH = 'app/Users';

export const SAVE_IS_USERS_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_USERS_MODAL_OPEN_STATUS`;
export const SEND_ADD_NEW_USER_REQUEST = `${PATH}SEND_ADD_NEW_USER_REQUEST`;
export const SEND_ADD_NEW_USER_ERROR = `${PATH}SEND_ADD_NEW_USER_ERROR`;
export const SEND_ADD_NEW_USER_LOADING = `${PATH}SEND_ADD_NEW_USER_LOADING`;
export const SEND_ADD_NEW_USER_SUCCESS = `${PATH}SEND_ADD_NEW_USER_SUCCESS`;
export const LOAD_USER_LIST_REQUEST = `${PATH}LOAD_USER_LIST_REQUEST`;
export const LOAD_USER_LIST_SUCCESS = `${PATH}LOAD_USER_LIST_SUCCESS`;
export const SEND_USER_STATUS_UPDATE_REQUEST = `${PATH}SEND_USER_STATUS_UPDATE_REQUEST`;
export const SEND_USER_UPDATE_REQUEST = `${PATH}SEND_USER_UPDATE_REQUEST`;
export const LOAD_SELECTED_USER_REQUEST = `${PATH}LOAD_SELECTED_USER_REQUEST`;
export const LOAD_SELECTED_USER_SUCCESS = `${PATH}LOAD_SELECTED_USER_SUCCESS`;
export const LOAD_USER_ACCESS_REQUEST = `${PATH}LOAD_USER_ACCESS_REQUEST`;
export const LOAD_USER_ACCESS_SUCCESS = `${PATH}LOAD_USER_ACCESS_SUCCESS`;
export const LOAD_USER_ROLE_LIST_REQUEST = `${PATH}LOAD_USER_ROLE_LIST_REQUEST`;
export const LOAD_USER_ROLE_LIST_SUCCESS = `${PATH}LOAD_USER_ROLE_LIST_SUCCESS`;