import React from 'react';
import PropTypes from 'prop-types';
import './NavBar.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Button from '../form/Button';
import Image from 'react-bootstrap/Image';
import User from '../../../images/user.png';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

const UserMenu = ({
    handleSignOut,
    userMenuClose,
    tenantImage,
    tenantName
}) =>{

  return (
    <div className="user_menu">
        <div style={{display:'flex', justifyContent:'flex-end'}} onClick={userMenuClose} >
            <ui5-icon class="samples-margin" name="decline" id="decline" ></ui5-icon>
        </div>
        <div style={{display:'flex', justifyContent:'center'}} >
            {tenantImage ? 
                <Image className="profileImageView" src={tenantImage ? tenantImage: User} roundedCircle /> : 
                <Avatar name={tenantName} size={120} round="50%" maxInitials={2}/>
            }
        </div>
        <div style={{display:'flex', justifyContent:'center',margin:'10px'}} >{tenantName}</div>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }} >
                <li className='setting_text'>
                    <Link to='./myAcccount'>My account</Link>
                </li>
            </div>
        <div style={{display:'flex', justifyContent:'center'}}>
            <Button 
                variant="primary" 
                text ='Sign Out'
                type="submit"
                onClick={handleSignOut}
            />
        </div>
    </div>
  )
}

UserMenu.propTypes = {
    handleSignOut: PropTypes.func,
    userMenuClose: PropTypes.func,
    tenantImage: PropTypes.any,
    tenantName: PropTypes.any,
}

export default UserMenu;