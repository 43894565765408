import React, { Component } from 'react';
import '../settingMenu.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/a2aMappingSelector';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Loader from '../../../layout/form/Loader';
import SelectField from '../../../layout/form/SelectField';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import {
  loadAccountCustomerRequest,
  loadMappingCustomerRequest,
  sendMappingCustomerRequest,
  saveIsAccountCustomerStatus,
  loadA2ACustomerError,
  sendMappingCustomerSuccess,
} from '../../../../store/action/a2aMappingAction';
import { makeSelectCustomerList } from '../../../../store/selector/customerSelector';
import Pagination from '../../../layout/form/Pagination';
import common from '../../../common';

const customStyles = {
  control: base => ({
    ...base,
    height: 20,
    minHeight: 32,
    marginBottom: 0,
    marginTop: -20
  })
};

const tenantId = sessionStorage.getItem("tenantId")
class XeroCustomerMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contactList: [],
      initial: true,
      pageNumber: 1,
      pageSize: 20
    }
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    const {
      isAccountCustomerList,
      mappingCustomerList
    } = prevProps;
    if (
      prevState.initial && isAccountCustomerList) {
      var contactList = []
      var newMappingContactList = []
      if (mappingCustomerList && mappingCustomerList.result && mappingCustomerList.result.length && mappingCustomerList.result.length > 0) {
        for (let q = 0; q < mappingCustomerList.result.length; q++) {
          const customerItem_ = mappingCustomerList.result[q]
          const item = {
            tenantId: customerItem_.tenantId,
            intlCustomerId: customerItem_.intlCustomerId,
            accSystemId: customerItem_.accSystemId,
            displayName: customerItem_.displayName,
            isChange: false,
            extlCustomerId: customerItem_.extlCustomerId,
          }
          newMappingContactList.push(item)
        }
      }

      return {
        initial: false,
        contactList: newMappingContactList.concat(contactList)
      }
    }
    return null
  }

  componentDidMount() {
    this.getCustomerList()
  }

  getCustomerList = () => {
    this.props.dispatch(loadAccountCustomerRequest())
    this.getMappingCustomerListData()
  }

  getMappingCustomerListData = () => {
    const mappingCustomerParms = {
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    }
    this.props.dispatch(loadMappingCustomerRequest(mappingCustomerParms))
  }

  handleCustomer = (e, rowData) => {
    const { contactList } = this.state;
    var newRow = {
      tenantId: rowData.original.tenantId,
      accSystemId: rowData.original.accSystemId,
      isChange: true,
      intlCustomerId: rowData.original.intlCustomerId,
      displayName: rowData.original.displayName,
      extlCustomerId: e
    }
    contactList[rowData.index] = newRow
    this.setState({
      contactList
    })
  }

  handleSaveCustomer = () => {
    const { contactList } = this.state;
    const Customers = contactList.filter(e => e.isChange === true)
    const parms = {
      tenantId: parseInt(tenantId),
      Customers: Customers,
      AccSystemId: common.accounting.xero
    }
    if (Customers && Customers.length > 0) {
      this.props.dispatch(sendMappingCustomerRequest(parms))
    }
  }

  handleClose = () => {
    this.setState({
      initial: true
    })
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.getCustomerList()
  }

  errorModalClose = () => {
    this.props.dispatch(loadA2ACustomerError(null))
  }

  successModalClose = () => {
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.props.dispatch(sendMappingCustomerSuccess(null))
    this.getCustomerList()
  }

  getNextPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
  }

  getPrevPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
  }

  setClickedPage = (page) => {
    this.setState({
      pageNumber: page
    })
  }

  setPageSize = (size) => {
    this.setState({
      pageSize: size,
      pageNumber: 1
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.mappingCustomerSuccess !== null) {
      setTimeout(
        () => this.successModalClose(),
        1000
      );
    }

    if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
      this.setState({
        initial: true
      })
      this.handleSaveCustomer()
      this.getMappingCustomerListData()
      this.props.dispatch(saveIsAccountCustomerStatus(false))
    }
  }

  render() {
    const {
      accountCustomerList,
      mappingCustomerList,
      a2aCustomerLoading,
      a2aCustomerError,
      mappingCustomerSuccess
    } = this.props;
    const {
      contactList,
    } = this.state;
    const columnsCustomer = [{
      Header: 'Customer ID',
      accessor: 'intlCustomerId',
      width: '15%',
    }, {
      Header: 'Customer Name',
      accessor: 'displayName',
    }, {
      Header: 'Acc.System Customer Name',
      accessor: 'extlCustomerId',
      Cell: (rowData) => (
        <div>
          <SelectField
            controlId="extlCustomerId"
            onChange={(e) => this.handleCustomer(e, rowData.cell.row)}
            options={accountCustomerList}
            style={customStyles}
            selectedValue={rowData.cell.row.original.extlCustomerId}
            display="name"
            id="id"
          />
        </div>
      ),
    }]

    return (
      <div>
        {a2aCustomerError !== '' &&
          <ErrorModal
            show={true}
            massage={a2aCustomerError}
            handleClose={this.errorModalClose}
          />}

        {(mappingCustomerSuccess) &&
          <SuccessModal
            show={true}
            massage={mappingCustomerSuccess}
            handleClose={this.successModalClose}
          />}
        {a2aCustomerLoading ?
          <Loader /> : ''}
        <div className="body-content">
          <div className="table-content">
            <Table
              columns={columnsCustomer}
              data={contactList}
            // data={accountProductCategoryList && accountProductCategoryList.length ? accountProductCategoryList : []}                   
            />
          </div>
          <Pagination
            currentPage={mappingCustomerList.page ? mappingCustomerList.page : this.state.pageNumber}
            getNextPage={this.getNextPage}
            getPrevPage={this.getPrevPage}
            totalPage={mappingCustomerList.totalPages}
            setClickedPage={this.setClickedPage}
            currentPageSize={this.state.pageSize}
            setPageSize={this.setPageSize}
          />
          <div className="filter-btns">
            <ButtonGroup
              primaryBtnClick={this.handleClose}
              // primaryBtnIcon='decline'
              primaryBtnText='Go Back'
              primaryBtnIcon='undo'
              primaryBtnVariant="outline-primary"
              secondaryBtnClick={this.handleSaveCustomer}
              secondaryBtnIcon='save'
              secondaryBtnText='Save'
              secondaryBtnVariant="outline-primary"
            />
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = createStructuredSelector({
  customerList: makeSelectCustomerList(),
  isAccountCustomerList: selectors.makeSelectIsLoadAccountCustomer(),
  mappingCustomerList: selectors.makeSelectMappingCustomer(),
  accountCustomerList: selectors.makeSelectAccountCustomer(),
  a2aCustomerLoading: selectors.makeSelectA2ACustomerLoading(),
  a2aCustomerError: selectors.makeSelectA2ACustomerError(),
  mappingCustomerSuccess: selectors.makeSelectSendMappingCustomerSuccess()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(XeroCustomerMapping));