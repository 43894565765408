import { 
    LOAD_EMPLOYEE_LIST_ERROR,
    LOAD_EMPLOYEE_LIST_LOADING,
    LOAD_EMPLOYEE_LIST_REQUEST,
    LOAD_EMPLOYEE_LIST_SUCCESS,
    LOAD_SELECTED_EMPLOYEE_SUCCESS,
    SAVE_IS_EMPLOYEE_MODAL_OPEN_STATUS,
    SEND_ADD_NEW_EMPLOYEE_REQUEST,
    SEND_EMPLOYEE_SUCCESS,
    SEND_TERMINATED_EMPLOYEE_REQUEST,
    SEND_UPDATE_EMPLOYEE_REQUEST,
    LOAD_SELECTED_EMPLOYEE_REQUEST
    } from '../constant/employeeConstant';
    
    export function saveIsEmployeeModalOpenStatus(open){
      return {
        type: SAVE_IS_EMPLOYEE_MODAL_OPEN_STATUS,
        open,
      }
    };
  
    export function loadEmployeeListRequest(data) {
      return {
        type: LOAD_EMPLOYEE_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadEmployeeListLoading(data) {
      return {
        type: LOAD_EMPLOYEE_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadEmployeeListSuccess(data) {
      return {
        type: LOAD_EMPLOYEE_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadEmployeeListError(data) {
      return {
        type: LOAD_EMPLOYEE_LIST_ERROR,
        payload: data,
      }
    };
  
    export function sendAddNewEmployeeRequest(data) {
      return {
        type: SEND_ADD_NEW_EMPLOYEE_REQUEST,
        payload: data,
      }
    };
    
    export function sendEmployeeSuccess(data) {
      return {
        type: SEND_EMPLOYEE_SUCCESS,
        payload: data,
      }
    };
    
    export function sendUpdateEmployeeRequest(data) {
      return {
        type: SEND_UPDATE_EMPLOYEE_REQUEST,
        payload: data,
      }
    };
    
    export function sendTerminatedEmployeeRequest(data) {
      return {
        type: SEND_TERMINATED_EMPLOYEE_REQUEST,
        payload: data,
      }
    };

    export function loadSelectedEmployeeSuccess(data) {
      return {
        type: LOAD_SELECTED_EMPLOYEE_SUCCESS,
        payload: data,
      }
    };
  
    export function loadSelectedEmployeeRequest(data) {
      return {
        type:LOAD_SELECTED_EMPLOYEE_REQUEST,
        payload: data,
      }
    };
    