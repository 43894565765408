import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
// import './form.css'
import ButtonGroup from '../../../layout/form/ButtonGroup';
import DatePicker from '../../../layout/form/datePicker';
import Form from 'react-bootstrap/cjs/Form';

const InformationModal = ({
    show,
    handleInfoModalClose,
    handleDateChange,
    activeFromDate,
    handleSubmit,
    handleCheck,
    isChecked
}) => (
      <BootstrapModal 
        show={show} 
        onHide={handleInfoModalClose}
        animation={false}
        size="lg"
        // dialogClassName="modal_width"
        centered
        >
        <BootstrapModal.Header closeButton>
            <BootstrapModal.Title style={{fontSize:'1.1rem'}}>
                Accounting System Integration
            </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body style={{color:'#000000'}}> 
            <div>Please note that the effective date is a very important date for deciding which invoices need to be integrated with the accounting system. Any invoices created on or after effective date, will be pushed into accounting system. Please make sure that the effective date is correct. If you are not sure, please check with your accountant before you activate the integration.</div>
            <div style={{margin:'10px 0'}}>
                <Form.Group controlId="addressCheckBox">
                    <Form.Check type="checkbox" style={{fontSize:"14px"}} label="I have read the instructions and understood  the significance of the effective date." onChange={handleCheck}/>
                </Form.Group>
            </div>
            <div>
                <DatePicker
                    text="Active From Date"
                    controlId="activeFromDate"
                    onChange={(e) => handleDateChange(e)}
                    value={activeFromDate === '' ? '' : new Date(activeFromDate) }
                />
            </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <ButtonGroup
                primaryBtnClick={handleInfoModalClose}
                primaryBtnVariant="outline-primary"
                primaryBtnText='Cancel'
                secondaryBtnClick={handleSubmit}
                secondaryBtnText='Active'                               
                secondaryBtnVariant="outline-primary"
                secondaryBtnDisabled={isChecked === false}  
            />
        </BootstrapModal.Footer>
      </BootstrapModal>
);

InformationModal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleInfo: PropTypes.any,
    handleInfoModalClose:PropTypes.any,
}

export default InformationModal;