import {
  LOAD_REVENUE_FORCASTING_ERROR,
  LOAD_REVENUE_FORCASTING_LOADING,
  LOAD_REVENUE_FORCASTING_REQUEST,
  LOAD_REVENUE_FORCASTING_SUCCESS,
  LOAD_REPORT_CSV_REQUEST,
  LOAD_REPORT_CSV_SUCCESS,
  LOAD_REPORT_ERROR,
  LOAD_REPORT_LOADING,
  LOAD_REPORT_PERIOD_REQUEST,
  LOAD_REPORT_PERIOD_SUCCESS,
  LOAD_REPORT_PRINT_REQUEST,
  LOAD_REPORT_PRINT_SUCCESS,
  LOAD_REPORT_REQUEST,
  LOAD_REPORT_SUCCESS,
} from '../constant/reportConstant';


export function loadRevenueForcastingRequest(data) {
  return {
    type: LOAD_REVENUE_FORCASTING_REQUEST,
    payload: data,
  }
};

export function loadRevenueForcastingLoading(data) {
  return {
    type: LOAD_REVENUE_FORCASTING_LOADING,
    payload: data,
  }
};

export function loadRevenueForcastingSuccess(data) {
  return {
    type: LOAD_REVENUE_FORCASTING_SUCCESS,
    payload: data,
  }
};

export function loadRevenueForcastingError(data) {
  return {
    type: LOAD_REVENUE_FORCASTING_ERROR,
    payload: data,
  }
};

export function loadReportRequest(data) {
  return {
    type: LOAD_REPORT_REQUEST,
    payload: data,
  }
};

export function loadReportLoading(data) {
  return {
    type: LOAD_REPORT_LOADING,
    payload: data,
  }
};

export function loadReportSuccess(data) {
  return {
    type: LOAD_REPORT_SUCCESS,
    payload: data,
  }
};

export function loadReportError(data) {
  return {
    type: LOAD_REPORT_ERROR,
    payload: data,
  }
};

export function loadReportPeriodRequest() {
  return {
    type: LOAD_REPORT_PERIOD_REQUEST,
  }
};

export function loadReportPeriodSuccess(data) {
  return {
    type: LOAD_REPORT_PERIOD_SUCCESS,
    payload: data,
  }
};

export function loadReportPrintRequest(data) {
  return {
    type: LOAD_REPORT_PRINT_REQUEST,
    payload: data,
  }
};

export function loadReportPrintSuccess(data) {
  return {
    type: LOAD_REPORT_PRINT_SUCCESS,
    payload: data,
  }
};

export function loadReportCSVRequest(data) {
  return {
    type: LOAD_REPORT_CSV_REQUEST,
    payload: data,
  }
};

export function loadReportCSVSuccess(data) {
  return {
    type: LOAD_REPORT_CSV_SUCCESS,
    payload: data,
  }
};
