import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../store/selector/navBarSelector';
import PropTypes from 'prop-types';
import Dashboard from '../pages/Dashboard';
import { makeSelectLoginUser } from '../../store/selector/authSelector';
import * as actions from '../../store/action/action';
import Cookies from 'js-cookie';
import { saveLoginUser } from '../../store/action/authAction';
import { loadTenantBasicRequest, loadTenantDefaultRequest, loadTenantOpenHoursRequest } from '../../store/action/tenantAction';
import NavBar from '../layout/navBar/NavBar';
import Footer from '../layout/footer/Footer';
import Project from './project/Project';
import Users from '../pages/settingMenu/users/Users';
import Profile from '../pages/settingMenu/profile/Profile';
import TimeSheet from './timesheet/TimeSheet';
import Employee from './employee/Employee';
import { loadUserAccessRequest } from '../../store/action/usersAction';
import Accounting from './settingMenu/accounting/Accounting';
import Myob from './settingMenu/accounting/Myob';
import { makeSelectUserAccessSuccess } from '../../store/selector/usersSelector';
import MyAccount from './settingMenu/users/MyAccount';
import UserRoles from './settingMenu/userRoles/UserRoles';
import Customer from './sales/customer/Customer';
import RevenueForcasting from './report/RevenueForcasting';
import Xero from './settingMenu/accounting/Xero';
import Invoice from './sales/invoice/Invoice';
import SalesReport from './report/SalesReport';
import AppInvoice from './app/appInvoice/Invoice';
import AppService from './app/appService/AppService';
import TenantPaymentMethod from './app/paymentMethod/TenantPaymentMethod';
import EmailConfiguration from './settingMenu/emailConfiguration/EmailConfiguration';
// import WarningModal from '../auth/warningModal';
// import InformationModal from '../auth/inforModal';

class Pages extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {

  //   }}

  handleSignOut = () => {
    Cookies.remove("user")
    this.props.dispatch(saveLoginUser(false))
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
  }

  componentDidMount() {
    const user = Cookies.get("user")
    if (this.props.isLoginUser || user) {
      const id = sessionStorage.getItem("tenantId")
      this.props.dispatch(loadTenantOpenHoursRequest(id))
      const userId = sessionStorage.getItem("tenantUserId")
      this.props.dispatch(loadTenantDefaultRequest(id))
      this.props.dispatch(loadTenantBasicRequest(id))
      this.props.dispatch(actions.loadTenantUserRequest(userId))
      this.props.dispatch(loadUserAccessRequest())
      this.props.dispatch(actions.loadCountryRequest());
      this.props.dispatch(actions.loadUOMRequest());
      this.props.dispatch(actions.loadAppBillingTermsRequest())
      this.props.dispatch(actions.loadUOMConversionFactorRequest())
    }
  }

  SecuredRoute = (props) => {
    const user = Cookies.get("user")
    return (
      <Route path={props.path} render={data => this.props.isLoginUser || user ? (
        <props.component {...data}></props.component>) :
        (<Redirect to={{ pathname: '/' }}></Redirect>)}></Route>
    )
  }

  UserAccessRoute = (props) => {
    const { userAccess } = this.props;
    const user = Cookies.get("user")
    return (
      <Route path={props.path} render={data => (this.props.isLoginUser || user) && (userAccess && userAccess.displayUser) ? (
        <props.component {...data}></props.component>) :
        (<Redirect to={{ pathname: '/' }}></Redirect>)}></Route>
    )
  }

  render() {
    const {
      isSideBarStatus,
      userAccess,
      // tenantUserExpireStatus,
      // billingTermsList,
      // tenantReactivateStatus
    } = this.props;
    return (
      <div>
        <NavBar />
        {/* {
          tenantUserExpireStatus ?
            <WarningModal
              show={tenantUserExpireStatus}
              handleWarningModalClose={this.handleWarningModalClose}
              handleWarning={this.handleWarning.bind(this)}
              billingTermsList={billingTermsList}
              handleSelect={this.handleSelect}
              billingTerms={this.state.billingTerms}
            />
            : ""
        }
        {
          tenantReactivateStatus && tenantReactivateStatus.activeStatus ?
            <InformationModal
              show={tenantReactivateStatus.activeStatus}
              handleInfoModalClose={this.handleInfoModalClose}
              tenantReactivateStatus={tenantReactivateStatus}
              billingTermsList={billingTermsList}
            />
            : ""
        } */}
        <div className={isSideBarStatus ? "content-full-sidebar" : "content-icon-sidebar"}>
          <this.SecuredRoute path='/dashboard' component={Dashboard} />
          <this.UserAccessRoute path='/users' component={Users} />
          {userAccess && userAccess.displayProfile && <this.SecuredRoute path='/profile' component={Profile} />}
          {userAccess && userAccess.displayProject && <this.SecuredRoute path='/project' component={Project} />}
          {userAccess && userAccess.displayTimeSheet && <this.SecuredRoute path='/timesheet' component={TimeSheet} />}
          {userAccess && userAccess.displayEmployee && <this.SecuredRoute path='/employee' component={Employee} />}
          {userAccess && userAccess.displayAccounting && <this.SecuredRoute path='/accounting' component={Accounting} />}
          {userAccess && userAccess.displayAccounting && <this.SecuredRoute path='/myob' component={Myob} />}
          {userAccess && userAccess.displayUser && <this.SecuredRoute path='/myAcccount' component={MyAccount} />}
          {userAccess && userAccess.displayUserRoles && <this.SecuredRoute path='/userRoles' component={UserRoles} />}
          {userAccess && userAccess.displayCustomer && <this.SecuredRoute path='/customer' component={Customer} />}
          {userAccess && userAccess.displayReport && <this.SecuredRoute path='/revenueForcasting' component={RevenueForcasting} />}
          {userAccess && userAccess.displayAccounting && <this.SecuredRoute path='/xero' component={Xero} />}
          {userAccess && userAccess.displayInvoice && <this.SecuredRoute path='/invoice' component={Invoice} />}
          {userAccess && userAccess.displaySalesReport && <this.SecuredRoute path='/salesReports' component={SalesReport} />}
          {userAccess && userAccess.displayAppService && <this.SecuredRoute path='/appBilling' component={AppService} />}
          {userAccess && userAccess.displayAppInvoice && <this.SecuredRoute path='/appInvoice' component={AppInvoice} />}
          {userAccess && userAccess.displayTenantPaymentMethod && <this.SecuredRoute path='/appPaymentMethod' component={TenantPaymentMethod} />}
          {userAccess && userAccess.displayEmailConfiguration && <this.SecuredRoute path='/emailConfiguration' component= { EmailConfiguration } />}
        </div>
        <Footer />
      </div>
    )
  }
}

Pages.propTypes = {
  isSideBarStatus: PropTypes.bool,
  isLoginUser: PropTypes.bool
}

const mapStateToProps = createStructuredSelector({
  isLoginUser: makeSelectLoginUser(),
  isSideBarStatus: selectors.makeSelectSideBarStatus(),
  userAccess: makeSelectUserAccessSuccess(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(Pages));
