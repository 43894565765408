import {
  LOAD_OBJECT_LIST_REQUEST,
  LOAD_OBJECT_LIST_SUCCESS,
  LOAD_OBJECT_OPERATION_LIST_REQUEST,
  LOAD_OBJECT_OPERATION_LIST_SUCCESS,
  LOAD_USER_ROLE_LIST_ERROR,
  LOAD_USER_ROLE_LIST_LOADING,
  LOAD_USER_ROLE_LIST_REQUEST,
  LOAD_USER_ROLE_LIST_SUCCESS,
  LOAD_USER_ROLE_OBJECT_LIST_REQUEST,
  LOAD_USER_ROLE_OBJECT_LIST_SUCCESS,
  SAVE_IS_USER_ROLE_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_USER_ROLE_REQUEST,
  SEND_DELETE_USER_ROLE_REQUEST,
  SEND_UPDATE_USER_ROLE_REQUEST,
  SEND_USER_ROLE_SUCCESS
} from '../constant/userRolesConstant';

export function saveIsUserRoleModalOpenStatus(open) {
  return {
    type: SAVE_IS_USER_ROLE_MODAL_OPEN_STATUS,
    open,
  }
};

export function loadUserRoleLoading(data) {
  return {
    type: LOAD_USER_ROLE_LIST_LOADING,
    payload: data,
  }
}

export function loadUserRoleError(data) {
  return {
    type: LOAD_USER_ROLE_LIST_ERROR,
    payload: data,
  }
}

export function loadUserRoleListRequest(data) {
  return {
    type: LOAD_USER_ROLE_LIST_REQUEST,
    payload: data,
  }
}

export function loadUserRoleListSuccess(data) {
  return {
    type: LOAD_USER_ROLE_LIST_SUCCESS,
    payload: data,
  }
}

export function loadObjectListRequest(data) {
  return {
    type: LOAD_OBJECT_LIST_REQUEST,
    payload: data,
  }
}

export function loadObjectListSuccess(data) {
  return {
    type: LOAD_OBJECT_LIST_SUCCESS,
    payload: data,
  }
}

export function loadObjectOperationListRequest(data) {
  return {
    type: LOAD_OBJECT_OPERATION_LIST_REQUEST,
    payload: data,
  }
}

export function loadObjectOperationListSuccess(data) {
  return {
    type: LOAD_OBJECT_OPERATION_LIST_SUCCESS,
    payload: data,
  }
}

export function loadUserRoleObjectListRequest(data) {
  return {
    type: LOAD_USER_ROLE_OBJECT_LIST_REQUEST,
    payload: data,
  }
}

export function loadUserRoleObjectListSuccess(data) {
  return {
    type: LOAD_USER_ROLE_OBJECT_LIST_SUCCESS,
    payload: data,
  }
}

export function sendAddNewUserRoleRequest(data) {
  return {
    type: SEND_ADD_NEW_USER_ROLE_REQUEST,
    payload: data,
  }
}

export function sendUpdateUserRoleRequest(data) {
  return {
    type: SEND_UPDATE_USER_ROLE_REQUEST,
    payload: data,
  }
}

export function sendDeleteUserRoleRequest(data) {
  return {
    type: SEND_DELETE_USER_ROLE_REQUEST,
    payload: data,
  }
}

export function sendUserRoleSuccess(data) {
  return {
    type: SEND_USER_ROLE_SUCCESS,
    payload: data,
  }
}