import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css'
import Button from './Button';

const InformationModal = ({
    show,
    handleModalClose,
    handleSave,
    title,
    body
}) => (
    <BootstrapModal
        show={show}
        onHide={handleModalClose}
        animation={false}
        // dialogClassName="modal_width"
        centered
    >
        <BootstrapModal.Header>
            <BootstrapModal.Title style={{ fontSize: '1.1rem' }}>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body> {body} </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <Button
                variant="outline-primary"
                text='Close'
                type="submit"
                onClick={handleModalClose}
            />
        </BootstrapModal.Footer>
    </BootstrapModal>
);

InformationModal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleSave: PropTypes.any,
    handleModalClose: PropTypes.any,
    title: PropTypes.any,
}

export default InformationModal;