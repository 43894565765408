import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../layout/form/ButtonGroup';

const Confirm = ({
    show,
    handleModalClose,
    handleConfirm,
    title,
    content,
    width
}) => (
    <BootstrapModal
        show={show}
        onHide={handleModalClose}
        animation={false}
        dialogClassName={width ? "modal_width" : ''}
        centered
    >
        <BootstrapModal.Header>
            <BootstrapModal.Title style={{ fontSize: '1.1rem' }}>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body> {content} </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <ButtonGroup
                primaryBtnClick={handleModalClose}
                primaryBtnVariant="outline-primary"
                primaryBtnText='No'
                secondaryBtnClick={handleConfirm}
                secondaryBtnText='Yes'
                secondaryBtnVariant="outline-primary"
            />
        </BootstrapModal.Footer>
    </BootstrapModal>
);

export default Confirm;