import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/projectSelector';
import * as actions from '../../../../store/action/projectAction';
import SuccessModal from '../../../layout/form/successModal';
// import Logo from '../../../../images/user.png';
import { formatNumber } from '../../../common';
import AddApprover from './AddNewApprover';
import DeleteModal from '../../../layout/form/deleteModal';
import Avatar from 'react-avatar';
import { makeSelectUserListSuccess } from '../../../../store/selector/usersSelector';

export class ProjectApprover extends Component {

    constructor(props) {
        super(props);

        this.state = {
            approverList: [],
            initial: true,
            isDelete: false,
            isNewApprover: false,
            selectedApprover: null
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { selectedProject } = prevProps;
        if (selectedProject && selectedProject.id && prevState.initial) {
            var itemList = []

            if (selectedProject.projectApprovers.length !== 0) {
                for (let i = 0; i < selectedProject.projectApprovers.length; i++) {
                    const element = selectedProject.projectApprovers[i];

                    const newRow = element
                    element.operation = "U"
                    itemList.push(newRow)
                }
            }
            return {
                approverList: itemList,
                initial: false
            }
        }

        return null
    }

    handleData = (userId) => {
        const { userList } = this.props
        const item = userList.result.find(e => e.id === userId)
        return (
            <div>
                <Avatar name={item?.firstName} size={70} round="50%" maxInitials={2} />
                <h5>{item?.firstName}</h5>
                <div>Email : {item?.email}</div>
                <div>Phone : {formatNumber(item?.mobileNo)}</div>
            </div>
        )
    }

    addNewRow = (details) => {
        this.setState({
            isNewApprover: true,
            selectedApprover: details
        })
    }

    handleModalClose = () => {
        this.setState({
            isNewApprover: false,
            selectedApprover: null
        })
    }

    handleDelete = (item) => {
        this.setState({
            isDelete: true,
            selectedApprover: item
        })
    }

    handleDeleteModalClose = () => {
        this.setState({
            isDelete: false,
            selectedApprover: null
        })
    }

    successModalClose = () => {
        const { selectedProjectId } = this.props;
        this.setState({
            initial: true,
        })
        this.props.dispatch(actions.loadSelectedProjectRequest(selectedProjectId))
        this.props.dispatch(actions.sendProjectActivitySuccess(null))
        this.handleModalClose()
        this.handleDeleteModalClose()
    }


    handleList = (data) => {
        if (data.length === 0) {
            return (data)
        }

        for (let i = 0; i < data.length; i++) {
            var element = data[i];
            element.approvalSequence = i + 1
            data[i] = element

            if (i === data.length - 1) {
                return (data)
            }
        }
    }

    handleDeleteApprove = () => {
        const { selectedProject, project } = this.props;
        const { approverList, selectedApprover } = this.state;
        const projectApprovers = []
        const element = {
            userId: selectedApprover.userId,
            operation: "D",
            id: selectedApprover.id,
            approvalSequence: selectedApprover.sequence
        }
        projectApprovers.push(element);
        var tempApproverList = approverList.filter(e => e.id !== selectedApprover.id)
        tempApproverList = this.handleList(tempApproverList)
        const parameters = {
            id: selectedProject.id ? selectedProject.id : 0,
            projectName: project.projectName ? project.projectName : selectedProject.projectName,
            projectCode: project.projectCode ? project.projectCode : selectedProject.projectCode,
            planStartDate: project.planStartDate ? project.planStartDate : selectedProject.planStartDate,
            planEndDate: project.planEndDate ? project.planEndDate : selectedProject.planEndDate,
            actualStartDate: project.actualStartDate ? project.actualStartDate : selectedProject.actualStartDate,
            actualEndDate: project.actualEndDate ? project.actualEndDate : selectedProject.actualEndDate,
            projectManagerId: project.projectManagerId ? project.projectManagerId : selectedProject.projectManagerId,
            status: project.status ? project.status : selectedProject.status,
            notes: project.notes ? project.notes : selectedProject.notes,
            projectBillingTypeId: project.projectBillingTypeId ? project.projectBillingTypeId : selectedProject.projectBillingTypeId,
            projectTypeId: project.projectTypeId ? project.projectTypeId : selectedProject.projectTypeId,
            purchaseOrderNo: project.purchaseOrderNo ? project.purchaseOrderNo : selectedProject.purchaseOrderNo,
            customerId: project.customerId ? project.customerId : selectedProject.customerId,
            ProjectActivities: [],
            ProjectAssignments: [],
            ProjectMilestones: [],
            ProjectApprovers: tempApproverList.concat(projectApprovers)
        }
        this.props.dispatch(actions.sendUpdateProjectActivityRequest(parameters))
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.projectActivitySuccess !== null) {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            handleTab,
            modifyProject,
            projectActivitySuccess,
            userList
        } = this.props;

        const {
            approverList,
            isNewApprover,
            selectedApprover,
            isDelete
        } = this.state

        return (
            <div>
                {(projectActivitySuccess) &&
                    <SuccessModal
                        show={true}
                        massage={projectActivitySuccess}
                        handleClose={this.successModalClose}
                    />}

                {isDelete &&
                    <DeleteModal
                        show={isDelete}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteApprove}
                        title={selectedApprover?.userId}
                    />}

                <div className='content-body'>
                    <div className="filter-header">
                        <div className="header-pages">
                            <span>Project </span>
                        </div>
                        <div className="btn-close" onClick={handleClose} >
                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                        </div>
                    </div>
                    <div className="content-header" style={{ margin: '-20px 0 15px 0' }}>
                        <div className="header-pages">
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectDetails', this.state)}><ui5-icon class="samples-margin" name="header" id="title-icon"></ui5-icon> Header</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectMilestone', this.state)}><ui5-icon class="samples-margin" name="add-coursebook" id="title-icon"></ui5-icon> Milestone</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectActivities', this.state)}><ui5-icon class="samples-margin" name="multiselect-all" id="title-icon"></ui5-icon> Activities</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectAssignments', this.state)}><ui5-icon class="samples-margin" name="add-employee" id="title-icon"></ui5-icon> Assignments</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectApprovers', this.state)}><ui5-icon class="samples-margin" name="kpi-managing-my-area" id="title-icon"></ui5-icon> Approvers</span>
                        </div>
                    </div>
                    <div className="profile-content">
                        <div className="page_header">
                            <div className="page_header-title">
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                                    <div><span>Approvers</span></div>
                                </div>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <div>
                                    <div className="card-list">
                                        {approverList && approverList.map(item => (
                                            <div className="col-sm" style={{ textAlign: 'center', margin: '20px 0', padding: '10px', borderRadius: '10px' }}>
                                                <h4 style={{ color: '#4278b7' }}>{item.approvalSequence} - Approver </h4>
                                                <div>{this.handleData(item.userId)}</div>
                                                <div style={{ color: '#4278b7', cursor: 'pointer', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                                    <div style={{ marginRight: '20px' }} onClick={() => this.addNewRow(item)}>EDIT</div>
                                                    <div onClick={() => this.handleDelete(item)}>DELETE</div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-sm" style={{ textAlign: 'center', margin: '20px 0', padding: '10px', borderRadius: '10px' }}>
                                            <div className={modifyProject ? "add-approver" : "add-approver-disabled"}>
                                                <ui5-icon class="samples-margin" name="sys-add" id="add-approver" onClick={modifyProject ? () => this.addNewRow(null) : ''}></ui5-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    isNewApprover &&
                    <AddApprover
                        show={isNewApprover}
                        handleModalClose={this.handleModalClose}
                        sequence={approverList.length + 1}
                        handleSaveAddNew={handleSaveAddNew}
                        selectedApprover={selectedApprover}
                        userList={userList.result}
                    />
                }
            </div>
        )
    }
}

ProjectApprover.propTypes = {
    isModalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
    selectedProject: selectors.makeSelectSelectedProjectSuccess(),
    projectActivitySuccess: selectors.makeSelectProjectActivitySuccess(),
    userList: makeSelectUserListSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default (compose(withConnect)(ProjectApprover));