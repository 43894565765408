import { createSelector } from 'reselect';

const selectTimesheetDomain = state => state.timesheet;

const makeSelectTimesheetOpen = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('isTimesheetModalOpen') : false
  );

const makeSelectTimesheetList = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('timesheetList') : []
  );

const makeSelectTimesheetListLoading = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('isTimesheetListLoading') : false
  );

const makeSelectTimesheetListError = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('timesheetListError') : null
  );

const makeSelectTimesheetSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('timesheetSuccess') : null
  );

const makeSelectSelectedTimesheetSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('selectedTimesheet') : ''
  );

const makeSelectTimeTypeSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('timeTypeList') : []
  );

const makeSelectTimesheetDetailsSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('timesheetDetails') : []
  );

const makeSelectUpdateTimesheetDetailsSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('timesheetDetailsSuccess') : false
  );

const makeTimesheetApprovalList = () =>
  createSelector(
    selectTimesheetDomain,
    timesheeState =>
      timesheeState ? timesheeState.get('timesheetApprovals') : []
  );

export {
  makeSelectTimesheetOpen,
  makeSelectTimesheetList,
  makeSelectTimesheetListLoading,
  makeSelectTimesheetListError,
  makeSelectTimesheetSuccess,
  makeSelectSelectedTimesheetSuccess,
  makeSelectTimeTypeSuccess,
  makeSelectTimesheetDetailsSuccess,
  makeSelectUpdateTimesheetDetailsSuccess,
  makeTimesheetApprovalList
}
