import { put, call } from 'redux-saga/effects';
import {
  addNewEmployeeRequest,
  getEmployeeList,
  updateEmployeeRequest,
  // deleteEmployeeRequest,
  getSelectedEmployeeRequest,
  // updateEmployeeStatusRequest,
} from '../service/employeeService';
import * as actions from '../../store/action/employeeAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewEmployeeRequest(data){
  yield put(actions.loadEmployeeListError(null))
  try{
    const response = yield call(addNewEmployeeRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      const id = response.data.result.id
      yield put(actions.loadSelectedEmployeeRequest(id))
      const massage = response.data.message
      yield put(actions.sendEmployeeSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadEmployeeListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage = common.error
    yield put(actions.loadEmployeeListError(massage))
  }
}

export function* loadEmployeeListRequest(data){
  yield put(actions.loadEmployeeListError(null))
  try{
    yield put(actions.loadEmployeeListLoading(true));
    const response = yield call(getEmployeeList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const projectList = response.data.result
      yield put(actions.loadEmployeeListSuccess(projectList))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      yield put(actions.loadEmployeeListLoading(false));
      const massage = common.error
      yield put(actions.loadEmployeeListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadEmployeeListLoading(false));
  }catch(error){
    yield put(actions.loadEmployeeListLoading(false));
    console.log(error)
    const massage = common.error
    yield put(actions.loadEmployeeListError(massage))
  }
}

export function* sendUpdateEmployeeRequest(data){
  yield put(actions.loadEmployeeListError(null))
  try{
    const response = yield call(updateEmployeeRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const massage = response.data.message
      yield put(actions.sendEmployeeSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      const massage = response.data.message
      yield put(actions.loadEmployeeListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage = common.error
    yield put(actions.loadEmployeeListError(massage))
  }
}

// export function* sendDeleteEmployeeRequest(data){
//   yield put(actions.loadEmployeeListError(null))
//   try{
//     yield put(actions.loadEmployeeListLoading(true));
//     const response = yield call(deleteEmployeeRequest,data.payload);
//     if(response && response.status && response.status === 200 && response.data &&  response.data.statusCode &&  response.data.statusCode === 404 && response.data.result.code ){
//       var massage = common.massages.projectDeleteMassage
//       const errorCode = common.deleteMassages.find(element => element.id === response.data.result.code)
//       if(errorCode){
//         massage = errorCode.description
//       }
//       yield put(actions.loadEmployeeListError(massage)) 
//     } 
//     else if(response && response.status && response.status === 200 && response.data &&  response.data.statusCode &&  response.data.statusCode === 200 ){
//       const massage = response.data.message
//       yield put(actions.sendEmployeeSuccess(massage)) 
//     } 
//     else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
//       yield put(saveLoginUser(false))
//       invalidToken()
//     } 
//     else{
//       const massage = response.data.message
//       yield put(actions.loadEmployeeListError(massage))
//     }
//     if(response && response.data && response.data.result && response.data.result.newAccessToken){
//       refeshTokenset(response.data.result.newAccessToken)
//     }
//     yield put(actions.loadEmployeeListLoading(false));
//   }catch(error){
//     yield put(actions.loadEmployeeListLoading(false));
//     const massage = common.error
//     yield put(actions.loadEmployeeListError(massage))
//   }
// }

export function* loadSelectedEmployeeRequest(data){
  yield put(actions.loadEmployeeListError(null))
  try{
    yield put(actions.loadEmployeeListLoading(true));
    const response = yield call(getSelectedEmployeeRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      const employee = response.data
      yield put(actions.loadSelectedEmployeeSuccess(employee))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else {
      yield put(actions.loadEmployeeListLoading(false));
      const massage = common.error
      yield put(actions.loadEmployeeListError(massage))
    }
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadEmployeeListLoading(false));
  }catch(error){
    yield put(actions.loadEmployeeListLoading(false));
    const massage = common.error
    yield put(actions.loadEmployeeListError(massage))
  }
}

export function* sendUpdateStatusEmployeeRequest(data){
  yield put(actions.loadEmployeeListError(null))
  try{
    const response = yield call(updateEmployeeRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const massage = response.data.message
      yield put(actions.sendEmployeeSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      const massage = response.data.message
      yield put(actions.loadEmployeeListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage = common.error
    yield put(actions.loadEmployeeListError(massage))
  }
}

