import React from "react";
import '../../layout/form/form.css'
import Button from '../../layout/form/Button';
import VemasLogo from '../../../images/WELAWA.png';


function Success({
    handleActivationSuccess
}) {
    return (
        <div>
            <div className="vemas_logo">
                <img alt="vemaslogo" id="vemas_logo" src={VemasLogo} />
            </div>
            <div style={{ margin: '0 5% 2% 5%' }}>
                <div style={{ textAlign: 'center', fontWeight: 600, borderBottom: '2px solid #d8a61e', paddingBottom: '5px', marginBottom: '40px', fontSize:"18px" }}>Congratulations! Your subscription is active now</div>
                <div>
                    <p>We highly recommend you set up a one-on-one training session with our experts to learn how to use the system properly . The training will help you use the system effectively.</p>
                    <p>If you have any questions or you want to set up a training session please contact us at <a href="mailto:admin@vemas.com.au">admin@vemas.com.au</a></p>
                    <p>
                        Enjoy your journey with VEMAS.
                    </p>
                </div>
                <div className="filter-btns" style={{ margin: '20px 0' }}>
                    <Button
                        onClick={() => handleActivationSuccess()}
                        variant="outline-primary"
                        text='Continue'
                    />
                </div>
            </div>
        </div>
    )
}

export default Success;