import {
  LOAD_SELECTED_TIMESHEET_REQUEST,
  LOAD_SELECTED_TIMESHEET_SUCCESS,
  LOAD_TIMESHEET_APPROVALS_REQUEST,
  LOAD_TIMESHEET_APPROVALS_SUCCESS,
  LOAD_TIMESHEET_DETAILS_REQUEST,
  LOAD_TIMESHEET_DETAILS_SUCCESS,
  LOAD_TIMESHEET_LIST_ERROR,
  LOAD_TIMESHEET_LIST_LOADING,
  LOAD_TIMESHEET_LIST_REQUEST,
  LOAD_TIMESHEET_LIST_SUCCESS,
  LOAD_TIME_TYPE_REQUEST,
  LOAD_TIME_TYPE_SUCCESS,
  SAVE_IS_TIMESHEET_OPEN_STATUS,
  SEND_ADD_NEW_TIMESHEET_REQUEST,
  SEND_DELETE_TIMESHEET_DETAILS_REQUEST,
  SEND_DELETE_TIMESHEET_REQUEST,
  SEND_TIMESHEET_COPY_REQUEST,
  SEND_TIMESHEET_DETAILS_COPY_REQUEST,
  SEND_TIMESHEET_DETAILS_REQUEST,
  SEND_TIMESHEET_DETAILS_SUCCESS,
  SEND_TIMESHEET_SUCCESS,
  SEND_UPDATE_TIMESHEET_REQUEST,
  SEND_UPDATE_TIMESHEET_STATUS_REQUEST
} from '../constant/timesheetConstant';

export function saveIsTimesheetOpenStatus(open) {
  return {
    type: SAVE_IS_TIMESHEET_OPEN_STATUS,
    open,
  }
};

export function loadTimesheetListRequest(data) {
  return {
    type: LOAD_TIMESHEET_LIST_REQUEST,
    payload: data,
  }
};

export function loadTimesheetListLoading(data) {
  return {
    type: LOAD_TIMESHEET_LIST_LOADING,
    payload: data,
  }
};

export function loadTimesheetListSuccess(data) {
  return {
    type: LOAD_TIMESHEET_LIST_SUCCESS,
    payload: data,
  }
};

export function loadTimesheetListError(data) {
  return {
    type: LOAD_TIMESHEET_LIST_ERROR,
    payload: data,
  }
};

export function sendAddNewTimesheetRequest(data) {
  return {
    type: SEND_ADD_NEW_TIMESHEET_REQUEST,
    payload: data,
  }
};

export function sendTimesheetSuccess(data) {
  return {
    type: SEND_TIMESHEET_SUCCESS,
    payload: data,
  }
};

export function sendUpdateTimesheetRequest(data) {
  return {
    type: SEND_UPDATE_TIMESHEET_REQUEST,
    payload: data,
  }
};

export function sendDeleteTimesheetRequest(data) {
  return {
    type: SEND_DELETE_TIMESHEET_REQUEST,
    payload: data,
  }
};

export function loadSelectedTimesheetSuccess(data) {
  return {
    type: LOAD_SELECTED_TIMESHEET_SUCCESS,
    payload: data,
  }
};

export function loadSelectedTimesheetRequest(data) {
  return {
    type: LOAD_SELECTED_TIMESHEET_REQUEST,
    payload: data,
  }
};

export function sendUpdateTimesheetStatusRequest(data) {
  return {
    type: SEND_UPDATE_TIMESHEET_STATUS_REQUEST,
    payload: data,
  }
};

export function loadTimeTypeSuccess(data) {
  return {
    type: LOAD_TIME_TYPE_SUCCESS,
    payload: data,
  }
};

export function loadTimeTypeRequest() {
  return {
    type: LOAD_TIME_TYPE_REQUEST
  }
};

export function loadTimesheetDetailsSuccess(data) {
  return {
    type: LOAD_TIMESHEET_DETAILS_SUCCESS,
    payload: data,
  }
};

export function loadTimesheetDetailsRequest(data) {
  return {
    type: LOAD_TIMESHEET_DETAILS_REQUEST,
    payload: data,
  }
};

export function sendTimesheetDetailsDeleteRequest(data) {
  return {
    type: SEND_DELETE_TIMESHEET_DETAILS_REQUEST,
    payload: data,
  }
};

export function sendTimesheetDetailsRequest(data) {
  return {
    type: SEND_TIMESHEET_DETAILS_REQUEST,
    payload: data,
  }
};

export function sendTimesheetDetailsSuccess(data) {
  return {
    type: SEND_TIMESHEET_DETAILS_SUCCESS,
    payload: data,
  }
};

export function sendTimesheetDetailsCopyRequest(data) {
  return {
    type: SEND_TIMESHEET_DETAILS_COPY_REQUEST,
    payload: data,
  }
};

export function sendTimesheetCopyRequest(data) {
  return {
    type: SEND_TIMESHEET_COPY_REQUEST,
    payload: data,
  }
};

export function loadTimesheetApprovalSuccess(data) {
  return {
    type: LOAD_TIMESHEET_APPROVALS_SUCCESS,
    payload: data,
  }
};

export function loadTimesheetApprovalRequest(data) {
  return {
    type: LOAD_TIMESHEET_APPROVALS_REQUEST,
    payload: data,
  }
};

