import { put, call } from 'redux-saga/effects';
import {
  addNewInvoiceRequest,
  getInvoiceList,
  updateInvoiceRequest,
  deleteInvoiceRequest,
  // printInvoiceRequest,
  getSelectedInvoiceRequest,
  // sendInvoiceRequest,
  printInvoiceByIdRequest,
  getPaymentTermRequest,
  getInvoiceEmailRequest,
  sendInvoiceEmail,
  getPaymentMethodList
} from '../service/invoiceService';
// import {
//   sendCustomerNotificationRequest
// } from '../service/customerService';
import * as actions from '../../store/action/invoiceAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import { getInvoiceTimesheetDetailList } from '../service/timesheetService';


export function* sendAddNewInvoiceRequest(data){
  // console.log('sendAddNewInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    // yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(addNewInvoiceRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      // console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendInvoiceSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 422 ){ 
      var massage = response.data.message
      
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
        //  for(let i = 0; i < errors.length; i++){
        //     error_ = error_ + errors[i] + '\n'
        //  }
         massage = error_
      yield put(actions.loadInvoiceListError(massage))
    }
    else {
      const massage = response.data.message
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewInvoiceLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewInvoiceLoading(false));
    // console.log(error.response.data.detail)
    const massage  = common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* loadInvoiceListRequest(data){
  // console.log('loadInvoiceListRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(getInvoiceList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result.result)
      const invoiceList = response.data.result
      yield put(actions.loadInvoiceListSuccess(invoiceList))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadInvoiceListLoading(false));
      const massage  = common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error)
    const massage  = common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* sendUpdateInvoiceRequest(data){
  // console.log('sendUpdateInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(updateInvoiceRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendInvoiceSuccess(massage))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 422 ){ 
      var massage = response.data.message
      
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
        //  for(let i = 0; i < errors.length; i++){
        //     error_ = error_ + errors[i] + '\n'
        //  }
         massage = error_
      yield put(actions.loadInvoiceListError(massage))
    }
    else{
      const massage = response.data.message
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  =common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* sendDeleteInvoiceRequest(data){
  // console.log('sendDeleteInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(deleteInvoiceRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendInvoiceSuccess(massage)) 
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadInvoiceListLoading(false));
      const massage  = common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  =common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* sendPrintInvoiceRequest(data){
  // console.log('sendPrintInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(updateInvoiceRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const id = data.payload.id
      yield put(actions.loadInvoicePrintByIdRequest(id));
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  = common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* loadSelectedInvoiceRequest(data){
  // console.log('loadSelectedInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(getSelectedInvoiceRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data)
      const invoice = response.data
      yield put(actions.loadSelectedInvoiceSuccess(invoice))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadInvoiceListError(massage))
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error)
    const massage  = common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* sendAddAndPrintInvoiceRequest(data){
  // console.log('sendAddAndPrintInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(addNewInvoiceRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      const id = response.data.result.id
      yield put(actions.loadInvoicePrintByIdRequest(id));
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 422 ){ 
      var massage = response.data.message
      
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
        //  for(let i = 0; i < errors.length; i++){
        //     error_ = error_ + errors[i] + '\n'
        //  }
         massage = error_
      yield put(actions.loadInvoiceListError(massage))
    }
    else{
      const massage  = common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  = common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* sendUpdateEmailInvoiceRequest(data){
  // console.log('sendEmailInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(updateInvoiceRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const id = data.payload.id
      yield put(actions.saveIsEmailEditorOpenStatus(true));
      yield put(actions.loadInvoiceEmailRequest(id));
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 422 ){ 
      var massage = response.data.message
      
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
         massage = error_
      yield put(actions.loadInvoiceListError(massage))
      yield put(actions.loadInvoiceListLoading(false));
    }
    else{
      const massage = response.data.message
      yield put(actions.loadInvoiceListError(massage))
      yield put(actions.loadInvoiceListLoading(false));
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  = common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}


export function* sendAddNewInvoiceEmailRequest(data){
  // console.log('sendEmailInvoiceRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(addNewInvoiceRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      const id = response.data.result.id
      yield put(actions.saveIsEmailEditorOpenStatus(true));
      yield put(actions.loadInvoiceEmailRequest(id));
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 422 ){ 
      var massage = response.data.message
      
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
         massage = error_
      yield put(actions.loadInvoiceListError(massage))
      yield put(actions.loadInvoiceListLoading(false));
    }
    else{
      const massage = response.data.message
      yield put(actions.loadInvoiceListError(massage))
      yield put(actions.loadInvoiceListLoading(false));
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  = common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}


export function* loadPrintbyIdRequest(data){
  // console.log('loadPrintbyIdRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(printInvoiceByIdRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(actions.loadInvoicePrintSuccess(pdf))
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  =common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}


export function* loadPaymentTermRequest(data){
  // console.log('loadPaymentTermRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    // yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(getPaymentTermRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200){
      // console.log(response.data)
      const paymentTerm = response.data.result.result
      yield put(actions.loadPaymentTermSuccess(paymentTerm))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    // yield put(actions.loadInvoiceListLoading(false));
    // console.log(error)
    const massage  =common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

export function* loadInvoiceEmailRequest(data){
  // console.log('loadInvoiceSendByIdRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(getInvoiceEmailRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data.result)
      const email = response.data
      yield put(actions.loadInvoiceEmailSuccess(email)) 
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  =common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}


export function* sendInvoiceEmailRequest(data){
  // console.log('loadInvoiceSendByIdRequest')
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(sendInvoiceEmail,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result)
      const massage = common.invoiceEmailMessage
      yield put(actions.sendInvoiceSuccess(massage)) 
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    // console.log(error.response)
    const massage  =common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}

// export function* invoiceNotificationRequest(data){
//   yield put(actions.loadInvoiceListError(''))
//   try{
//     const response = yield call(sendCustomerNotificationRequest,data.payload);
//     if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
//       // console.log(response.data.message)
//       const massage = response.data.message
//       yield put(actions.sendInvoiceSuccess(massage))
//     }
//     else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
//       yield put(saveLoginUser(false))
//       invalidToken()
//     }
//     else {
//       const massage = response.data.message
//       yield put(actions.loadInvoiceListError(massage))
//     }
//     if(response && response.data && response.data.result && response.data.result.newAccessToken){
//       refeshTokenset(response.data.result.newAccessToken)
//     }
//   }catch(error){
//     const massage = common.error
//     yield put(actions.loadInvoiceListError(massage))
//   }
// }

export function* loadPaymentMethodRequest(){
  try{
    const response = yield call(getPaymentMethodList);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      yield put(actions.loadPaymentMethodSuccess(response.data.result.result))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    console.log(error)
  }
}



export function* loadInvoiceTimesheetDetailsRequest(data){
  yield put(actions.loadInvoiceListError(''))
  try{
    yield put(actions.loadInvoiceListLoading(true));
    const response = yield call(getInvoiceTimesheetDetailList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const details = response.data.result.result
      yield put(actions.loadInvoiceTimsheetDetailsSuccess(details))
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadInvoiceListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadInvoiceListLoading(false));
  }catch(error){
    yield put(actions.loadInvoiceListLoading(false));
    const massage  =common.error
    yield put(actions.loadInvoiceListError(massage))
  }
}
