import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function addNewTitleRequest(data){
    const parameters = JSON.stringify(data)
      return axios.post(baseURL+'titles', parameters, {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : sessionStorage.getItem("accessToken")
          }
      })
}

export function getTitleList(parameters){
    return axios.get(baseURL+'titles?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&search='+parameters.name, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateTitleRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'titles/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteTitleRequest(id){
    return axios.delete(baseURL+'titles/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}