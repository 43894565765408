import { put, call } from 'redux-saga/effects';
import {
  addNewUserRoleRequest,
  deleteUserRoleRequest,
  getObjectList,
  getObjectOperationList,
  getUserRoleObjectRequest,
  getUserRoleRequest,
  updateUserRoleRequest
} from '../service/userRoleService';
import * as actions from '../../store/action/userRoleAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadUserRoleListRequest(data) {
  yield put(actions.loadUserRoleError(null))
  try {
    yield put(actions.loadUserRoleLoading(true));
    const response = yield call(getUserRoleRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const roleList = response.data.result
      yield put(actions.loadUserRoleListSuccess(roleList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadUserRoleLoading(false));
      const massage = common.error
      yield put(actions.loadUserRoleError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadUserRoleLoading(false));
  } catch (error) {
    yield put(actions.loadUserRoleLoading(false));
    console.log(error)
    const massage = common.error
    yield put(actions.loadUserRoleError(massage))
  }
}


export function* loadUserRoleObjectListRequest(data) {
  yield put(actions.loadUserRoleError(null))
  try {
    yield put(actions.loadUserRoleLoading(true));
    const response = yield call(getUserRoleObjectRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const roleList = response.data.result.result
      yield put(actions.loadUserRoleObjectListSuccess(roleList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadUserRoleLoading(false));
      const massage = common.error
      yield put(actions.loadUserRoleError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadUserRoleLoading(false));
  } catch (error) {
    yield put(actions.loadUserRoleLoading(false));
    console.log(error)
    const massage = common.error
    yield put(actions.loadUserRoleError(massage))
  }
}

export function* sendAddNewUserRoleRequest(data) {
  yield put(actions.loadUserRoleError(null))
  try {
    const response = yield call(addNewUserRoleRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      const massage = response.data.message
      yield put(actions.sendUserRoleSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadUserRoleError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadUserRoleError(massage))
  }
}

export function* sendUpdateUserRoleRequest(data) {
  yield put(actions.loadUserRoleError(null))
  try {
    const response = yield call(updateUserRoleRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendUserRoleSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadUserRoleError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadUserRoleError(massage))
  }
}

export function* sendDeleteUserRoleRequest(data) {
  yield put(actions.loadUserRoleError(null))
  try {
    yield put(actions.loadUserRoleLoading(true));
    const response = yield call(deleteUserRoleRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendUserRoleSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else if (response && response.status && response.data.statusCode && response.data.statusCode === 409) {
      const massage = response.data.result
      yield put(actions.loadUserRoleError(massage))
    }
    else {
      yield put(actions.loadUserRoleLoading(false));
      const massage = common.error
      yield put(actions.loadUserRoleError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadUserRoleLoading(false));
  } catch (error) {
    yield put(actions.loadUserRoleLoading(false));
    const massage = common.error
    yield put(actions.loadUserRoleError(massage))
  }
}

export function* loadObjectRequest(data) {
  try {
    const response = yield call(getObjectList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(actions.loadObjectListSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}

export function* loadObjectOperationRequest(data) {
  try {
    const response = yield call(getObjectOperationList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(actions.loadObjectOperationListSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}

