import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../layout/form/ButtonGroup';
import TextField from '../../layout/form/TextField';
import common from '../../common';
import SelectField from '../../layout/form/SelectField';
// import Form from 'react-bootstrap/cjs/Form';
import PhoneNumberField from '../../layout/form/PhoneNumberField';
import Avatar from 'react-avatar';

export class AddEmployee extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            titleId:'',
            firstName:'',
            lastName:'',
            email:'',
            mobile:'',
            status:'',
            employeeId:'',
            managerId:'',
            costRate:parseFloat(0).toFixed(2),
            sellRate:parseFloat(0).toFixed(2),
            initial:true,
            managerList: [],
            modifyEmployee : false
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedEmployee, isUpdate, objectStatusList, empolyeeList, userAccess } = prevProps;
        if(selectedEmployee && selectedEmployee.id && prevState.initial && isUpdate && empolyeeList ){
            var list = empolyeeList.filter(e => e.id !== selectedEmployee.id)
            var access = false
            if(userAccess && userAccess.modifyEmployee){
                access = true
            }
            return {
                titleId:selectedEmployee.titleId,
                firstName:selectedEmployee.firstName,
                lastName:selectedEmployee.lastName,
                email:selectedEmployee.email,
                mobile:selectedEmployee.mobile,
                status:selectedEmployee.statusId,
                id: selectedEmployee.id,
                employeeId: selectedEmployee.employeeId,
                managerId: selectedEmployee.managerId,
                costRate: parseFloat(selectedEmployee.costRate).toFixed(2),
                sellRate: parseFloat(selectedEmployee.sellRate).toFixed(2),
                managerList: list,
                initial:false,
                modifyEmployee : access
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && !isUpdate && prevState.initial){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            access = false
            if(userAccess && userAccess.createEmployee){
                access = true
            }
            return {
                status:defaultStatus[0].id,
                managerList: empolyeeList,
                initial:false,
                modifyEmployee : access
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    mobileNumChange = (e) => {
        this.setState({
            mobile:e
        })
    }

    handleChangeStatus = (value) =>{
        this.setState({status:value})
    }

    handleChangeManager = (value) =>{
        this.setState({managerId:value})
    }

    handleChangeTitle = (value) =>{
        this.setState({titleId:value})
    }
    
  
    render() {
        const {
            isEmployeeModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            titleList,
            objectStatusList,
        } = this.props;

        const { modifyEmployee } = this.state;

        return (
            <div>
                <Modal
                    show ={isEmployeeModalOpen} 
                    onHide={handleModalClose}
                    title={"Employee Information"}
                    width='30%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form >
                                <div style={{textAlign:'center',marginBottom:'20px'}}>
                                    <Avatar name={this.state.firstName === "" ? "Employee Information"  : this.state.firstName + " " +  this.state.lastName} size={100} round="50%" maxInitials={2}/>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Title"
                                            controlId="titleId"
                                            onChange={this.handleChangeTitle}
                                            options={titleList}
                                            selectedValue={this.state.titleId}
                                            disabled={!modifyEmployee}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Employee id"
                                            controlId="employeeId"
                                            // placeholder="First Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.employeeId}
                                            maxLength={common.maxLengths.employeeId}
                                            disabled={this.props.isUpdate}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="First Name"
                                            controlId="firstName"
                                            // placeholder="First Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.firstName}
                                            maxLength={common.maxLengths.employeeFirstName}
                                            disabled={!modifyEmployee}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Last Name"
                                            controlId="lastName"
                                            // placeholder="Last Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.lastName}
                                            maxLength={common.maxLengths.employeeLastName}
                                            disabled={!modifyEmployee}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Email"
                                            controlId="email"
                                            // placeholder="Email"
                                            type="email"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.email}
                                            maxLength={common.maxLengths.employeeEmail}
                                            disabled={!modifyEmployee}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <PhoneNumberField
                                            controlId="mobile"
                                            text="Mobile Number"
                                            type="text"
                                            // onBlur={this.mobileNumChange}
                                            hint="Please enter valid mobile number (eg:- +xxxxxxxxxx)"
                                            // required={this.state.isMobileValidate}
                                            onChange={this.mobileNumChange}
                                            maxLength={common.maxLengths.employeeMobile}
                                            value={this.state.mobile}
                                            disabled={!modifyEmployee}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Manager"
                                            controlId="managerId"
                                            onChange={this.handleChangeManager}
                                            options={this.state.managerList}
                                            selectedValue={this.state.managerId}
                                            display="firstName"
                                            disabled={!modifyEmployee}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Cost rate"
                                            controlId="costRate"
                                            // placeholder="Email"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={this.state.costRate}
                                            disabled={!modifyEmployee}
                                            style={{textAlign:'right'}}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Sell rate"
                                            controlId="sellRate"
                                            // placeholder="Email"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={this.state.sellRate}
                                            disabled={!modifyEmployee}
                                            style={{textAlign:'right'}}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            onChange={this.handleChangeStatus}
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            required
                                            disabled={!modifyEmployee}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                                secondaryBtnDisabled={!modifyEmployee}
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

AddEmployee.propTypes = {
    isEmployeeModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedEmployee: PropTypes.any,
    isUpdate: PropTypes.bool
}

export default AddEmployee;