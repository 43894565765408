import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewProjectRequest(data) {
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.post(baseURL + 'project', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getProjectList(parameters) {
    return axios.get(baseURL + 'project?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber + '&projectName=' + parameters.name + '&toDate=' + parameters.toDate + '&fromDate=' + parameters.fromDate + '&commonSearch=' + parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function updateProjectRequest(data) {
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL + 'project/' + data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteProjectRequest(id) {
    return axios.delete(baseURL + 'project/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedProjectRequest(id) {
    return axios.get(baseURL + 'project/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getSummarisedProjectList(id) {
    return axios.get(baseURL + 'project/activity/status/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getProjectAllocationTypeRequest() {
    return axios.get(baseURL + 'project/allocationType', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getRevenueForcastingReport(parameters) {
    return axios.get(baseURL + 'project/revenueForcasting?toDate='+parameters.toDate+'&fromDate='+parameters.fromDate+'&assignee='+parameters.resource+'&projectId='+parameters.projectId, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getProjectListbyStatus(id) {
    return axios.get(baseURL + 'project/status/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getProjectTypeRequest() {
    return axios.get(baseURL + 'project/type', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getProjectBillingTypeRequest() {
    return axios.get(baseURL + 'project/billingType', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function importProjectActivitiesRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'project/importActivities/' + data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function getProjectTimeInputTypeRequest() {
    return axios.get(baseURL + 'project/timeInputType', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}