const PATH = 'app/Timesheet';

export const SAVE_IS_TIMESHEET_OPEN_STATUS = `${PATH}SAVE_IS_TIMESHEET_OPEN_STATUS`;
export const LOAD_TIMESHEET_LIST_REQUEST = `${PATH}LOAD_TIMESHEET_LIST_REQUEST`;
export const LOAD_TIMESHEET_LIST_ERROR = `${PATH}LOAD_TIMESHEET_LIST_ERROR`;
export const LOAD_TIMESHEET_LIST_LOADING = `${PATH}LOAD_TIMESHEET_LIST_LOADING`;
export const LOAD_TIMESHEET_LIST_SUCCESS = `${PATH}LOAD_TIMESHEET_LIST_SUCCESS`;
export const SEND_ADD_NEW_TIMESHEET_REQUEST = `${PATH}SEND_ADD_NEW_TIMESHEET_REQUEST`;
export const SEND_UPDATE_TIMESHEET_REQUEST = `${PATH}SEND_UPDATE_TIMESHEET_REQUEST`;
export const SEND_UPDATE_TIMESHEET_STATUS_REQUEST = `${PATH}SEND_UPDATE_TIMESHEET_STATUS_REQUEST`;
export const SEND_DELETE_TIMESHEET_REQUEST = `${PATH}SEND_DELETE_TIMESHEET_REQUEST`;
export const SEND_TIMESHEET_SUCCESS = `${PATH}SEND_TIMESHEET_SUCCESS`;
export const LOAD_SELECTED_TIMESHEET_REQUEST = `${PATH}LOAD_SELECTED_TIMESHEET_REQUEST`;
export const LOAD_SELECTED_TIMESHEET_SUCCESS = `${PATH}LOAD_SELECTED_TIMESHEET_SUCCESS`;
export const LOAD_TIME_TYPE_REQUEST = `${PATH}LOAD_TIME_TYPE_REQUEST`;
export const LOAD_TIME_TYPE_SUCCESS = `${PATH}LOAD_TIME_TYPE_SUCCESS`;
export const LOAD_TIMESHEET_DETAILS_REQUEST = `${PATH}LOAD_TIMESHEET_DETAILS_REQUEST`;
export const LOAD_TIMESHEET_DETAILS_SUCCESS = `${PATH}LOAD_TIMESHEET_DETAILS_SUCCESS`;
export const SEND_DELETE_TIMESHEET_DETAILS_REQUEST = `${PATH}SEND_DELETE_TIMESHEET_DETAILS_REQUEST`;
export const SEND_TIMESHEET_DETAILS_REQUEST = `${PATH}SEND_TIMESHEET_DETAILS_REQUEST`;
export const SEND_TIMESHEET_DETAILS_SUCCESS = `${PATH}SEND_TIMESHEET_DETAILS_SUCCESS`;
export const SEND_TIMESHEET_DETAILS_COPY_REQUEST = `${PATH}SEND_TIMESHEET_DETAILS_COPY_REQUEST`;
export const SEND_TIMESHEET_COPY_REQUEST = `${PATH}SEND_TIMESHEET_COPY_REQUEST`;
export const LOAD_TIMESHEET_APPROVALS_REQUEST = `${PATH}LOAD_TIMESHEET_APPROVALS_REQUEST`;
export const LOAD_TIMESHEET_APPROVALS_SUCCESS = `${PATH}LOAD_TIMESHEET_APPROVALS_SUCCESS`;