const PATH = 'app/AppBilling';

export const LOAD_APP_BILLING_ERROR = `${PATH}LOAD_APP_BILLING_ERROR`;
export const LOAD_APP_BILLING_LOADING = `${PATH}LOAD_APP_BILLING_LOADING`;
export const LOAD_APP_SERVICE_TENANT_REQUEST = `${PATH}LOAD_APP_SERVICE_TENANT_REQUEST`;
export const LOAD_APP_SERVICE_TENANT_SUCCESS = `${PATH}LOAD_APP_SERVICE_TENANT_SUCCESS`;
export const SEND_APP_BILLING_REQUEST = `${PATH}SEND_APP_BILLING_REQUEST`;
export const SEND_APP_BILLING_SUCCESS = `${PATH}SEND_APP_BILLING_SUCCESS`;
export const LOAD_PAYMENT_SETUP_REQUEST = `${PATH}LOAD_PAYMENT_SETUP_REQUEST`;
export const LOAD_PAYMENT_SETUP_SUCCESS = `${PATH}LOAD_PAYMENT_SETUP_SUCCESS`;
export const SEND_PAYMENT_METHOD_REQUEST = `${PATH}SEND_PAYMENT_METHOD_REQUEST`;
export const SAVE_IS_APP_INVOICE_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_APP_INVOICE_MODAL_OPEN_STATUS`;
export const LOAD_APP_INVOICE_LIST_REQUEST = `${PATH}LOAD_APP_INVOICE_LIST_REQUEST`;
export const LOAD_APP_INVOICE_LIST_ERROR = `${PATH}LOAD_APP_INVOICE_LIST_ERROR`;
export const LOAD_APP_INVOICE_LIST_LOADING = `${PATH}LOAD_APP_INVOICE_LIST_LOADING`;
export const LOAD_APP_INVOICE_LIST_SUCCESS = `${PATH}LOAD_APP_INVOICE_LIST_SUCCESS`;
export const LOAD_SELECTED_APP_INVOICE_REQUEST = `${PATH}LOAD_SELECTED_APP_INVOICE_REQUEST`;
export const LOAD_SELECTED_APP_INVOICE_SUCCESS = `${PATH}LOAD_SELECTED_APP_INVOICE_SUCCESS`;
export const LOAD_APP_INVOICE_PRINT_REQUEST = `${PATH}LOAD_APP_INVOICE_PRINT_REQUEST`;
export const LOAD_APP_INVOICE_PRINT_SUCCESS = `${PATH}LOAD_APP_INVOICE_PRINT_SUCCESS`;
export const LOAD_APP_PAYMENT_METHOD_REQUEST = `${PATH}LOAD_APP_PAYMENT_METHOD_REQUEST`;
export const LOAD_APP_PAYMENT_METHOD_SUCCESS = `${PATH}LOAD_APP_PAYMENT_METHOD_SUCCESS`;
export const SEND_DEFAULT_PAYMENT_METHOD_REQUEST = `${PATH}SEND_DEFAULT_PAYMENT_METHOD_REQUEST`;
export const SEND_UPDATE_PAYMENT_METHOD_REQUEST = `${PATH}SEND_UPDATE_PAYMENT_METHOD_REQUEST`;
export const SEND_UPDATE_PACKAGE_REQUEST = `${PATH}SEND_UPDATE_PACKAGE_REQUEST`;