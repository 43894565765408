import { fromJS } from 'immutable';
import {
  LOAD_OBJECT_LIST_SUCCESS,
  LOAD_OBJECT_OPERATION_LIST_SUCCESS,
  LOAD_USER_ROLE_LIST_ERROR,
  LOAD_USER_ROLE_LIST_LOADING,
  LOAD_USER_ROLE_LIST_SUCCESS,
  LOAD_USER_ROLE_OBJECT_LIST_SUCCESS,
  SAVE_IS_USER_ROLE_MODAL_OPEN_STATUS,
  SEND_USER_ROLE_SUCCESS,
} from '../constant/userRolesConstant';

export const initialState = fromJS({
  isAddNewUserRoleModalOpen: false,
  loading: false,
  success: null,
  error: null,
  roleList: [],
  objectList: [],
  objectOperationList: [],
  roleObjectList: [],
});

function userRoleReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_USER_ROLE_MODAL_OPEN_STATUS:
      return state.set('isAddNewUserRoleModalOpen', action.open);
    case LOAD_USER_ROLE_LIST_LOADING:
      return state.set('loading', action.payload);
    case LOAD_USER_ROLE_LIST_ERROR:
      return state.set('error', action.payload);
    case LOAD_USER_ROLE_LIST_SUCCESS:
      return state.set('roleList', action.payload);
    case LOAD_OBJECT_LIST_SUCCESS:
      return state.set('objectList', action.payload);
    case LOAD_OBJECT_OPERATION_LIST_SUCCESS:
      return state.set('objectOperationList', action.payload);
    case LOAD_USER_ROLE_OBJECT_LIST_SUCCESS:
      return state.set('roleObjectList', action.payload);
    case SEND_USER_ROLE_SUCCESS:
      return state.set('success', action.payload);
    default:
      return state;
  }
}

export default userRoleReducer;
