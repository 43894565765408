import React, { Component } from "react";
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../store/action/appBillingAction';
import * as selectors from '../../../../store/selector/appBillingSelector';
import { makeSelectTenantPaymentMethod } from "../../../../store/selector/tenantSelector";
import Button from "../../../layout/form/Button";
import PaymentMethod from "./PaymentMethod";
import Method from "./Method";
import Loader from "../../../layout/form/Loader";
import ErrorModal from "../../../layout/form/errorModal";
import SuccessModal from "../../../layout/form/successModal";
import { loadTenantPaymentMethodRequest, loadTenantPaymentMethodSuccess } from "../../../../store/action/tenantAction";
import { saveTenantUserExpireStatus } from "../../../../store/action/action";
import Card from './Card'

class TenantPaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryPaymentMethod: null,
            otherPaymentMethod: [],
            initial: true,
            isValidationError: false,
            errorMassage: '',
            isCardPayment: false,
            selectedMethod: null
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial } = prevState;
        const { tenantPaymentMethod } = prevProps;
        if (initial && tenantPaymentMethod && tenantPaymentMethod.result && tenantPaymentMethod.result.length > 0) {
            const primaryMethod = tenantPaymentMethod.result.find(e => e.defaultMethod === true)
            const otherList = tenantPaymentMethod.result.filter(e => e.defaultMethod === false && e.methodType)

            return {
                primaryPaymentMethod: primaryMethod ? primaryMethod : null,
                otherPaymentMethod: otherList
            }
        }
        return null;
    }

    getPaymentMethod = () => {
        this.props.dispatch(loadTenantPaymentMethodRequest())
    }

    addPaymentMethod = () => {
        this.props.dispatch(actions.loadPaymentSetupRequest())
    }

    handleModalClose = () => {
        this.props.dispatch(actions.loadPaymentSetupSuccess(null))
    }

    handleSubmitPaymentMethod = async (event, stripe, elements) => {
        event.preventDefault();

        const { paymentSetup } = this.props;

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            clientSecret: paymentSetup.setupIntentClientSecret,
            redirect: 'if_required'
        })

        if (error) {
            this.setState({ isValidationError: true, errorMassage: error.message })
        } else {
            this.props.dispatch(actions.loadPaymentSetupSuccess(null))
            const parms = {
                setupIntent: paymentSetup.setupIntent,
                id: paymentSetup.id
            }
            this.props.dispatch(actions.sendPaymentMethodRequest(parms))
        }
    };

    setDefaultMethod = (e) => {
        if (e.methodType !== "au_becs_debit") {
            var year = e.methodExpires.substr(e.methodExpires.length - 4);
            var month = e.methodExpires.substring(0, 2);
            var date = new Date();
            var lastDay = new Date(parseInt(year), parseInt(month) + 1, 0);
            if (lastDay < date) {
                this.setState({
                    isValidationError: true,
                    errorMassage: 'This card has already expired'
                })
            }
        }
        this.props.dispatch(actions.sendDefaultPaymentMethodRequest(e.id))
    }

    updateMethod = (details) => {
        if (details.methodType !== "au_becs_debit") {
            this.setState({
                isCardPayment: true,
                selectedMethod: details
            })
        }
    }

    handleCloseCardPayment = () => {
        this.setState({
            isCardPayment: false,
            selectedMethod: null
        })
    }

    updatePaymentMethod = (details) => {
        const { selectedMethod } = this.state;
        const parms = {
            type: details.methodType === "au_becs_debit" ? "au_becs_debit" : "card",
            cardExpMonth: details.month,
            cardExpYear: details.year,
            id: selectedMethod.id
        }
        this.props.dispatch(actions.sendUpdatePaymentMethodRequest(parms))
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadAppBillingError(''))
    }

    successModalClose = () => {
        this.props.dispatch(loadTenantPaymentMethodSuccess([]))
        this.getPaymentMethod()
        this.handleCloseCardPayment()
        this.props.dispatch(actions.sendAppBillingSuccess(''))
        // this.props.dispatch(actions.loadAppBillingLoading(false))
        this.setState({
            primaryPaymentMethod: null,
            otherPaymentMethod: [],
            initial: true
        })
    }

    componentDidMount = () => {
        this.getPaymentMethod()
        this.props.dispatch(saveTenantUserExpireStatus(false))
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.appBillingSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {
        const {
            isAppBillingLoading,
            appBillingError,
            appBillingSuccess,
            tenantPaymentMethod,
            paymentSetup
        } = this.props;

        const {
            isValidationError,
            errorMassage,
            otherPaymentMethod,
            primaryPaymentMethod,
            isCardPayment,
            selectedMethod
        } = this.state;

        return (
            <div>
                {isAppBillingLoading && <Loader show={isAppBillingLoading} />}

                {(isValidationError || appBillingError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : appBillingError}
                        handleClose={this.errorModalClose}
                    />}

                {(appBillingSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={appBillingSuccess}
                        handleClose={this.successModalClose}
                    />}
                <div>
                    <div className="content-header">
                        <div className="header-pages">
                            <span><Link to='./appBilling'><ui5-icon class="samples-margin" name="crm-service-manager" id="tab-icon"></ui5-icon> App Services</Link></span>
                            <span><Link to='./appPaymentMethod'><ui5-icon class="samples-margin" name="payment-approval" id="tab-icon"></ui5-icon> Payment method</Link></span>
                            <span><Link to='./appInvoice'><ui5-icon class="samples-margin" name="customer-order-entry" id="tab-icon"></ui5-icon> App Invoice</Link></span>
                        </div>
                    </div>
                    <div className="content-body">
                        <div style={{ padding: '10px' }}>
                            <h5 style={{ color: '#4278b7', fontWeight: 600 }}>Payment Details</h5>
                            <h6 style={{ fontWeight: 600, marginLeft: '5px' }}>Primary payment method</h6>
                            <div className="method-card-list">
                                <div className="col-sm">
                                    {primaryPaymentMethod && primaryPaymentMethod !== null &&
                                        <Method
                                            methodExpires={primaryPaymentMethod.methodExpires}
                                            methodNumber={primaryPaymentMethod.methodNumber}
                                            methodType={primaryPaymentMethod.methodType}
                                            primaryMethod={true}
                                            updateMethod={() => this.updateMethod(primaryPaymentMethod)}
                                        />}
                                </div>
                            </div>
                            <h6 style={{ fontWeight: 600, marginLeft: '5px' }}>Other payment methods</h6>
                            <div className="method-card-list">
                                {otherPaymentMethod && otherPaymentMethod.map(e => (
                                    <div className="col-sm">
                                        <Method
                                            methodExpires={e.methodExpires}
                                            methodNumber={e.methodNumber}
                                            methodType={e.methodType}
                                            primaryMethod={false}
                                            setDefaultMethod={() => this.setDefaultMethod(e)}
                                            updateMethod={() => this.updateMethod(e)}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div style={{ textAlign: 'center' }}>
                                {tenantPaymentMethod && tenantPaymentMethod.paymentMethod === 'au_becs_debit' ? <span style={{ margin: '20px' }}>You have an active direct debit payment method is configured</span> :
                                    tenantPaymentMethod && tenantPaymentMethod.paymentMethod === 'card' ? <span style={{ margin: '20px' }}>You have an active card payment method is configured</span> : ''}

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    <Button
                                        variant="primary"
                                        text='Add New Payment Method'
                                        type="submit"
                                        onClick={this.addPaymentMethod}
                                        maxWidth="350px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    paymentSetup != null && paymentSetup.setupIntent && paymentSetup.setupIntent != null &&
                    <PaymentMethod
                        paymentSetup={paymentSetup}
                        handleClose={this.handleModalClose}
                        handleSubmit={this.handleSubmitPaymentMethod}
                    />
                }

                {
                    isCardPayment &&
                    <Card
                        data={selectedMethod}
                        isModalOpen={isCardPayment}
                        handleModalClose={this.handleCloseCardPayment}
                        handleSaveAddNew={this.updatePaymentMethod}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    tenantPaymentMethod: makeSelectTenantPaymentMethod(),
    paymentSetup: selectors.makeSelectPaymentSetupSuccess(),
    isAppBillingLoading: selectors.makeSelectAppBillingLoading(),
    appBillingError: selectors.makeSelectAppBillingError(),
    appBillingSuccess: selectors.makeSelectAppBillingSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default withRouter(compose(withConnect)(TenantPaymentMethod));