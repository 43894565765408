import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
// import File from 'react-bootstrap/cjs/FormFile';
// import Button from '../../layout/form/Button';
import DatePicker from '../../layout/form/datePicker';
import Modal from "../../layout/form/Modal";
import ButtonGroup from "../../layout/form/ButtonGroup";
import Table from "../../layout/form/Table";

class ImportProjectActivity extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activityList: [],
            tempActivityList:[],
            isDeleteActivity: false,
            step: 'firstStep'
        };

    };

    handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const text = e.target.result;
                this.parseCSV(text);
            };

            reader.readAsText(file);
        }
    };

    parseCSV = (text) => {
        const lines = text.split('\n');

        const list = []
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i]
            const result = line.split(',');
            const element = {
                externalId: result[0] ? result[0] : '',
                description: result[1] ? result[1] : '',
                startDate: result[2] ? result[2] : null,
                endDate: result[3] ? result[3] : null
            }
            list.push(element)
        }
        this.setState({
            activityList: list
        });
    };

    secondStep = () => {
        this.setState({
            step: 'secondStep'
        })
    }

    render() {
        const {
            modifyProject,
            show,
            handleModalClose,
            handleSaveAddNew
        } = this.props;

        const { step } = this.state;

        const columns = [{
            Header: 'External Id',
            accessor: 'externalId',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.externalId}
                        type="text"
                        defaultValue={rowData.cell.row.original.externalId}
                        size="sm"
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Description',
            accessor: 'description',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.description}
                        type="text"
                        defaultValue={rowData.cell.row.original.description}
                        size="sm"
                        onBlur={(e) => this.handleDescription(e, rowData.cell.row)}
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Start Date',
            accessor: 'startDate',
            width: '15%',
            Cell: (rowData) => (
                <div style={{ margin: '-10px 0' }}>
                    <DatePicker
                        // text="Start Date"
                        disabled={!modifyProject}
                        controlId={rowData.cell.row.original.startDate}
                        onChange={(e) => this.handleDateChange(e, rowData.cell.row, "startDate")}
                        value={rowData.cell.row.original.startDate === null ? '' : new Date(rowData.cell.row.original.startDate)}
                    />
                </div>
            ),
        }, {
            Header: 'End Date',
            accessor: 'endDate',
            width: '15%',
            Cell: (rowData) => (
                <div style={{ margin: '-10px 0' }}>
                    <DatePicker
                        // text="End Date"
                        disabled={!modifyProject}
                        controlId={rowData.cell.row.original.endDate}
                        onChange={(e) => this.handleDateChange(e, rowData.cell.row, "endDate")}
                        value={rowData.cell.row.original.endDate === null ? '' : new Date(rowData.cell.row.original.endDate)}
                    />
                </div>
            ),
        },
            // {
            //     Header: 'Action',
            //     width: '8%',
            //     Cell: ((rowData) => (
            //         <div>
            //             {<span style={{ display: 'flex', justifyContent: 'center' }}>
            //                 <span onClick={modifyProject ? () => this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyProject ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
            //             </span>}
            //         </div>
            //     )),
            //     accessor: 'action'
            // }
        ]
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleModalClose}
                    title="Project Activities"
                    body={
                        <div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                        <section className="step-wizard">
                                            <ul className="step-wizard-list">
                                                <li className={step === "firstStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                                    <span className="progress-count">1</span>
                                                    <span className="progress-label">Upload</span>
                                                </li>
                                                <li className={step === "secondStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                                    <span className="progress-count">2</span>
                                                    <span className="progress-label">Review</span>
                                                </li>
                                            </ul>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            {
                                step === "firstStep" ?

                                    <div className="profile-content">
                                        <div className="page_header">
                                            <div style={{ textAlign: 'center', margin: '70px 0' }}>
                                                <h6 style={{ margin: '30px 0' }}>Upload Your Project Activities</h6>
                                                <input type="file" accept=".csv" onChange={this.handleFileUpload} />
                                            </div>
                                            {/* <File id="userPhotoUpload" type="file" accept=".csv"   hidden ref={photoUpload => this.photoUpload = photoUpload} onChange={this.handleChangeLogo} />
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        variant="outline-primary"
                                                        text='Change'
                                                        size='sm'
                                                        onClick={() => this.photoUpload.click()}
                                                    />
                                                </div> */}
                                        </div>
                                    </div> :
                                    step === "secondStep" ?
                                        <div>
                                            <div style={{ marginBottom: '10px' }}>
                                                <Table
                                                    columns={columns}
                                                    data={this.state.activityList}
                                                />
                                            </div>
                                        </div> : ''
                            }
                        </div>
                    }
                    footer={
                        <div style={{ display: 'flex' }}>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Cancel'
                                primaryBtnIcon='decline'
                                secondaryBtnClick={step === "firstStep" ? () => this.secondStep() : () => handleSaveAddNew(this.state)}
                                secondaryBtnText={step === "firstStep" ? 'Verify' : 'Import'}
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnIcon='upload-to-cloud'
                            />
                        </div>}
                />

            </div>
        )
    }
}

export default ImportProjectActivity;