import React, { Component } from 'react';
import Modal from '../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from '../../layout/form/Table';
import Button from '../../layout/form/Button';

export class TimesheetApproval extends Component {

    render() {
        const {
            isApprovalModalOpen,
            handleModalClose,
            timesheetApprovals,
            setStatusDescription,
            setDateFormat
        } = this.props;

        const columns = [{
            Header: 'Project',
            accessor: 'projectName',
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.projectCode ? rowData.cell.row.original.projectName + " (" + rowData.cell.row.original.projectCode + ") " : rowData.cell.row.original.projectName}
                </div>
            ),
            width: "18%",
        }, {
            Header: 'Approver Name',
            accessor: 'userName',
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.userName}
                </div>
            ),
            width: "18%",
        }, {
            Header: 'Approver Sequence',
            accessor: 'approverSequence',
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.approverSequence > 0 ? rowData.cell.row.original.approverSequence : ""}
                </div>
            ),
            width: "8%",
        }, {
            Header: 'Date',
            accessor: 'date',
            Cell: ((row) => (
                <div >
                    {row.row.original.date === '0001-01-01T00:00:00' ? '' : setDateFormat(row.row.original.date)}
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Note',
            accessor: 'note',
        }, {
            Header: 'Status',
            accessor: 'status',
            width: "14%",
            Cell: ((row) => (
                <div style={{ padding: '2px' }}>
                    {row.row.original.statusId === "10" ?
                        <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{setStatusDescription(row.row.original)}</div> :
                        row.row.original.statusId === "60" ?
                            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{setStatusDescription(row.row.original)}</div> :
                            row.row.original.statusId === "50" ?
                                <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{setStatusDescription(row.row.original)}</div> :
                                row.row.original.statusId === "40" ?
                                    <div style={{ backgroundColor: '#eb7f36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{setStatusDescription(row.row.original)}</div> :
                                    row.row.original.statusId === "20" ?
                                        <div style={{ backgroundColor: '#a2eb36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{setStatusDescription(row.row.original)}</div> :
                                        <div style={{ backgroundColor: '#6da4e4', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{setStatusDescription(row.row.original)}</div>
                    }
                </div>
            )),
        }]

        return (
            <div>
                <Modal
                    show={isApprovalModalOpen}
                    onHide={handleModalClose}
                    title={"Timesheet Journey"}
                    width='80%'
                    body={
                        <div>
                            <div className="table-content">
                                <Table
                                    columns={columns}
                                    data={timesheetApprovals && timesheetApprovals.length ? timesheetApprovals : []}
                                />
                            </div>
                        </div>
                    }
                    footer={
                        <div>
                            <Button
                                onClick={handleModalClose}
                                variant="outline-primary"
                                text='Go Back'
                                icon='undo'
                            />
                        </div>
                    }
                />
            </div>
        )
    }
}

export default TimesheetApproval;