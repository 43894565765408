import React, { Component } from 'react';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Switch from "react-switch";
import Table from '../../../layout/form/Table';

export class AddAccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initial: true,
            operationList: [],
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { objectOperationList, selectedObject } = prevProps;
        const { initial } = prevState;

        if (initial && objectOperationList && objectOperationList.length && objectOperationList.length > 0) {
            var list = []
            if (selectedObject.operationList.length > 0) {
                list = selectedObject.operationList
            } else {
                for (let i = 0; i < objectOperationList.length; i++) {
                    const element = objectOperationList[i]

                    const item = {
                        accessStatus: element.accessStatus ? element.accessStatus : false,
                        objectId: selectedObject.id,
                        id: element.id,
                        description: element.description
                    }
                    list.push(item)

                }
            }
            return {
                operationList: list,
                initial: false,
            }
        }

        return null;
    }

    handleChangeSwitch = (value, rowData) => {
        const { operationList } = this.state;
        const newRow = {
            accessStatus: value,
            objectId: rowData.original.objectId,
            id: rowData.original.id,
            description: rowData.original.description
        }
        operationList[rowData.index] = newRow
        this.setState({
            operationList: operationList
        })
    }

    render() {
        const {
            isAccessModalOpen,
            handleModalClose,
            handleAddNewAccess,
            selectedObject,
            modifyUserRole
        } = this.props;

        const { operationList } = this.state;

        const columns = [{
            Header: 'Operation',
            accessor: 'id'
        }, {
            Header: 'Permission',
            width: "30%",
            Cell: ((row) => (
                <div>
                    <Switch
                        checked={row.row.original.accessStatus}
                        onChange={(e) => this.handleChangeSwitch(e, row.row)}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        disabled={!modifyUserRole}
                    />
                </div>
            )),
            accessor: 'permission'
        }]

        return (
            <div>
                <Modal
                    show={isAccessModalOpen}
                    onHide={() => handleModalClose()}
                    title={selectedObject.description + " - permission"}
                    width='40%'
                    body={
                        <div>
                            <div className="table-content">
                                <Table
                                    columns={columns}
                                    data={operationList}
                                />
                            </div>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={() => handleModalClose()}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleAddNewAccess(this.state)}
                                secondaryBtnText='Add'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnIcon='save'
                                secondaryBtnDisabled={!modifyUserRole}
                            />
                        </div>
                    }
                />
            </div>
        )
    }
}

export default AddAccess;