import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../store/selector/projectSelector';
import * as actions from '../../../store/action/projectAction';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import Button from '../../layout/form/Button';
// import TextField from '../../layout/form/TextField';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import SelectField from '../../layout/form/SelectField';
// import DatePicker from '../../layout/form/datePicker';
import Table from '../../layout/form/Table';
import common from '../../common';
import moment from 'moment';
import DatePicker from '../../layout/form/datePicker';
// import { makeSelectUserListSuccess } from '../../../store/selector/usersSelector';
import SuccessModal from '../../layout/form/successModal';
import { makeSelectEmployeeList } from '../../../store/selector/employeeSelector';

const customStyles = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 32,
      marginBottom: 0,
      marginTop: -20
    })
  };

export class projectAssignments extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            assignmentList:[],
            tempAssignmentList:[],
            initial:true,
            isDeleteAssignment: false
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedProject } = prevProps;
        if(selectedProject && selectedProject.id && prevState.initial ){    
            var itemList = []
            
            if(selectedProject.projectAssignments.length !== 0){
                for (let i = 0; i < selectedProject.projectAssignments.length; i++) {
                    const element = selectedProject.projectAssignments[i];
                    const newRow = element
                    element.operation = "U"
                    itemList.push(newRow)
                }
            }
            return {
                assignmentList: itemList,
                initial:false
            }
        }
        
        return null
    }

    componentDidUpdate(prevProps, prevState){
        const { 
            assignmentList, 
            isDeleteAssignment, 
            tempAssignmentList,
        } = this.state;
        if(isDeleteAssignment){
            const srvActivitiyList = assignmentList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = assignmentList.filter(data => (data.id !== null ))
            this.setState({
                assignmentList:srvActivitiyList, 
                isDeleteAssignment:false, 
                tempAssignmentList:tempAssignmentList.concat(tempItemList)
            })
        }

        if (this.props.projectAssignmentSuccess !== null){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
    }

    successModalClose = () => {
        const { selectedProjectId } = this.props;
        this.setState({
            initial:true,
            tempAssignmentList:[]
        })
        if(selectedProjectId > 0){
            this.props.dispatch(actions.loadSelectedProjectRequest(selectedProjectId))
        }
        this.props.dispatch(actions.sendProjectAssignmentSuccess(null))
    }

    addNewRow = () => {
        var { assignmentList } = this.state;
        var { selectedProject, project } = this.props;
        var newRow = {
            assignee:0,
            role:'',
            startDate: selectedProject.planStartDate ? selectedProject.planStartDate : project.planStartDate ? project.planStartDate : null,
            endDate: selectedProject.planEndDate ? selectedProject.planEndDate : project.planEndDate ? project.planEndDate : null,
            operation: "C",
            id: 0,
            allocationType: ""
        }
        assignmentList.push(newRow)
        this.setState({assignmentList})
    }

    handleResource = (id,rowData) => {
        const {assignmentList} = this.state;
        const {employeeList} = this.props;
        const selectedRow = employeeList.result.find(row => row.id === id)
        const newRow = rowData.original
        newRow.assignee = id
        newRow.sellRate = selectedRow.sellRate
        newRow.allocationPercentage = 100
        assignmentList[rowData.index] = newRow
        this.setState({
            assignmentList,
            isChangeData: true
        })
    }

    handleSellRate = (e,rowData) => {
        const {assignmentList} = this.state;
        const newRow = rowData.original
        newRow.sellRate = e.target.value
        assignmentList[rowData.index] = newRow
        this.setState({
            assignmentList,
            isChangeData: true
        })
    }

    handlePercentage = (e,rowData) => {
        const {assignmentList} = this.state;
        const newRow = rowData.original
        newRow.allocationPercentage = e.target.value
        assignmentList[rowData.index] = newRow
        this.setState({
            assignmentList,
            isChangeData: true
        })
    }

    handleType = (id,rowData) => {
        const {assignmentList} = this.state;
        const newRow = rowData.original
        newRow.allocationType = id
        assignmentList[rowData.index] = newRow
        this.setState({
            assignmentList,
            isChangeData: true
        })
    }

    handleDateChange = (date,rowData, id) => {
        const {assignmentList} = this.state;
        if(id === 'startDate'){
            const newRow = rowData.original
            newRow.startDate = date === null ? '' : moment(date).format(common.dateFormat)
            assignmentList[rowData.index] = newRow
            this.setState({
                assignmentList,
                isChangeData: true
            })
        }
        else if(id === 'endDate'){
            const newRow = rowData.original
            newRow.endDate = date === null ? '' : moment(date).format(common.dateFormat)
            assignmentList[rowData.index] = newRow
            this.setState({
                assignmentList,
                isChangeData: true
            })
        }
    }

    handleDelete = (rowData) => {
        var { assignmentList } = this.state;
        const newRow = rowData.original
        newRow.operation = "D"

        assignmentList[rowData.index] = newRow
        this.setState({
            assignmentList, 
            isDeleteAssignment:true,
            isChangeData: rowData.original.id > 0 ? true : false,
        })
    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            handleTab,
            modifyProject,
            // isUpdate,
            // selectedProject,
            employeeList,
            projectAssignmentSuccess,
            projectAllocationTypeList
        } = this.props;

        const columns = [{
            Header: 'Resource',
            accessor: 'resource',
            Cell:(rowData) =>(
                <div>
                    <SelectField
                        controlId="resource"
                        onChange={(e)=>this.handleResource(e,rowData.cell.row)}
                        options={employeeList && employeeList.result && employeeList.result.length ? employeeList.result : []}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.assignee}
                        display='firstName'
                        disabled={!modifyProject}
                        // id="partId"
                    />
                </div>
              ),    
          },{
            Header: 'Sell Rate',
            accessor: 'sellRate',
            Cell:(rowData) =>(
                <div>
                    <BootstrapFormControl 
                        controlId={rowData.cell.row.original.sellRate}
                        onBlur={(e)=>this.handleSellRate(e,rowData.cell.row)}
                        type="text"
                        defaultValue={rowData.cell.row.original.sellRate}
                        size="sm"
                        disabled={!modifyProject}
                    />
                </div>
              ),    
            width:'8%',
          },{
            Header: 'Percentage',
            accessor: 'percentage',
            Cell:(rowData) =>(
                <div>
                    <BootstrapFormControl 
                        controlId={rowData.cell.row.original.allocationPercentage}
                        type="text"
                        defaultValue={rowData.cell.row.original.allocationPercentage}
                        onBlur={(e)=>this.handlePercentage(e,rowData.cell.row)}
                        size="sm"
                        disabled={!modifyProject}
                    />
                </div>
              ),
              width:'8%',    
          },{
            Header: 'Type',
            accessor: 'type',
            Cell:(rowData) =>(
                <div>
                    <SelectField
                        controlId="type"
                        onChange={(e)=>this.handleType(e,rowData.cell.row)}
                        options={projectAllocationTypeList && projectAllocationTypeList.length ? projectAllocationTypeList : []}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.allocationType}
                        disabled={!modifyProject}
                        // id="partId"
                    />
                </div>
              ),    
              width:'8%',    
          },{
            Header: 'Start Date',
            accessor: 'startDate',
            width:'12%',
            Cell:(rowData) =>(
                <div style={{margin:'-10px 0'}}>
                    <DatePicker
                        // text="Start Date"
                        disabled={!modifyProject}
                        controlId={rowData.cell.row.original.startDate}
                        onChange={(e) => this.handleDateChange(e,rowData.cell.row,"startDate")}
                        value={rowData.cell.row.original.startDate === null ? '' : new Date(rowData.cell.row.original.startDate)}
                    />
                </div>
              ),    
          },{
            Header: 'End Date',
            accessor: 'endDate',
            width:'12%',
            Cell:(rowData) =>(
                <div style={{margin:'-10px 0'}}>
                    <DatePicker
                        // text="End Date"
                        disabled={!modifyProject}
                        controlId={rowData.cell.row.original.endDate}
                        onChange={(e) => this.handleDateChange(e,rowData.cell.row,"endDate")}
                        value={rowData.cell.row.original.endDate === null ? '' : new Date(rowData.cell.row.original.endDate)}
                    />
                </div>
              ),    
          },{
            Header:'Action',
            width:'8%',
            Cell:((rowData)=>(
                <div>
                    {<span style={{display:'flex',justifyContent:'center'}}>
                        <span onClick={modifyProject ? ()=>this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyProject ? "table-icon": "table-icon-disabled"}></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {(projectAssignmentSuccess) && 
                <SuccessModal
                    show={true} 
                    massage={projectAssignmentSuccess}
                    handleClose={this.successModalClose}
                />}
            <div className='content-body'>
                <div className="filter-header">
                    <div className="header-pages">
                        <span>Project</span>
                    </div>
                    <div className="btn-close" onClick={handleClose} >
                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                    </div>
                </div>
                <div className="content-header" style={{margin:'-20px 0 15px 0'}}>
                    <div className="header-pages">
                        <span style={{fontSize:'14px',color:'#4278b7'}} onClick={() => handleTab('projectDetails',this.state)}><ui5-icon class="samples-margin" name="header" id="title-icon"></ui5-icon> Header</span>
                        <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectMilestone', this.state)}><ui5-icon class="samples-margin" name="add-coursebook" id="title-icon"></ui5-icon> Milestone</span>
                        <span style={{fontSize:'14px',color:'#4278b7'}} onClick={() => handleTab('projectActivities',this.state)}><ui5-icon class="samples-margin" name="multiselect-all" id="title-icon"></ui5-icon> Activities</span> 
                        <span style={{fontSize:'14px',color:'#4278b7'}} onClick={() => handleTab('projectAssignments',this.state)}><ui5-icon class="samples-margin" name="add-employee" id="title-icon"></ui5-icon> Assignments</span>
                        <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectApprovers', this.state)}><ui5-icon class="samples-margin" name="kpi-managing-my-area" id="title-icon"></ui5-icon> Approvers</span>
                    </div>
                </div>
                <div className="profile-content">
                    <div className="page_header">
                        <div className="page_header-title">
                            <span>Assignments</span>
                        </div>
                        <div style={{marginBottom:'10px'}}>
                            <Table
                                columns={columns}
                                data={this.state.assignmentList}
                            />
                            <div className={modifyProject ? "add-icon" : "add-icon-disabled"}>
                                <ui5-icon class="samples-margin" name="sys-add" id="add-activity" onClick={modifyProject ? () => this.addNewRow() : ''}></ui5-icon>
                            </div>
                            
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <Button
                                variant="outline-primary" 
                                text ='Save'
                                type="submit"
                                onClick={modifyProject ?  () => handleSaveAddNew(this.state) : ''}
                                icon='save'
                                disabled = {!modifyProject}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

projectAssignments.propTypes = {
    isModalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
    // userList: makeSelectUserListSuccess(),
    employeeList: makeSelectEmployeeList(),
    selectedProject: selectors.makeSelectSelectedProjectSuccess(),
    projectAssignmentSuccess: selectors.makeSelectProjectAssignmentSuccess(),
    projectAllocationTypeList: selectors.makeSelectProjectAllocationTypeListSuccess()
});

function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

export default (compose(withConnect)(projectAssignments));