import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import '../../../layout/form/form.css';

class Method extends Component {

    render() {
        const {
            methodType,
            methodNumber,
            methodExpires,
            primaryMethod,
            setDefaultMethod,
            updateMethod
        } = this.props;
        return (
            <div>
                <Card className="card" style={{ margin: '10px' }}>
                    <Card.Body>
                        <div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <ui5-icon class="samples-margin" name="credit-card" id="card-icon"></ui5-icon>
                                </div>
                                {/* <div className="col-sm-1"></div> */}
                                <div className="col-sm">
                                    <div className="card-top">
                                        {methodType === "au_becs_debit" ? <span>BECS Direct Debit (AU)</span> : <span>{methodType.toUpperCase()}</span>}
                                    </div>
                                    <div>{"**** **** **** " + methodNumber}</div>
                                    <div>{methodType === "au_becs_debit" ? "" : "Expire in - " + methodExpires}</div>
                                </div>
                            </div>
                            {
                                primaryMethod && methodType !== "au_becs_debit" ?
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div style={{ marginRight: '30px', color: '#4278b7', cursor: 'pointer' }} onClick={() => updateMethod()}>EDIT</div>
                                    </div> :
                                    primaryMethod ? "" :
                                        !primaryMethod && methodType === "au_becs_debit" ?
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <div style={{ marginRight: '30px', color: '#4278b7', cursor: 'pointer' }} onClick={() => setDefaultMethod()}>SET AS PRIMARY</div>
                                            </div> :
                                            <div className='row'>
                                                <div className='col-sm'></div>
                                                <div className='col-sm-6'>
                                                    <div className='row' style={{ color: '#4278b7', cursor: 'pointer' }}>
                                                        <div style={{ marginRight: '10px' }} onClick={() => setDefaultMethod()}>SET AS PRIMARY</div>
                                                        <div onClick={() => updateMethod()}>{methodType === "au_becs_debit" ? "" : "EDIT"}</div>
                                                    </div>
                                                </div>
                                            </div>
                            }

                        </div>
                        <div>

                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

Method.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.any,
    cardContentText: PropTypes.any,
    cardContentData: PropTypes.any,
    detailField1_Text: PropTypes.any,
    detailField1_Data: PropTypes.any,
    detailField2_Text: PropTypes.any,
    detailField2_Data: PropTypes.any,
}

export default Method;