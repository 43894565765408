const PATH = 'app/Invoice';

export const SAVE_IS_INVOICE_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_INVOICE_MODAL_OPEN_STATUS`;
export const LOAD_INVOICE_LIST_REQUEST = `${PATH}LOAD_INVOICE_LIST_REQUEST`;
export const LOAD_INVOICE_LIST_ERROR = `${PATH}LOAD_INVOICE_LIST_ERROR`;
export const LOAD_INVOICE_LIST_LOADING = `${PATH}LOAD_INVOICE_LIST_LOADING`;
export const LOAD_INVOICE_LIST_SUCCESS = `${PATH}LOAD_INVOICE_LIST_SUCCESS`;
export const SEND_ADD_NEW_INVOICE_REQUEST = `${PATH}SEND_ADD_NEW_INVOICE_REQUEST`;
export const SEND_INVOICE_SUCCESS = `${PATH}SEND_INVOICE_SUCCESS`;
export const SEND_UPDATE_INVOICE_REQUEST = `${PATH}SEND_UPDATE_INVOICE_REQUEST`;
export const SEND_DELETE_INVOICE_REQUEST = `${PATH}SEND_DELETE_INVOICE_REQUEST`;
export const LOAD_INVOICE_PRINT_REQUEST = `${PATH}LOAD_INVOICE_PRINT_REQUEST`;
export const LOAD_INVOICE_PRINT_SUCCESS = `${PATH}LOAD_INVOICE_PRINT_SUCCESS`;
export const LOAD_SELECTED_INVOICE_REQUEST = `${PATH}LOAD_SELECTED_INVOICE_REQUEST`;
export const LOAD_SELECTED_INVOICE_SUCCESS = `${PATH}LOAD_SELECTED_INVOICE_SUCCESS`;
export const LOAD_INVOICE_ADD_PRINT_REQUEST = `${PATH}LOAD_INVOICE_ADD_PRINT_REQUEST`;
export const LOAD_INVOICE_SEND_REQUEST = `${PATH}LOAD_INVOICE_SEND_REQUEST`;
export const LOAD_INVOICE_SEND_SUCCESS = `${PATH}LOAD_INVOICE_SEND_SUCCESS`;
export const LOAD_INVOICE_PRINT_BY_ID_REQUEST = `${PATH}LOAD_INVOICE_PRINT_BY_ID_REQUEST`;
export const LOAD_INVOICE_SEND_BY_ID_REQUEST = `${PATH}LOAD_INVOICE_SEND_BY_ID_REQUEST`;
export const LOAD_PAYMENT_TERM_REQUEST = `${PATH}LOAD_PAYMENT_TERM_REQUEST`;
export const LOAD_PAYMENT_TERM_SUCCESS = `${PATH}LOAD_PAYMENT_TERM_SUCCESS`;
export const LOAD_INVOICE_EMAIL_REQUEST = `${PATH}LOAD_INVOICE_EMAIL_REQUEST`;
export const LOAD_INVOICE_EMAIL_SUCCESS = `${PATH}LOAD_INVOICE_EMAIL_SUCCESS`;
export const SEND_INVOICE_EMAIL_REQUEST = `${PATH}SEND_INVOICE_EMAIL_REQUEST`;
export const SAVE_IS_EMAIL_EDITOR_OPEN_STATUS = `${PATH}SAVE_IS_EMAIL_EDITOR_OPEN_STATUS`;
export const LOAD_INVOICE_ADD_SEND_REQUEST = `${PATH}LOAD_INVOICE_ADD_SEND_REQUEST`;
export const SEND_INVOICE_NOTIFICATION_REQUEST = `${PATH}SEND_INVOICE_NOTIFICATION_REQUEST`;
export const LOAD_PAYMENT_METHOD_REQUEST = `${PATH}LOAD_PAYMENT_METHOD_REQUEST`;
export const LOAD_PAYMENT_METHOD_SUCCESS = `${PATH}LOAD_PAYMENT_METHOD_SUCCESS`;
export const LOAD_INVOICE_TIMESHEET_DETAILS_REQUEST = `${PATH}LOAD_INVOICE_TIMESHEET_DETAILS_REQUEST`;
export const LOAD_INVOICE_TIMESHEET_DETAILS_SUCCESS = `${PATH}LOAD_INVOICE_TIMESHEET_DETAILS_SUCCESS`;