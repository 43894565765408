import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import '../../../layout/form/form.css'
import '../../../auth/auth.css'
import { makeSelectTenantBasic } from '../../../../store/selector/tenantSelector';
// import Default from '../../../auth/activate/Default';
import User from '../../../../images/user.png';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import SelectField from '../../../layout/form/SelectField';
import common from '../../../common';
import TextField from '../../../layout/form/TextField';
import { loadEmailClientListError, loadEmailClientListLoading, sendAddNewEmailClientRequest, sendEmailClientSuccess, sendUpdateEmailClientRequest } from '../../../../store/action/emailConfigurationAction';
import Button from '../../../layout/form/Button';
// import SelectField from '../layout/form/SelectField';

class EmailClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmPasswordError: null,
            passwordError: null,
            emailError: null,
            current_item: 'firstStep',
            emailType: null,
            email: null,
            confirmPassword: null,
            password: null,
            error: false,
            initial: true
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        if(prevState.initial && prevProps.selectedEmailType != null){
            return {
                initial: false,
                emailType: prevProps.selectedEmailType
            }
        }
        return null;
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    handleChangeStep = (e) => {

        if (e === "thirdStep") {
            const emailValidate = this.validateEmail(this.state.email)
            if (emailValidate === null) {
                this.setState({
                    emailError: "( Please enter correct email )"
                })
                return
            }
        }
        this.setState({
            current_item: e,
            confirmPasswordError: null,
            passwordError: null
        })
    }

    handleSubmit = (e) => {
        const { password, confirmPassword, emailType, email } = this.state;
        const { emailClientList } = this.props;
        if (e === "fourthStep" && (password === null || password === "")) {
            this.setState({
                passwordError: "( Please enter the password )"
            })
            return
        }

        if (e === "fourthStep" && password !== confirmPassword) {
            this.setState({
                confirmPasswordError: "( Password and confirm password does not match )"
            })
            return
        }
        this.props.dispatch(loadEmailClientListLoading(true))
        const params = {
            serverId: emailType,
            email: email,
            password: password
        }
        if (emailClientList && emailClientList.length > 0) {
            const id = emailClientList[0].id
            params.id = id
            this.props.dispatch(sendUpdateEmailClientRequest(params))
        } else
            this.props.dispatch(sendAddNewEmailClientRequest(params))
        this.setState({
            confirmPasswordError: null,
            passwordError: null
        })
    }

    handleError = () => {
        this.setState({
            confirmPasswordError: null,
            passwordError: null,
            emailError: null,
            current_item: 'firstStep',
            emailType: null,
            email: null,
            confirmPassword: null,
            password: null,
            error: false
        })
    }

    handleSelect = (value, id) => {
        this.setState({
            [id]: value,
            confirmPasswordError: null,
            passwordError: null,
            emailError: null,
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            confirmPasswordError: null,
            passwordError: null,
            emailError: null,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { emailClientSuccess, emailClientListError } = this.props
        if (emailClientSuccess) {
            this.props.dispatch(sendEmailClientSuccess(false))
            this.setState({
                current_item: "fourthStep"
            })
        }
        if (emailClientListError) {
            this.props.dispatch(loadEmailClientListError(false))
            this.setState({
                error: true
            })
        }
    }

    render() {
        const {
            show,
            handleNewClientClose,
            tenantDetails,
            emailServer
        } = this.props;

        const {
            current_item,
            emailType,
            email,
            passwordError,
            confirmPasswordError,
            emailError,
            error
        } = this.state;

        return (
            <dev>
                <BootstrapModal
                    show={show}
                    animation={false}
                    // size="md"
                    dialogClassName="active_modal_width"
                    centered
                >
                    <BootstrapModal.Body>

                        <section className="step-wizard">
                            <ul className="step-wizard-list">
                                <li className={current_item === "firstStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">1</span>
                                    <span className="progress-label">Email Client</span>
                                </li>
                                <li className={current_item === "secondStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">2</span>
                                    <span className="progress-label">Email</span>
                                </li>
                                <li className={current_item === "thirdStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">3</span>
                                    <span className="progress-label">Password</span>
                                </li>
                                <li className={current_item === "fourthStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">4</span>
                                    <span className="progress-label">Success</span>
                                </li>
                                <li className={current_item === "fifthStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">5</span>
                                    <span className="progress-label">Confirm</span>
                                </li>
                            </ul>
                        </section>
                        <div>
                            <div className="vemas_logo">
                                <img alt="vemaslogo" id="vemas_logo" src={tenantDetails && tenantDetails.logo ? tenantDetails.logo : User} />
                            </div>
                            <div style={{ margin: '0 5% 2% 5%' }}>
                                <div style={{ textAlign: 'center', fontWeight: 600, borderBottom: '2px solid #d8a61e', paddingBottom: '5px', marginBottom: '40px', fontSize: "18px" }}>
                                    {tenantDetails && tenantDetails.businessName ? tenantDetails.businessName : null}
                                </div>
                                <div>
                                    {
                                        current_item === "firstStep" ?
                                            <div>
                                                <div style={{ margin: '40px' }}>
                                                    <SelectField
                                                        text="Email type"
                                                        controlId="emailType"
                                                        onChange={(e) => this.handleSelect(e, 'emailType')}
                                                        options={emailServer}
                                                        required={true}
                                                        selectedValue={emailType}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="filter-btns" style={{ margin: '20px 0' }}>
                                                    <ButtonGroup
                                                        primaryBtnClick={() => handleNewClientClose()}
                                                        primaryBtnVariant="outline-primary"
                                                        primaryBtnText='Close'
                                                        secondaryBtnClick={() => this.handleChangeStep("secondStep")}
                                                        secondaryBtnText='Next'
                                                        secondaryBtnVariant="outline-primary"
                                                        secondaryBtnDisabled={emailType === null}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            current_item === "secondStep" ?
                                                <div>
                                                    <div style={{ margin: '40px' }}>
                                                        <SelectField
                                                            text="Email type"
                                                            controlId="emailType"
                                                            options={emailServer}
                                                            selectedValue={emailType}
                                                            disabled
                                                        />
                                                        <TextField
                                                            text="Email"
                                                            controlId="email"
                                                            placeholder="Email"
                                                            type="email"
                                                            onChange={this.handleChange}
                                                            defaultValue={email}
                                                            maxLength={common.maxLengths.email}
                                                            hint={emailError}
                                                        />
                                                    </div>
                                                    <div className="filter-btns" style={{ margin: '20px 0' }}>
                                                        <ButtonGroup
                                                            primaryBtnClick={() => this.handleChangeStep("firstStep")}
                                                            primaryBtnVariant="outline-primary"
                                                            primaryBtnText='Back'
                                                            secondaryBtnClick={() => this.handleChangeStep("thirdStep")}
                                                            secondaryBtnText='Next'
                                                            secondaryBtnVariant="outline-primary"
                                                            secondaryBtnDisabled={email === null}
                                                        />
                                                    </div>
                                                </div> :
                                                current_item === "thirdStep" ?
                                                    <div>
                                                        {error ?
                                                            <div>
                                                                <div style={{ fontSize: "18px", fontWeight: 600, textAlign: 'center' }}>Your email configuration details are incorrect. Please check the details and try again. </div>
                                                                <div className="filter-btns" style={{ margin: '20px 0' }}>
                                                                    <Button
                                                                        onClick={() => this.handleError()}
                                                                        variant="outline-primary"
                                                                        text='Back'
                                                                    />
                                                                </div>
                                                            </div> :
                                                            <div>
                                                                <TextField
                                                                    text="Email"
                                                                    controlId="email"
                                                                    placeholder="Email"
                                                                    type="email"
                                                                    defaultValue={email}
                                                                    disabled
                                                                />
                                                                <TextField
                                                                    text="Password"
                                                                    controlId="password"
                                                                    placeholder="Password"
                                                                    type="password"
                                                                    onBlur={this.handleChange}
                                                                    hint={passwordError}
                                                                />
                                                                <TextField
                                                                    text="Confirm password"
                                                                    controlId="confirmPassword"
                                                                    placeholder="Confirm password"
                                                                    type="password"
                                                                    onBlur={this.handleChange}
                                                                    hint={confirmPasswordError}
                                                                />
                                                                <div className="filter-btns" style={{ margin: '20px 0' }}>
                                                                    <ButtonGroup
                                                                        primaryBtnClick={() => this.handleChangeStep("secondStep")}
                                                                        primaryBtnVariant="outline-primary"
                                                                        primaryBtnText='Back'
                                                                        secondaryBtnClick={() => this.handleSubmit("fourthStep")}
                                                                        secondaryBtnText='Next'
                                                                        secondaryBtnVariant="outline-primary"
                                                                    />
                                                                </div>
                                                            </div>}
                                                    </div> :
                                                    current_item === "fourthStep" ?
                                                        <div>
                                                            <div style={{ fontSize: "18px", fontWeight: 600, textAlign: 'center' }}>We just sent you a test email. Please check your inbox and confirm </div>
                                                            <div className="filter-btns" style={{ margin: '20px 0' }}>
                                                                <ButtonGroup
                                                                    primaryBtnClick={() => this.handleError()}
                                                                    primaryBtnVariant="outline-primary"
                                                                    primaryBtnText='No'
                                                                    secondaryBtnClick={() => this.handleChangeStep("fifthStep")}
                                                                    secondaryBtnText='Confirm'
                                                                    secondaryBtnVariant="outline-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        current_item === "fifthStep" ?
                                                            <div>
                                                                <div style={{ fontSize: "18px", fontWeight: 600, textAlign: 'center' }}>Your email client has been configured correctly. </div>
                                                                <div className="filter-btns" style={{ margin: '20px 0' }}>
                                                                    <Button
                                                                        onClick={() => handleNewClientClose()}
                                                                        variant="outline-primary"
                                                                        text='Close'
                                                                    />
                                                                </div>
                                                            </div>
                                                            : null}
                                </div>
                            </div>
                        </div>
                    </BootstrapModal.Body>
                </BootstrapModal>
            </dev>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    tenantDetails: makeSelectTenantBasic(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default withRouter(compose(withConnect)(EmailClient));