import { createSelector } from 'reselect';

const selectAppBillingDomain = state => state.appBilling;

const makeSelectAppTenantServiceDetails = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('appTenantServiceList') : []
  );

const makeSelectAppBillingLoading = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('isAppBillingLoading') : false
  );

const makeSelectAppBillingError = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('appBillingError') : ''
  );

const makeSelectAppBillingSuccess = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('appBillingSuccess') : ''
  );

const makeSelectPaymentSetupSuccess = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('paymentSetup') : null
  );


const makeSelectAppInvoicePopupOpen = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('isAppInvoiceModalOpen') : false
  );

const makeSelectAppInvoiceList = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('appInvoiceList') : []
  );

const makeSelectAppInvoiceListLoading = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('isAppInvoiceListLoading') : false
  );

const makeSelectAppInvoiceListError = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('appInvoiceListError') : ''
  );

const makeSelectSelectedInvoiceSuccess = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('selectedAppInvoice') : {}
  );

const makeSelectAppInvoicePrintSuccess = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('appInvoicePrint') : ''
  );

const makeSelectAppPaymentMethodSuccess = () =>
  createSelector(
    selectAppBillingDomain,
    appBillingState =>
      appBillingState ? appBillingState.get('appPaymentMethod') : []
  );

export {
  makeSelectAppTenantServiceDetails,
  makeSelectAppBillingLoading,
  makeSelectAppBillingError,
  makeSelectAppBillingSuccess,
  makeSelectPaymentSetupSuccess,
  makeSelectSelectedInvoiceSuccess,
  makeSelectAppInvoiceListError,
  makeSelectAppInvoiceListLoading,
  makeSelectAppInvoiceList,
  makeSelectAppInvoicePopupOpen,
  makeSelectAppInvoicePrintSuccess,
  makeSelectAppPaymentMethodSuccess
}
