const PATH = 'app/Customer';

export const SAVE_IS_CUSTOMER_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_CUSTOMER_MODAL_OPEN_STATUS`;
export const LOAD_CUSTOMER_LIST_REQUEST = `${PATH}LOAD_CUSTOMER_LIST_REQUEST`;
export const LOAD_CUSTOMER_LIST_ERROR = `${PATH}LOAD_CUSTOMER_LIST_ERROR`;
export const LOAD_CUSTOMER_LIST_LOADING = `${PATH}LOAD_CUSTOMER_LIST_LOADING`;
export const LOAD_CUSTOMER_LIST_SUCCESS = `${PATH}LOAD_CUSTOMER_LIST_SUCCESS`;
export const SEND_ADD_NEW_CUSTOMER_REQUEST = `${PATH}SEND_ADD_NEW_CUSTOMER_REQUEST`;
export const SEND_CUSTOMER_SUCCESS = `${PATH}SEND_CUSTOMER_SUCCESS`;
export const SEND_UPDATE_CUSTOMER_REQUEST = `${PATH}SEND_UPDATE_CUSTOMER_REQUEST`;
export const SEND_DELETE_CUSTOMER_REQUEST = `${PATH}SEND_DELETE_CUSTOMER_REQUEST`;
export const LOAD_SELECTED_CUSTOMER_REQUEST = `${PATH}LOAD_SELECTED_CUSTOMER_REQUEST`;
export const LOAD_SELECTED_CUSTOMER_SUCCESS = `${PATH}LOAD_SELECTED_CUSTOMER_SUCCESS`;
export const SEND_CUSTOMER_NOTIFICATION_REQUEST = `${PATH}SEND_CUSTOMER_NOTIFICATION_REQUEST`;