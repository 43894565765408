import React, { Component } from 'react';
import Table from '../../../layout/form/Table';
import Switch from "react-switch";
import Button from '../../../layout/form/Button';

class IntegrationScenario extends Component {


    render() {

        const {
            scenarios,
            getActive,
            handleSaveScenario
        } = this.props;

        const columns = [
            {
                Header: 'Description',
                accessor: 'description',
            }, {
                Header: 'Status',
                accessor: 'status',
                width: "15%",
                Cell: (rowData) => (
                    <div >
                        <Switch
                            checked={rowData.cell.row.original.status}
                            onChange={(e) => getActive(e, rowData.cell.row)}
                            checkedIcon={false}
                            uncheckedIcon={false}
                        // disabled={isMyobDisabled || row.row.original.status === 2 || !userAccess.accountIntergrte}
                        // onColor={row.row.original.status ? '#ff555d' : '#080'}
                        />
                    </div>
                ),
            }
        ]

        return (
            <div>
                
                <div className="body-content">
                    <div className="table-content" >
                        <Table
                            columns={columns}
                            data={scenarios}
                        />
                    </div>
                    <div className="filter-btns">
                        <Button
                            onClick={() => handleSaveScenario()}
                            // primaryBtnIcon='decline'
                            text='Save'
                            icon='save'
                            variant="outline-primary"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default IntegrationScenario;
