import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css'
import ButtonGroup from './ButtonGroup';

const DeleteModal = ({
    show,
    handleDeleteModalClose,
    handleDelete,
    title
}) => (
      <BootstrapModal 
        show={show} 
        onHide={handleDeleteModalClose}
        animation={false}
        // dialogClassName="modal_width"
        centered
        >
        <BootstrapModal.Header>
            <BootstrapModal.Title style={{fontSize:'1.1rem'}}>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body style={{color:'#bb2124'}}> Do you want to delete this? </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <ButtonGroup
                primaryBtnClick={handleDeleteModalClose}
                primaryBtnVariant="outline-primary"
                primaryBtnText='No'
                secondaryBtnClick={handleDelete}
                secondaryBtnText='Yes'                               
                secondaryBtnVariant="outline-primary"  
            />
        </BootstrapModal.Footer>
      </BootstrapModal>
);

DeleteModal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleDelete: PropTypes.any,
    handleDeleteModalClose:PropTypes.any,
    title: PropTypes.any,
}

export default DeleteModal;