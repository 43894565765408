import React from 'react';
import ButtonGroup from '../../layout/form/ButtonGroup';
import Modal from '../../layout/form/Modal';
import DatePicker from '../../layout/form/datePicker';
import SelectField from '../../layout/form/SelectField';
import Table from '../../layout/form/Table';

const Report = ({
    isReportModalOpen,
    handleModalClose,
    handleDateChange,
    handleReport,
    reportToDate,
    reportFromDate,
    reportResource,
    handleSelect,
    title,
    userList,
    columns,
    projectReport,
    ...props
}) => (
    <div>
        <Modal
            show={isReportModalOpen}
            onHide={handleModalClose}
            title={title}
            width='70%'
            body={
                <div style={{ minHeight: '40vh' }}>
                    {/* <h5>Basic Data</h5> */}
                    <form style={{ marginTop: "5px" }}>
                        <div className='row'>
                            <div className="col-sm">
                                <DatePicker
                                    text="From"
                                    controlId="reportFromDate"
                                    onChange={(e) => handleDateChange(e, "reportFromDate")}
                                    value={reportFromDate === null ? '' : new Date(reportFromDate)}
                                // disabled={!modifyTimesheet}
                                // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="To"
                                    controlId="reportToDate"
                                    onChange={(e) => handleDateChange(e, "reportToDate")}
                                    value={reportToDate === null ? '' : new Date(reportToDate)}
                                // disabled={!modifyTimesheet}
                                // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <SelectField
                                    text="Resource"
                                    controlId="reportResource"
                                    onChange={(e) => handleSelect(e, 'reportResource')}
                                    options={
                                        userList && userList.length > 0 ?
                                            userList.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ?
                                                1 : ((b.firstName.toLowerCase() > a.firstName.toLowerCase()) ? -1 : 0)) : []}
                                    required={true}
                                    selectedValue={reportResource}
                                    display='firstName'
                                />
                            </div>
                        </div>
                    </form>
                    <div className="table-content">
                        <Table
                            columns={columns}
                            data={projectReport}
                        />
                    </div>
                </div>
            }
            footer={
                <div>
                    <ButtonGroup
                        primaryBtnClick={handleModalClose}
                        primaryBtnVariant="outline-primary"
                        // primaryBtnText='Cancel'
                        // primaryBtnIcon='decline'
                        primaryBtnText='Go Back'
                        primaryBtnIcon='undo'
                        secondaryBtnClick={handleReport}
                        secondaryBtnText='Run Report'
                        secondaryBtnVariant="outline-primary"
                        secondaryBtnIcon='accept'
                    />
                </div>
            }
        />
    </div>
);

Report.propTs = {
}

export default Report;