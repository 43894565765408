const PATH = 'app/a2aMapping';

export const LOAD_A2A_MAPPING_ERROR = `${PATH}LOAD_A2A_MAPPING_ERROR`;
export const LOAD_A2A_MAPPING_LOADING = `${PATH}LOAD_A2A_MAPPING_LOADING`;
export const LOAD_A2A_MAPPING_SUCCESS = `${PATH}LOAD_A2A_MAPPING_SUCCESS`;
export const LOAD_A2A_EMPLOYEE_REQUEST = `${PATH}LOAD_A2A_EMPLOYEE_REQUEST`;
export const LOAD_A2A_EMPLOYEE_SUCCESS = `${PATH}LOAD_A2A_EMPLOYEE_SUCCESS`;
export const SEND_A2A_EMPLOYEE_REQUEST = `${PATH}SEND_A2A_EMPLOYEE_REQUEST`;
export const LOAD_ACCOUNT_EMPLOYEE_REQUEST = `${PATH}LOAD_ACCOUNT_EMPLOYEE_REQUEST`;
export const LOAD_ACCOUNT_EMPLOYEE_SUCCESS = `${PATH}LOAD_ACCOUNT_EMPLOYEE_SUCCESS`;
export const SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS = `${PATH}SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS`;
export const LOAD_A2A_PAY_TYPE_REQUEST = `${PATH}LOAD_A2A_PAY_TYPE_REQUEST`;
export const LOAD_A2A_PAY_TYPE_SUCCESS = `${PATH}LOAD_A2A_PAY_TYPE_SUCCESS`;
export const SEND_A2A_PAY_TYPE_REQUEST = `${PATH}SEND_A2A_PAY_TYPE_REQUEST`;
export const LOAD_ACCOUNT_PAY_TYPE_REQUEST = `${PATH}LOAD_ACCOUNT_PAY_TYPE_REQUEST`;
export const LOAD_ACCOUNT_PAY_TYPE_SUCCESS = `${PATH}LOAD_ACCOUNT_PAY_TYPE_SUCCESS`;
export const SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS = `${PATH}SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS`;
export const LOAD_ACCOUNT_COMPANY_FILE_REQUEST = `${PATH}LOAD_ACCOUNT_COMPANY_FILE_REQUEST`;
export const LOAD_ACCOUNT_COMPANY_FILE_SUCCESS = `${PATH}LOAD_ACCOUNT_COMPANY_FILE_SUCCESS`;
export const SEND_ACCOUNT_COMPANY_FILE_REQUEST = `${PATH}SEND_ACCOUNT_COMPANY_FILE_REQUEST`;
export const LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_REQUEST = `${PATH}LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_REQUEST`;
export const LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_SUCCESS = `${PATH}LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_SUCCESS`;
export const LOAD_ACCOUNT_CUSTOMER_REQUEST = `${PATH}LOAD_ACCOUNT_CUSTOMER_REQUEST`;
export const LOAD_ACCOUNT_CUSTOMER_SUCCESS = `${PATH}LOAD_ACCOUNT_CUSTOMER_SUCCESS`;
export const SAVE_IS_LOAD_ACCOUNT_CUSTOMER = `${PATH}SAVE_IS_LOAD_ACCOUNT_CUSTOMER`;
export const LOAD_A2A_CUSTOMER_REQUEST = `${PATH}LOAD_A2A_CUSTOMER_REQUEST`;
export const LOAD_A2A_CUSTOMER_SUCCESS = `${PATH}LOAD_A2A_CUSTOMER_SUCCESS`;
export const SEND_A2A_CUSTOMER_REQUEST = `${PATH}SEND_A2A_CUSTOMER_REQUEST`;
export const SEND_A2A_CUSTOMER_SUCCESS = `${PATH}SEND_A2A_CUSTOMER_SUCCESS`;
export const LOAD_A2A_CUSTOMER_LOADING = `${PATH}LOAD_A2A_CUSTOMER_LOADING`;
export const LOAD_A2A_CUSTOMER_ERROR = `${PATH}LOAD_A2A_CUSTOMER_ERROR`;
export const LOAD_A2A_PROJECT_REQUEST = `${PATH}LOAD_A2A_PROJECT_REQUEST`;
export const LOAD_A2A_PROJECT_SUCCESS = `${PATH}LOAD_A2A_PROJECT_SUCCESS`;
export const LOAD_A2A_PROJECT_LOADING = `${PATH}LOAD_A2A_PROJECT_LOADING`;
export const LOAD_A2A_PROJECT_ERROR = `${PATH}LOAD_A2A_PROJECT_ERROR`;
