import React, { Component } from "react";
import common from "../../../common";
// import TextField from '../../../layout/form/TextField';
import SelectField from "../../../layout/form/SelectField";
import Modal from "../../../layout/form/Modal";
import ButtonGroup from "../../../layout/form/ButtonGroup";

function nextTenYears() {
    const years = []
    const date = new Date()
    const year = date.getUTCFullYear()
    for (let i = 0; i < 10; i++) {
        const element = {
            id: year + i,
            description: year + i
        }
        years.push(element)
    }
    return years;
}

class Card extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tenYears: nextTenYears(),
            month:'',
            year:'',
            initial: true
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { data } = prevProps;
        if( prevState.initial && data){
            var year_ = data.methodExpires.substr(data.methodExpires.length - 4);
            var month_ = data.methodExpires.substring(0,2);
            return {
                initial:false,
                year: parseInt(year_),
                month: parseInt(month_)
            }
        }
        return null
    }

    handleChangeSelect = (value, id) => {
        this.setState({
          [id]: value
        })
    }

    render() {
        const {
            isModalOpen,
            handleModalClose,
            handleSaveAddNew
        } = this.props;
        return (
            <div>
                <Modal
                    show={isModalOpen}
                    onHide={handleModalClose}
                    title="Card Information"
                    width='30%'
                    body={
                        <form style={{ margin: "5px 0 50px 0" }}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <SelectField
                                        text="Month"
                                        controlId="month"
                                        onChange={(e) => this.handleChangeSelect(e, 'month')}
                                        options={common.month}
                                        placeholder="month"
                                        selectedValue={this.state.month}
                                    />
                                </div>
                                <div className="col-sm">
                                    <SelectField
                                        text="Year"
                                        controlId="year"
                                        onChange={(e) => this.handleChangeSelect(e, 'year')}
                                        options={this.state.tenYears}
                                        placeholder="year"
                                        selectedValue={this.state.year}
                                    />
                                </div>
                            </div>
                        </form>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />


            </div>
        )
    }
}

export default Card;