import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../../layout/form/form.css';
import ButtonGroup from '../../layout/form/ButtonGroup';

const CopyModal = ({
    show,
    handleCopyModalClose,
    handleCopy,
    title
}) => (
      <BootstrapModal 
        show={show} 
        onHide={handleCopyModalClose}
        animation={false}
        centered
        >
        <BootstrapModal.Header>
            <BootstrapModal.Title style={{fontSize:'1.1rem'}}>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body > Do you want to copy this? </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <ButtonGroup
                primaryBtnClick={handleCopyModalClose}
                primaryBtnVariant="outline-primary"
                primaryBtnText='No'
                secondaryBtnClick={handleCopy}
                secondaryBtnText='Yes'                               
                secondaryBtnVariant="outline-primary"  
            />
        </BootstrapModal.Footer>
      </BootstrapModal>
);

CopyModal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleCopy: PropTypes.any,
    handleCopyModalClose:PropTypes.any,
    title: PropTypes.any,
}

export default CopyModal;