import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import '../layout/form/form.css'
import './auth.css'
import Expired from './activate/Expired';
import ActivationDetails from './activate/ActivationDetails';
import BillingInformation from './activate/BillingInformation';
import Success from './activate/Success';
import { makeSelectPaymentSetupSuccess } from '../../store/selector/appBillingSelector';
import { loadPaymentSetupRequest, loadPaymentSetupSuccess } from '../../store/action/appBillingAction';
import { loadTenantBasicRequest, loadTenantPackageRequest, sendTenantActivateRequest } from '../../store/action/tenantAction';
import { makeSelectTenantActivateError, makeSelectTenantActivateLoading, makeSelectTenantActivateSuccess, makeSelectTenantBasic, makeSelectTenantPackage } from '../../store/selector/tenantSelector';
import Loader from '../layout/form/Loader';
import Default from './activate/Default';
import { loadAppPackageRequest, saveTenantUserExpireStatus } from '../../store/action/action';
import { makeSelectAppBillingTermsList, makeSelectAppPackagesList } from '../../store/selector/selector';
// import SelectField from '../layout/form/SelectField';

class WarningModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_item: 'firstStep',
            trailPeriod: 30,
            trialExpiryDate: new Date(),
            initial: true,
            appPrice: 20,
            isChangePackage: false,
            isChangeBillingTerm: false,
            selectedPackage: 0,
            selectedBillingTerm: 0,
            packageDescription: '',
            billingTermDescription: ''
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { tenantPackage, appPackageList, billingTermsList } = prevProps;
        const { initial } = prevState;
        if (initial && tenantPackage && tenantPackage.id > 0 && appPackageList && appPackageList.length > 0 && billingTermsList && billingTermsList.list && billingTermsList.list.length > 0) {
            const billingTermId = tenantPackage.billingTerm ? tenantPackage.billingTerm : billingTermsList.default
            const package_ = appPackageList.find(e => e.id === tenantPackage.id)
            const billingTerm = billingTermsList.list.find(e => e.id === billingTermId)
            return {
                trailPeriod: tenantPackage.trailPeriod,
                trialExpiryDate: tenantPackage.trialExpiryDate,
                appPrice: tenantPackage.price,
                selectedPackage: tenantPackage.id,
                selectedBillingTerm: billingTermsList.default,
                packageDescription: package_.description,
                billingTermDescription: billingTerm.description,
                initial: false
            }
        }
        return null
    }

    handleChangeStep = (e) => {
        this.setState({
            current_item: e
        })
        if (e === "thirdStep") {
            this.props.dispatch(loadPaymentSetupRequest())
        }
        if (e === "secondStep") {
            this.props.dispatch(loadPaymentSetupSuccess(null))
        }
    }

    handleFirstStep = (e) => {
        this.setState({
            current_item: e
        })
    }

    handleChangePackage = () => {
        this.setState({
            isChangePackage: true
        })
    }

    handleChangePackageClose = () => {
        const { tenantPackage, appPackageList } = this.props;
        const package_ = appPackageList.find(e => e.id === tenantPackage.id)
        this.setState({
            isChangePackage: false,
            selectedPackage: tenantPackage.id,
            packageDescription: package_.description,
        })
    }

    clickPackage = (id) => {
        const { appPackageList } = this.props;
        const package_ = appPackageList.find(e => e.id === id)
        this.setState({
            selectedPackage: id,
            appPrice: package_.price,
            packageDescription: package_.description,
        })
    }

    handlePackage = () => {
        this.setState({
            isChangePackage: false
        })
    }

    handleChangeBillingTerm = () => {
        this.setState({
            isChangeBillingTerm: true
        })
    }

    handleChangeBillingTermClose = () => {
        const { billingTermsList } = this.props
        const billingTerm = billingTermsList.list.find(e => e.id === billingTermsList.default)
        this.setState({
            isChangeBillingTerm: false,
            selectedBillingTerm: billingTermsList.default,
            billingTermDescription: billingTerm.description
        })
    }

    clickBillingTerm = (id) => {
        const { billingTermsList } = this.props;
        const billingTerm = billingTermsList.list.find(e => e.id === id)
        this.setState({
            selectedBillingTerm: id,
            billingTermDescription: billingTerm.description
        })
    }

    handleBillingTerm = () => {
        this.setState({
            isChangeBillingTerm: false,
        })
    }

    handleSubmitPaymentMethod = async (event, stripe, elements) => {
        event.preventDefault();

        const { paymentSetup } = this.props;

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            clientSecret: paymentSetup.setupIntentClientSecret,
            redirect: 'if_required'
        })

        if (error) {
            this.setState({ isValidationError: true, errorMassage: error.message })
        } else {
            this.props.dispatch(loadPaymentSetupSuccess(null))
            const parms = {
                setupIntent: paymentSetup.setupIntent,
                paymentSetupId: paymentSetup.id,
                billingTermId: this.state.selectedBillingTerm,
                packageId: this.state.selectedPackage
            }
            this.props.dispatch(sendTenantActivateRequest(parms))
            this.setState({
                current_item: "fourthStep"
            })
        }
    };

    handleActivationSuccess = () => {
        this.props.dispatch(saveTenantUserExpireStatus(false))
        this.props.history.push("/dashboard")
    }

    componentDidMount() {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantBasicRequest(id))
        this.props.dispatch(loadAppPackageRequest())
        this.props.dispatch(loadTenantPackageRequest())
    }

    render() {
        const {
            show,
            handleWarningModalClose,
            paymentSetup,
            tenantActivateLoading,
            tenantActivateSuccess,
            appPackageList,
            billingTermsList
        } = this.props;

        const {
            current_item,
            trialExpiryDate,
            trailPeriod,
            appPrice,
            isChangePackage,
            isChangeBillingTerm,
            selectedPackage,
            selectedBillingTerm,
            packageDescription,
            billingTermDescription
        } = this.state;

        return (
            <dev>
                {tenantActivateLoading ?
                    <Loader show={tenantActivateLoading} /> : ''}
                <BootstrapModal
                    show={show}
                    animation={false}
                    // size="md"
                    dialogClassName="active_modal_width"
                    centered
                >
                    <BootstrapModal.Body>

                        <section className="step-wizard">
                            <ul className="step-wizard-list">
                                <li className={current_item === "firstStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">1</span>
                                    <span className="progress-label">Activate</span>
                                </li>
                                <li className={current_item === "secondStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">2</span>
                                    <span className="progress-label">Billing Info</span>
                                </li>
                                <li className={current_item === "thirdStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">3</span>
                                    <span className="progress-label">Payment Method</span>
                                </li>
                                <li className={tenantActivateSuccess !== null && current_item === "fourthStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">4</span>
                                    <span className="progress-label">Success</span>
                                </li>
                            </ul>
                        </section>
                        {
                            current_item === "firstStep" ?
                                <Expired
                                    handleWarningModalClose={handleWarningModalClose}
                                    handleChangeStep={this.handleChangeStep}
                                    trialExpiryDate={trialExpiryDate}
                                    trailPeriod={trailPeriod}
                                /> :
                                current_item === "secondStep" ?
                                    <ActivationDetails
                                        handleChangeStep={this.handleChangeStep}
                                        handleFirstStep={this.handleFirstStep}
                                        appPrice={appPrice}
                                        isChangePackage={isChangePackage}
                                        handleChangePackage={this.handleChangePackage}
                                        handleChangePackageClose={this.handleChangePackageClose}
                                        handleChangeBillingTerm={this.handleChangeBillingTerm}
                                        appPackageList={appPackageList}
                                        isChangeBillingTerm={isChangeBillingTerm}
                                        handleChangeBillingTermClose={this.handleChangeBillingTermClose}
                                        billingTermsList={billingTermsList}
                                        clickPackage={this.clickPackage}
                                        handlePackage={this.handlePackage}
                                        selectedPackage={selectedPackage}
                                        clickBillingTerm={this.clickBillingTerm}
                                        selectedBillingTerm={selectedBillingTerm}
                                        handleBillingTerm={this.handleBillingTerm}
                                        packageDescription={packageDescription}
                                        billingTermDescription={billingTermDescription}
                                    /> :
                                    current_item === "thirdStep" && paymentSetup != null && paymentSetup.setupIntent && paymentSetup.setupIntent != null ?
                                        <BillingInformation
                                            paymentSetup={paymentSetup}
                                            handleChangeStep={this.handleChangeStep}
                                            handleSubmit={this.handleSubmitPaymentMethod}
                                        /> :
                                        tenantActivateSuccess !== null && current_item === "fourthStep" ?
                                            <Success
                                                handleActivationSuccess={this.handleActivationSuccess}
                                            /> : <Default />
                        }
                    </BootstrapModal.Body>
                    {/* <BootstrapModal.Footer>
            <ButtonGroup
                primaryBtnClick={handleWarningModalClose}
                primaryBtnVariant="outline-primary"
                primaryBtnText='Cancel'
                secondaryBtnClick={handleWarning}
                secondaryBtnText='Active'
                secondaryBtnVariant="outline-primary"
            />
        </BootstrapModal.Footer> */}

                </BootstrapModal>
            </dev>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    paymentSetup: makeSelectPaymentSetupSuccess(),
    tenantActivateLoading: makeSelectTenantActivateLoading(),
    tenantActivateError: makeSelectTenantActivateError(),
    tenantActivateSuccess: makeSelectTenantActivateSuccess(),
    tenantDetails: makeSelectTenantBasic(),
    appPackageList: makeSelectAppPackagesList(),
    tenantPackage: makeSelectTenantPackage(),
    billingTermsList: makeSelectAppBillingTermsList()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default withRouter(compose(withConnect)(WarningModal));