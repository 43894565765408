import React, { Component } from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import Button from './Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css'

class SuccessModal extends Component {
    render() {
        const {
            show,
            massage,
            handleClose
        } =this.props
        return (
            <div>
                <BootstrapModal show={show} onHide={handleClose}>
                    <BootstrapModal.Body style={{color:'#22bb33',fontSize:'14px'}}>{massage}</BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <Button 
                        variant="outline-success" 
                        text ='Close'
                        type="submit"
                        onClick={handleClose}
                    />
                    </BootstrapModal.Footer>
                </BootstrapModal>
            </div>
        )
    }
}

SuccessModal.propTypes = {
    show: PropTypes.bool,
    massage: PropTypes.any,
    handleClose: PropTypes.func
}


export default SuccessModal;