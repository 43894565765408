import {
  LOAD_INVOICE_ADD_PRINT_REQUEST,
  LOAD_INVOICE_ADD_SEND_REQUEST,
  LOAD_INVOICE_EMAIL_REQUEST,
  LOAD_INVOICE_EMAIL_SUCCESS,
  LOAD_INVOICE_LIST_ERROR,
  LOAD_INVOICE_LIST_LOADING,
  LOAD_INVOICE_LIST_REQUEST,
  LOAD_INVOICE_LIST_SUCCESS,
  LOAD_INVOICE_PRINT_BY_ID_REQUEST,
  // LOAD_INVOICE_PRINT_BY_ID_SUCCESS,
  LOAD_INVOICE_PRINT_REQUEST,
  LOAD_INVOICE_PRINT_SUCCESS,
  LOAD_INVOICE_SEND_BY_ID_REQUEST,
  // LOAD_INVOICE_SEND_BY_ID_SUCCESS,
  LOAD_INVOICE_SEND_REQUEST,
  LOAD_INVOICE_SEND_SUCCESS,
  LOAD_INVOICE_TIMESHEET_DETAILS_REQUEST,
  LOAD_INVOICE_TIMESHEET_DETAILS_SUCCESS,
  LOAD_PAYMENT_METHOD_REQUEST,
  LOAD_PAYMENT_METHOD_SUCCESS,
  LOAD_PAYMENT_TERM_REQUEST,
  LOAD_PAYMENT_TERM_SUCCESS,
  LOAD_SELECTED_INVOICE_REQUEST,
  LOAD_SELECTED_INVOICE_SUCCESS,
  SAVE_IS_EMAIL_EDITOR_OPEN_STATUS,
  SAVE_IS_INVOICE_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_INVOICE_REQUEST,
  SEND_DELETE_INVOICE_REQUEST,
  SEND_INVOICE_EMAIL_REQUEST,
  SEND_INVOICE_NOTIFICATION_REQUEST,
  SEND_INVOICE_SUCCESS,
  SEND_UPDATE_INVOICE_REQUEST,
} from '../constant/invoiceConstant';

export function saveIsInvoiceModalOpenStatus(open) {
  return {
    type: SAVE_IS_INVOICE_MODAL_OPEN_STATUS,
    open,
  }
};

export function loadInvoiceListRequest(data) {
  return {
    type: LOAD_INVOICE_LIST_REQUEST,
    payload: data,
  }
};

export function loadInvoiceListLoading(data) {
  return {
    type: LOAD_INVOICE_LIST_LOADING,
    payload: data,
  }
};

export function loadInvoiceListSuccess(data) {
  return {
    type: LOAD_INVOICE_LIST_SUCCESS,
    payload: data,
  }
};

export function loadInvoiceListError(data) {
  return {
    type: LOAD_INVOICE_LIST_ERROR,
    payload: data,
  }
};

export function sendAddNewInvoiceRequest(data) {
  return {
    type: SEND_ADD_NEW_INVOICE_REQUEST,
    payload: data,
  }
};

export function sendInvoiceSuccess(data) {
  return {
    type: SEND_INVOICE_SUCCESS,
    payload: data,
  }
};

export function sendUpdateInvoiceRequest(data) {
  return {
    type: SEND_UPDATE_INVOICE_REQUEST,
    payload: data,
  }
};

export function sendDeleteInvoiceRequest(data) {
  return {
    type: SEND_DELETE_INVOICE_REQUEST,
    payload: data,
  }
};

export function loadInvoicePrintRequest(data) {
  return {
    type: LOAD_INVOICE_PRINT_REQUEST,
    payload: data,
  }
};

export function loadInvoicePrintSuccess(data) {
  return {
    type: LOAD_INVOICE_PRINT_SUCCESS,
    payload: data,
  }
};

export function loadSelectedInvoiceRequest(data) {
  return {
    type: LOAD_SELECTED_INVOICE_REQUEST,
    payload: data,
  }
};

export function loadSelectedInvoiceSuccess(data) {
  return {
    type: LOAD_SELECTED_INVOICE_SUCCESS,
    payload: data,
  }
};

export function loadAddAndPrintInvoiceRequest(data) {
  return {
    type: LOAD_INVOICE_ADD_PRINT_REQUEST,
    payload: data,
  }
};

export function loadInvoiceSendRequest(data) {
  return {
    type: LOAD_INVOICE_SEND_REQUEST,
    payload: data,
  }
};

export function loadInvoiceSendSuccess(data) {
  return {
    type: LOAD_INVOICE_SEND_SUCCESS,
    payload: data,
  }
};

export function loadInvoicePrintByIdRequest(data) {
  return {
    type: LOAD_INVOICE_PRINT_BY_ID_REQUEST,
    payload: data,
  }
};

// export function loadInvoicePrintByIdSuccess(data) {
//   return {
//     type: LOAD_INVOICE_PRINT_BY_ID_SUCCESS,
//     payload: data,
//   }
// };

export function loadInvoiceSendByIdRequest(data) {
  return {
    type: LOAD_INVOICE_SEND_BY_ID_REQUEST,
    payload: data,
  }
};

// export function loadInvoiceSendByIdSuccess(data) {
//   return {
//     type: LOAD_INVOICE_SEND_BY_ID_SUCCESS,
//     payload: data,
//   }
// };
export function loadPaymentTermRequest() {
  return {
    type: LOAD_PAYMENT_TERM_REQUEST,
  }
};

export function loadPaymentTermSuccess(data) {
  return {
    type: LOAD_PAYMENT_TERM_SUCCESS,
    payload: data,
  }
};


export function loadInvoiceEmailRequest(data) {
  return {
    type: LOAD_INVOICE_EMAIL_REQUEST,
    payload: data,
  }
};

export function loadInvoiceEmailSuccess(data) {
  return {
    type: LOAD_INVOICE_EMAIL_SUCCESS,
    payload: data,
  }
};

export function sendInvoiceEmailRequest(data) {
  return {
    type: SEND_INVOICE_EMAIL_REQUEST,
    payload: data,
  }
};

export function saveIsEmailEditorOpenStatus(data) {
  return {
    type: SAVE_IS_EMAIL_EDITOR_OPEN_STATUS,
    payload: data,
  }
};

export function loadAddAndSendInvoiceRequest(data) {
  return {
    type: LOAD_INVOICE_ADD_SEND_REQUEST,
    payload: data,
  }
};

export function sendInvoiceNotificationRequest(data) {
  return {
    type: SEND_INVOICE_NOTIFICATION_REQUEST,
    payload: data,
  }
};

export function loadPaymentMethodRequest(data) {
  return {
    type: LOAD_PAYMENT_METHOD_REQUEST,
    payload: data,
  }
};

export function loadPaymentMethodSuccess(data) {
  return {
    type: LOAD_PAYMENT_METHOD_SUCCESS,
    payload: data,
  }
};

export function loadInvoiceTimsheetDetailsRequest(data) {
  return {
    type: LOAD_INVOICE_TIMESHEET_DETAILS_REQUEST,
    payload: data,
  }
};

export function loadInvoiceTimsheetDetailsSuccess(data) {
  return {
    type: LOAD_INVOICE_TIMESHEET_DETAILS_SUCCESS,
    payload: data,
  }
};

