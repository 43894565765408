import { fromJS } from 'immutable';
import {
  LOAD_EMPLOYEE_LIST_ERROR,
  LOAD_EMPLOYEE_LIST_LOADING,
  LOAD_EMPLOYEE_LIST_SUCCESS,
  LOAD_SELECTED_EMPLOYEE_SUCCESS,
  SAVE_IS_EMPLOYEE_MODAL_OPEN_STATUS,
  SEND_EMPLOYEE_SUCCESS,
} from '../constant/employeeConstant';

export const initialState = fromJS({
    isEmployeeModalOpen:false,
    employeeList:[],
    isEmployeeListLoading:false,
    employeeListError:null,
    employeeSuccess:null,
    selectedEmployee:'',
});

function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_EMPLOYEE_MODAL_OPEN_STATUS:
      return state.set('isEmployeeModalOpen', action.open)
    case LOAD_EMPLOYEE_LIST_SUCCESS:
      return state.set('employeeList', action.payload)
    case LOAD_EMPLOYEE_LIST_LOADING:
      return state.set('isEmployeeListLoading', action.payload)
    case LOAD_EMPLOYEE_LIST_ERROR:
      return state.set('employeeListError', action.payload)
    case SEND_EMPLOYEE_SUCCESS:
      return state.set('employeeSuccess', action.payload)
    case LOAD_SELECTED_EMPLOYEE_SUCCESS:
      return state.set('selectedEmployee', action.payload)
    default:
      return state;
  }
}

export default employeeReducer;
