import {
  LOAD_A2A_CUSTOMER_ERROR,
  LOAD_A2A_CUSTOMER_LOADING,
  LOAD_A2A_CUSTOMER_REQUEST,
  LOAD_A2A_CUSTOMER_SUCCESS,
  LOAD_A2A_EMPLOYEE_REQUEST,
  LOAD_A2A_EMPLOYEE_SUCCESS,
  LOAD_A2A_MAPPING_ERROR,
  LOAD_A2A_MAPPING_LOADING,
  LOAD_A2A_MAPPING_SUCCESS,
  LOAD_A2A_PAY_TYPE_REQUEST,
  LOAD_A2A_PAY_TYPE_SUCCESS,
  LOAD_A2A_PROJECT_ERROR,
  LOAD_A2A_PROJECT_LOADING,
  LOAD_A2A_PROJECT_REQUEST,
  LOAD_A2A_PROJECT_SUCCESS,
  LOAD_ACCOUNT_COMPANY_FILE_REQUEST,
  LOAD_ACCOUNT_COMPANY_FILE_SUCCESS,
  LOAD_ACCOUNT_CUSTOMER_REQUEST,
  LOAD_ACCOUNT_CUSTOMER_SUCCESS,
  LOAD_ACCOUNT_EMPLOYEE_REQUEST,
  LOAD_ACCOUNT_EMPLOYEE_SUCCESS,
  LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_REQUEST,
  LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_SUCCESS,
  LOAD_ACCOUNT_PAY_TYPE_REQUEST,
  LOAD_ACCOUNT_PAY_TYPE_SUCCESS,
  SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS,
  SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS,
  SAVE_IS_LOAD_ACCOUNT_CUSTOMER,
  SEND_A2A_CUSTOMER_REQUEST,
  SEND_A2A_CUSTOMER_SUCCESS,
  SEND_A2A_EMPLOYEE_REQUEST,
  SEND_A2A_PAY_TYPE_REQUEST,
  SEND_ACCOUNT_COMPANY_FILE_REQUEST,
} from '../constant/a2aMappingConstant';

export function loadA2AMappingLoading(data) {
  return {
    type: LOAD_A2A_MAPPING_LOADING,
    payload: data,
  }
};

export function loadA2AMappingError(data) {
  return {
    type: LOAD_A2A_MAPPING_ERROR,
    payload: data,
  }
};

export function loadA2AMappingSuccess(data) {
  return {
    type: LOAD_A2A_MAPPING_SUCCESS,
    payload: data,
  }
};

export function saveAccountEmployeeListStatus(data) {
  return {
    type: SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS,
    payload: data,
  }
};

export function loadAccountEmployeeRequest(data) {
  return {
    type: LOAD_ACCOUNT_EMPLOYEE_REQUEST,
    payload: data,
  }
};

export function loadAccountEmployeeSuccess(data) {
  return {
    type: LOAD_ACCOUNT_EMPLOYEE_SUCCESS,
    payload: data,
  }
};

export function loadMappingEmployeeRequest(data) {
  return {
    type: LOAD_A2A_EMPLOYEE_REQUEST,
    payload: data,
  }
};

export function loadMappingEmployeeSuccess(data) {
  return {
    type: LOAD_A2A_EMPLOYEE_SUCCESS,
    payload: data,
  }
};

export function sendMappingEmployeeRequest(data) {
  return {
    type: SEND_A2A_EMPLOYEE_REQUEST,
    payload: data,
  }
};

export function saveAccountPayTypeListStatus(data) {
  return {
    type: SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS,
    payload: data,
  }
};

export function loadAccountPayTypeRequest(data) {
  return {
    type: LOAD_ACCOUNT_PAY_TYPE_REQUEST,
    payload: data,
  }
};

export function loadAccountPayTypeSuccess(data) {
  return {
    type: LOAD_ACCOUNT_PAY_TYPE_SUCCESS,
    payload: data,
  }
};

export function loadMappingPayTypeRequest(data) {
  return {
    type: LOAD_A2A_PAY_TYPE_REQUEST,
    payload: data,
  }
};

export function loadMappingPayTypeSuccess(data) {
  return {
    type: LOAD_A2A_PAY_TYPE_SUCCESS,
    payload: data,
  }
};

export function sendMappingPayTypeRequest(data) {
  return {
    type: SEND_A2A_PAY_TYPE_REQUEST,
    payload: data,
  }
};

export function loadCompanyFileRequest(data) {
  return {
    type: LOAD_ACCOUNT_COMPANY_FILE_REQUEST,
    payload: data,
  }
};

export function loadCompanyFileSuccess(data) {
  return {
    type: LOAD_ACCOUNT_COMPANY_FILE_SUCCESS,
    payload: data,
  }
};

export function sendCompanyFileRequest(data) {
  return {
    type: SEND_ACCOUNT_COMPANY_FILE_REQUEST,
    payload: data,
  }
};

export function loadAccountEmployeeSynchroniseRequest(data) {
  return {
    type: LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_REQUEST,
    payload: data,
  }
};

export function loadAccountEmployeeSynchroniseSuccess(data) {
  return {
    type: LOAD_ACCOUNT_EMPLOYEE_SYNCHRONISE_SUCCESS,
    payload: data,
  }
};


export function loadAccountCustomerRequest(data) {
  return {
    type: LOAD_ACCOUNT_CUSTOMER_REQUEST,
    payload: data,
  }
};

export function loadAccountCustomerSuccess(data) {
  return {
    type: LOAD_ACCOUNT_CUSTOMER_SUCCESS,
    payload: data,
  }
};

export function saveIsAccountCustomerStatus(data) {
  return {
    type: SAVE_IS_LOAD_ACCOUNT_CUSTOMER,
    payload: data,
  }
};

export function loadMappingCustomerRequest(data) {
  return {
    type: LOAD_A2A_CUSTOMER_REQUEST,
    payload: data,
  }
};

export function loadMappingCustomerSuccess(data) {
  return {
    type: LOAD_A2A_CUSTOMER_SUCCESS,
    payload: data,
  }
};

export function sendMappingCustomerRequest(data) {
  return {
    type: SEND_A2A_CUSTOMER_REQUEST,
    payload: data,
  }
};

export function sendMappingCustomerSuccess(data) {
  return {
    type: SEND_A2A_CUSTOMER_SUCCESS,
    payload: data,
  }
};

export function loadA2ACustomerLoading(data) {
  return {
    type: LOAD_A2A_CUSTOMER_LOADING,
    payload: data,
  }
};

export function loadA2ACustomerError(data) {
  return {
    type: LOAD_A2A_CUSTOMER_ERROR,
    payload: data,
  }
};

export function loadMappingProjectRequest(data) {
  return {
    type: LOAD_A2A_PROJECT_REQUEST,
    payload: data,
  }
};

export function loadMappingProjectSuccess(data) {
  return {
    type: LOAD_A2A_PROJECT_SUCCESS,
    payload: data,
  }
};

export function loadA2AProjectLoading(data) {
  return {
    type: LOAD_A2A_PROJECT_LOADING,
    payload: data,
  }
};

export function loadA2AProjectError(data) {
  return {
    type: LOAD_A2A_PROJECT_ERROR,
    payload: data,
  }
};