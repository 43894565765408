import { createSelector } from 'reselect';

const selectTitleDomain = state => state.title;

const makeSelectTitlePopupOpen =() =>
  createSelector(
    selectTitleDomain,
    titleState => 
      titleState ? titleState.get('isAddNewTitleModalOpen') : false
  );

const makeSelectTitleList = () =>
  createSelector(
    selectTitleDomain,
    titleState => 
      titleState ? titleState.get('titleList') : []
  );

const makeSelectTitleListLoading = () =>
  createSelector(
    selectTitleDomain,
    titleState => 
      titleState ? titleState.get('isTitleListLoading') : false
  );

const makeSelectTitleListError = () =>
  createSelector(
    selectTitleDomain,
    titleState => 
      titleState ? titleState.get('titleListError') : ''
  );

const makeSelectTitleSuccess = () =>
  createSelector(
    selectTitleDomain,
    titleState => 
      titleState ? titleState.get('titleSuccess') : ''
  );

export {
    makeSelectTitlePopupOpen,
    makeSelectTitleList,
    makeSelectTitleListLoading,
    makeSelectTitleListError,
    makeSelectTitleSuccess
}
