import React, { Component } from 'react';
import '../settingMenu.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/a2aMappingSelector';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
// import ButtonGroup from '../../../layout/form/ButtonGroup';
import Loader from '../../../layout/form/Loader';
// import SelectField from '../../../layout/form/SelectField';
import ErrorModal from '../../../layout/form/errorModal';
// import SuccessModal from '../../../layout/form/successModal';
import {
  // loadAccountProjectRequest,
  loadMappingProjectRequest,
  // sendMappingProjectRequest,
  // saveIsAccountProjectStatus,
  loadA2AProjectError,
  // sendMappingProjectSuccess,
} from '../../../../store/action/a2aMappingAction';
// import { makeSelectProjectList } from '../../../../store/selector/projectSelector';
import Pagination from '../../../layout/form/Pagination';
// import common from '../../../common';

// const customStyles = {
//   control: base => ({
//     ...base,
//     height: 20,
//     minHeight: 32,
//     marginBottom: 0,
//     marginTop: -20
//   })
// };

// const tenantId = sessionStorage.getItem("tenantId")
class XeroProjectMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contactList: [],
      initial: true,
      pageNumber: 1,
      pageSize: 20
    }
  }

  // static getDerivedStateFromProps(prevProps, prevState) {
  //   const {
  //     isAccountProjectList,
  //     mappingProjectList
  //   } = prevProps;
  //   if (
  //     prevState.initial && isAccountProjectList) {
  //     var contactList = []
  //     var newMappingContactList = []
  //     if (mappingProjectList && mappingProjectList.result && mappingProjectList.result.length && mappingProjectList.result.length > 0) {
  //       for (let q = 0; q < mappingProjectList.result.length; q++) {
  //         const projectItem_ = mappingProjectList.result[q]
  //         const item = {
  //           tenantId: projectItem_.tenantId,
  //           intlProjectId: projectItem_.intlProjectId,
  //           accSystemId: projectItem_.accSystemId,
  //           displayName: projectItem_.displayName,
  //           isChange: false,
  //           extlProjectId: projectItem_.extlProjectId,
  //         }
  //         newMappingContactList.push(item)
  //       }
  //     }

  //     return {
  //       initial: false,
  //       contactList: newMappingContactList.concat(contactList)
  //     }
  //   }
  //   return null
  // }

  componentDidMount() {
    this.getMappingProjectListData()
  }

  // getProjectList = () => {
  //   this.props.dispatch(loadAccountProjectRequest())
  //   this.getMappingProjectListData()
  // }

  getMappingProjectListData = () => {
    const mappingProjectParms = {
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    }
    this.props.dispatch(loadMappingProjectRequest(mappingProjectParms))
  }

  handleProject = (e, rowData) => {
    const { contactList } = this.state;
    var newRow = {
      tenantId: rowData.original.tenantId,
      accSystemId: rowData.original.accSystemId,
      isChange: true,
      intlProjectId: rowData.original.intlProjectId,
      displayName: rowData.original.displayName,
      extlProjectId: e
    }
    contactList[rowData.index] = newRow
    this.setState({
      contactList
    })
  }

  // handleSaveProject = () => {
  //   const { contactList } = this.state;
  //   const Projects = contactList.filter(e => e.isChange === true)
  //   const parms = {
  //     tenantId: parseInt(tenantId),
  //     Projects: Projects,
  //     AccSystemId: common.accounting.xero
  //   }
  //   if (Projects && Projects.length > 0) {
  //     this.props.dispatch(sendMappingProjectRequest(parms))
  //   }
  // }

  handleClose = () => {
    this.setState({
      initial: true
    })
    // this.props.dispatch(saveIsAccountProjectStatus(false))
    this.getProjectList()
  }

  errorModalClose = () => {
    this.props.dispatch(loadA2AProjectError(null))
  }

  // successModalClose = () => {
  //   // this.props.dispatch(saveIsAccountProjectStatus(false))
  //   this.props.dispatch(sendMappingProjectSuccess(null))
  //   this.getProjectList()
  // }

  getNextPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
  }

  getPrevPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
  }

  setClickedPage = (page) => {
    this.setState({
      pageNumber: page
    })
  }

  setPageSize = (size) => {
    this.setState({
      pageSize: size,
      pageNumber: 1
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.mappingProjectSuccess !== null) {
    //   setTimeout(
    //     () => this.successModalClose(),
    //     1000
    //   );
    // }

    if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
      // this.setState({
      //   initial: true
      // })
      this.getMappingProjectListData()
      // this.props.dispatch(saveIsAccountProjectStatus(false))
    }
  }

  render() {
    const {
      mappingProjectList,
      a2aProjectLoading,
      a2aProjectError
    } = this.props;

    const columnsProject = [{
      Header: 'Project ID',
      accessor: 'id',
      width: '15%',
    }, {
      Header: 'Internal Project Name',
      accessor: 'intlProjectName',
    }, {
      Header: 'External Project Name',
      accessor: 'extProjectName',
    }]

    return (
      <div>
        {a2aProjectError !== null &&
          <ErrorModal
            show={true}
            massage={a2aProjectError}
            handleClose={this.errorModalClose}
          />}

        {a2aProjectLoading ?
          <Loader /> : ''}
        <div className="body-content">
          <div className="table-content">
            <Table
              columns={columnsProject}
              data={mappingProjectList && mappingProjectList.result && mappingProjectList.result.length ? mappingProjectList.result : []}
            />
          </div>
          <Pagination
            currentPage={mappingProjectList.page ? mappingProjectList.page : this.state.pageNumber}
            getNextPage={this.getNextPage}
            getPrevPage={this.getPrevPage}
            totalPage={mappingProjectList.totalPages}
            setClickedPage={this.setClickedPage}
            currentPageSize={this.state.pageSize}
            setPageSize={this.setPageSize}
          />
        </div>

      </div>
    )
  }
}
const mapStateToProps = createStructuredSelector({
  mappingProjectList: selectors.makeSelectMappingProject(),
  a2aProjectLoading: selectors.makeSelectA2AProjectLoading(),
  a2aProjectError: selectors.makeSelectA2AProjectError()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(XeroProjectMapping));