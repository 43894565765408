import React, { Component } from 'react';
import '../settingMenu.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/accountSelector';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
// import Table from '../../../layout/form/Table';
// import Button from '../../../layout/form/Button';
import { makeSelectTenantAccountSystemSuccess } from '../../../../store/selector/tenantSelector';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';
import { loadAccountLoading, loadAccountSystemRequest, loadAccountSystemSuccess, sendConnectionRequest, sendConnectionSuccess, sendConnectionUpdateRequest, sendConnectionUpdateSuccess, sendDeleteConnectionRequest } from '../../../../store/action/accountAction';
import { loadTenantAccountSystemRequest, loadTenantAccountSystemSuccess } from '../../../../store/action/tenantAction';
import Loader from '../../../layout/form/Loader';
import { Link } from 'react-router-dom';
// import Cards from '../../../layout/form/Cards'
import Switch from "react-switch";
import common from '../../../common';
import InformationModal from './inforModal';
import moment from 'moment';
import UpdateAccountSystem from './UpdateSystem';

function openInNewTab  (url){
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const date = new Date();
const dateFormat = sessionStorage.getItem("dateFormat")

class Accounting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            myob : {},
            xero: {},
            initial : true,
            activeSystem : null,
            myobStatus : 0,
            xeroStatus: 0,
            isMyobDisabled: false,
            isXeroDisabled: false,
            isActiveFromDateModal: false,
            activeFromDate:date,
            isChecked:false,
            systemId:'',
            myobActiveDate:'',
            xeroActiveDate: '',
            isUpdateAccountSystemModalOpen:false
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { accountSystem, tenantAccountSystem} = prevProps;
        const { initial } = prevState;
        if(accountSystem && accountSystem.length && accountSystem.length > 0 && initial && tenantAccountSystem && tenantAccountSystem.id === 0){
            const activeSystem = accountSystem.find(element => element.systemId === tenantAccountSystem.systemId)
            const myobSystem = accountSystem.find(element => element.systemId === common.accounting.myob)
            const xeroSystem = accountSystem.find(element => element.systemId === common.accounting.xero)
            var myobStatus = 0
            var xeroStatus = 0
            var isMyobDisabled = false
            var isXeroDisabled = false
            var myobActiveDate = ''
            var xeroActiveDate = ''
            if(tenantAccountSystem && tenantAccountSystem.connectionActive !== 0 && tenantAccountSystem.systemId ===  common.accounting.myob){
                myobStatus = tenantAccountSystem.connectionActive
                isMyobDisabled = false
                isXeroDisabled = true
                myobActiveDate = tenantAccountSystem.activeFromDate
            }else if (tenantAccountSystem && tenantAccountSystem.connectionActive !== 0 && tenantAccountSystem.systemId === common.accounting.xero) {
                xeroStatus = tenantAccountSystem.connectionActive
                isMyobDisabled = true
                isXeroDisabled = false
                xeroActiveDate = tenantAccountSystem.activeFromDate
            }
            return {
                initial: false,
                activeSystem : activeSystem && activeSystem.systemId ? activeSystem.systemId : null,
                myob: myobSystem,
                myobStatus: myobStatus,
                isMyobDisabled : isMyobDisabled,
                myobActiveDate: myobActiveDate,
                xero: xeroSystem,
                xeroStatus: xeroStatus,
                isXeroDisabled: isXeroDisabled,
                xeroActiveDate: xeroActiveDate,
            }
        }
        return null
    }

    getActiveFromDate = (value, systemId) => {
        if(value){
            this.setState({
                isActiveFromDateModal:true,
                systemId:systemId
            }) 
        }else{
            this.props.dispatch(sendDeleteConnectionRequest(systemId))
            this.props.dispatch(loadAccountLoading(true))
            this.getAccountDetails()
        }
        
    }

    handleCheck = (e) => {
        this.setState({
            isChecked: e.target.checked
        })
    }

    handleInfoModalClose = () => {
        this.setState({
            isActiveFromDateModal:false,
            systemId:''
        }) 
    }

    handleDateChange = (date) => {
        this.setState({
            activeFromDate:date
        })
    }

    handleConnection = () => {
        const parameters = {
            systemId : this.state.systemId,
            activeFromDate: moment(this.state.activeFromDate).format(common.dateFormat)
        }
        this.props.dispatch(sendConnectionRequest(parameters))
    }

    updateAccountSystemModalOpen = (systemId) => {
        this.setState({
            isUpdateAccountSystemModalOpen:true,
            systemId:systemId
        }) 
    }

    updateAccountSystemModalClose = () => {
        this.setState({
            isUpdateAccountSystemModalOpen:false,
            systemId:''
        }) 
    }

    handleUpdateActiveDate = (details) => {
        const parameters = {
            systemId : this.state.systemId,
            activeFromDate:  moment(details.activeFromDate).format(common.dateFormat)
        }
        this.props.dispatch(sendConnectionUpdateRequest(parameters))
    }

    getAccountDetails = () => {
        this.props.dispatch(loadTenantAccountSystemSuccess(''))
        this.props.dispatch(loadAccountSystemSuccess([]))
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantAccountSystemRequest(id))
        this.props.dispatch(loadAccountSystemRequest())
        this.setState({
            initial:true
        })
    }

    componentDidMount (){
        this.getAccountDetails()
    }

    componentDidUpdate (prevProps, prevState){
        const { accountConnection,accountConnectionUpdate } = this.props
        const { myob,xero } = this.state;
        const id = sessionStorage.getItem("tenantId")
        var baseURI = ""
        if(accountConnection && accountConnection.statusCode && accountConnection.statusCode === 201 ){
            if(accountConnection.result.systemId === common.accounting.myob){
                baseURI = myob.baseURI
                baseURI = baseURI.replace("clientId", myob.clientId)
                baseURI = baseURI.replace("redirectUri", myob.redirectURL)
                baseURI = baseURI.replace("tenantId" , id)
                openInNewTab(baseURI)
            } else if (accountConnection.result.systemId === common.accounting.xero) {
                baseURI = xero.baseURI
                baseURI = baseURI.replace("clientId", xero.clientId)
                baseURI = baseURI.replace("redirectUri", xero.redirectURL)
                baseURI = baseURI.replace("tenantId", id)
                openInNewTab(baseURI)
            } 
            this.props.dispatch(loadAccountLoading(true))
            this.props.dispatch(sendConnectionSuccess(''))
            this.handleInfoModalClose()
            this.setState({
                initial:true
            })
            this.getAccountDetails()
        }
        if(accountConnectionUpdate){
            this.props.dispatch(sendConnectionUpdateSuccess(false))
            this.updateAccountSystemModalClose()
            this.setState({
                initial:true
            }) 
            this.getAccountDetails()
        }
    }

    render() {
        const { 
            isAccountLoading,
            tenantAccountSystem,
            userAccess
        } = this.props;
        const {
            myob,
            myobStatus,
            isMyobDisabled,
            isUpdateAccountSystemModalOpen,
            xero,
            xeroStatus,
            isXeroDisabled,
        } = this.state

        return (
            <div>
                {isAccountLoading ? 
                    <Loader/> :''}

                <div className="content-header">
                    <div className="header-pages">
                        <span>
                            <Link to='./accounting'>
                                <ui5-icon class="samples-margin" name="connected" id="tab-icon"></ui5-icon> Connection
                            </Link>
                        </span>
                        {userAccess && userAccess.accountIntergrte &&  tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.myob && tenantAccountSystem.connectionActive === 1 ? 
                        <span>
                            <Link to='./myob'>
                                <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                            </Link>
                        </span> : ''}
                        {userAccess && userAccess.accountIntergrte &&  tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.xero && tenantAccountSystem.connectionActive === 1 ? 
                        <span>
                            <Link to='./xero'>
                                <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                            </Link>
                        </span> : ''}
                    </div>
                </div>
                <div className="body-content">
                    <div className="table-content" >
                        <div className='row'>
                            <div className='col-sm account_system'>
                                <div className='row' style={{margin:'5px 0'}}>
                                    <div className='col-sm-2'>
                                        <img src={myob.logo} alt={myob.systemName} id="acc_sys_logo_myob"/>
                                    </div>
                                    <div className='col-sm systemName'>
                                        <div>{myob.systemName}</div>
                                    </div>
                                    <div className='col-sm-3 switch-button'>
                                        <div>
                                            <Switch 
                                                checked={myobStatus}
                                                onChange={(e) => this.getActiveFromDate(e,common.accounting.myob)} 
                                                checkedIcon={false} 
                                                uncheckedIcon={false}
                                                disabled = {isMyobDisabled || myobStatus === 2 || !userAccess.accountIntergrte }
                                                onColor={myobStatus === 2 ? '#ff555d':'#080'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='system-description'>{myob.description}</div>
                                <div className='system-date'>
                                    <div>
                                        {myobStatus === 1 ? <span>Active From Date : {moment(this.state.myobActiveDate).format(dateFormat? dateFormat : common.dateFormat)}</span> : ''}
                                    </div>
                                    <div>
                                        {userAccess.accountIntergrte && myobStatus === 1 ?
                                            <span onClick={() => this.updateAccountSystemModalOpen(common.accounting.myob)}><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span> }
                                        {userAccess.accountIntergrte && myobStatus === 2 ?
                                            <span onClick={() => this.getAccountDetails()}><ui5-icon class="samples-margin" name="synchronize" id="account-icon-color"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="synchronize" id="account-icon"></ui5-icon></span> }
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm account_system'>
                                <div className='row' style={{ margin: '5px 0' }}>
                                    <div className='col-sm-2'>
                                        <img src={xero.logo} alt={xero.systemName} id="acc_sys_logo_xero" />
                                    </div>
                                    <div className='col-sm systemName'>
                                        <div>{xero.systemName}</div>
                                    </div>
                                    <div className='col-sm-3 switch-button'>
                                        <div>
                                            <Switch
                                                checked={xeroStatus}
                                                onChange={(e) => this.getActiveFromDate(e, common.accounting.xero)}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                disabled={isXeroDisabled || xeroStatus === 2}
                                                onColor={xeroStatus === 2 ? '#ff555d' : '#080'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='system-description'>{xero.description}</div>
                                <div className='system-date'>
                                    <div>
                                        {xeroStatus === 1 ? <span>Active From Date : {moment(this.state.xeroActiveDate).format(dateFormat ? dateFormat : common.dateFormat)}</span> : ''}
                                    </div>
                                    <div>
                                        {xeroStatus === 1 ?
                                            <span onClick={() => this.updateAccountSystemModalOpen(common.accounting.xero)}><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span>}
                                        {xeroStatus === 2 ?
                                            <span onClick={() => this.getAccountDetails()}><ui5-icon class="samples-margin" name="synchronize" id="account-icon-color"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="synchronize" id="account-icon"></ui5-icon></span>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {this.state.isActiveFromDateModal ? 
                    <InformationModal
                        show={this.state.isActiveFromDateModal}
                        handleInfoModalClose={this.handleInfoModalClose}
                        handleDateChange={this.handleDateChange}
                        activeFromDate={this.state.activeFromDate}
                        isChecked={this.state.isChecked}
                        handleCheck={this.handleCheck}
                        handleSubmit={this.handleConnection}
                    /> : ''}
                {
                    isUpdateAccountSystemModalOpen && 
                    <UpdateAccountSystem
                        isUpdateAccountSystemModalOpen ={isUpdateAccountSystemModalOpen}
                        handleModalClose={this.updateAccountSystemModalClose}
                        activeFromDate={
                            this.state.myobActiveDate !== '' ? 
                                this.state.myobActiveDate : ''
                        }
                        handleSaveAddNew={this.handleUpdateActiveDate}
                    />
                }
            </div>
        )
    }
}

Accounting.propTypes = {
    tenantAccountSystem: PropTypes.any,
    accountSystem: PropTypes.any,
    quickBooksConnection: PropTypes.any,
    isAccountLoading: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
    tenantAccountSystem: makeSelectTenantAccountSystemSuccess(),
    accountSystem: selectors.makeSelectAccountSystem(),
    quickBooksConnection: selectors.makeSelectQuickBooksConnection(),
    isAccountLoading: selectors.makeSelectAccountLoading(),
    accountConnection: selectors.makeSelectAccountConnection(),
    accountConnectionUpdate : selectors.makeSelectAccountConnectionUpdate(),
    userAccess: makeSelectUserAccessSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(Accounting));