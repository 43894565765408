import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import '../pages.css';
import Table from '../../layout/form/Table';
import * as actions from '../../../store/action/reportAction';
import * as selectors from '../../../store/selector/reportSelector';
import moment from 'moment';
import Loader from "../../layout/form/Loader";
import { Link } from 'react-router-dom';
import common from "../../common";
import ButtonGroup from "../../layout/form/ButtonGroup";
import SelectField from '../../layout/form/SelectField';
import DatePicker from '../../layout/form/datePicker';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { makeSelectEmployeeList } from "../../../store/selector/employeeSelector";
import { loadEmployeeListRequest } from "../../../store/action/employeeAction";
import { loadProjectListByStatusRequest, loadProjectListSuccess } from "../../../store/action/projectAction";
import { makeSelectProjectList } from "../../../store/selector/projectSelector";
import { invalidToken, refeshTokenset } from '../../common';
import { saveLoginUser } from '../../../store/action/authAction';
import { getRevenueForcastingReport } from "../../service/projectService";
import { CSVLink } from 'react-csv';
import Button from "../../layout/form/Button";
import { makeSelectUserAccessSuccess } from "../../../store/selector/usersSelector";

var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
var lastDay = new Date(date.getFullYear(), date.getMonth() + 3, 0);

function setMonth(value1, value2) {
    var list = []
    var date1 = new Date(value1)
    var date2 = new Date(value2)
    date2 = new Date(date2.getFullYear(), date2.getMonth(), 1);
    const date1ForecastAccessor = "forecast" + date1.getFullYear() + (date1.getMonth() + 1)
    const date1ActualsAccessor = "actuals" + date1.getFullYear() + (date1.getMonth() + 1)
    const firstElement = {
        Header: date1.getFullYear() + " - " + date1.toLocaleString('default', { month: 'long' }),
        page: 1,
        columns: [
            {
                Header: () => (<div style={{ textAlign: "right" }}>Forecast</div>),
                accessor: date1ForecastAccessor,
                Cell: ((row) => (
                    <div style={{
                        textAlign: "right"
                    }}>
                        {row.row.original[date1ForecastAccessor] ? parseFloat(row.row.original[date1ForecastAccessor]).toFixed(2) : "0.00"}
                    </div>
                ))
            },
            {
                Header: () => (<div style={{ textAlign: "right" }}>Actuals</div>),
                accessor: date1ActualsAccessor,
                Cell: ((row) => (
                    <div style={{
                        textAlign: "right"
                    }}>
                        {row.row.original[date1ActualsAccessor] ? parseFloat(row.row.original[date1ActualsAccessor]).toFixed(2) : "0.00"}
                    </div>
                ))
            }
        ]
    }
    list.push(firstElement)
    var newDate = new Date(date1.getFullYear(), date1.getMonth(), 1);
    for (let i = 1; newDate < date2; i++) {
        newDate = new Date(date1.getFullYear(), date1.getMonth() + i, 1);
        const newDateForecastAccessor = "forecast" + newDate.getFullYear() + (newDate.getMonth() + 1)
        const newDateActualsAccessor = "actuals" + newDate.getFullYear() + (newDate.getMonth() + 1)
        const element = {
            Header: newDate.getFullYear() + " - " + newDate.toLocaleString('default', { month: 'long' }),
            page: i + 1,
            columns: [
                {
                    Header: () => (<div style={{ textAlign: "right" }}>Forecast</div>),
                    accessor: newDateForecastAccessor,
                    Cell: ((row) => (
                        <div style={{
                            textAlign: "right"
                        }}>
                            {row.row.original[newDateForecastAccessor] ? parseFloat(row.row.original[newDateForecastAccessor]).toFixed(2) : "0.00"}
                        </div>
                    ))
                },
                {
                    Header: () => (<div style={{ textAlign: "right" }}>Actuals</div>),
                    accessor: newDateActualsAccessor,
                    Cell: ((row) => (
                        <div style={{
                            textAlign: "right"
                        }}>
                            {row.row.original[newDateActualsAccessor] ? parseFloat(row.row.original[newDateActualsAccessor]).toFixed(2) : "0.00"}
                        </div>
                    ))
                }
            ]
        }
        list.push(element)
    }
    return list;
}

function setCSVHeaders(value1, value2) {
    var list = [{
        label: 'Project',
        key: 'project'
    }, {
        label: 'Resource',
        key: 'resource'
    }, {
        label: 'Rate',
        key: 'rate'
    }]
    var date1 = new Date(value1)
    var date2 = new Date(value2)
    date2 = new Date(date2.getFullYear(), date2.getMonth(), 1);
    const date1ForecastAccessor = "forecast" + date1.getFullYear() + (date1.getMonth() + 1)
    const date1ActualsAccessor = "actuals" + date1.getFullYear() + (date1.getMonth() + 1)
    const forecastElement = {
        label: date1.getFullYear() + " - " + date1.toLocaleString('default', { month: 'long' }) + " Forecast",
        key: date1ForecastAccessor
    }
    const actualsElement = {
        label: date1.getFullYear() + " - " + date1.toLocaleString('default', { month: 'long' }) + " Actuals",
        key: date1ActualsAccessor
    }
    list.push(forecastElement)
    list.push(actualsElement)
    var newDate = new Date(date1.getFullYear(), date1.getMonth(), 1);
    for (let i = 1; newDate < date2; i++) {
        newDate = new Date(date1.getFullYear(), date1.getMonth() + i, 1);
        const newDateForecastAccessor = "forecast" + newDate.getFullYear() + (newDate.getMonth() + 1)
        const newDateActualsAccessor = "actuals" + newDate.getFullYear() + (newDate.getMonth() + 1)
        const forecastElement = {
            label: newDate.getFullYear() + " - " + newDate.toLocaleString('default', { month: 'long' }) + " Forecast",
            key: newDateForecastAccessor
        }
        const actualsElement = {
            label: newDate.getFullYear() + " - " + newDate.toLocaleString('default', { month: 'long' }) + " Actuals",
            key: newDateActualsAccessor
        }
        list.push(forecastElement)
        list.push(actualsElement)
    }
    return list;
}

function setRevenueForcastingData(revenueForcasting) {
    var list = []
    for (let i = 0; i < revenueForcasting.length; i++) {
        const item = revenueForcasting[i]
        const forecastAccessor = "forecast" + item.year + item.month
        const actualsAccessor = "actuals" + item.year + item.month
        const element = list.find(e => e.projectId === item.projectId && e.resourceId === item.resourceId)
        if (list.length > 0 && element && element.projectId > 0) {
            const index = list.indexOf(e => e.projectId === item.projectId && e.resourceId === item.resourceId)
            element[forecastAccessor] = item.forecast
            element[actualsAccessor] = item.actuals
            list[index] = element
        } else {
            var e = {
                [forecastAccessor]: item.forecast,
                [actualsAccessor]: item.actuals,
                project: item.project,
                resource: item.resource,
                rate: item.rate,
                projectId: item.projectId,
                resourceId: item.resourceId
            }
            list.push(e)
        }
    }
    if (list.length > 0) {
        list = list.sort((a, b) => (a.project && a.project.toLowerCase() > b.project && b.project.toLowerCase()) ?
            1 : ((b.project && b.project.toLowerCase() > a.project && a.project.toLowerCase()) ? -1 : 0))
    }
    return list;
}

class RevenueForcasting extends Component {
    constructor(props) {
        super(props);
        const a = setMonth(moment(firstDay).format(common.dateFormat), moment(lastDay).format(common.dateFormat))
        this.state = {
            revenueForcasting: [],
            isFilterFormOpen: false,
            fromDate: moment(firstDay).format(common.dateFormat),
            toDate: moment(lastDay).format(common.dateFormat),
            pageNumber: 1,
            pageSize: 20,
            initial: true,
            monthList: a.slice(0, 4),
            allMonthList: a,
            totalMonth: a.length,
            currntMonth: a.length > 4 ? 4 : a.length,
            firstMonth: 1,
            isPrevMonth: false,
            isNextMonth: a.length > 4 ? true : false,
            resource: '',
            projectId: '',
            csvHeaders: [],
            csvData: []
        }
        this.csvLinkEl = React.createRef();
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial } = prevState;
        const { revenueForcasting } = prevProps;
        var list = []
        if (initial && revenueForcasting && revenueForcasting.result && revenueForcasting.result.length > 0) {
            list = setRevenueForcastingData(revenueForcasting.result)
            return {
                initial: false,
                revenueForcasting: list
            }
        }
        return null
    }


    getReportCSV = () => {
        const parameters = {
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            resource: this.state.resource,
            projectId: this.state.projectId
        }
        return getRevenueForcastingReport(parameters).then(
            (response) => response,
            (err) => err
        )
    }

    downloadReport = async () => {
        this.props.dispatch(actions.loadRevenueForcastingLoading(true));
        const response = await this.getReportCSV();

        if (response && response.data && response.data.statusCode && response.data.statusCode === 200 && response.data.result.errorCode && response.data.result.errorCode === 5000) {
            const massage = 'Data not available for requested period'
            this.props.dispatch(actions.loadRevenueForcastingError(massage))
        }
        else if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
            this.setState({
                csvData: setRevenueForcastingData(response.data.result.result),
                csvHeaders: setCSVHeaders(this.state.fromDate, this.state.toDate),
                // csvData: [
                //     { details: { firstName: 'Ahmed', lastName: 'Tomi' }, job: 'manager' },
                //     { details: { firstName: 'John'} },
                // ],
                // // response.data.result.tableData,
                // csvHeaders: [
                //     { label: 'First Name', key: 'details.firstName' },
                //     { label: 'Last Name', key: 'details.lastName' },
                //     { label: 'Job', key: 'job' },
                // ]
            }, () => {
                setTimeout(() => {
                    this.csvLinkEl.current.link.click();
                });
            });
        }
        else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
            this.props.dispatch(saveLoginUser(false))
            invalidToken()
        }
        else {
            const massage = common.error
            this.props.dispatch(actions.loadRevenueForcastingError(massage))
        }
        if (response && response.data && response.data.result && response.data.result.newAccessToken) {
            refeshTokenset(response.data.result.newAccessToken)
        }
        this.props.dispatch(actions.loadRevenueForcastingLoading(false));
    }

    componentDidMount() {
        const parameters = {
            pageSize: 9999,
            pageNumber: 1,
            userId: '',
            name: '',
            email: '',
            phoneNumber: '',
            status: '',
            mobile: '',
            city: '',
            commonSearch: '',
            statusId: '',
            firstName: '',
            lastName: ''
        }
        this.props.dispatch(loadProjectListSuccess([]))
        this.getRevenueForcasting()
        this.props.dispatch(loadEmployeeListRequest(parameters))
        this.props.dispatch(loadProjectListByStatusRequest("50"))
    }

    getRevenueForcasting = () => {
        const parameters = {
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            resource: this.state.resource,
            projectId: this.state.projectId
        }
        this.props.dispatch(actions.loadRevenueForcastingSuccess([]))
        this.props.dispatch(actions.loadRevenueForcastingRequest(parameters))
        this.setState({
            initial: true,
            revenueForcasting: []
        })
    }

    handleClose = () => {
        this.setState({
            fromDate: moment(firstDay).format(common.dateFormat),
            toDate: moment(lastDay).format(common.dateFormat),
            resource: '',
            projectId: '',
            revenueForcasting: []
        })
        this.props.dispatch(actions.loadRevenueForcastingSuccess([]))
    }

    handleDateChange = (date, id) => {
        if (date === null) {
            return
        }
        else if (id === "fromDate") {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const list = setMonth(moment(firstDay).format(common.dateFormat), this.state.toDate)
            const next = list.length > 4 ? true : false
            const current = list.length > 4 ? 4 : list.length
            const total = list.length
            this.setState({
                [id]: moment(firstDay).format(common.dateFormat),
                monthList: list.slice(0, 4),
                allMonthList: list,
                revenueForcasting: [],
                isPrevMonth: false,
                isNextMonth: next,
                totalMonth: total,
                currntMonth: current,
            })
        } else if (id === "toDate") {
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const list = setMonth(this.state.fromDate, moment(lastDay).format(common.dateFormat))
            const next = list.length > 4 ? true : false
            const current = list.length > 4 ? 4 : list.length
            const total = list.length
            this.setState({
                [id]: moment(lastDay).format(common.dateFormat),
                monthList: list.slice(0, 4),
                allMonthList: list,
                revenueForcasting: [],
                isPrevMonth: false,
                isNextMonth: next,
                currntMonth: current,
                totalMonth: total,
            })
        }
    }

    handleChangeSelect = (id, value) => {
        const list = setMonth(this.state.fromDate, this.state.toDate)
        const next = list.length > 4 ? true : false
        const current = list.length > 4 ? 4 : list.length
        const total = list.length
        this.setState({
            [id]: value,
            monthList: list.slice(0, 4),
            allMonthList: list,
            revenueForcasting: [],
            isPrevMonth: false,
            isNextMonth: next,
            currntMonth: current,
            totalMonth: total,
        })
    }

    setMonthFormat = (value) => {
        const date = moment(value).format('MMMM');
        return date
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    previousMonth = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Previous month
        </Tooltip>
    );

    nextMonth = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Next month
        </Tooltip>
    );

    changeNextMonth = () => {
        const { totalMonth, currntMonth, allMonthList, firstMonth } = this.state;
        this.setState({
            monthList: allMonthList.slice(firstMonth, currntMonth + 1),
            firstMonth: firstMonth + 1,
            currntMonth: currntMonth + 1,
            isNextMonth: totalMonth <= currntMonth + 1 ? false : true,
            isPrevMonth: true
        })
    }

    changePrevMonth = () => {
        const { currntMonth, allMonthList, firstMonth } = this.state;
        this.setState({
            monthList: allMonthList.slice(firstMonth - 1, currntMonth),
            firstMonth: firstMonth - 1,
            currntMonth: currntMonth - 1,
            isNextMonth: true,
            isPrevMonth: firstMonth <= 1 ? false : true
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getRevenueForcasting()
        }
    }

    render() {
        const {
            isRevenueForcastingLoading,
            employeeList,
            projectList,
            userAccess
        } = this.props;
        const {
            monthList,
            revenueForcasting,
            isPrevMonth,
            isNextMonth,
            csvHeaders,
            csvData
        } = this.state;
        const columns = [{
            Header: 'Project',
            columns: [
                {
                    Header: '',
                    accessor: 'project',
                    width: "20%",
                }
            ]
        }, {
            Header: 'Resource',
            columns: [
                {
                    Header: '',
                    accessor: 'resource',
                    width: "20%",
                }
            ]
        }, {
            Header: "Rate",
            columns: [
                {
                    Header: '',
                    accessor: 'rate',
                    width: "5%",
                    Cell: ((row) => (
                        <div style={{
                            textAlign: "right"
                        }}>
                            {row.row.original.rate ? parseFloat(row.row.original.rate).toFixed(2) : "0.00"}
                        </div>
                    ))
                }
            ]
        }]
        return (
            <div>
                {(isRevenueForcastingLoading) &&
                    <Loader show={isRevenueForcastingLoading} />}
                <div className="content-header">
                    <div className="header-pages">
                        <span>
                            {userAccess &&
                                userAccess.displayReport &&
                                <Link to='./revenueForcasting'>
                                    <ui5-icon class="samples-margin" name="manager-insight" id="header-icon"></ui5-icon><span>REVENUE FORCASTING</span>
                                </Link>}
                            {userAccess &&
                                userAccess.displaySalesReport &&
                                <Link to='./salesReports'>
                                    <ui5-icon class="samples-margin" name="monitor-payments" id="header-icon"></ui5-icon><span>SALES REPORT</span>
                                </Link>}
                        </span>

                    </div>
                </div>
                <div className="content-body">
                    <div className="filter-form">
                        <div className="row ">
                            <div className="col-sm">
                                <DatePicker
                                    text="From date"
                                    controlId="fromDate"
                                    onChange={(e) => this.handleDateChange(e, "fromDate")}
                                    value={this.state.fromDate === '' ? '' : new Date(this.state.fromDate)}
                                    required
                                // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="To date"
                                    controlId="toDate"
                                    onChange={(e) => this.handleDateChange(e, "toDate")}
                                    value={this.state.toDate === '' ? '' : new Date(this.state.toDate)}
                                    required
                                // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <SelectField
                                    text="Resource"
                                    onChange={(e) => this.handleChangeSelect("resource", e)}
                                    selectedValue={this.state.resource}
                                    controlId="resource"
                                    options={employeeList && employeeList.result && employeeList.result.length ? employeeList.result : []}
                                    display='firstName'
                                />
                            </div>
                            <div className="col-sm">
                                <SelectField
                                    text="Project"
                                    onChange={(e) => this.handleChangeSelect("projectId", e)}
                                    selectedValue={this.state.projectId}
                                    controlId="projectId"
                                    display="projectName"
                                    options={projectList}
                                />
                            </div>
                        </div>
                        <div className="filter-btns">
                            <ButtonGroup
                                primaryBtnClick={() => this.getRevenueForcasting()}
                                primaryBtnIcon='accept'
                                primaryBtnText='Run Report'
                                primaryBtnVariant="outline-primary"
                                secondaryBtnClick={() => this.downloadReport()}
                                secondaryBtnIcon='email'
                                secondaryBtnText='CSV'
                                secondaryBtnVariant="outline-primary"
                            />
                            <Button
                                onClick={() => this.handleClose()}
                                icon='decline'
                                text='Cancel'
                                variant="outline-primary"
                            />
                        </div>
                    </div>
                    <div className="table-content">
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.previousMonth}
                            >
                                <span className={isPrevMonth ? "header-icon" : "header-icon-disabled"} onClick={isPrevMonth ? () => this.changePrevMonth() : ''}>
                                    <ui5-icon class="samples-margin" name="close-command-field" id="header-icon"></ui5-icon>
                                </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.nextMonth}
                            >
                                <span className={isNextMonth ? "header-icon" : "header-icon-disabled"} onClick={isNextMonth ? () => this.changeNextMonth() : ''}>
                                    <ui5-icon class="samples-margin" name="open-command-field" id="header-icon"></ui5-icon>
                                </span>
                            </OverlayTrigger>
                        </div>
                        <Table
                            columns={columns.concat(monthList)}
                            data={revenueForcasting}
                        />
                    </div>
                </div>
                <CSVLink
                    headers={csvHeaders}
                    filename="Revenue forcasting.csv"
                    data={csvData}
                    ref={this.csvLinkEl}
                />
                {/* <Pagination
                    currentPage={revenueForcasting.page ? revenueForcasting.page :this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {revenueForcasting.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                /> */}
            </div>
        )
    }

}

const mapStateToProps = createStructuredSelector({
    revenueForcasting: selectors.makeSelectRevenueForcasting(),
    isRevenueForcastingLoading: selectors.makeSelectRevenueForcastingLoading(),
    employeeList: makeSelectEmployeeList(),
    projectList: makeSelectProjectList(),
    userAccess: makeSelectUserAccessSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default (compose(withConnect)(RevenueForcasting));