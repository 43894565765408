import { 
  SAVE_ACTIVE_ROUTE,
    SAVE_IS_SIDE_BAR_STATUS,
  } from '../constant/navBarConstant';
  
  export function saveIsSideBarStatus(open){
    return {
      type: SAVE_IS_SIDE_BAR_STATUS,
      open,
    }
  };

  export function saveActiveRoute(data){
    return {
      type: SAVE_ACTIVE_ROUTE,
      data,
    }
  };
  