import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './NavBar.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";

const SideBarIcon = ({
    activeRoute,
    redirectCustomer,
    redirectProject,
    redirectTimesheet,
    redirectEmployee,
    redirectReport,
    userAccess
}) => {

    return (
        <div className='side-bar-icon'>
            {userAccess &&
                (userAccess.displayCustomer ||
                    userAccess.displayInvoice
                ) &&
                <li className='text-box'>
                    <Link
                        onClick={redirectCustomer} to='./customer'
                        className={activeRoute === '#/customer' ? 'nav_text_active' : 'nav_text'}>
                        <ui5-icon class="samples-margin" name="business-by-design" id="side-icon"></ui5-icon>
                    </Link>
                </li>}
            {userAccess &&
                (userAccess.displayProject) &&
                <li className='text-box'>
                    <Link
                        onClick={redirectProject} to='./project'
                        className={activeRoute === '#/project' ? 'nav_text_active' : 'nav_text'}>
                        <ui5-icon class="samples-margin" name="project-definition-triangle" id="side-icon"></ui5-icon>
                    </Link>
                </li>}
            {userAccess &&
                (userAccess.displayTimeSheet) &&
                <li className='text-box'>
                    <Link
                        onClick={redirectTimesheet} to='./timesheet'
                        className={activeRoute === '#/timesheet' ? 'nav_text_active' : 'nav_text'}>
                        <ui5-icon class="samples-margin" name="fob-watch" id="side-icon"></ui5-icon>
                    </Link>
                </li>}
            {userAccess &&
                (userAccess.displayEmployee) &&
                <li className='text-box'>
                    <Link
                        onClick={redirectEmployee} to='./employee'
                        className={activeRoute === '#/employee' ? 'nav_text_active' : 'nav_text'}>
                        <ui5-icon class="samples-margin" name="employee" id="side-icon"></ui5-icon>
                    </Link>
                </li>}
            {userAccess &&
                (userAccess.displayReport) &&
                <li className='text-box'>
                    <Link
                        onClick={redirectReport} to='./revenueForcasting'
                        className={activeRoute === '#/revenueForcasting' ? 'nav_text_active' : 'nav_text'}>
                        <ui5-icon class="samples-margin" name="manager-insight" id="side-icon"></ui5-icon>
                    </Link>
                </li>}
            {userAccess &&
                (userAccess.displaySalesReport) &&
                <li className='text-box'>
                    <Link
                        onClick={redirectReport} to='./salesReports'
                        className={activeRoute === '#/revenueForcasting' ? 'nav_text_active' : 'nav_text'}>
                        <ui5-icon class="samples-margin" name="manager-insight" id="side-icon"></ui5-icon>
                    </Link>
                </li>}

        </div>
    )
}

SideBarIcon.propTypes = {
    isSideBarStatus: PropTypes.bool,
    redirectVehicle: PropTypes.func,
    redirectCustomer: PropTypes.func,
    activeRoute: PropTypes.any,
    redirectDashboard: PropTypes.func,
    redirectCalendar: PropTypes.func,
    redirectSupplier: PropTypes.func,
    redirectNotification: PropTypes.func,
}

export default SideBarIcon;