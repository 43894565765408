import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function getTenantPeriod(){
    return axios.get(baseURL+'tenants/period',{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
  };

export function getReportList(parameters){
    return axios.get(baseURL+'invoices/report?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&startDate='+parameters.startDate+'&endDate='+parameters.endDate+'&groupBy='+parameters.groupBy+'&operation='+parameters.operation, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}