import {
  LOAD_EMAIL_CLIENT_LIST_ERROR,
  LOAD_EMAIL_CLIENT_LIST_LOADING,
  LOAD_EMAIL_CLIENT_LIST_REQUEST,
  LOAD_EMAIL_CLIENT_LIST_SUCCESS,
  LOAD_EMAIL_SERVER_LIST_REQUEST,
  LOAD_EMAIL_SERVER_LIST_SUCCESS,
  SEND_ADD_NEW_EMAIL_CLIENT_REQUEST,
  SEND_DELETE_EMAIL_CLIENT_REQUEST,
  SEND_EMAIL_CLIENT_SUCCESS,
  SEND_UPDATE_EMAIL_CLIENT_REQUEST,
} from '../constant/emailConfigurationConstant';


export function loadEmailClientListRequest(data) {
  return {
    type: LOAD_EMAIL_CLIENT_LIST_REQUEST,
    payload: data,
  }
};

export function loadEmailClientListLoading(data) {
  return {
    type: LOAD_EMAIL_CLIENT_LIST_LOADING,
    payload: data,
  }
};

export function loadEmailClientListSuccess(data) {
  return {
    type: LOAD_EMAIL_CLIENT_LIST_SUCCESS,
    payload: data,
  }
};

export function loadEmailClientListError(data) {
  return {
    type: LOAD_EMAIL_CLIENT_LIST_ERROR,
    payload: data,
  }
};

export function sendAddNewEmailClientRequest(data) {
  return {
    type: SEND_ADD_NEW_EMAIL_CLIENT_REQUEST,
    payload: data,
  }
};

export function sendUpdateEmailClientRequest(data) {
  return {
    type: SEND_UPDATE_EMAIL_CLIENT_REQUEST,
    payload: data,
  }
};

export function sendEmailClientSuccess(data) {
  return {
    type: SEND_EMAIL_CLIENT_SUCCESS,
    payload: data,
  }
};

export function loadEmailServerListRequest(data) {
  return {
    type: LOAD_EMAIL_SERVER_LIST_REQUEST,
    payload: data,
  }
};

export function loadEmailServerListSuccess(data) {
  return {
    type: LOAD_EMAIL_SERVER_LIST_SUCCESS,
    payload: data,
  }
};

export function sendDeleteEmailClientRequest(data) {
  return {
    type: SEND_DELETE_EMAIL_CLIENT_REQUEST,
    payload: data,
  }
};


