import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import React from 'react';
import Modal from '../../../layout/form/Modal';
import ButtonGroup from '../../../layout/form/ButtonGroup';

class CheckoutForm extends React.Component {

  render() {
    const { handleClose, stripe, elements, handleSubmit } = this.props
    return (
      // <form onSubmit={this.handleSubmit}>
      //   <PaymentElement />
      //   <button disabled={!this.props.stripe}>Submit</button>
      // </form>
      <div>
        <Modal
          show={true}
          onHide={handleClose}
          title="Add new payment method"
          width='35%'
          body={
            <div>
              <form>
                <PaymentElement />
              </form>
            </div>
          }
          footer={
            <div>
              <ButtonGroup
                primaryBtnClick={handleClose}
                primaryBtnVariant="outline-primary"
                // primaryBtnText='Cancel'
                // primaryBtnIcon='decline'
                primaryBtnText='Go Back'
                primaryBtnIcon='undo'
                secondaryBtnClick={(e) => handleSubmit(e, stripe, elements)}
                // secondaryBtnDisabled={!stripe}
                secondaryBtnText='Save'
                secondaryBtnVariant="outline-primary"
                secondaryBtnIcon='save'
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default function InjectedCheckoutForm({ clientSecret, handleSubmit, handleClose }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} clientSecret={clientSecret} handleSubmit={handleSubmit} handleClose={handleClose}/>
      )}
    </ElementsConsumer>
  )
}