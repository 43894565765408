import React, { Component } from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../../layout/form/form.css';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import ButtonGroup from '../../layout/form/ButtonGroup';
import SelectField from '../../layout/form/SelectField';
// import TextField from '../../layout/form/TextField';
import TimePicker from '../../layout/form/timePicker';
import Table from '../../layout/form/Table';
// import common from '../../common';
import moment from 'moment';

const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};

function setTotal(list, id) {
    var total = 0
    var breakTime = 0
    var projectActivityTotal = 0
    var projectActivityBreakHours = 0

    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.typeInclude) {
            total = parseFloat(total) + parseFloat(element.time)
            if (element.projectActivityId === id) {
                projectActivityTotal = parseFloat(projectActivityTotal) + parseFloat(element.time)
            }
        } else {
            breakTime = parseFloat(breakTime) + parseFloat(element.time)
            if (element.projectActivityId === id) {
                projectActivityBreakHours = parseFloat(projectActivityBreakHours) + parseFloat(element.time)
            }
        }
    }
    const value = {
        total: total,
        breakTime: breakTime,
        projectActivityTotal: projectActivityTotal,
        projectActivityBreakHours: projectActivityBreakHours
    }
    return value;
}

function calTime(start, end, uom) {
    var time_start = new Date();
    var time_end = new Date();
    var value_start = [];
    var value_end = [];
    var timeDiff = 0
    value_start = start.split(':');
    value_end = end.split(':');
    time_start.setHours(value_start[0], value_start[1], value_start[2], 0)
    time_end.setHours(value_end[0], value_end[1], value_end[2], 0)
    timeDiff = time_end - time_start // millisecond 
    var tempTime = moment.duration(timeDiff);
    var time = tempTime.hours() + tempTime.minutes() / 60;
    if (uom === "H") {
        time = tempTime.hours() + tempTime.minutes() / 60;
    } else if (uom === "MIN")
        time = tempTime.hours() * 60 + tempTime.minutes();
    if (time < 0) {
        time = 0
    }
    return parseFloat(time)
}

function CreateRow(list, projectList, uom) {
    var itemList = []
    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        var timeDisabled = false
        var hourDisabled = true
        var totalTime = 0
        const projectActivity = projectList.find(e => e.id === element.projectActivityId)
        if (projectActivity && projectActivity.projectTimeInputTypeId && projectActivity.projectTimeInputTypeId === 20) { // hours 
            timeDisabled = true
            hourDisabled = false
            totalTime = element.time ? element.time : 0
        } else if (projectActivity && projectActivity.projectTimeInputTypeId && projectActivity.projectTimeInputTypeId === 10) { // start,end time 
            totalTime = calTime(element.startTime, element.endTime, uom)
        }
        const newRow = element
        element.time = totalTime
        element.operation = element.operation && element.operation === "D" ? "D" : "U"
        element.timeDisabled = timeDisabled
        element.hourDisabled = hourDisabled
        itemList.push(newRow)
    }
    return itemList
}

function setStatus (list){
    var status= true;
    if(list.length > 0){
        status = list[0].statusId
    }
    return status;
}


export class TimesheetTimeDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timeDetailList: [],
            tempTimeDetailList: [],
            initial: true,
            isDeleteTimeDetail: false,
            totalTime: 0,
            breakTime: 0,
            timeDetailsStartTime: "06:00:00",
            timeDetailsEndTime: "06:00:00",
            projectActivityBreakHours: 0,
            projectActivityTotalHours: 0,
            selectedProjectActivity: '',
            modifyTimesheet: true
        }

    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial, timeDetailsStartTime, timeDetailsEndTime } = prevState;
        const { timesheetDetails, selectedCellData, summarisedProjectList, selectedTimesheet, tenantDetails,modifyTimesheet } = prevProps;

        if (selectedTimesheet && selectedTimesheet.id > 0 && timesheetDetails && timesheetDetails.length && initial && selectedCellData && selectedCellData.original && selectedCellData.original.projectActivityId) {
            var itemList = []
            var value = {}
            var modifyTimesheet_ = modifyTimesheet
            itemList = CreateRow(timesheetDetails, summarisedProjectList.result, tenantDetails.timeEntryUOMId)
            value = setTotal(itemList, selectedCellData.original.projectActivityId)
            var projectActivity = summarisedProjectList.result.find(e => e.id === selectedCellData.original.projectActivityId)
            var endTime = timeDetailsEndTime
            var startTime = timeDetailsStartTime
            if (itemList.length > 0) {
                const lastRow = itemList[itemList.length - 1]
                startTime = lastRow.endTime
                endTime = lastRow.endTime
            }
            var status = setStatus(itemList)
            if(status === "30"){
                modifyTimesheet_ = false
            }
            return {
                initial: false,
                timeDetailList: itemList,
                totalTime: value.total,
                breakTime: value.breakTime,
                timeDetailsStartTime: startTime,
                timeDetailsEndTime: endTime,
                projectActivityBreakHours: value.projectActivityBreakHours,
                projectActivityTotalHours: value.projectActivityTotal,
                selectedProjectActivity: projectActivity.description,
                modifyTimesheet: modifyTimesheet_
            }
        }
        return null;
    }

    addNewRowMiddle = (row) => {
        var { timeDetailList } = this.state;
        const { selectedCellData, tenantDetails } = this.props
        const selectedProjectActivityId = selectedCellData.original.projectActivityId;
        const { summarisedProjectList } = this.props;
        const index = row.index
        var dayStartTime = "06:00:00"
        var dayEndTime = "06:00:00"
        var timeDisabled = false
        var hourDisabled = true
        if (index > 0) {
            const nextElement = timeDetailList[index]
            const prevElement = timeDetailList[index - 1]
            dayEndTime = nextElement.startTime
            dayStartTime = prevElement.endTime

            if (nextElement.startTime === prevElement.endTime) {
                return;
            }
        }
        const project = summarisedProjectList.result.find(e => e.id === this.props.selectedCellData.original.projectActivityId)
        if (project && project.projectTimeInputTypeId && project.projectTimeInputTypeId === 20) { // hours 
            timeDisabled = true
            hourDisabled = false
        }
        var newRow = {
            type: 0,
            startTime: dayStartTime,
            endTime: dayEndTime,
            time: calTime(dayStartTime, dayEndTime, tenantDetails.timeEntryUOMId),
            operation: "C",
            id: null,
            note: '',
            typeInclude: false,
            projectActivityId: this.props.selectedCellData.original.projectActivityId,
            projectId: project.projectId,
            workingDate: this.props.selectdDate,
            timeDisabled: timeDisabled,
            hourDisabled: hourDisabled,
            unitOfMeasure: tenantDetails.timeEntryUOMId,
            statusId: "10"
        }
        timeDetailList.splice(index, 0, newRow)
        const value = setTotal(timeDetailList, selectedProjectActivityId)
        this.setState({
            timeDetailList,
            totalTime: value.total,
            breakTime: value.breakTime,
            projectActivityBreakHours: value.projectActivityBreakHours,
            projectActivityTotalHours: value.projectActivityTotal
        })
    }

    addNewRow = () => {
        var { timeDetailList, timeDetailsEndTime, timeDetailsStartTime } = this.state;
        const { summarisedProjectList, selectedCellData, tenantDetails } = this.props;
        var timeDisabled = false
        var hourDisabled = true
        const project = summarisedProjectList.result.find(e => e.id === this.props.selectedCellData.original.projectActivityId)
        if (project && project.projectTimeInputTypeId && project.projectTimeInputTypeId === 20) { // hours 
            timeDisabled = true
            hourDisabled = false
        }
        var newRow = {
            type: 0,
            startTime: timeDetailsStartTime,
            endTime: timeDetailsEndTime,
            time: 0,
            operation: "C",
            id: null,
            note: '',
            typeInclude: false,
            projectActivityId: selectedCellData.original.projectActivityId,
            projectId: project.projectId,
            workingDate: this.props.selectdDate,
            timeDisabled: timeDisabled,
            hourDisabled: hourDisabled,
            unitOfMeasure: tenantDetails.timeEntryUOMId,
            statusId: "10"
        }
        timeDetailList.push(newRow)
        this.setState({
            timeDetailList
        })
    }

    handleType = (id, rowData) => {
        const { timeDetailList } = this.state;
        const { selectedCellData } = this.props
        const selectedProjectActivityId = selectedCellData.original.projectActivityId;
        const { timeTypeList } = this.props;
        const element = timeTypeList.find(e => e.id === id)
        const newRow = rowData.original
        newRow.type = id
        newRow.typeInclude = element.include
        newRow.operation = "U"

        timeDetailList[rowData.index] = newRow
        const value = setTotal(timeDetailList, selectedProjectActivityId)
        this.setState({
            timeDetailList,
            totalTime: value.total,
            breakTime: value.breakTime,
            projectActivityBreakHours: value.projectActivityBreakHours,
            projectActivityTotalHours: value.projectActivityTotal
        })
    }

    handleStartTimeChange = (seconds, rowData) => {
        const { timeDetailList, timeDetailsStartTime, timeDetailsEndTime } = this.state;
        const { selectedCellData, tenantDetails } = this.props
        const selectedProjectActivityId = selectedCellData.original.projectActivityId;
        var date = new Date(null);
        var lastRowStartTime = timeDetailsStartTime
        var lastRowEndTime = timeDetailsEndTime
        date.setSeconds(seconds);
        const hhmmssFormat = date.toISOString().substr(11, 8);
        var prevRow = timeDetailList[rowData.index - 1]
        if (timeDetailList.length >= 2 && hhmmssFormat < (prevRow && prevRow.endTime)) {
            return
        }

        const time = calTime(hhmmssFormat, rowData.original.endTime, tenantDetails.timeEntryUOMId)
        const newRow = rowData.original
        newRow.startTime = hhmmssFormat
        newRow.time = time

        if (newRow.endTime < newRow.startTime) {
            newRow.endTime = hhmmssFormat
        }
        if (rowData.index + 1 === timeDetailList.length) {
            lastRowStartTime = hhmmssFormat
            lastRowEndTime = hhmmssFormat
        }
        timeDetailList[rowData.index] = newRow
        const value = setTotal(timeDetailList, selectedProjectActivityId)
        this.setState({
            timeDetailList,
            totalTime: value.total,
            breakTime: value.breakTime,
            timeDetailsEndTime: lastRowEndTime,
            timeDetailsStartTime: lastRowStartTime,
            projectActivityBreakHours: value.projectActivityBreakHours,
            projectActivityTotalHours: value.projectActivityTotal
        })
    }

    handleEndTimeChange = (seconds, rowData) => {
        const { timeDetailList } = this.state;
        const { selectedCellData, tenantDetails } = this.props
        const selectedProjectActivityId = selectedCellData.original.projectActivityId;
        var date = new Date(null);
        date.setSeconds(seconds);
        const hhmmssFormat = date.toISOString().substr(11, 8);
        const row = timeDetailList[rowData.index + 1]
        if (hhmmssFormat > (row && row.startTime) || hhmmssFormat < rowData.original.startTime) {
            return
        }
        const time = calTime(rowData.original.startTime, hhmmssFormat, tenantDetails.timeEntryUOMId)
        const newRow = rowData.original
        newRow.endTime = hhmmssFormat
        newRow.time = time

        timeDetailList[rowData.index] = newRow
        const value = setTotal(timeDetailList, selectedProjectActivityId)
        this.setState({
            timeDetailList,
            totalTime: value.total,
            breakTime: value.breakTime,
            timeDetailsStartTime: hhmmssFormat,
            timeDetailsEndTime: hhmmssFormat,
            projectActivityBreakHours: value.projectActivityBreakHours,
            projectActivityTotalHours: value.projectActivityTotal
        })
    }

    handleHours = (e, rowData) => {
        const { timeDetailList } = this.state;
        const { selectedCellData } = this.props
        const selectedProjectActivityId = selectedCellData.original.projectActivityId;
        const newRow = rowData.original
        newRow.time = e.target.value
        timeDetailList[rowData.index] = newRow
        const value = setTotal(timeDetailList, selectedProjectActivityId)
        this.setState({
            timeDetailList,
            totalTime: value.total,
            breakTime: value.breakTime,
            projectActivityBreakHours: value.projectActivityBreakHours,
            projectActivityTotalHours: value.projectActivityTotal
        })
    }

    handleNote = (e, rowData) => {
        const { timeDetailList } = this.state;
        const newRow = rowData.original
        newRow.note = e.target.value
        timeDetailList[rowData.index] = newRow
        this.setState({
            timeDetailList
        })
    }

    handleDelete = (rowData) => {
        const { timeDetailList } = this.state;
        const newRow = rowData.original
        newRow.operation = "D"
        timeDetailList[rowData.index] = newRow
        this.setState({
            timeDetailList,
            isDeleteTimeDetail: true
        })
    }

    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if (objectStatusList && objectStatusList.length && objectStatusList.length !== 0) {
            const desc = objectStatusList.find(select => select.id === data.statusId)
            return (desc && desc.description ? desc.description : '')
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            timeDetailList,
            isDeleteTimeDetail,
            tempTimeDetailList,
        } = this.state;
        const { selectedCellData } = this.props
        const selectedProjectActivityId = selectedCellData.original.projectActivityId;
        if (isDeleteTimeDetail) {
            const timeList = timeDetailList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = timeDetailList.filter(data => (data.id !== null && data.operation === "D"))
            const value = setTotal(timeList, selectedProjectActivityId)
            this.setState({
                timeDetailList: timeList,
                isDeleteTimeDetail: false,
                tempTimeDetailList: tempTimeDetailList.concat(tempItemList),
                totalTime: value.total,
                breakTime: value.breakTime,
                projectActivityBreakHours: value.projectActivityBreakHours,
                projectActivityTotalHours: value.projectActivityTotal
            })
        }
    }

    render() {
        const {
            show,
            handleClose,
            handleSaveDetails,
            title,
            timeTypeList,
            timesheetDetails,
            summarisedProjectList,
            uomList,
            tenantDetails,
            selectedCellData
        } = this.props;

        const {
            timeDetailList,
            totalTime,
            breakTime,
            tempTimeDetailList,
            projectActivityBreakHours,
            projectActivityTotalHours,
            selectedProjectActivity,
            modifyTimesheet
        } = this.state;

        const columns = [{
            Header: 'Project',
            accessor: 'projectActivityId',
            Cell: (rowData) => (
                <div>
                    {
                        <SelectField
                            controlId="id"
                            // onChange={(e) => this.handleRowProject(e, rowData.cell.row)}
                            options={summarisedProjectList && summarisedProjectList.result && summarisedProjectList.result.length ? summarisedProjectList.result : []}
                            style={customStyles}
                            selectedValue={rowData.cell.row.original.projectActivityId}
                            disabled
                        />}
                </div>
            ),
            width: '12%',
        }, {
            Header: 'Type',
            accessor: 'type',
            width: '15%',
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="type"
                        onChange={(e) => this.handleType(e, rowData.cell.row)}
                        options={timeTypeList && timeTypeList.length > 0 ? timeTypeList : []}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.type}
                        // display='firstName'
                        disabled={!modifyTimesheet || (selectedCellData.original.projectActivityId !== rowData.cell.row.original.projectActivityId)}
                    />
                </div>
            ),
        }, {
            Header: 'Start time',
            accessor: 'startTime',
            width: '9%',
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.timeDisabled ?
                        <BootstrapFormControl disabled size="sm" /> :
                        <div style={{ margin: '10px 0 -10px 0' }}>
                            <TimePicker
                                controlId={rowData.cell.row.original.startTime}
                                onChange={(e) => this.handleStartTimeChange(e, rowData.cell.row)}
                                value={rowData.cell.row.original.startTime}
                                disabled={!modifyTimesheet || rowData.cell.row.original.type === 0 || (selectedCellData.original.projectActivityId !== rowData.cell.row.original.projectActivityId) || rowData.cell.row.original.timeDisabled}
                            />
                        </div>}
                </div>

            ),
        }, {
            Header: 'End time',
            accessor: 'endTime',
            width: '9%',
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.timeDisabled ?
                        <BootstrapFormControl disabled size="sm" /> :
                        <div style={{ margin: '10px 0 -10px 0' }}>
                            <TimePicker
                                controlId={rowData.cell.row.original.endTime}
                                onChange={(e) => this.handleEndTimeChange(e, rowData.cell.row)}
                                value={rowData.cell.row.original.endTime}
                                disabled={!modifyTimesheet || rowData.cell.row.original.type === 0 || (selectedCellData.original.projectActivityId !== rowData.cell.row.original.projectActivityId) || rowData.cell.row.original.timeDisabled}
                            />
                        </div>}
                </div>

            ),
        }, {
            Header: 'UOM',
            accessor: 'unitOfMeasure',
            width: '8%',
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="unitOfMeasure"
                        options={uomList}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.unitOfMeasure}
                        disabled
                    />
                </div>
            ),
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>{tenantDetails && tenantDetails.timeEntryUOMId === "H" ? "Hours" : "Minits"}</div>),
            accessor: 'time',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="total"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.time).toFixed(2)}
                        size="sm"
                        style={{ textAlign: 'right' }}
                        onBlur={(e) => this.handleHours(e, rowData.cell.row)}
                        disabled={!modifyTimesheet || rowData.cell.row.original.type === 0 || (selectedCellData.original.projectActivityId !== rowData.cell.row.original.projectActivityId) || rowData.cell.row.original.hourDisabled}
                    />
                </div>
            )),
        }, {
            Header: 'Note',
            accessor: 'note',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.note}
                        type="text"
                        defaultValue={rowData.cell.row.original.note}
                        size="sm"
                        onBlur={(e) => this.handleNote(e, rowData.cell.row)}
                        disabled={!modifyTimesheet || rowData.cell.row.original.type === 0 || (selectedCellData.original.projectActivityId !== rowData.cell.row.original.projectActivityId)}
                    />
                </div>
            ),
        }, {
            Header: 'Status',
            accessor: 'statusId',
            width: "12%",
            Cell: ((row) => (
                <div style={{ padding: '2px' }}>
                    {row.row.original.statusId === "10" ?
                        <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                        row.row.original.statusId === "60" ?
                            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                            row.row.original.statusId === "30" ?
                                <div style={{ backgroundColor: '#6da4e4', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                row.row.original.statusId === "40" ?
                                    <div style={{ backgroundColor: '#eb7f36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                    row.row.original.statusId === "20" ?
                                        <div style={{ backgroundColor: '#a2eb36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                        <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div>
                    }
                </div>
            )),
        }, {
            Header: 'Action',
            width: '5%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <span onClick={modifyTimesheet && !rowData.cell.row.original.timeDisabled ? () => this.addNewRowMiddle(rowData.row) : ''}><ui5-icon class="samples-margin" name="sys-add" id={modifyTimesheet && !rowData.cell.row.original.timeDisabled ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                        <span onClick={modifyTimesheet && (selectedCellData.original.projectActivityId === rowData.cell.row.original.projectActivityId) ? () => this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyTimesheet && (selectedCellData.original.projectActivityId === rowData.cell.row.original.projectActivityId) ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                <BootstrapModal
                    show={show}
                    onHide={handleClose}
                    animation={false}
                    centered
                >
                    <div style={{ top: '60px', right: 10, left: 'auto', bottom: 'auto', position: "fixed", backgroundColor: '#fff', width: '85%' }}>
                        <BootstrapModal.Header closeButton>
                            <BootstrapModal.Title style={{ fontSize: '1.1rem', color: '#4278b7' }}>Time entry for {title} / {selectedProjectActivity}</BootstrapModal.Title>
                        </BootstrapModal.Header>
                        <BootstrapModal.Body style={{ maxHeight: 'calc(100vh - 200px)', minHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                            <form style={{ marginTop: "5px" }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <Table
                                        columns={columns}
                                        data={timeDetailList.filter(data => (data.operation !== "D"))}
                                    />
                                    <div className="add-icon">
                                        <ui5-icon class="samples-margin" name="sys-add" id={modifyTimesheet ? "table-icon" : "table-icon-disabled"} onClick={modifyTimesheet ? () => this.addNewRow() : ''}></ui5-icon>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', fontSize: '16px', fontWeight: 700, color: '#4278b7' }}>
                                    <div style={{ marginRight: '30px' }}>
                                        <div>Project - </div>
                                        <div>Day - </div>
                                    </div>
                                    <div>
                                        <div>
                                            Total time:
                                        </div>
                                        <div>
                                            Total time:
                                        </div>
                                    </div>
                                    <div style={{ margin: '0 30px 0 5px', textAlign: 'right' }}>
                                        <div>{(projectActivityTotalHours || projectActivityBreakHours) ? parseFloat(projectActivityTotalHours + projectActivityBreakHours).toFixed(2) : "00.00"}</div>
                                        <div>{(totalTime || breakTime) ? parseFloat(totalTime + breakTime).toFixed(2) : "00.00"}</div>
                                    </div>
                                    <div>
                                        <div>
                                            Worked time:
                                        </div>
                                        <div>
                                            Worked time:
                                        </div>
                                    </div>
                                    <div style={{ margin: '0 30px 0 5px', textAlign: 'right' }}>
                                        <div>{projectActivityTotalHours ? parseFloat(projectActivityTotalHours).toFixed(2) : "00.00"}</div>
                                        <div>{totalTime ? parseFloat(totalTime).toFixed(2) : "00.00"}</div>
                                    </div>
                                    <div>
                                        <div>
                                            Break time:
                                        </div>
                                        <div>
                                            Break time:
                                        </div>
                                    </div>
                                    <div style={{ margin: '0 30px 0 5px', textAlign: 'right' }}>
                                        <div>{projectActivityBreakHours ? parseFloat(projectActivityBreakHours).toFixed(2) : "00.00"}</div>
                                        <div> {breakTime ? parseFloat(breakTime).toFixed(2) : "00.00"}</div>
                                    </div>
                                </div>
                            </form>
                        </BootstrapModal.Body>
                        <BootstrapModal.Footer>
                            <ButtonGroup
                                primaryBtnClick={handleClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Close'
                                secondaryBtnClick={() => handleSaveDetails(totalTime, timeDetailList, tempTimeDetailList, selectedCellData.original.projectActivityId)}
                                secondaryBtnText={timesheetDetails.length > 0 ? 'Update' : 'Add'}
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnDisabled={!modifyTimesheet || (totalTime === 0 && timesheetDetails.length === 0)}

                            />
                        </BootstrapModal.Footer>
                    </div>
                </BootstrapModal>
            </div>)
    }
}

TimesheetTimeDetails.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleClose: PropTypes.any,
    title: PropTypes.any,
}

export default TimesheetTimeDetails;