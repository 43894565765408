import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Table from '../../../layout/form/Table';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../store/action/appBillingAction';
import * as selectors from '../../../../store/selector/appBillingSelector';
import { makeSelectAppBillingTermsList, makeSelectUOMList } from '../../../../store/selector/selector';
import common, { numberWithCommas } from '../../../common';
import moment from 'moment';
import { loadAppPackageRequest, saveTenantUserExpireStatus } from '../../../../store/action/action';
import { loadTenantBasicRequest, loadTenantPackageRequest, loadTenantPackageSuccess } from '../../../../store/action/tenantAction';
import { makeSelectTenantActiveStatus, makeSelectTenantBasic, makeSelectTenantPackage } from '../../../../store/selector/tenantSelector';
// import SelectField from '../../../layout/form/SelectField';
// import Switch from "react-switch";
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import Button from '../../../layout/form/Button';
import ActiveModal from './ActiveModal';
import UpdatePackage from './UpdatePackage';
import VemasLogo from '../../../../images/WELAWA.png';

const dateFormat = sessionStorage.getItem("dateFormat")

class AppService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPaymentMethodModalOpen: false,
            initial: true,
            isValidationError: false,
            errorMassage: '',
            isActivate: false,
            billingTerm: '',
            isChangePackage: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial } = prevState;
        const { billingTermsList, tenantPackage } = prevProps;
        if (initial && billingTermsList && billingTermsList.list && billingTermsList.list.length > 0 && tenantPackage && tenantPackage.id > 0) {
            const billingTerm = billingTermsList.list.find(e => e.id === (tenantPackage.billingTerm ? tenantPackage.billingTerm : billingTermsList.default))
            return {
                initial: false,
                billingTerm
            }
        }
        return null;
    }


    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    setUnitOfMeasure = (data) => {
        const { uomList } = this.props;
        if (uomList && uomList.length && uomList.length !== 0) {
            const desc = uomList.find(select => select.id === data.unitOfMeasure)
            return (desc && desc.description ? desc.description : '')
        }
    }

    getAppServiceTenant = () => {
        this.props.dispatch(loadTenantPackageRequest())
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadAppBillingError(''))
    }

    successModalClose = () => {
        this.props.dispatch(loadTenantPackageSuccess(null))
        this.props.dispatch(actions.sendAppBillingSuccess(''))
        this.setState({
            initial: true,
            isChangePackage: false
        })
        this.getAppServiceTenant()
    }

    handleActivate = () => {
        this.setState({
            isActivate: true
        })
    }

    handleActivationSuccess = () => {
        this.props.dispatch(saveTenantUserExpireStatus(false))
        this.handleActivateClose()
    }

    handleActivateClose = () => {
        this.setState({
            isActivate: false
        })
        this.getTenantDetails()
    }

    handleChangePackage = () => {
        this.setState({
            isChangePackage: true
        })
    }

    handleChangePackageClose = () => {
        this.setState({
            isChangePackage: false
        })
    }

    handlePackageUpdate = (details) => {
        const parms = {
            billingTermId: details.selectedBillingTerm,
            packageId: details.selectedPackage
        }
        this.props.dispatch(actions.sendUpdatePackageRequest(parms))
    }

    componentDidMount = () => {
        const id = sessionStorage.getItem("tenantId")
        this.getAppServiceTenant()
        this.props.dispatch(saveTenantUserExpireStatus(false))
        this.props.dispatch(loadTenantBasicRequest(id))
        this.props.dispatch(loadAppPackageRequest())
    }

    getTenantDetails = () => {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantBasicRequest(id))
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.appBillingSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {
        const {
            isAppBillingLoading,
            appBillingError,
            appBillingSuccess,
            tenantDetails,
            tenantPackage
        } = this.props;

        const {
            isValidationError,
            errorMassage,
            isActivate,
            billingTerm,
            isChangePackage
        } = this.state;

        return (
            <div>
                {isAppBillingLoading ?
                    <Loader show={isAppBillingLoading} /> : ''}

                {(isValidationError || appBillingError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : appBillingError}
                        handleClose={this.errorModalClose}
                    />}

                {(appBillingSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={appBillingSuccess}
                        handleClose={this.successModalClose}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <span><Link to='./appBilling'><ui5-icon class="samples-margin" name="crm-service-manager" id="tab-icon"></ui5-icon> App Billing</Link></span>
                        <span><Link to='./appPaymentMethod'><ui5-icon class="samples-margin" name="payment-approval" id="tab-icon"></ui5-icon> Payment method</Link></span>
                        <span><Link to='./appInvoice'><ui5-icon class="samples-margin" name="customer-order-entry" id="tab-icon"></ui5-icon> App Invoice</Link></span>
                    </div>
                </div>
                <div className="content-body">
                    <div>
                        <h5 style={{ marginLeft: '5px' }}>App Billing Information</h5>
                    </div>
                    <div className="vemas_logo">
                        <img alt="vemaslogo" id="vemas_logo" src={VemasLogo} />
                    </div>
                    <div style={{ margin: '-2% 5% 2% 5%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ cursor: 'pointer', textAlign: 'center', padding: '10px', width: '50%' }}>
                                {/* <h4 style={{ color: "orange", fontWeight: 600 }}>
                                    {tenantPackage && tenantPackage.description ? "You have successfully subscribed " + tenantPackage.description + " package" : ""}
                                </h4> */}
                                {
                                    tenantDetails && tenantDetails.statusId === '20' ?
                                        <h4 style={{ color: "orange", fontWeight: 600 }}>You need to activate your account.</h4> :
                                        <h4 style={{ color: "orange", fontWeight: 600 }}>Your account has been activated.</h4>
                                }

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className="confirm-title">
                                        {tenantDetails && tenantDetails.statusId !== "20" && tenantPackage && tenantPackage.activatedDate !== '1/1/0001' ? <p>Activation date</p> : ""}
                                        <p>Package</p>
                                        <p>Subscription fee</p>
                                        <p>Billing term</p>
                                    </div>
                                    <div className="confirm-details">
                                        {tenantDetails && tenantDetails.statusId !== "20" && tenantPackage && tenantPackage.activatedDate !== '1/1/0001' ? <p>: {this.setDateFormat(tenantPackage.activatedDate)}</p> : ""}
                                        <p>: {tenantPackage && tenantPackage.description}</p>
                                        <p>: {tenantPackage && tenantPackage.price ? "$ " + numberWithCommas(parseFloat(tenantPackage && tenantPackage.price).toFixed(2)) : ""} ( Per month)</p>
                                        <p>: {billingTerm !== '' ? billingTerm.description : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', margin: '20px', padding: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <Button
                                variant="primary"
                                text={tenantDetails && tenantDetails.statusId === "20" ? 'Activate' : 'Change Package or Billing Term'}
                                type="submit"
                                onClick={tenantDetails && tenantDetails.statusId === "20" ? () => this.handleActivate() : () => this.handleChangePackage()}
                                maxWidth="350px"
                            />
                        </div>
                    </div>
                </div>
                {
                    isActivate ?
                        <ActiveModal
                            show={isActivate}
                            handleActivateClose={this.handleActivateClose}
                            handleActivationSuccess={this.handleActivationSuccess}
                        /> : null

                }
                {
                    isChangePackage ?
                        <UpdatePackage
                            show={isChangePackage}
                            handleChangePackageClose={this.handleChangePackageClose}
                            handlePackageUpdate={this.handlePackageUpdate}
                        /> : null
                }
            </div>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    appTenantServiceList: selectors.makeSelectAppTenantServiceDetails(),
    isAppBillingLoading: selectors.makeSelectAppBillingLoading(),
    appBillingError: selectors.makeSelectAppBillingError(),
    appBillingSuccess: selectors.makeSelectAppBillingSuccess(),
    billingTermsList: makeSelectAppBillingTermsList(),
    uomList: makeSelectUOMList(),
    isTenantActiveStatus: makeSelectTenantActiveStatus(),
    paymentSetup: selectors.makeSelectPaymentSetupSuccess(),
    tenantDetails: makeSelectTenantBasic(),
    tenantPackage: makeSelectTenantPackage(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default withRouter(compose(withConnect)(AppService));