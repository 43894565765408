import React from 'react'
import './Footer.css';
import hithsaLogo from '../../../images/hithsa2.png';

const Footer = () => {

    return (
        <div className="app-footer">
            <div className="footer-right">
                Copyright © 2021. All rights reserved
            </div>
            <div>
            <img alt="hithsaLogo" id="hithsaLogo" src={hithsaLogo}/>
            </div>
            <div className="footer-left">
                Version 2.0
            </div>
                
        </div>
    )
}

export default Footer;
