import React, { Component } from 'react';
// import Table from '../../../layout/form/Table';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../store/action/emailConfigurationAction';
import * as selectors from '../../../../store/selector/emailConfigurationSelector';
import { loadTenantBasicRequest } from '../../../../store/action/tenantAction';
import { makeSelectTenantBasic } from '../../../../store/selector/tenantSelector';
import Loader from '../../../layout/form/Loader';
// import ErrorModal from '../../../layout/form/errorModal';
// import Button from '../../../layout/form/Button';
import {
    Card,
} from 'react-bootstrap';
import EmailClient from './EmailClient';
import DeleteModal from '../../../layout/form/deleteModal';

class EmailConfiguration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isValidationError: false,
            errorMassage: '',
            isNewClient: false,
            initial: true,
            tenantEmailClient: null,
            selectedEmailType: null,
            oauth2URL: null,
            code: null,
            oauth2RedirectURL: null,
            isNewOauth2Client: false,
            isDeleteModalOpen: false,
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { emailClientList, emailServer } = prevProps;
        const { initial } = prevState;
        if (initial && emailClientList && emailClientList.length > 0 && emailServer && emailServer.length > 0) {
            const emailClient = emailClientList[0]
            const email = emailServer.find(e => parseInt(e.id) === parseInt(emailClient.serverId))
            email.tenantEmail = emailClient.email
            return {
                initial: false,
                tenantEmailClient: email
            }
        }

        return null;
    }

    setDescription = (id) => {
        const { emailServer } = this.props;
        if (emailServer && emailServer.length && emailServer.length !== 0) {
            const desc = emailServer.find(select => parseInt(select.id) === parseInt(id))
            return (desc && desc.description ? desc.description : '')
        }
    }

    getTenantEmailConfig = () => {
        this.props.dispatch(actions.loadEmailClientListRequest())
    }

    errorModalClose = () => {
        this.props.dispatch(actions.loadEmailClientListError(null))
    }

    handleNewClient = (id) => {
        this.setState({
            isNewClient: true,
            selectedEmailType: id
        })
    }

    handleNewClientClose = () => {
        this.setState({
            isNewClient: false,
            selectedEmailType: null
        })
        this.getTenantEmailConfig()
    }

    updateClient = (details) => {
        const id = details.id
        if (details && details.authType === "BASIC") {
            this.handleNewClient(id)
        } else if (details && details.authType === "OAUTH2") {
            this.handleOauth2Client(id)
        }
    }

    handleOauth2Client = (id) => {
        const { emailServer } = this.props
        const tenantId = sessionStorage.getItem("tenantId")
        const server = emailServer.find(select => parseInt(select.id) === parseInt(id))
        var baseURI = server.baseURI
        baseURI = baseURI.replace("clientId", server.clientId)
        baseURI = baseURI.replace("redirectUri", server.redirectURL)
        baseURI = baseURI.replace("tenantId", tenantId)
        // openInNewTab(baseURI)
        this.setState({
            oauth2URL: baseURI,
            oauth2RedirectURL: server.redirectURL,
            selectedEmailType: id
        })
    }

    componentDidMount = () => {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantBasicRequest(id))
        this.props.dispatch(actions.loadEmailServerListRequest())
        this.getTenantEmailConfig()
    }

    getTenantDetails = () => {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantBasicRequest(id))
    }

    openPopup = (url) => {
        const width = 600;
        const height = 700;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const popup = window.open(
            url,
            'Microsoft OAuth',
            `width=${width},height=${height},top=${top},left=${left}`
        );

        const popupTick = setInterval(() => {
            if (!popup || popup.closed || popup.closed === undefined) {
                clearInterval(popupTick);
                return;
            }

            try {
                const popupUrl = popup.location.href;
                if (popupUrl.includes(this.state.oauth2RedirectURL)) {
                    const params = new URLSearchParams(popup.location.search);
                    const code = params.get('code'); // Extract the authorization code

                    if (code) {
                        this.setState({ code: code, isNewOauth2Client: true, oauth2URL: null }); // Save the code in the state
                        popup.close(); // Close the popup after retrieving the code
                        clearInterval(popupTick);
                    }
                }
            } catch (error) {
                // Ignore errors related to cross-origin access until redirected back
            }
        }, 1000); // Poll every second
    };

    handleSubmit = () => {
        const { emailClientList } = this.props
        const params = {
            serverId: this.state.selectedEmailType,
            code: this.state.code
        }
        if (emailClientList && emailClientList.length > 0) {
            const id = emailClientList[0].id
            params.id = id
            this.props.dispatch(actions.sendUpdateEmailClientRequest(params))
        } else
            this.props.dispatch(actions.sendAddNewEmailClientRequest(params))
        this.setState({
            code: null,
            oauth2RedirectURL: null,
            isNewOauth2Client: false
        })

    }

    handleDeleteConfirm = () => {
        this.setState({ isDeleteModalOpen: true })
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false })
    }

    handleDeleteEmail = () => {
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteEmailClientRequest(this.state.tenantEmailClient.id))
    }

    componentDidUpdate(prevProps, prevState) {
        const { isNewOauth2Client } = this.state;
        if (isNewOauth2Client) {
            this.props.dispatch(actions.loadEmailClientListLoading(true))
            this.setState({
                isNewOauth2Client: false
            })
            this.handleSubmit()
        } if (this.props.emailClientSuccess) {
            this.props.dispatch(actions.loadEmailClientListSuccess([]))
            this.props.dispatch(actions.sendEmailClientSuccess(false))
            this.setState({
                tenantEmailClient: null,
                initial: true
            })
            this.getTenantEmailConfig()
        }
    }

    render() {
        const {
            tenantDetails,
            emailServer,
            emailClientList,
            isEmailClientListLoading,
            emailClientListError,
            emailClientSuccess
        } = this.props;

        const {
            isNewClient,
            tenantEmailClient,
            oauth2URL,
            isDeleteModalOpen
        } = this.state;

        return (
            <div>
                {isEmailClientListLoading ?
                    <Loader show={isEmailClientListLoading} /> : ''}

                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteEmail}
                        title={this.setDescription(tenantEmailClient.id)}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <span><ui5-icon class="samples-margin" name="crm-service-manager" id="tab-icon"></ui5-icon> Email Configuration</span>
                    </div>
                </div>
                <div className="content-body">
                    <div className="content-body">
                        <div style={{ padding: '10px' }}>
                            <h5 style={{ color: '#4278b7', fontWeight: 600 }}>Email Configuration</h5>
                            <h6 style={{ fontWeight: 600, marginLeft: '5px' }}>Active client</h6>
                            <div className="method-card-list">
                                <div className="col-sm">
                                    {tenantEmailClient !== null ? <Card className="card" style={{ margin: '10px' }}>
                                        <Card.Body>
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <img src={tenantEmailClient.logo} alt={tenantEmailClient.id} id="email-icon" />
                                                    </div>
                                                    <div className="col-sm" style={{ paddingTop: '20px' }}>
                                                        <div>{this.setDescription(tenantEmailClient.id)}</div>

                                                    </div>
                                                </div>
                                                <div className='row' style={{ color: '#4278b7', cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
                                                    <div onClick={() => this.handleDeleteConfirm()}>REMOVE</div>
                                                </div>

                                            </div>
                                        </Card.Body>
                                    </Card> : ""}
                                </div>
                            </div>
                            <h6 style={{ fontWeight: 600, marginLeft: '5px' }}>Email client</h6>
                            <div className="method-card-list">
                                {emailServer && emailServer.map(e => (
                                    <div className="col-sm">
                                        <Card className="card" style={{ margin: '10px' }}>
                                            <Card.Body>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <img src={e.logo} alt={e.id} id="email-icon" />
                                                        </div>
                                                        <div className="col-sm" style={{ paddingTop: '20px' }}>
                                                            <div>{this.setDescription(e.id)}</div>
                                                        </div>
                                                    </div>
                                                    <div className='row' style={{ color: '#4278b7', cursor: emailClientList && emailClientList.length > 0 ? 'not-allowed' : 'pointer', display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
                                                        <div onClick={emailClientList && emailClientList.length > 0 ? "" : () => this.updateClient(e) }>ACTIVATE</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isNewClient ?
                        <EmailClient
                            show={isNewClient}
                            handleNewClientClose={this.handleNewClientClose}
                            tenantDetails={tenantDetails}
                            emailServer={emailServer}
                            emailClientList={emailClientList}
                            emailClientSuccess={emailClientSuccess}
                            emailClientListError={emailClientListError}
                            selectedEmailType={this.state.selectedEmailType}
                        />
                        : null
                }
                {
                    oauth2URL === null ? null : this.openPopup(oauth2URL)
                    // <iframe title="{title}" src={oauth2URL} height="100%" width="100%" ></iframe>
                }
            </div>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    tenantDetails: makeSelectTenantBasic(),
    emailServer: selectors.makeSelectEmailServerList(),
    emailClientList: selectors.makeSelectEmailClientList(),
    isEmailClientListLoading: selectors.makeSelectEmailClientListLoading(),
    emailClientSuccess: selectors.makeSelectEmailClientSuccess(),
    emailClientListError: selectors.makeSelectEmailClientListError()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default withRouter(compose(withConnect)(EmailConfiguration));