import { put, call } from 'redux-saga/effects';
import {
  getAccountCompanyFileRequest,
  getAccountCustomerRequest,
  getAccountEmployeeRequest,
  getAccountEmployeeSynchroniseRequest,
  getAccountPayTypeRequest,
  getMappingCustomerRequest,
  getMappingEmployeeRequest,
  getMappingPayTypeRequest,
  getMappingProjectRequest,
  sendAccountCompanyFileRequest,
  sendMappingCustomerRequest,
  sendMappingEmployeeRequest,
  sendMappingPayTypeRequest
} from '../service/a2aMappingService';
import * as actions from '../../store/action/a2aMappingAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadAccountEmployeeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(getAccountEmployeeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result.result
      yield put(actions.loadAccountEmployeeSuccess(system))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}


export function* loadMappingEmployeeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    yield put(actions.loadA2AMappingLoading(true));
    const response = yield call(getMappingEmployeeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result
      yield put(actions.loadMappingEmployeeSuccess(system))
      yield put(actions.saveAccountEmployeeListStatus(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AMappingLoading(false));
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2AMappingLoading(false));
  } catch (error) {
    yield put(actions.loadA2AMappingLoading(false));
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* sendUpdateMappingEmployeeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(sendMappingEmployeeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.loadA2AMappingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* loadAccountPayTypeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(getAccountPayTypeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result.result
      yield put(actions.loadAccountPayTypeSuccess(system))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}


export function* loadMappingPayTypeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    yield put(actions.loadA2AMappingLoading(true));
    const response = yield call(getMappingPayTypeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result
      yield put(actions.loadMappingPayTypeSuccess(system))
      yield put(actions.saveAccountPayTypeListStatus(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AMappingLoading(false));
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2AMappingLoading(false));
  } catch (error) {
    yield put(actions.loadA2AMappingLoading(false));
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* sendUpdateMappingPayTypeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(sendMappingPayTypeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.loadA2AMappingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* loadAccountCompanyFileRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    yield put(actions.loadA2AMappingLoading(true));
    const response = yield call(getAccountCompanyFileRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result.result
      yield put(actions.loadCompanyFileSuccess(system))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AMappingLoading(false));
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2AMappingLoading(false));
  } catch (error) {
    yield put(actions.loadA2AMappingLoading(false));
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* sendCompanyFileRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(sendAccountCompanyFileRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.loadA2AMappingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* loadEmployeeSynchroniseRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    yield put(actions.loadA2AMappingLoading(true));
    const response = yield call(getAccountEmployeeSynchroniseRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const result = response.data
      yield put(actions.loadAccountEmployeeSynchroniseSuccess(result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AMappingLoading(false));
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadA2AMappingLoading(false));
  } catch (error) {
    yield put(actions.loadA2AMappingLoading(false));
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}


export function* loadAccountCustomerRequest(data) {
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadA2ACustomerError(''))
  try {
    // yield put(actions.loadA2ACustomerLoading(true));
    const response = yield call(getAccountCustomerRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const system = response.data.result.result
      yield put(actions.loadAccountCustomerSuccess(system))
      //yield put(actions.saveIsAccountProductCategory(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      // yield put(actions.loadA2ACustomerLoading(false));
      const massage = common.error
      yield put(actions.loadA2ACustomerError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadA2ACustomerLoading(false));
  } catch (error) {
    // yield put(actions.loadA2ACustomerLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadA2ACustomerError(massage))
  }
}


export function* loadMappingCustomerRequest(data) {
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadA2ACustomerError(''))
  try {
    yield put(actions.loadA2ACustomerLoading(true));
    const response = yield call(getMappingCustomerRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const system = response.data.result
      yield put(actions.loadMappingCustomerSuccess(system))
      yield put(actions.saveIsAccountCustomerStatus(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2ACustomerLoading(false));
      const massage = common.error
      yield put(actions.loadA2ACustomerError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2ACustomerLoading(false));
  } catch (error) {
    yield put(actions.loadA2ACustomerLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadA2ACustomerError(massage))
  }
}

export function* sendUpdateMappingCustomerRequest(data) {
  // console.log('sendUpdateCustomerRequest')
  yield put(actions.loadA2ACustomerError(''))
  try {
    // yield put(actions.sendUpdateCustomerLoading(true));
    const response = yield call(sendMappingCustomerRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendMappingCustomerSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadA2ACustomerError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateCustomerLoading(false));
  } catch (error) {
    // yield put(actions.sendUpdateCustomerLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadA2ACustomerError(massage))
  }
}

export function* loadMappingProjectRequest(data) {
  yield put(actions.loadA2AProjectError(null))
  try {
    yield put(actions.loadA2AProjectLoading(true));
    const response = yield call(getMappingProjectRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result
      yield put(actions.loadMappingProjectSuccess(system))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AProjectLoading(false));
      const massage = common.error
      yield put(actions.loadA2AProjectError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2AProjectLoading(false));
  } catch (error) {
    yield put(actions.loadA2AProjectLoading(false));
    const massage = common.error
    yield put(actions.loadA2AProjectError(massage))
  }
}

