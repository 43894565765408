import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 32,
      marginBottom: '1rem',
      // marginTop: -20
    })
  };

class SelectField extends React.Component {
    handleChange = value => {
      if(value === null){
        this.props.onChange('')
      }else{
        const {id} = this.props
        this.props.onChange(id ? value[id]: value.id);
      }
    };
  
    render() {
        const { 
            options,
            text,
            required,
            display,
            controlId,
            selectedValue,
            style,
            id,
            disabled,
            ...props
        } = this.props;
      return (
        <div style={{fontSize:'14px'}}>
          <label htmlFor={controlId} style={{fontSize:'14px',fontWeight:500}}>{text} {required ? <span style={{color:'#FF5733'}}>*</span> : ''} </label>
          <Select
          {...props}
            // defaultValue={options.filter(option => option.id === selectedValue)}
            value={selectedValue && options && options.filter(option => option.id === selectedValue)}
            id={controlId}
            options={options}
            onChange={this.handleChange}
            getOptionValue={(option) => (id ? option[id] : option.id)}
            getOptionLabel={(option) => (display ?  option[display] : option.description)}
            styles={style ? style : customStyles}
            isDisabled={disabled}
          />
        </div>
      );
    }
  }
  

SelectField.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.string, 
    options: PropTypes.any,
    display: PropTypes.any,
    required: PropTypes.any,
    selectedValue: PropTypes.any,
    style: PropTypes.any,
    id: PropTypes.any,
    disabled: PropTypes.any
}

export default SelectField;