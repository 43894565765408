import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import Table from '../../../layout/form/Table';
import moment from 'moment';
import common from '../../../common';
import AddTenantHoliday from './TenantHolidayModal';
import { loadTenantCalendarRequest, loadTenantDetailsError, sendAddTenantCalendarRequest, sendDeleteTenantCalendarRequest, sendTenantDetailsSuccess } from '../../../../store/action/tenantAction';
import { makeSelectTenantCalendarSuccess, makeSelectTenantError, makeSelectTenantLoading, makeSelectTenantUpdateSuccess } from '../../../../store/selector/tenantSelector';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DatePicker from '../../../layout/form/datePicker';
import Button from '../../../layout/form/Button';
import DeleteModal from '../../../layout/form/deleteModal';

const dateFormat = sessionStorage.getItem("dateFormat")
const today = new Date()
var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
var endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
class TenantHoliday extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAddNew: false,
            fromDate: moment(startDate).format(common.dateFormat),
            toDate: moment(endDate).format(common.dateFormat),
            isDeleteModalOpen: false,
            selectedRow: {}
        }
    }


    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    handleDateChange = (date, id) => {
        if (date === null && id === "fromDate") {
            const setDate = moment(startDate).format(common.dateFormat);
            this.setState({
                [id]: setDate
            })
        } else if (date === null && id === "toDate") {
            const setDate = moment(endDate).format(common.dateFormat);
            this.setState({
                [id]: setDate
            })
        }
        else {
            const setDate = moment(date).format(common.dateFormat);
            this.setState({
                [id]: setDate
            })
        }
    }

    addNewRow = () => {
        this.setState({
            isAddNew: true
        })
    }

    handleModalClose = () => {
        this.setState({
            isAddNew: false
        })
    }

    handleSaveAddNew = (details) => {
        this.handleModalClose()
        const parameters = {
            holidays: [
                {
                    description: details.description,
                    toDate: details.toDate,
                    fromDate: details.fromDate
                }
            ]
        }
        this.props.dispatch(sendAddTenantCalendarRequest(parameters))
    }

    getTenantCalendar = () => {
        const parameters = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        this.props.dispatch(loadTenantCalendarRequest(parameters))
    }

    errorModalClose = () => {
        this.props.dispatch(loadTenantDetailsError(''))
    }

    successModalClose = () => {
        this.getTenantCalendar()
        this.props.dispatch(sendTenantDetailsSuccess(''))
    }

    handleDeleteConfirm = (data) => {
        this.setState({ isDeleteModalOpen: true, selectedRow: data })
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false, selectedRow: {} })
    }

    handleDeleteDate = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(sendDeleteTenantCalendarRequest(this.state.selectedRow))
    }

    componentDidUpdate() {
        if (this.props.tenantDetailsSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    componentDidMount() {
        this.getTenantCalendar()
    }

    render() {
        const columns = [{
            Header: 'Date',
            accessor: 'date',
            width: "20%",
            Cell: ((row) => (
                <div>
                    {row.row.original.date === '1/1/0001' ? '' : this.setDateFormat(row.row.original.date)}
                </div>
            )),
        }, {
            Header: 'Description',
            accessor: 'description',
            Cell: ((row) => (
                <div>
                    {row.row.original.description}
                </div>
            ))
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span onClick={() => this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]
        const {
            tenantCalendar,
            handleTab,
            handleCalendarTab,
            isTenantDetailsLoading,
            tenantDetailsError,
            tenantDetailsSuccess
        } = this.props;
        const { isAddNew, isDeleteModalOpen, selectedRow } = this.state;
        return (
            <div>
                {(isTenantDetailsLoading) ?
                    <Loader show={isTenantDetailsLoading} /> : ''}

                {(tenantDetailsError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={tenantDetailsError}
                        handleClose={this.errorModalClose}
                    />}

                {(tenantDetailsSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={tenantDetailsSuccess}
                        handleClose={this.successModalClose}
                    />}

                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteDate}
                        title={this.setDateFormat(selectedRow.date)}
                    />}

                <div className="content-header">
                    <div className="header-pages">
                        <span onClick={() => handleTab('basicData', this.state)}><Link><ui5-icon class="samples-margin" name="home" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Basic Data</Link></span>
                        <span onClick={() => handleTab('finance', this.state)}><ui5-icon class="samples-margin" name="customer-financial-fact-sheet" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Finance</span>
                        {/* <span onClick={() => handleTab('correspondence', this.state)}><ui5-icon class="samples-margin" name="letter" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Correspondence</span> */}
                        <span onClick={() => handleTab('openingHours', this.state)}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Calendar</span>
                        {/* <span onClick={() => handleTab('marketing', this.state)}><ui5-icon class="samples-margin" name="official-service" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Marketing</span> */}
                        {/* <span onClick={() => handleTab('generateQR', this.state)}><ui5-icon class="samples-margin" name="bar-code" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> QR</span> */}
                        {/* <span onClick={() => handleTab('compliance', this.state)}><ui5-icon class="samples-margin" name="attachment-zip-file" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Compliance</span> */}
                    </div>
                </div>
                <div className="page_header-title"></div>
                <div className="profile-content">
                    <div className="content-header">
                        <div className="header-pages">
                            <span onClick={() => handleCalendarTab('publicHoliday')}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Public holidays</span>
                            <span onClick={() => handleCalendarTab('tenantHoliday')}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Company holidays</span>
                            <span onClick={() => handleCalendarTab('openingHours')}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Opening Hours</span>
                        </div>
                    </div>
                </div>
                <div className="body-content">
                    <div className="col-sm ">
                        <div className="row">
                            <div className="col-sm">
                                <DatePicker
                                    text="From Date"
                                    controlId="fromDate"
                                    onChange={(e) => this.handleDateChange(e, "fromDate")}
                                    value={new Date(this.state.fromDate)}
                                // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="To Date"
                                    controlId="toDate"
                                    onChange={(e) => this.handleDateChange(e, "toDate")}
                                    value={new Date(this.state.toDate)}
                                // format={dateFormat}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="btnSave">
                        <Button
                            variant="outline-primary"
                            text='Search'
                            onClick={() => this.getTenantCalendar()}
                            icon="search"
                        />
                    </div>
                </div>
                <div className="table-content">
                    <div>
                        <h6 style={{ marginLeft: '5px' }}>Company holidays</h6>
                    </div>
                    <Table
                        columns={columns}
                        data={tenantCalendar && tenantCalendar.result && tenantCalendar.result.length ? tenantCalendar.result : []}
                    />
                    <div className="add-icon" >
                        <ui5-icon class="samples-margin" name="add-activity" id="add-activity" onClick={() => this.addNewRow()}></ui5-icon>
                    </div>
                </div>
                {isAddNew ?
                    <AddTenantHoliday
                        isAddNew={isAddNew}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                    />
                    : ''}
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    tenantCalendar: makeSelectTenantCalendarSuccess(),
    isTenantDetailsLoading: makeSelectTenantLoading(),
    tenantDetailsError: makeSelectTenantError(),
    tenantDetailsSuccess: makeSelectTenantUpdateSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(TenantHoliday));