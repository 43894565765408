import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from './ButtonGroup';
import TextEditor from './TextEditor';
import DatePicker from './datePicker';
import TimePicker from './timePicker';
import TextField from './TextField';
import common from '../../common';
import moment from 'moment';
import Form from 'react-bootstrap/cjs/Form';
import SelectField from './SelectField';

const today = new Date() 
export class Email extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            email:'',
            date:moment(today).format(common.dateTimeFormat),
            requestedTime:moment(today).format(common.timeFormat),
            emailAddress:'',
            emailSubject:'',
            initial:true,
            id:0,
            isScheduled: false,
            emailTemplate: '',
            window: window.location.hash 
        };

    };

    static getDerivedStateFromProps(prevProps,prevState) {
        if(prevProps.data && prevProps.data.id && prevState.initial){
            return {
                emailAddress: prevProps.data.emailAddress ? prevProps.data.emailAddress : prevProps.data.customerEmail ? prevProps.data.customerEmail : '',
                emailSubject: prevProps.data.emailSubject,
                id: prevProps.data.customerId ? prevProps.data.customerId : prevProps.data.id ? prevProps.data.id : 0,
                initial:false
            }
        }
        return null
    }

    onEditorChange  = (editorState) => {
        this.setState({
            email:editorState,
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleDateChange = (date, id) => {
        if(date === null){
            this.setState({
                [id]: moment(today).format(common.dateTimeFormat),
            })
            return
        }
        else{
            this.setState({
                [id]:moment(date).format(common.dateTimeFormat),
            })
        }
    }

    handleTimeChange = (seconds, id) => {
        var date = new Date(null);
        date.setSeconds(seconds);
        var hhmmssFormat = date.toISOString().substr(11, 8);
        this.setState({
            [id]:hhmmssFormat
        })
    }
    
    handleCheck = (e) => {
        if(e.target.checked){
            this.setState({
                isScheduled: e.target.checked
            })
        }else{
            this.setState({
                isScheduled: e.target.checked,
                date:moment(today).format(common.dateTimeFormat),
                requestedTime:moment(today).format(common.timeFormat),
            })
        }
        
    }

    handleEmailTemplate = (value) =>{
        const { emailTemplateList } = this.props;
        if(value === ''){
            this.setState({
                emailSubject:'',
                email: '',
                emailTemplate:value
            })
        }else{
            const element = emailTemplateList.find(e => e.id === value)
            this.setState({
                emailTemplate:value,
                email : decodeURIComponent(element.body),
                emailSubject: element.name,
            })
        }
        
    }
  
    render() {
        const {
            isModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            isSelectedEmails,
            data,
            emailTemplateList
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isModalOpen} 
                    onHide={handleModalClose}
                    title={isSelectedEmails ? 'Email' :
                        this.state.window === '#/customer' ? "Email - " + data.displayName : 
                        this.state.window === '#/jobCard' ? "Email - Job Number " + data.jobNumber : 'Email'}
                    width='60%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{marginTop:"5px"}}>
                                <div className='row'>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Select email template"
                                            controlId="emailTemplate"
                                            onChange={this.handleEmailTemplate}
                                            options={emailTemplateList && emailTemplateList.length > 0 ? emailTemplateList : []}
                                            selectedValue={this.state.emailTemplate}
                                            display = "name"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                {!isSelectedEmails && <div className='row'>
                                    <div className="col-sm">
                                        <TextField
                                            text="Email Address"
                                            onBlur={this.handleChange}
                                            controlId="emailAddress"
                                            type="email"
                                            defaultValue={this.state.emailAddress}
                                            maxLength={common.maxLengths.emailAddress}
                                            required
                                        />
                                    </div>
                                </div>}
                                <div className='row'>
                                    <div className="col-sm">
                                        <TextField
                                            text="Email Subject"
                                            onBlur={this.handleChange}
                                            controlId="emailSubject"
                                            type="text"
                                            defaultValue={this.state.emailSubject}
                                            maxLength={common.maxLengths.emailSubject}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm">
                                        <div className='email-body'>
                                            <TextEditor
                                                editorState={this.state.email}
                                                onEditorStateChange={(e) => this.onEditorChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm">
                                        <Form.Group controlId="addressCheckBox">
                                            <Form.Check type="checkbox" style={{fontSize:"14px"}} onChange={(e) => this.handleCheck(e)} checked={this.state.isScheduled} label ="Schedule"/>
                                        </Form.Group>
                                    </div>
                                </div>
                                {this.state.isScheduled && 
                                    <div className='row' style={{marginTop:'10px'}}>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Scheduled date"
                                                controlId="date"
                                                onChange={(e) => this.handleDateChange(e,"date")}
                                                value={this.state.date === '' ? '' :new Date(this.state.date)}
                                                required
                                                // format={dateFormat}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <TimePicker
                                                text="Scheduled time"
                                                controlId="requestedTime"
                                                onChange={(e) => this.handleTimeChange(e,"requestedTime")}
                                                value={this.state.requestedTime}
                                                required
                                            />
                                        </div>
                                    </div>}
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Send'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

Email.propTypes = {
    isAddNewTitleModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any
}

export default Email;