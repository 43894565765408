import React,{ Component } from "react";
import '../pages.css';
import '../settingMenu/settingMenu.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
// import PropTypes from 'prop-types';
import '../pages.css';
import '../settingMenu/settingMenu.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import common from '../../common';
import * as actions from '../../../store/action/employeeAction';
import { loadObjectStatusRequest, loadObjectStatusSuccess, saveCommonSearchSuccess } from '../../../store/action/action';
import * as selectors from '../../../store/selector/employeeSelector';
import Table from '../../layout/form/Table';
import Pagination from '../../layout/form/Pagination';
import SearchField from '../../layout/form/SearchField';
import TextField from '../../layout/form/TextField';
import ButtonGroup from '../../layout/form/ButtonGroup';
import Loader from '../../layout/form/Loader';
import ErrorModal from '../../layout/form/errorModal';
import DeleteModal from '../../layout/form/deleteModal';
import { makeSelectUserAccessSuccess } from "../../../store/selector/usersSelector";
import { makeSelectObjectStatusList } from "../../../store/selector/selector";
import AddEmployee from "./AddNewEmployee";
import SuccessModal from "../../layout/form/successModal";
import { loadUserListSuccess, saveIsUsersModalOpenStatus } from "../../../store/action/usersAction";
import SelectField from "../../layout/form/SelectField";
import { loadTitleListRequest } from "../../../store/action/titleAction";
import { makeSelectTitleList } from '../../../store/selector/titleSelector';

class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            statusId: '',
            lastName: '',
            firstName: '',
            email: '',
            mobile: '',
            commonSearch:'',
            pageNumber:1,
            pageSize:20,
            isUpdate: false,
            isValidationError: false,
            Employee:'',
            selectedEmployeeId: 0,
            isDeleteModalOpen: false,
            selectedRow: {}
        }
    }

    componentDidMount (){
        const parameters= {
            pageSize:9999,
            pageNumber:'',
            name:'',
          }
        this.props.dispatch(actions.loadEmployeeListSuccess([]))
        this.props.dispatch(loadTitleListRequest(parameters))
        this.props.dispatch(loadObjectStatusSuccess([]))
        this.props.dispatch(loadUserListSuccess([]))
        this.getEmployeeList()
        this.props.dispatch(loadObjectStatusRequest(common.objectId.employee));
    }

    getEmployeeList = () => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            statusId: this.state.statusId,
            firstName: this.state.firstName,
            lastName:  this.state.lastName,
            email:  this.state.email,
            mobile:  this.state.mobile,
            commonSearch: this.state.commonSearch
        }
        this.props.dispatch(actions.loadEmployeeListRequest(parameters))
    }

    addTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Add new Employee
        </Tooltip>
    );

    filterTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Filter
        </Tooltip>
    );

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadEmployeeListError(null))
    }

    successModalClose = () => {
        this.props.dispatch(actions.loadSelectedEmployeeSuccess(''))
        this.props.dispatch(actions.sendEmployeeSuccess(null))
        this.handleClose()
    }

    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if(objectStatusList && objectStatusList.length && objectStatusList.length !== 0){
          const desc = objectStatusList.find(select =>  select.id === data.statusId)
          return (desc && desc.description ? desc.description : '')
        }
    }

    handleModal = (isUpdate,data) => {
        if(isUpdate){
            this.props.dispatch(actions.loadSelectedEmployeeSuccess(''))
            this.props.dispatch(actions.loadSelectedEmployeeRequest(data.id))
        }
        this.props.dispatch(actions.saveIsEmployeeModalOpenStatus(true))
        this.setState({
            isUpdate: isUpdate
        })
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen:!this.state.isFilterFormOpen,
            statusId: this.state.statusId,
            firstName: this.state.firstName,
            lastName:  this.state.lastName,
            email:  this.state.email,
            mobile:  this.state.mobile,
            commonSearch: ''
        })
    }

    handleFilterFormClose = () =>{
        this.setState({
            isFilterFormOpen: false,
            statusId: '',
            firstName: '',
            lastName:  '',
            email:  '',
            mobile:  '',
            commonSearch: '',
            pageNumber:1,
            pageSize:20,
        })

        const parameters = {
            statusId: '',
            firstName: '',
            lastName:  '',
            email:  '',
            mobile:  '',
            commonSearch: '',
            pageNumber:1,
            pageSize:20,
        }
        this.props.dispatch(actions.loadEmployeeListRequest(parameters))
    }

    handleFilter = () =>{
        this.getEmployeeList()
        this.handleFilterForm()
    }
    
    handleClose = (data) => {
        this.setState({
            isUpdate: false,
        })
        this.props.dispatch(actions.loadSelectedEmployeeSuccess(''))
        this.props.dispatch(actions.saveIsEmployeeModalOpenStatus(false))
        this.getEmployeeList()
    }

    handleChangeStatus = (value) =>{
        this.setState({status:value})
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    onPressKeySearch = (value) => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            statusId: this.state.statusId,
            firstName: this.state.firstName,
            lastName:  this.state.lastName,
            email:  this.state.email,
            mobile:  this.state.mobile,
            commonSearch: value
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber:1,
                pageSize:20
            })
        }
        this.setState({
            commonSearch:value
        })
        this.props.dispatch(actions.loadEmployeeListRequest(parameters))
    }

    handleChangeSearchValue = (e)=> {
        if(e.target.value === "" || e.target.value === null){
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen:false,
            statusId: '',
            firstName: '',
            lastName:  '',
            email:  '',
            mobile:  '',
            pageSize:20,
            pageNumber:1,
        })
    }

    handleFilterFormClose = () =>{
        this.setState({
            isFilterFormOpen: false,
            statusId: '',
            firstName: '',
            lastName:  '',
            email:  '',
            mobile:  '',
            commonSearch:'',
            pageNumber:1,
            pageSize:20,
        })

        const parameters = {
            statusId: '',
            firstName: '',
            lastName:  '',
            email:  '',
            mobile:  '',
            commonSearch:'',
            pageNumber:1,
            pageSize:20,
        }
        this.props.dispatch(actions.loadEmployeeListRequest(parameters))
    }

    handleSaveAddNew = (details) => {
        if(details.employeeId === '' ||
        details.email  === ''  ){
            this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
            return
        }
        const parameters= {
            id: details.id ? details.id : 0, 
            TitleId:details.titleId,
            FirstName:details.firstName,
            LastName:details.lastName,
            Email:details.email === ''  ? null: details.email,
            Mobile:details.mobile,
            StatusId:details.status,
            EmployeeId: details.employeeId,
            managerId: details.managerId,
            sellRate: details.sellRate,
            costRate: details.costRate
        }
        if(this.state.isUpdate){
            this.props.dispatch(actions.sendUpdateEmployeeRequest(parameters))
        }else 
            this.props.dispatch(actions.sendAddNewEmployeeRequest(parameters))
    }

    successModalClose = () => {
        this.props.dispatch(actions.loadSelectedEmployeeSuccess(''))
        this.props.dispatch(actions.sendEmployeeSuccess(null))
        this.handleClose()
    }

    handleAddUser = (details) => {
        this.props.dispatch(actions.loadSelectedEmployeeSuccess(details))
        this.props.dispatch(saveIsUsersModalOpenStatus(true))
        this.props.history.push('/users')
        this.props.dispatch(actions.loadEmployeeListSuccess([]))
    }

    handleViewUser =  (details) => {
        const data = {
            component:'employee',
            id: details.user
        }
        this.props.dispatch(saveCommonSearchSuccess(data))
        this.props.history.push('/users')
        this.props.dispatch(actions.loadEmployeeListSuccess([]))
    }

    componentDidUpdate (prevProps, prevState){
        if (this.props.employeeSuccess !== null){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getEmployeeList()
        }
    }

    render(){
        const { 
            isEmployeeListLoading,
            employeeListError,
            isEmployeeModalOpen,
            userAccess,
            employeeList,
            objectStatusList,
            selectedEmployee,
            employeeSuccess,
            titleList
        } = this.props;

        const {
            isDeleteModalOpen,
            selectedRow,
            isFilterFormOpen,
            isUpdate
        } = this.state;

        const columns = [{
            Header: 'First Name',
            accessor: 'firstName',
            width: "12%",
        },{
            Header: 'Last Name',
            accessor: 'lastName',
            width: "12%",
        },{
            Header: 'Mobile No',
            accessor: 'mobile',
            width: "12%",
        },{
            Header: 'Email',
            accessor: 'email'
        },{
            Header: 'User ID',
            accessor: 'userId',
            width: "15%",
            Cell:((row)=>(
                <div >
                  {row.row.original.userId === 0 ? '' : row.row.original.user }
                </div>
              )),
        },{
            Header: 'Status',
            accessor: 'statusId',
            width: "10%",
            Cell:((row)=>(
                <div style={{padding:'2px'}}>
                    {row.row.original.statusId === "10" ? 
                        <div style={{backgroundColor:'#ebd936',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'10px',paddingRight:'10px'}}>{this.setStatusDescription(row.row.original)}</div> :
                        row.row.original.statusId === "20" ? 
                        <div style={{backgroundColor:'#36eb69',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'10px',paddingRight:'10px'}}>{this.setStatusDescription(row.row.original)}</div> :
                        <div style={{backgroundColor:'#ff6384',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'10px',paddingRight:'10px'}}>{this.setStatusDescription(row.row.original)}</div>
                    }
                </div>
              )),
          },{
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    <span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(true, row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>  
                      
                    <span className="dropdown" style={{ float: 'left' }}>
                        <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                        <div className="dropdown-content">
                            {/* <li onClick={() => this.handleModal(true, row.row.original)}><ui5-icon class="samples-margin" name="display" id="action-icon"></ui5-icon> Display</li> */}
                            {row.row.original.userId === 0 ? <li onClick={() => this.handleAddUser(row.row.original)}><ui5-icon class="samples-margin" name="add-document" id="action-icon"></ui5-icon> Create User</li> :
                                <li onClick={() => this.handleViewUser(row.row.original)}><ui5-icon class="samples-margin" name="add-document" id="action-icon"></ui5-icon> Display User</li>}

                        </div>
                    </span>
                    </span>
                </div>
            )),
            accessor: 'action'
        }]
        return(
            <div>
            {(isEmployeeListLoading ) &&
                <Loader show={isEmployeeListLoading}/> }

            {(this.state.isValidationError || employeeListError !== null ) && 
                <ErrorModal
                    show={true} 
                    massage={this.state.errorMassage ? this.state.errorMassage : employeeListError}
                    handleClose={this.errorModalClose}
                />}

            {employeeSuccess !== null && 
                    <SuccessModal
                        show={true} 
                        massage={employeeSuccess}
                        handleClose={this.successModalClose}
                    />}

            {isDeleteModalOpen && 
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteEmployee}								
                        title={selectedRow.employeeName}
                    />}
            <div className="content-header">
                <div className="header-pages">
                    <ui5-icon class="samples-margin" name="employee" id="header-icon"></ui5-icon><span>EMPLOYEE</span>
                </div>
            </div>
            <div className="content-body">
                <div style={{display:'flex', justifyContent:'space-between', margin:'5px 0'}}>
                    <div style={{display:'flex'}}>
                        <div>
                            <h5 style={{marginLeft:'5px'}}>Employee List</h5>
                        </div>
                        <div style={{marginLeft:'20px'}}>
                            <SearchField
                                onClickSearch={this.getEmployeeList}
                                controlId="commonSearch"
                                onChange={this.handleChangeSearchValue}
                                defaultValue={this.state.commonSearch}
                                onPressKeySearch={this.onPressKeySearch}
                            />
                        </div>
                    </div>
                    <div className="header-btn">
                        <div style={{display:'flex'}}>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.addTooltip}
                            >
                                <span className={userAccess && userAccess.createEmployee ? "header-icon" : "header-icon-disabled"}  onClick={userAccess && userAccess.createEmployee ? () => this.handleModal(false) : ''}>
                                    <ui5-icon class="samples-margin" name="add" id="header-icon"></ui5-icon>
                                </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.filterTooltip}
                            >
                                <span className="header-icon"  onClick={() => this.handleFilterForm()}>
                                    <ui5-icon class="samples-margin" name="filter" id="header-icon"></ui5-icon>
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                {isFilterFormOpen && 
                    <div className="filter-form">
                        <div className="filter-header">
                            <h5>Filter Form</h5>
                            <div className="btn-close" onClick={this.handleFilterFormClose} >
                                <ui5-icon class="samples-margin" name="decline" id="btn-icon"></ui5-icon>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField
                                    text="First Name"
                                    controlId="firstName"
                                    // placeholder="First Name"
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.firstName}
                                    maxLength={common.maxLengths.employeeFirstName}
                                />
                            </div>
                            <div className="col-sm">
                                <TextField
                                    text="Last Name"
                                    controlId="lastName"
                                    // placeholder="Last Name"
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.lastName}
                                    maxLength={common.maxLengths.employeeLastName}
                                />
                            </div>
                            <div className="col-sm">
                            <TextField
                                            text="Email"
                                            controlId="email"
                                            // placeholder="Email"
                                            type="email"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.email}
                                            maxLength={common.maxLengths.employeeEmail}
                                        />
                            </div>
                            <div className="col-sm">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            onChange={this.handleChangeStatus}
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            required
                                        />
                                    </div>
                        </div>
                        <div className="filter-btns">
                            <ButtonGroup
                                primaryBtnClick={this.handleFilter}
                                primaryBtnIcon='search'
                                primaryBtnText='Search'
                                primaryBtnVariant="outline-primary" 
                                secondaryBtnClick={this.handleFilterFormClose}
                                secondaryBtnIcon='clear-filter'
                                secondaryBtnText='Clear'
                                secondaryBtnVariant="outline-primary" 
                            />
                        </div>
                    </div>}
                <div className="table-content">
                    <Table
                        columns={columns}
                        data={employeeList.result && employeeList.result.length ? employeeList.result : []}                   
                    />
                </div>
                <Pagination
                    currentPage={employeeList.page ? employeeList.page :this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {employeeList.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
            </div>
            {
                isEmployeeModalOpen &&
                    <AddEmployee
                        isEmployeeModalOpen={isEmployeeModalOpen}
                        handleModalClose ={this.handleClose}
                        objectStatusList={objectStatusList}
                        handleSaveAddNew={this.handleSaveAddNew}
                        selectedEmployee={selectedEmployee}
                        titleList={titleList}
                        isUpdate={isUpdate}
                        userAccess={userAccess}
                        empolyeeList={employeeList.result && employeeList.result.length ? employeeList.result : []} 
                    />
            }
          </div>
        )
    }
}


Employee.propTypes = {

}
  
const mapStateToProps = createStructuredSelector({
    employeeList: selectors.makeSelectEmployeeList(),
    isEmployeeModalOpen : selectors.makeSelectEmployeePopupOpen(),
    isEmployeeListLoading: selectors.makeSelectEmployeeListLoading(),
    employeeListError: selectors.makeSelectEmployeeListError(),
    employeeSuccess: selectors.makeSelectEmployeeSuccess(),
    selectedEmployee: selectors.makeSelectSelectedEmployeeSuccess(),
    userAccess: makeSelectUserAccessSuccess(),
    objectStatusList:makeSelectObjectStatusList(),
    titleList: makeSelectTitleList(),
});
  
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
}
  
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
  
  
export default (compose(withConnect)(Employee));