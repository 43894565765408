import { put, call } from 'redux-saga/effects';
import {
  addNewTimesheetRequest,
  getTimesheetList,
  updateTimesheetRequest,
  deleteTimesheetRequest,
  getSelectedTimesheet,
  updateTimesheetStatusRequest,
  getTimeTypeList,
  getTimesheetDetailList,
  deleteTimesheetDetailList,
  sendCopyTimesheetDetailsRequest,
  sendCopyTimesheetRequest,
  getTimesheetApprovals,
  // getSelectedTimesheetRequest
} from '../service/timesheetService';
import * as actions from '../../store/action/timesheetAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewTimesheetRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  try {
    const response = yield call(addNewTimesheetRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      const id = response.data.result.id
      yield put(actions.loadSelectedTimesheetRequest(id))
      const massage = response.data.message
      yield put(actions.sendTimesheetSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}

export function* loadTimesheetListRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  try {
    yield put(actions.loadTimesheetListLoading(true));
    const response = yield call(getTimesheetList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result
      yield put(actions.loadTimesheetListSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadTimesheetListLoading(false));
      const massage = common.error
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    yield put(actions.loadTimesheetListLoading(false));
    console.log(error)
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}

export function* sendUpdateTimesheetRequest(data) {
  yield put(actions.loadTimesheetListLoading(true))
  yield put(actions.loadTimesheetListError(null))
  try {
    const response = yield call(updateTimesheetRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(actions.sendTimesheetDetailsSuccess(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}

export function* sendDeleteTimesheetRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  try {
    yield put(actions.loadTimesheetListLoading(true));
    const response = yield call(deleteTimesheetRequest, data.payload);
    if (response && response.status && response.status === 200 && response.data && response.data.statusCode && response.data.statusCode === 404 && response.data.result.code) {
      var massage = common.massages.projectDeleteMassage
      const errorCode = common.deleteMassages.find(element => element.id === response.data.result.code)
      if (errorCode) {
        massage = errorCode.description
      }
      yield put(actions.loadTimesheetListError(massage))
    }
    else if (response && response.status && response.status === 200 && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendTimesheetSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    yield put(actions.loadTimesheetListLoading(false));
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}

export function* loadSelectedTimesheetRequest(data) {
  yield put(actions.sendTimesheetDetailsSuccess(false))
  yield put(actions.loadTimesheetListError(null))
  try {
    yield put(actions.loadTimesheetListLoading(true));
    const response = yield call(getSelectedTimesheet, data.payload);
    if (response && response.status && response.status === 200) {
      const project = response.data
      yield put(actions.loadSelectedTimesheetSuccess(project))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadTimesheetListLoading(false));
      const massage = common.error
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    yield put(actions.loadTimesheetListLoading(false));
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}

export function* loadTimesheetDetailsRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  try {
    yield put(actions.loadTimesheetListLoading(true));
    const response = yield call(getTimesheetDetailList, data.payload);
    if (response && response.status && response.status === 200) {
      const project = response.data.result.result
      yield put(actions.loadTimesheetDetailsSuccess(project))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadTimesheetListLoading(false));
      const massage = common.error
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    yield put(actions.loadTimesheetListLoading(false));
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}

export function* sendUpdateStatusTimesheetRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  try {
    const response = yield call(updateTimesheetStatusRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      var massage = response.data.message
      if(data.payload.statusId === "30"){
        massage = "Timesheet successfully Submitted"
      }
      yield put(actions.sendTimesheetSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}


export function* loadTimeTypeListRequest() {
  try {
    const response = yield call(getTimeTypeList);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const timeTypeList = response.data.result.result
      yield put(actions.loadTimeTypeSuccess(timeTypeList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.lg(error)
  }
}


export function* sendDeleteTimesheetDetailsRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  try {
    const response = yield call(deleteTimesheetDetailList, data.payload);
    if (response && response.status && response.status === 200) {
      yield put(actions.sendTimesheetDetailsSuccess(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    yield put(actions.loadTimesheetListLoading(false));
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}

export function* sendNewTimesheetDetailsRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  yield put(actions.loadTimesheetListLoading(true))
  try {
    const response = yield call(addNewTimesheetRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      yield put(actions.sendTimesheetDetailsSuccess(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}


export function* copyTimesheetDetailsRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  yield put(actions.loadTimesheetListLoading(true))
  try {
    const response = yield call(sendCopyTimesheetDetailsRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(actions.loadTimesheetListLoading(false));
      yield put(actions.sendTimesheetDetailsSuccess(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage));
    yield put(actions.loadTimesheetListLoading(false));
  }
}


export function* copyTimesheetRequest(data) {
  yield put(actions.loadTimesheetListError(null))
  yield put(actions.loadTimesheetListLoading(true))
  try {
    const response = yield call(sendCopyTimesheetRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      yield put(actions.loadTimesheetListLoading(false));
      yield put(actions.sendTimesheetDetailsSuccess(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = "You do not have a timesheet for the week of " + data.payload.weekStartDate
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage));
    yield put(actions.loadTimesheetListLoading(false));
  }
}


export function* loadTimesheetApprovalRequest(data) {
  yield put(actions.sendTimesheetDetailsSuccess(false))
  yield put(actions.loadTimesheetListError(null))
  try {
    yield put(actions.loadTimesheetListLoading(true));
    const response = yield call(getTimesheetApprovals, data.payload);
    if (response && response.status && response.status === 200) {
      const approvals = response.data.result.result
      yield put(actions.loadTimesheetApprovalSuccess(approvals))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadTimesheetListLoading(false));
      const massage = common.error
      yield put(actions.loadTimesheetListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadTimesheetListLoading(false));
  } catch (error) {
    yield put(actions.loadTimesheetListLoading(false));
    const massage = common.error
    yield put(actions.loadTimesheetListError(massage))
  }
}