import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import '../../../layout/form/form.css'
import '../../../auth/auth.css'
import ActivationDetails from '../../../auth/activate/ActivationDetails';
import BillingInformation from '../../../auth/activate/BillingInformation';
import Success from '../../../auth/activate/Success';
import { makeSelectPaymentSetupSuccess } from '../../../../store/selector/appBillingSelector';
import { loadPaymentSetupRequest, loadPaymentSetupSuccess } from '../../../../store/action/appBillingAction';
import { loadTenantBasicRequest, loadTenantPackageRequest, sendTenantActivateRequest } from '../../../../store/action/tenantAction';
import { makeSelectTenantActivateError, makeSelectTenantActivateLoading, makeSelectTenantActivateSuccess, makeSelectTenantBasic, makeSelectTenantPackage } from '../../../../store/selector/tenantSelector';
import Loader from '../../../layout/form/Loader';
import Default from '../../../auth/activate/Default';
import { loadAppPackageRequest } from '../../../../store/action/action';
import { makeSelectAppBillingTermsList, makeSelectAppPackagesList } from '../../../../store/selector/selector';
// import SelectField from '../layout/form/SelectField';

class ActiveModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_item: 'secondStep',
            trailPeriod: 30,
            trialExpiryDate: new Date(),
            initial: true,
            appPrice: 20,
            isChangePackage: false,
            isChangeBillingTerm: false,
            selectedPackage: 0,
            selectedBillingTerm: 0
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { tenantPackage, appPackageList, billingTermsList } = prevProps;
        const { initial } = prevState;
        if (initial && tenantPackage && tenantPackage.id > 0 && appPackageList && appPackageList.length > 0 && billingTermsList && billingTermsList.list && billingTermsList.list.length > 0) {
            return {
                trailPeriod: tenantPackage.trailPeriod,
                trialExpiryDate: tenantPackage.trialExpiryDate,
                appPrice: tenantPackage.price,
                selectedPackage: tenantPackage.id,
                selectedBillingTerm: billingTermsList.default,
                initial: false
            }
        }
        return null
    }

    handleChangePackage = () => {
        this.setState({
            isChangePackage: true
        })
    }

    handleChangePackageClose = () => {
        const { tenantPackage } = this.props;
        this.setState({
            isChangePackage: false,
            selectedPackage: tenantPackage.id
        })
    }

    clickPackage = (id) => {
        this.setState({
            selectedPackage: id
        })
    }

    handlePackage = () => {
        this.setState({
            isChangePackage: false
        })
    }

    handleChangeBillingTerm = () => {
        this.setState({
            isChangeBillingTerm: true
        })
    }

    handleChangeBillingTermClose = () => {
        const { billingTermsList } = this.props
        this.setState({
            isChangeBillingTerm: false,
            selectedBillingTerm: billingTermsList.default,
        })
    }

    clickBillingTerm = (id) => {
        this.setState({
            selectedBillingTerm: id
        })
    }

    handleBillingTerm = () => {
        this.setState({
            isChangeBillingTerm: false,
        })
    }

    handleChangeStep = (e) => {
        this.setState({
            current_item: e
        })
        if (e === "thirdStep") {
            this.props.dispatch(loadPaymentSetupRequest())
        }
        if (e === "secondStep") {
            this.props.dispatch(loadPaymentSetupSuccess(null))
        }
    }

    handleSubmitPaymentMethod = async (event, stripe, elements) => {
        event.preventDefault();

        const { paymentSetup } = this.props;

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            clientSecret: paymentSetup.setupIntentClientSecret,
            redirect: 'if_required'
        })

        if (error) {
            this.setState({ isValidationError: true, errorMassage: error.message })
        } else {
            this.props.dispatch(loadPaymentSetupSuccess(null))
            const parms = {
                setupIntent: paymentSetup.setupIntent,
                paymentSetupId: paymentSetup.id,
                billingTermId: this.state.selectedBillingTerm,
                packageId: this.state.selectedPackage
            }
            this.props.dispatch(sendTenantActivateRequest(parms))
            this.setState({
                current_item: "fourthStep"
            })
        }
    };


    componentDidMount() {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantBasicRequest(id))
        this.props.dispatch(loadAppPackageRequest())
        this.props.dispatch(loadTenantPackageRequest())
    }

    render() {
        const {
            show,
            paymentSetup,
            tenantActivateLoading,
            tenantActivateSuccess,
            handleActivationSuccess,
            handleActivateClose,
            appPackageList,
            billingTermsList
        } = this.props;

        const {
            current_item,
            appPrice,
            isChangePackage,
            isChangeBillingTerm,
            selectedPackage,
            selectedBillingTerm
        } = this.state;

        return (
            <dev>
                {tenantActivateLoading ?
                    <Loader show={tenantActivateLoading} /> : ''}
                <BootstrapModal
                    show={show}
                    animation={false}
                    // size="md"
                    dialogClassName="active_modal_width"
                    centered
                >
                    <BootstrapModal.Body>

                        <section className="step-wizard">
                            <ul className="step-wizard-list">
                                <li className={current_item === "secondStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">1</span>
                                    <span className="progress-label">Billing Info</span>
                                </li>
                                <li className={current_item === "thirdStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">2</span>
                                    <span className="progress-label">Payment Method</span>
                                </li>
                                <li className={tenantActivateSuccess !== null && current_item === "fourthStep" ? "step-wizard-item current-item" : "step-wizard-item"}>
                                    <span className="progress-count">3</span>
                                    <span className="progress-label">Success</span>
                                </li>
                            </ul>
                        </section>
                        {
                            current_item === "secondStep" ?
                                <ActivationDetails
                                    handleChangeStep={this.handleChangeStep}
                                    handleFirstStep={handleActivateClose}
                                    appPrice={appPrice}
                                    isChangePackage={isChangePackage}
                                    handleChangePackage={this.handleChangePackage}
                                    handleChangePackageClose={this.handleChangePackageClose}
                                    handleChangeBillingTerm={this.handleChangeBillingTerm}
                                    appPackageList={appPackageList}
                                    isChangeBillingTerm={isChangeBillingTerm}
                                    handleChangeBillingTermClose={this.handleChangeBillingTermClose}
                                    billingTermsList={billingTermsList}
                                    clickPackage={this.clickPackage}
                                    handlePackage={this.handlePackage}
                                    selectedPackage={selectedPackage}
                                    clickBillingTerm={this.clickBillingTerm}
                                    selectedBillingTerm={selectedBillingTerm}
                                    handleBillingTerm={this.handleBillingTerm}
                                /> :
                                current_item === "thirdStep" && paymentSetup != null && paymentSetup.setupIntent && paymentSetup.setupIntent != null ?
                                    <BillingInformation
                                        paymentSetup={paymentSetup}
                                        handleChangeStep={this.handleChangeStep}
                                        handleSubmit={this.handleSubmitPaymentMethod}
                                    /> :
                                    tenantActivateSuccess !== null && current_item === "fourthStep" ?
                                        <Success
                                            handleActivationSuccess={handleActivationSuccess}
                                        /> : <Default />
                        }
                    </BootstrapModal.Body>
                    {/* <BootstrapModal.Footer>
            <ButtonGroup
                primaryBtnClick={handleActiveModalClose}
                primaryBtnVariant="outline-primary"
                primaryBtnText='Cancel'
                secondaryBtnClick={handleWarning}
                secondaryBtnText='Active'
                secondaryBtnVariant="outline-primary"
            />
        </BootstrapModal.Footer> */}

                </BootstrapModal>
            </dev>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    paymentSetup: makeSelectPaymentSetupSuccess(),
    tenantActivateLoading: makeSelectTenantActivateLoading(),
    tenantActivateError: makeSelectTenantActivateError(),
    tenantActivateSuccess: makeSelectTenantActivateSuccess(),
    tenantDetails: makeSelectTenantBasic(),
    appPackageList: makeSelectAppPackagesList(),
    tenantPackage: makeSelectTenantPackage(),
    billingTermsList: makeSelectAppBillingTermsList()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default withRouter(compose(withConnect)(ActiveModal));