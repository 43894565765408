import {
  LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST,
  LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS,
  LOAD_TENANT_BASIC_REQUEST,
  LOAD_TENANT_BASIC_SUCCESS,
  LOAD_TENANT_CALENDAR_REQUEST,
  LOAD_TENANT_CALENDAR_SUCCESS,
  LOAD_TENANT_CORRESPONDENCE_REQUEST,
  LOAD_TENANT_CORRESPONDENCE_SUCCESS,
  LOAD_TENANT_DEFAULT_REQUEST,
  LOAD_TENANT_DEFAULT_SUCCESS,
  LOAD_TENANT_DETAILS_ERROR,
  LOAD_TENANT_DETAILS_LOADING,
  LOAD_TENANT_FINANCE_REQUEST,
  LOAD_TENANT_FINANCE_SUCCESS,
  LOAD_TENANT_OPEN_HOURS_REQUEST,
  LOAD_TENANT_OPEN_HOURS_SUCCESS,
  LOAD_TENANT_PACKAGE_REQUEST,
  LOAD_TENANT_PACKAGE_SUCCESS,
  LOAD_TENANT_PAYMENT_METHOD_REQUEST,
  LOAD_TENANT_PAYMENT_METHOD_SUCCESS,
  LOAD_TENANT_SERVICE_AREA_REQUEST,
  LOAD_TENANT_SERVICE_AREA_SUCCESS,
  LOAD_TENANT_SERVICE_REQUEST,
  LOAD_TENANT_SERVICE_SUCCESS,
  SAVE_TENANT_ACTIVE_STATUS,
  SEND_CREATE_TENANT_CALENDAR_REQUEST,
  SEND_DELETE_TENANT_CALENDAR_REQUEST,
  SEND_TENANT_ACTIVATE_ERROR,
  SEND_TENANT_ACTIVATE_LOADING,
  SEND_TENANT_ACTIVATE_REQUEST,
  SEND_TENANT_ACTIVATE_SUCCESS,
  SEND_TENANT_DETAILS_SUCCESS,
  SEND_TENANT_IMAGE_REQUEST,
  SEND_UPDATE_TENANT_DETAILS_REQUEST,
  SEND_UPDATE_TENANT_FINANCE_REQUEST,
  SEND_UPDATE_TENANT_OPEN_HOURS_REQUEST,
} from '../constant/tenantConstant';

export function loadTenantFinanceRequest(data) {
  return {
    type: LOAD_TENANT_FINANCE_REQUEST,
    payload: data,
  }
};

export function loadTenantBasicRequest(data) {
  return {
    type: LOAD_TENANT_BASIC_REQUEST,
    payload: data,
  }
};

export function loadTenantDetailsLoading(data) {
  return {
    type: LOAD_TENANT_DETAILS_LOADING,
    payload: data,
  }
};

export function loadTenantFinanceSuccess(data) {
  return {
    type: LOAD_TENANT_FINANCE_SUCCESS,
    payload: data,
  }
};

export function loadTenantBasicSuccess(data) {
  return {
    type: LOAD_TENANT_BASIC_SUCCESS,
    payload: data,
  }
};

export function loadTenantDetailsError(data) {
  return {
    type: LOAD_TENANT_DETAILS_ERROR,
    payload: data,
  }
};

export function sendTenantDetailsSuccess(data) {
  return {
    type: SEND_TENANT_DETAILS_SUCCESS,
    payload: data,
  }
};

export function sendTenantDetailsRequest(data) {
  return {
    type: SEND_UPDATE_TENANT_DETAILS_REQUEST,
    payload: data,
  }
};

export function loadTenantCorrespondenceRequest(data) {
  return {
    type: LOAD_TENANT_CORRESPONDENCE_REQUEST,
    payload: data,
  }
};

export function loadTenantCorrespondenceSuccess(data) {
  return {
    type: LOAD_TENANT_CORRESPONDENCE_SUCCESS,
    payload: data,
  }
};

export function loadTenantDefaultRequest(data) {
  return {
    type: LOAD_TENANT_DEFAULT_REQUEST,
    payload: data,
  }
};

export function loadTenantDefaultSuccess(data) {
  return {
    type: LOAD_TENANT_DEFAULT_SUCCESS,
    payload: data,
  }
};

export function loadTenantAccountSystemRequest(data) {
  return {
    type: LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST,
    payload: data,
  }
};

export function loadTenantAccountSystemSuccess(data) {
  return {
    type: LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS,
    payload: data,
  }
};

export function loadTenantServicesAreaRequest() {
  return {
    type: LOAD_TENANT_SERVICE_AREA_REQUEST,
  }
};

export function loadTenantServicesAreaSuccess(data) {
  return {
    type: LOAD_TENANT_SERVICE_AREA_SUCCESS,
    payload: data,
  }
};

export function loadTenantServicesRequest() {
  return {
    type: LOAD_TENANT_SERVICE_REQUEST,
  }
};

export function loadTenantServicesSuccess(data) {
  return {
    type: LOAD_TENANT_SERVICE_SUCCESS,
    payload: data,
  }
};

export function loadTenantOpenHoursRequest(data) {
  return {
    type: LOAD_TENANT_OPEN_HOURS_REQUEST,
    payload: data,
  }
};

export function loadTenantOpenHoursSuccess(data) {
  return {
    type: LOAD_TENANT_OPEN_HOURS_SUCCESS,
    payload: data,
  }
};

export function sendTenantFinanceRequest(data) {
  return {
    type: SEND_UPDATE_TENANT_FINANCE_REQUEST,
    payload: data,
  }
};

export function sendTenantOpenHoursRequest(data) {
  return {
    type: SEND_UPDATE_TENANT_OPEN_HOURS_REQUEST,
    payload: data,
  }
};

export function saveTenantActiveSataus(data) {
  return {
    type: SAVE_TENANT_ACTIVE_STATUS,
    payload: data,
  }
};

export function loadTenantPaymentMethodRequest(data) {
  return {
    type: LOAD_TENANT_PAYMENT_METHOD_REQUEST,
    payload: data,
  }
};

export function loadTenantPaymentMethodSuccess(data) {
  return {
    type: LOAD_TENANT_PAYMENT_METHOD_SUCCESS,
    payload: data,
  }
};


export function sendTenantActivateLoading(data) {
  return {
    type: SEND_TENANT_ACTIVATE_LOADING,
    payload: data,
  }
};

export function sendTenantActivateRequest(data) {
  return {
    type: SEND_TENANT_ACTIVATE_REQUEST,
    payload: data,
  }
};

export function sendTenantActivateSuccess(data) {
  return {
    type: SEND_TENANT_ACTIVATE_SUCCESS,
    payload: data,
  }
};

export function sendTenantActivateError(data) {
  return {
    type: SEND_TENANT_ACTIVATE_ERROR,
    payload: data,
  }
};

export function loadTenantPackageRequest(data) {
  return {
    type: LOAD_TENANT_PACKAGE_REQUEST,
    payload: data,
  }
};

export function loadTenantPackageSuccess(data) {
  return {
    type: LOAD_TENANT_PACKAGE_SUCCESS,
    payload: data,
  }
};

export function sendTenantImageRequest(data) {
  return {
    type: SEND_TENANT_IMAGE_REQUEST,
    payload: data,
  }
};


export function sendAddTenantCalendarRequest(data) {
  return {
    type: SEND_CREATE_TENANT_CALENDAR_REQUEST,
    payload: data,
  }
};

export function loadTenantCalendarRequest(data) {
  return {
    type: LOAD_TENANT_CALENDAR_REQUEST,
    payload: data,
  }
};

export function loadTenantCalendarSuccess(data) {
  return {
    type: LOAD_TENANT_CALENDAR_SUCCESS,
    payload: data,
  }
};

export function sendDeleteTenantCalendarRequest(data) {
  return {
    type: SEND_DELETE_TENANT_CALENDAR_REQUEST,
    payload: data,
  }
};
