import {
  LOAD_APP_BILLING_ERROR,
  LOAD_APP_BILLING_LOADING,
  LOAD_APP_INVOICE_LIST_ERROR,
  LOAD_APP_INVOICE_LIST_LOADING,
  LOAD_APP_INVOICE_LIST_REQUEST,
  LOAD_APP_INVOICE_LIST_SUCCESS,
  LOAD_APP_SERVICE_TENANT_REQUEST,
  LOAD_APP_SERVICE_TENANT_SUCCESS,
  LOAD_APP_INVOICE_PRINT_REQUEST,
  LOAD_APP_INVOICE_PRINT_SUCCESS,
  LOAD_PAYMENT_SETUP_REQUEST,
  LOAD_PAYMENT_SETUP_SUCCESS,
  LOAD_SELECTED_APP_INVOICE_REQUEST,
  LOAD_SELECTED_APP_INVOICE_SUCCESS,
  SAVE_IS_APP_INVOICE_MODAL_OPEN_STATUS,
  SEND_APP_BILLING_REQUEST,
  SEND_APP_BILLING_SUCCESS,
  SEND_PAYMENT_METHOD_REQUEST,
  LOAD_APP_PAYMENT_METHOD_REQUEST,
  LOAD_APP_PAYMENT_METHOD_SUCCESS,
  SEND_DEFAULT_PAYMENT_METHOD_REQUEST,
  SEND_UPDATE_PAYMENT_METHOD_REQUEST,
  SEND_UPDATE_PACKAGE_REQUEST
} from '../constant/appBillingConstant';


export function loadAppServiceTenantRequest(data) {
  return {
    type: LOAD_APP_SERVICE_TENANT_REQUEST,
    payload: data,
  }
};

export function loadAppBillingLoading(data) {
  return {
    type: LOAD_APP_BILLING_LOADING,
    payload: data,
  }
};

export function loadAppServiceTenantSuccess(data) {
  return {
    type: LOAD_APP_SERVICE_TENANT_SUCCESS,
    payload: data,
  }
};

export function loadAppBillingError(data) {
  return {
    type: LOAD_APP_BILLING_ERROR,
    payload: data,
  }
};

export function sendAppBillingRequest(data) {
  return {
    type: SEND_APP_BILLING_REQUEST,
    payload: data,
  }
};

export function sendAppBillingSuccess(data) {
  return {
    type: SEND_APP_BILLING_SUCCESS,
    payload: data,
  }
};

export function loadPaymentSetupRequest(data) {
  return {
    type: LOAD_PAYMENT_SETUP_REQUEST,
    payload: data,
  }
};

export function loadPaymentSetupSuccess(data) {
  return {
    type: LOAD_PAYMENT_SETUP_SUCCESS,
    payload: data,
  }
};

export function sendPaymentMethodRequest(data) {
  return {
    type: SEND_PAYMENT_METHOD_REQUEST,
    payload: data,
  }
};


export function saveIsAppInvoiceModalOpenStatus(open) {
  return {
    type: SAVE_IS_APP_INVOICE_MODAL_OPEN_STATUS,
    open,
  }
};

export function loadAppInvoiceListRequest(data) {
  return {
    type: LOAD_APP_INVOICE_LIST_REQUEST,
    payload: data,
  }
};

export function loadAppInvoiceListLoading(data) {
  return {
    type: LOAD_APP_INVOICE_LIST_LOADING,
    payload: data,
  }
};

export function loadAppInvoiceListSuccess(data) {
  return {
    type: LOAD_APP_INVOICE_LIST_SUCCESS,
    payload: data,
  }
};

export function loadAppInvoiceListError(data) {
  return {
    type: LOAD_APP_INVOICE_LIST_ERROR,
    payload: data,
  }
};

export function loadSelectedAppInvoiceRequest(data) {
  return {
    type: LOAD_SELECTED_APP_INVOICE_REQUEST,
    payload: data,
  }
};

export function loadSelectedAppInvoiceSuccess(data) {
  return {
    type: LOAD_SELECTED_APP_INVOICE_SUCCESS,
    payload: data,
  }
};

export function loadAppInvoicePrintRequest(data) {
  return {
    type: LOAD_APP_INVOICE_PRINT_REQUEST,
    payload: data,
  }
};

export function loadAppInvoicePrintSuccess(data) {
  return {
    type: LOAD_APP_INVOICE_PRINT_SUCCESS,
    payload: data,
  }
};

export function loadAppPaymentMethodRequest(data) {
  return {
    type: LOAD_APP_PAYMENT_METHOD_REQUEST,
    payload: data,
  }
};

export function loadAppPaymentMethodSuccess(data) {
  return {
    type: LOAD_APP_PAYMENT_METHOD_SUCCESS,
    payload: data,
  }
};

export function sendDefaultPaymentMethodRequest(data) {
  return {
    type: SEND_DEFAULT_PAYMENT_METHOD_REQUEST,
    payload: data,
  }
};

export function sendUpdatePaymentMethodRequest(data) {
  return {
    type: SEND_UPDATE_PAYMENT_METHOD_REQUEST,
    payload: data,
  }
};

export function sendUpdatePackageRequest(data) {
  return {
    type: SEND_UPDATE_PACKAGE_REQUEST,
    payload: data,
  }
};

