import React from 'react';
import ButtonGroup from '../../layout/form/ButtonGroup';
import Modal from '../../layout/form/Modal';
import DatePicker from '../../layout/form/datePicker';
import SelectField from '../../layout/form/SelectField';
import common from '../../common';

const ExportModal = ({
    isExportModalOpen,
    handleModalClose,
    handleDateChange,
    handleGetCSV,
    weekStartDateTo,
    weekStartDateFrom,
    exportType,
    exportTimesheetId,
    handleSelect,
    ...props
}) => (
    <div>
        <Modal
            show={isExportModalOpen}
            onHide={handleModalClose}
            title="Export Timesheets"
            width='40%'
            body={
                <div style={{ minHeight: '40vh' }}>
                    {/* <h5>Basic Data</h5> */}
                    <form style={{ marginTop: "5px" }}>
                        {exportTimesheetId === 0 ?
                            <div>
                                <div className='row'>
                                    <div className="col-sm">
                                        <DatePicker
                                            text="From the starting day of the week"
                                            controlId="weekStartDateFrom"
                                            onChange={(e) => handleDateChange(e, "weekStartDateFrom")}
                                            value={weekStartDateFrom === null ? '' : new Date(weekStartDateFrom)}
                                        // disabled={!modifyTimesheet}
                                        // format={dateFormat}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Up to the start date of the week"
                                            controlId="weekStartDateTo"
                                            onChange={(e) => handleDateChange(e, "weekStartDateTo")}
                                            value={weekStartDateTo === null ? '' : new Date(weekStartDateTo)}
                                        // disabled={!modifyTimesheet}
                                        // format={dateFormat}
                                        />
                                    </div>
                                </div>
                            </div> : ''}
                        <div className='row'>
                            <div className="col-sm">
                                <SelectField
                                    text="Type"
                                    controlId="exportType"
                                    onChange={(e) => handleSelect(e, 'exportType')}
                                    options={common.timesheetExportType}
                                    required={true}
                                    selectedValue={exportType}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            }
            footer={
                <div>
                    <ButtonGroup
                        primaryBtnClick={handleModalClose}
                        primaryBtnVariant="outline-primary"
                        // primaryBtnText='Cancel'
                        // primaryBtnIcon='decline'
                        primaryBtnText='Go Back'
                        primaryBtnIcon='undo'
                        secondaryBtnClick={handleGetCSV}
                        secondaryBtnText='Export'
                        secondaryBtnVariant="outline-primary"
                        secondaryBtnIcon='download-from-cloud'
                    />
                </div>
            }
        />
    </div>
);

ExportModal.propTs = {
}

export default ExportModal;