const PATH = 'app/Employee';

export const SAVE_IS_EMPLOYEE_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_EMPLOYEE_MODAL_OPEN_STATUS`;
export const LOAD_EMPLOYEE_LIST_REQUEST = `${PATH}LOAD_EMPLOYEE_LIST_REQUEST`;
export const LOAD_EMPLOYEE_LIST_ERROR = `${PATH}LOAD_EMPLOYEE_LIST_ERROR`;
export const LOAD_EMPLOYEE_LIST_LOADING = `${PATH}LOAD_EMPLOYEE_LIST_LOADING`;
export const LOAD_EMPLOYEE_LIST_SUCCESS = `${PATH}LOAD_EMPLOYEE_LIST_SUCCESS`;
export const SEND_ADD_NEW_EMPLOYEE_REQUEST = `${PATH}SEND_ADD_NEW_EMPLOYEE_REQUEST`;
export const SEND_EMPLOYEE_SUCCESS = `${PATH}SEND_EMPLOYEE_SUCCESS`;
export const SEND_UPDATE_EMPLOYEE_REQUEST = `${PATH}SEND_UPDATE_EMPLOYEE_REQUEST`;
export const SEND_TERMINATED_EMPLOYEE_REQUEST = `${PATH}SEND_TERMINATED_EMPLOYEE_REQUEST`;
export const LOAD_SELECTED_EMPLOYEE_REQUEST = `${PATH}LOAD_SELECTED_EMPLOYEE_REQUEST`;
export const LOAD_SELECTED_EMPLOYEE_SUCCESS = `${PATH}LOAD_SELECTED_EMPLOYEE_SUCCESS`;