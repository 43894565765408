import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import Address from './Address'
import common from '../../../common';
import SelectField from '../../../layout/form/SelectField';
import Form from 'react-bootstrap/cjs/Form';
import PhoneNumberField from '../../../layout/form/PhoneNumberField';

export class AddCustomer extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            titleId:'',
            firstName:'',
            middleName:'',
            lastName:'',
            email:'',
            companyName:'',
            phone:'',
            mobile:'',
            fax:'',
            displayName:'',
            customField1:'',
            customField2:'',
            notes:'',
            status:'',
            streetNameBilling:'',
            streetNoBilling:'',
            stateBilling:'', 
            cityBilling:'', 
            postCodeBilling:'', 
            countryBilling:'',
            streetNameShipping:'',
            streetNoShipping:'',
            stateShipping:'', 
            cityShipping:'', 
            postCodeShipping:'', 
            countryShipping:'',
            initial:true,
            billingId : '',
            shippingId : '',
            noteId:'',
            isClickMoreFields: false,
            isUserAccess: false
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedCustomer, isUpdate, selectedRow, objectStatusList,userAccess } = prevProps;
        var access = false
        if(selectedCustomer && selectedCustomer.id && prevState.initial && isUpdate && selectedCustomer.id === selectedRow.id){
            
            var note = {}

            if(selectedCustomer.notes !==0 ){
                note = selectedCustomer.notes[0]
            }
            if(userAccess && userAccess.modifyCustomer){
                access = true
            }
            return {
                titleId:selectedCustomer.titleId,
                firstName:selectedCustomer.firstName,
                middleName:selectedCustomer.middleName,
                lastName:selectedCustomer.lastName,
                email:selectedCustomer.emailAddress,
                companyName:selectedCustomer.businessName,
                phone:selectedCustomer.fixedPhone,
                mobile:selectedCustomer.mobilePhone,
                fax:selectedCustomer.fax,
                displayName:selectedCustomer.displayName ? selectedCustomer.displayName :'',
                customField1:selectedCustomer.customField1,
                customField2:selectedCustomer.customField2,
                notes:note ? note.noteName : '',
                noteId:note ? note.id : '',
                status:selectedCustomer.objectStatus,
                streetNameBilling:selectedCustomer.billStreetName,
                streetNoBilling:selectedCustomer.billStreetNumber,
                stateBilling:selectedCustomer.billRegion, 
                cityBilling:selectedCustomer.billCity, 
                postCodeBilling:selectedCustomer.billPostalCode, 
                countryBilling:selectedCustomer.billCountry && selectedCustomer.billCountry.code ? selectedCustomer.billCountry.code :'',
                streetNameShipping:selectedCustomer.shpStreetName,
                streetNoShipping:selectedCustomer.shpStreetNumber,
                stateShipping:selectedCustomer.shpRegion, 
                cityShipping:selectedCustomer.shpCity, 
                postCodeShipping:selectedCustomer.shpPostalCode, 
                countryShipping:selectedCustomer.shpCountry && selectedCustomer.shpCountry.code ? selectedCustomer.shpCountry.code :'',
                initial:false,
                isUserAccess: access
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && !isUpdate && prevState.initial){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            if(userAccess && userAccess.createCustomer){
                access = true
            }
            return {
                status:defaultStatus[0].id,
                initial:false,
                isUserAccess: access
            }
        }
        return null
    }

    handleChange = (e) => {
        var display = this.state.displayName
        if (e.target.id === "firstName" ){
            display =  e.target.value +" "+ this.state.lastName
        }

        if (e.target.id === "lastName" ){
            display = this.state.firstName +" "+ e.target.value 
        }

        if (e.target.id === "displayName" ){
            display =  e.target.value 
        } 

        if (e.target.id === "companyName" && this.state.firstName === "" && this.state.lastName === ""){
            display =  e.target.value 
        }

        this.setState({
            [e.target.id]: e.target.value,
            displayName:display
        })
    }

    mobileNumChange = (e) => {
        this.setState({
            mobile:e
        })
    }

    handleChangeStatus = (value) =>{
        this.setState({status:value})
      }

    handleChangeTitle = (value) =>{
        this.setState({titleId:value})
    }

    handleChangeBillingCountry  = (value) =>{
        this.setState({countryBilling:value})
    }

    handleChangeShippingCountry  = (value) =>{
        this.setState({countryShipping:value})
    }

    handleCheck = (e) => {
        // console.log(e.target.checked)
        if(e.target.checked){
            this.setState({
                streetNoShipping:this.state.streetNoBilling,
                streetNameShipping:this.state.streetNameBilling,
                cityShipping:this.state.cityBilling,
                postCodeShipping:this.state.postCodeBilling,
                stateShipping:this.state.stateBilling,
                countryShipping: this.state.countryBilling
            })
        }else{
            this.setState({
                streetNoShipping:'',
                streetNameShipping:'',
                cityShipping:'',
                postCodeShipping:'',
                stateShipping:'',
                countryShipping: ''
            })
        }
    }

    handleCheckMoreFields = (e) => {
        this.setState({
            isClickMoreFields: e.target.checked
        })
    }
    
  
    render() {
        const {
            isAddNewCustomerModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            selectedRow,
            selectedCustomer,
            isUpdate,
            titleList,
            objectStatusList,
            countryList
        } = this.props;

        const { isClickMoreFields, isUserAccess } = this.state;

        return (
            <div>
                {((isUpdate && isUpdate && selectedCustomer.id === selectedRow.id) || !isUpdate) &&<Modal
                    show ={isAddNewCustomerModalOpen} 
                    onHide={handleModalClose}
                    title={"Customer Information" + (isUpdate && selectedRow && selectedRow.id ? " - "+selectedRow.displayName : '')}
                    width='60%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form >
                                <div className="row">
                                    <div className="col-sm-2">
                                        <SelectField
                                            text="Title"
                                            controlId="titleId"
                                            onChange={this.handleChangeTitle}
                                            options={titleList}
                                            selectedValue={this.state.titleId}
                                            disabled = {!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <TextField
                                            text="First Name"
                                            controlId="firstName"
                                            // placeholder="First Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.firstName}
                                            maxLength={common.maxLengths.customerFirstName}
                                            disabled = {!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <TextField
                                            text="Last Name"
                                            controlId="lastName"
                                            // placeholder="Last Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.lastName}
                                            maxLength={common.maxLengths.customerLastName}
                                            disabled = {!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm-5">
                                        <TextField
                                            text="Email"
                                            controlId="email"
                                            // placeholder="Email"
                                            type="email"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.email}
                                            maxLength={common.maxLengths.customerEmail}
                                            disabled = {!isUserAccess}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <TextField
                                            text="Display Name"
                                            controlId="displayName"
                                            // placeholder="Display Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.displayName}
                                            maxLength={common.maxLengths.customerDisplayName}
                                            required
                                            disabled = {!isUserAccess}
                                            // disabled={isUpdate}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <TextField
                                            text="Company Name"
                                            controlId="companyName"
                                            // placeholder="Company Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.companyName}
                                            maxLength={common.maxLengths.customerBusinessName}
                                            disabled = {!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        {/* <TextField
                                            text="Mobile"
                                            controlId="mobile"
                                            // placeholder="Mobile"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.mobile}
                                            maxLength={common.maxLengths.customerMobile}
                                        /> */}
                                        <PhoneNumberField
                                            controlId="mobile"
                                            text="Mobile Number"
                                            type="text"
                                            // onBlur={this.mobileNumChange}
                                            hint="Please enter valid mobile number (eg:- +xxxxxxxxxx)"
                                            // required={this.state.isMobileValidate}
                                            onChange={this.mobileNumChange}
                                            maxLength={common.maxLengths.customerMobile}
                                            value={this.state.mobile}
                                            disabled = {!isUserAccess}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{display:'flex', justifyContent:'flex-end'}}>
                                    <div className="col-sm">
                                        <Form.Group controlId="addressCheckBox">
                                            <Form.Check type="checkbox" style={{fontSize:"14px"}} label="Add more information" onChange={this.handleCheckMoreFields}/>
                                        </Form.Group>
                                    </div>
                                </div>
                                { isClickMoreFields ? 
                                <div className="row">
                                    <div className="col-sm-2">
                                        <TextField
                                            text="Phone"
                                            controlId="phone"
                                            // placeholder="Phone"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.phone}
                                            maxLength={common.maxLengths.customerPhone}
                                            disabled = {!isClickMoreFields || !isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <TextField
                                            text="Fax"
                                            controlId="fax"
                                            // placeholder="Fax"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.fax}
                                            maxLength={common.maxLengths.customerFax}
                                            disabled = {!isClickMoreFields || !isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <TextField
                                            text="Custom Field 1"
                                            controlId="customField1"
                                            // placeholder="Custom Field 1"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.customField1}
                                            maxLength={common.maxLengths.customerCustomField1}
                                            disabled = {!isClickMoreFields || !isUserAccess}
                                        />
                                        
                                    </div>
                                    <div className="col-sm">
                                        <div className="row">
                                            <div className="col-sm">
                                                <TextField
                                                    text="Custom Field 2"
                                                    controlId="customField2"
                                                    // placeholder="Custom Field 2"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    defaultValue={this.state.customField2}
                                                    maxLength={common.maxLengths.customerCustomField2}
                                                    disabled = {!isClickMoreFields || !isUserAccess}
                                                />
                                            </div>
                                            <div className="col-sm">
                                                <SelectField
                                                    text="Status"
                                                    controlId="status"
                                                    onChange={this.handleChangeStatus}
                                                    options={objectStatusList}
                                                    selectedValue={this.state.status}
                                                    required
                                                    disabled = { !isUserAccess}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {isClickMoreFields ? 
                                <div style={{marginTop:'20px'}}>
                                    <Tabs defaultActiveKey="address" id="masterDataMappingTab" style={{fontSize:'14px'}}>
                                        <Tab 
                                            eventKey="address" 
                                            title={<span><ui5-icon class="samples-margin" name="addresses" id="tab-icon"></ui5-icon> Address</span>}>
                                            <Address
                                                data={this.state}
                                                handleChange={this.handleChange}
                                                countryList={countryList}
                                                handleCheck={this.handleCheck}
                                                handleChangeBillingCountry={this.handleChangeBillingCountry}
                                                handleChangeShippingCountry={this.handleChangeShippingCountry}
                                                isClickMoreFields={isClickMoreFields}
                                                isUserAccess={isUserAccess}
                                            />
                                        </Tab>
                                        <Tab 
                                            eventKey="notes" 
                                            title={<span><ui5-icon class="samples-margin" name="notes" id="tab-icon"></ui5-icon> Notes</span>}>
                                            <div style={{margin:'0 5px'}}>
                                                <TextField
                                                    text="Notes"
                                                    as="textarea"
                                                    row={50}
                                                    controlId="notes"
                                                    defaultValue={this.state.notes}
                                                    style={{ height: 200 }}
                                                    onChange={this.handleChange}
                                                    disabled = {!isClickMoreFields || !isUserAccess}
                                                    // placeholder="Notes"
                                                />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div> : ''}
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                                secondaryBtnDisabled={ !isUserAccess}
                            />
                        </div>
                    }
                />}
        </div>
        )
    }
}

AddCustomer.propTypes = {
    isAddNewCustomerModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedCustomer: PropTypes.any,
    isUpdate: PropTypes.bool
}

export default AddCustomer;