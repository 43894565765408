import { fromJS } from 'immutable';
import {
  LOAD_INVOICE_EMAIL_SUCCESS,
  LOAD_INVOICE_LIST_ERROR,
  LOAD_INVOICE_LIST_LOADING,
  LOAD_INVOICE_LIST_SUCCESS,
  LOAD_INVOICE_PRINT_SUCCESS,
  LOAD_INVOICE_TIMESHEET_DETAILS_SUCCESS,
  LOAD_PAYMENT_METHOD_SUCCESS,
  LOAD_PAYMENT_TERM_SUCCESS,
  LOAD_SELECTED_INVOICE_SUCCESS,
  SAVE_IS_EMAIL_EDITOR_OPEN_STATUS,
  SAVE_IS_INVOICE_MODAL_OPEN_STATUS,
  SEND_INVOICE_SUCCESS,
} from '../constant/invoiceConstant';

export const initialState = fromJS({
  isAddNewInvoiceModalOpen: false,
  invoiceList: [],
  isInvoiceListLoading: false,
  invoiceListError: '',
  invoiceSuccess: '',
  invoicePrint: '',
  selectedInvoice: {},
  paymentTermList: [],
  invoiceEmail: null,
  isEmailEditorOpen: false,
  paymentMethodList: [],
  invoiceTimesheetDetails: []
});

function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_INVOICE_MODAL_OPEN_STATUS:
      return state.set('isAddNewInvoiceModalOpen', action.open)
    case LOAD_INVOICE_LIST_SUCCESS:
      return state.set('invoiceList', action.payload)
    case LOAD_INVOICE_LIST_LOADING:
      return state.set('isInvoiceListLoading', action.payload)
    case LOAD_INVOICE_LIST_ERROR:
      return state.set('invoiceListError', action.payload)
    case SEND_INVOICE_SUCCESS:
      return state.set('invoiceSuccess', action.payload)
    case LOAD_INVOICE_PRINT_SUCCESS:
      return state.set('invoicePrint', action.payload)
    case LOAD_SELECTED_INVOICE_SUCCESS:
      return state.set('selectedInvoice', action.payload)
    case LOAD_PAYMENT_TERM_SUCCESS:
      return state.set('paymentTermList', action.payload)
    case LOAD_INVOICE_EMAIL_SUCCESS:
      return state.set('invoiceEmail', action.payload)
    case SAVE_IS_EMAIL_EDITOR_OPEN_STATUS:
      return state.set('isEmailEditorOpen', action.payload)
    case LOAD_PAYMENT_METHOD_SUCCESS:
      return state.set('paymentMethodList', action.payload)
    case LOAD_INVOICE_TIMESHEET_DETAILS_SUCCESS:
      return state.set('invoiceTimesheetDetails', action.payload)
    default:
      return state;
  }
}

export default invoiceReducer;
